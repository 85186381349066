import { Component, OnInit } from '@angular/core';
import { RechargeService } from '../../services/recharge.service';
import { ProductMasterTableData, UpdateProductMaster } from '../../model/product-master.model';
import { error } from 'console';
import { CommonComponent } from 'src/app/theme/shared/common/common.component';
import 'sweetalert2/src/sweetalert2.scss';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-product-master',
  templateUrl: './product-master.component.html',
  styleUrls: ['./product-master.component.scss']
})
export class ProductMasterComponent extends CommonComponent implements OnInit {
  productMasterTableData: ProductMasterTableData[] = [];
  isAdmin: boolean = false;
  isDataAvailable: boolean;
  gridView: boolean = false;
  fileName = 'ExcelSheet.xlsx';
  loading: boolean = true;
  columns = [
    'Service Type',
    'Service Name',
    'Is Active',
    'Commission Percentage',
    'Action'
  ];
  noData: boolean;

  constructor(
    private rechargeService: RechargeService
  ) {
    super();
  }

  ngOnInit(): void {
    this.rechargeService.getProductsData().subscribe((val)=>{
      if(val){
        this.productMasterTableData = val.data;
        this.noData = this.productMasterTableData?.length === 0;
        this.isDataAvailable = this.productMasterTableData?.length > 0;
        this.gridView = true;
      }
      this.loading = false;
    })
  }

  commissionPercentageChange(value: number,data: ProductMasterTableData){
    this.productMasterTableData.forEach(element => {
      if(element.id == data.id){
        element.commissionPercentage = value;
      }
    });
  }

  updateProductMaster(data: ProductMasterTableData){
    const updateProductMasterDetails = new UpdateProductMaster();
    updateProductMasterDetails.commissionPercentage = data.commissionPercentage;
    updateProductMasterDetails.isActive = data.isActive;
    updateProductMasterDetails.rechargeType = data.rechargeType;
    updateProductMasterDetails.serviceProvider = data.serviceProvider;
    updateProductMasterDetails.id = data.id;
    if(data.commissionPercentage){
      Swal.fire({
        title: 'Are you sure?',
        text: 'you want to update the commission percentage ?',
        type: 'warning',
        showCloseButton: true,
        showCancelButton: true,
      }).then((willDelete) => {
        if (willDelete.dismiss) {
          Swal.fire('', 'Commission Percentage Update Cancelled', 'error');
        } else {
          this.rechargeService.postSetRechargeCommissionAndStatus(updateProductMasterDetails).subscribe((val)=>{
            if(!val.errorCode){
              this.successSwal('Commission Percentage Updated Successfully');
            }
          },error =>{
            this.dangerSwal("Something went wrong please try again after sometime");
          });
        }
      })
    }else{
      this.warningSwal('Please Enter Commission Percentage and Try Again !');
    }
  }

  changeIsActiveStatus(data: ProductMasterTableData){
    this.productMasterTableData.forEach(element => {
      if(element.id == data.id){
        if(data.isActive==true){
          element.isActive = false;
        }else{
          element.isActive = true;
        }
      }
    });
  }

  isNumberKey(evt) {
    var charCode = evt.charCode;
    // Allow numbers (0-9), backspace (8), and dot (46) if not already present
    if ((charCode >= 48 && charCode <= 57) || charCode === 46) {
        return true;
    }
    return false;
}
}
