import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { KeyValue, PushRequest, Type } from '../../model/recharge.model';
import { RechargeService } from '../../services/recharge.service';
import { CommonComponent } from 'src/app/theme/shared/common/common.component';

@Component({
  selector: 'app-recharge',
  templateUrl: './recharge.component.html',
  styleUrls: ['./recharge.component.scss']
})
export class RechargeComponent extends CommonComponent implements OnInit {
  rechargeRequest: FormGroup;
  allConnectionTypeData: Type[] = [];
  allServiceProviderData: KeyValue[] = [];
  loading: boolean = false;

  constructor(
    private rechargeService: RechargeService,
    private fb: FormBuilder
  ) {
    super();
    // Initialize form with empty string instead of null
    this.rechargeRequest = this.fb.group({
      connectionType: new FormControl('', [Validators.required]),
      serviceProvider: new FormControl('', [Validators.required]),
      mobileNo: new FormControl('', [Validators.required]),
      amount: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit(): void {
    // Fetch connection types
    this.rechargeService.getConnectionTypeData().subscribe((val) => {
      if (val) {
        this.allConnectionTypeData = val.data;
      }
    });

    // Fetch service provider data when connectionType changes
    this.rechargeRequest.controls['connectionType'].valueChanges.subscribe((val) => {
      if (val) {
        this.rechargeService.getServiceProviderData(val).subscribe((response) => {
          if (response) {
            this.allServiceProviderData = response.data.sort((a, b) => a.name.localeCompare(b.name)); // Sort alphabetically
            this.rechargeRequest.controls['serviceProvider'].setValue(''); // Reset service provider
          }
        });
      }
    });
  }

  submitRecharge() {
    if (!this.rechargeRequest.valid) {
      this.warningSwal("Please fill all required fields.");
      return;
    }

    const pushRequest = new PushRequest();
    pushRequest.connectionType = this.rechargeRequest.controls['connectionType'].value;
    pushRequest.serviceProvider = this.rechargeRequest.controls['serviceProvider'].value; // Already stored as ID
    pushRequest.phNo = this.rechargeRequest.controls['mobileNo'].value;
    pushRequest.amount = this.rechargeRequest.controls['amount'].value;
    pushRequest.productId = this.getServiceProviderIdByName(pushRequest.serviceProvider);

    console.log(pushRequest);

    this.loading = true;
    let flag = false;

    this.rechargeService.postRechargePushRequest(pushRequest).subscribe(
      (val) => {
        if (val.message === "Timedout") {
          this.warningSwal("Your recharge failed due to timeout");
          this.loading = false;
          this.cancel();
          flag = true;
          this.loading = false;
        }
        else if (val.message === "TransactionFailed" ) {
          this.warningSwal("Your recharge failed. Please try again later.");
          this.loading = false;
          this.cancel();
          flag = true;
          this.loading = false;
        }
        else if (!val.data) {
          this.warningSwal("Unknown error occured.");
          this.loading = false;
          this.cancel();
          flag = true;
          this.loading = false;
        }
        else if (val.data && val.data.data) {
          let msg = `${val.data.data.txn_desc}
          Transaction ID: ${val.data.data.txnid}
          Status: ${val.data.data?.txn_status}`;

          this.successSwal(msg);
          this.loading = false;
          this.cancel();
          flag = true;
          this.loading = false;
        }

        setTimeout(() => {
          if (!flag) {
            this.warningSwal("Your recharge is pending");
            this.loading = false;
            this.cancel();
          }
        }, 20000);
      },
      (error) => {
        this.dangerSwal("Something went wrong, please try again later.");
        this.loading = false;
      }
    );
  }

  cancel() {
    this.rechargeRequest.reset();
    this.allServiceProviderData = [];
  }

  getServiceProviderIdByName(name: string): string {
    const provider = this.allServiceProviderData.find(sp => sp.name === name);
    return provider ? provider.id : '';
  }
  
}
