import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ItemMasterService } from 'src/app/mlm/inventory/services/item-master.service';
import { AddToCart, ItemsDetails, SearchItems } from '../../../purchase/model/purchase.model';
import { PurchaseService } from '../../../purchase/services/purchase.service';
import { MembersService } from 'src/app/mlm/member/services/members.service';
import { DetailsDisplayId } from 'src/app/mlm/member/model/details-displayid.model';
import { CommonComponent } from 'src/app/theme/shared/common/common.component';

@Component({
  selector: 'app-item-details',
  templateUrl: './item-details.component.html',
  styleUrls: ['./item-details.component.scss']
})
export class ItemDetailsComponent extends CommonComponent implements OnInit {
  public viewImage: number;
  itemId: string;
  allItemsDetailsData: ItemsDetails[] = [];
  itemDetails = new ItemsDetails();
  qtyGroup: FormGroup;
  itemImages: any[];
  displayId: string;
  displayIdInformations: DetailsDisplayId;

  constructor(
    private router:Router,
    private purchaseService: PurchaseService,
    public fb: FormBuilder,
    private itemMasterService: ItemMasterService,
    private memberService: MembersService
  ){
    super();
    this.qtyGroup = this.fb.group({
      qty: new FormControl('', [Validators.required])
    });
  }

  ngOnInit(): void {
    this.itemId = this.router.url.split('/')?.[6];
    this.displayId = this.router.url.split('/')?.[4];

    this.memberService.getverifyMemberInfoDisplayId(this.displayId).subscribe((val)=>{
      if(val){
        this.displayIdInformations = val.data;
        console.log(this.displayIdInformations);
      }
    })

    console.log(this,this.itemId)
    const search = new SearchItems();
    search.catId = '';
    search.subCatId = '';
    search.prodId = '';
    search.isActive = null;
    this.purchaseService.getAllItemsData(search).subscribe((val) => {
      if (val) {
        this.allItemsDetailsData = val.data;
        for(let i=0;i<this.allItemsDetailsData.length;i++){
          if (this.allItemsDetailsData[i].itemId === this.itemId) {
            this.itemDetails.itemId = this.allItemsDetailsData[i].itemId;
            this.itemDetails.itemName = this.allItemsDetailsData[i].itemName;
            this.itemDetails.bv = this.allItemsDetailsData[i].bv;
            this.itemDetails.catId = this.allItemsDetailsData[i].catId;
            this.itemDetails.catName = this.allItemsDetailsData[i].catName;
            this.itemDetails.subCatId = this.allItemsDetailsData[i].subCatId;
            this.itemDetails.subCatName = this.allItemsDetailsData[i].subCatName;
            this.itemDetails.distributorPrice = this.allItemsDetailsData[i].distributorPrice;
            this.itemDetails.hsn = this.allItemsDetailsData[i].hsn;
            this.itemDetails.itemDesc = this.allItemsDetailsData[i].itemDesc;
            this.itemDetails.mrp = this.allItemsDetailsData[i].mrp;
            this.itemDetails.prodDesc = this.allItemsDetailsData[i].prodDesc;
            this.itemDetails.pv = this.allItemsDetailsData[i].pv;
            this.itemDetails.prodId = this.allItemsDetailsData[i].prodId;
            this.itemDetails.prodName = this.allItemsDetailsData[i].prodName;
            this.itemDetails.serial = this.allItemsDetailsData[i].serial;
            this.itemDetails.active = this.allItemsDetailsData[i].active;
          }
        }
      }
    });

    this.itemImages = [];
      this.itemMasterService.getItemImageUploadData(this.itemId).subscribe((val)=>{
        if(val){
          this.viewImage = val.data[0].id;
          val.data.forEach((element: any) => {
            if (element.imagetype == "details") {
              this.itemImages.push(element);
            }
          })
        }
      })
  }

  submitQty(itemDetails: ItemsDetails){
    let qtyValue: number;
    if(this.qtyGroup.controls['qty'].value === null){
      qtyValue = this.qtyGroup.controls['qty'].value;
    }
    qtyValue = this.qtyGroup.controls['qty'].value;
    const addToCart = new AddToCart();
    addToCart.displayId = parseInt(this.displayId);
    addToCart.bv = itemDetails.bv;
    addToCart.itemId = itemDetails.itemId;
    addToCart.qty = qtyValue;

    console.log(addToCart);
    this.purchaseService.postAddToCartData(addToCart).subscribe((val)=>{
      if(val){
        this.successSwal("Product added to cart");
      }
    })
  }

  buyNow(){
    this.purchaseService.getAllCartData(this.displayId).subscribe((val)=>{
      if(val.data.length>0){
        setTimeout(() => {
          this.router.navigateByUrl("/home/franchise-purchase/cart/" + this.displayId);
        }, 1000);
      }else{
        this.warningSwal("Your Cart Is Empty");
      }
    });
  }
}
