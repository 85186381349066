<div class="col-sm-12">
    <app-card [hidHeader]="true">
        <div class="row align-items-center m-l-0">
            <div class="col-sm-6 text-left">
                <h5>BI Module Search</h5>
            </div>
        </div>
        <form [formGroup]="displayIdForm" class="form">
            <div class="app-modal-body" class="col-sm-8 text-left">
                <!-- <div class="row"> -->
                <div>
                    <div class="form-group">
                        <label class="d-block">Display Id</label>
                        <input type="text" class="form-control" formControlName="displayId" placeholder="Display Id">
                    </div>
                    <button type="submit" class="btn btn-primary" (click)="submitSearch()">Search</button>
                    <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="reset()">Cancel</button>
                </div>
                <!-- </div> -->
            </div>
        </form>
        <br>
        <ng-container *ngIf="!loading; then thenTemplate; else elseTemplate"></ng-container>
        <ng-template #thenTemplate>
            <ng-container *ngIf="isDataAvailable; else elseTemplate">
                <div class="row mb-n4" *ngIf="members">
                    <div class="col-md-12 col-xl-3">
                        <app-card [hidHeader]="true" cardClass="bg-c-yellow borderless">
                            <div class="row">
                                <div class="col-8">
                                    <h4>{{membersData[0]?.totalMember.toString() }}</h4>
                                    <div class=" col-12">
                                        <div class="row">
                                            <h4>(</h4>
                                            <h4 style="color: green;">
                                                {{membersData[0]?.totalActiveMember.toString() }}
                                            </h4>
                                            <h4>/</h4>
                                            <h4 style="color: red;">
                                                {{membersData[0]?.totalInactiveMember.toString()}}
                                            </h4>
                                            <h4>
                                                )
                                            </h4>
                                        </div>
                                    </div>
                                    <h6 class="text-c-black" style="font-weight: bold;">Total Members</h6>
                                </div>
                                <div class="col-4 text-right">
                                    <i class="feather icon-users f-28"></i>
                                </div>
                            </div>
                        </app-card>
                    </div>
                    <div class="col-md-12 col-xl-3">
                        <app-card [hidHeader]="true" cardClass="bg-c-green borderless">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h4>{{membersData[1]?.totalMember.toString() }}</h4>
                                    <div class=" col-12">
                                        <div class="row">
                                            <h4>(</h4>
                                            <h4 style="color: green;">
                                                {{membersData[1]?.totalActiveMember.toString() }}
                                            </h4>
                                            <h4>/</h4>
                                            <h4 style="color: red;">
                                                {{membersData[1]?.totalInactiveMember.toString()}}
                                            </h4>
                                            <h4>
                                                )
                                            </h4>
                                        </div>
                                    </div>
                                    <h6 class="text-c-black" style="font-weight: bold;">Direct Members</h6>
                                </div>
                                <div class="col-4 text-right">
                                    <i class="feather icon-users f-28"></i>
                                </div>
                            </div>
                        </app-card>
                    </div>
                    <div class="col-md-12 col-xl-3">
                        <app-card [hidHeader]="true" cardClass="bg-c-yellow borderless">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h4>{{membersData[2]?.totalMember.toString() }}</h4>
                                    <div class=" col-12">
                                        <div class="row">
                                            <h4>(</h4>
                                            <h4 style="color: green;">
                                                {{membersData[2]?.totalActiveMember.toString() }}
                                            </h4>
                                            <h4>/</h4>
                                            <h4 style="color: red;">
                                                {{membersData[2]?.totalInactiveMember.toString()}}
                                            </h4>
                                            <h4>
                                                )
                                            </h4>
                                        </div>
                                    </div>
                                    <h6 class="text-c-black" style="font-weight: bold;">Left Members</h6>
                                </div>
                                <div class="col-4 text-right">
                                    <i class="feather icon-users f-28"></i>
                                </div>
                            </div>
                        </app-card>
                    </div>
                    <div class="col-md-12 col-xl-3">
                        <app-card [hidHeader]="true" cardClass="bg-c-green borderless">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h4>{{membersData[3]?.totalMember.toString() }}</h4>
                                    <div class=" col-12">
                                        <div class="row">
                                            <h4>(</h4>
                                            <h4 style="color: green;">
                                                {{membersData[3]?.totalActiveMember.toString() }}
                                            </h4>
                                            <h4>/</h4>
                                            <h4 style="color: red;">
                                                {{membersData[3]?.totalInactiveMember.toString()}}
                                            </h4>
                                            <h4>
                                                )
                                            </h4>
                                        </div>
                                    </div>
                                    <h6 class="text-c-black" style="font-weight: bold;">Right Members</h6>
                                </div>
                                <div class="col-4 text-right">
                                    <i class="feather icon-users f-28"></i>
                                </div>
                            </div>
                        </app-card>
                    </div>
                </div>
            </ng-container>
            <ng-template #elseTemplate>
                <div class="spinner">
                    <h5>Loading...</h5>
                    <div class="bounce1"></div>
                    <div class="bounce2"></div>
                    <div class="bounce3"></div>
                </div>
            </ng-template>

        </ng-template>
        <ng-template #elseTemplate>

        </ng-template>


    </app-card>
</div>