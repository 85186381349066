import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoggerService } from 'src/app/theme/shared/log/logger.service';
import { environment } from 'src/environments/environment';
import { AbstractHttpService } from '../../../theme/shared/base/abstract-http';
import { MemberRegistration } from '../model/member-registration.model';
import { DownlineSubmit } from '../model/details-displayid.model';

@Injectable({
  providedIn: 'root'
})
export class MembersService extends AbstractHttpService {
  private _members_registration_states : string = environment.baseUrl + "api/v1/fetchallstates";
  private _membersregitrationPost : string = environment.baseUrl + "api/v1/member";
  private _verifyMemberInfoDisplayId : string = environment.baseUrl + "api/v1/verifymemberinfo";
  private _verifyMemberInfoParentId : string = environment.baseUrl + "api/v1/verifymemberinfo";
  private _verifyMemberInfoSponsorId : string = environment.baseUrl + "api/v1/verifymemberinfo";
  private _verifyMemberInfoNameDuplication : string = environment.baseUrl + "api/v1/verifyusernameduplication";
  private _verifyMemberMobileNumber : string = environment.baseUrl + "api/v1/ifmobileregistered";
  private _registrationEligibleSponsorOrg : string = environment.baseUrl + "api/v1/registrationeligiblesponsororg";
  private _activeInactive : string = environment.baseUrl + "api/v1/updatememberactive";
  private _directMember : string = environment.baseUrl + "api/v1/verifymemberinfo";
  private _downlineMemberGenerate : string = environment.baseUrl + "api/v1/displayidwisesearch";



  constructor(
    protected  http: HttpClient,
    protected  logger: LoggerService
    ) {
    super('MembersService', http, logger);
  };

  getAllStatesData(): Observable<any>{
    return this.http.get<any>(this._members_registration_states);
  }

  postRegistrationData(memberPostForm: MemberRegistration) : Observable<any>{
    return this.http.post<MemberRegistration>(this._membersregitrationPost ,memberPostForm);
  }

  getverifyMemberInfoDisplayId(displayId: any): Observable<any>{
    const url = this._verifyMemberInfoDisplayId + `?displayId=${displayId}`+`&parentId=0`+`&sponsorId=0`;
    return this.http.get<any>(url);
  }

  getverifyMemberInfoParentId(parentId: number): Observable<any>{
    const url = this._verifyMemberInfoParentId + `?displayId=0`+`&parentId=${parentId}`+`&sponsorId=0`;
    return this.http.get<any>(url);
  }

  getverifyMemberInfoSponsorId(sponsorId: number): Observable<any>{
    const url = this._verifyMemberInfoSponsorId + `?displayId=0`+`&parentId=0`+`&sponsorId=${sponsorId}`;
    return this.http.get<any>(url);
  }

  getverifyMemberInfoNameDuplication(userName: string): Observable<any>{
    const url = this._verifyMemberInfoNameDuplication + `?userName=${userName}`;
    return this.http.get<any>(url);
  }

  getverifyMemberMobileNumber(displayId: number,phoneNumber: number): Observable<any>{
    const url = this._verifyMemberMobileNumber + `?displayId=${displayId}`+`&phnNo=${phoneNumber}`;
    return this.http.get<any>(url);
  }

  getRgistrationEligibleSponsorOrg(sponsorId: number): Observable<any>{
    const url = this._registrationEligibleSponsorOrg + `?sponsorId=${sponsorId}`;
    return this.http.get<any>(url);
  }

  getDirectMember(parentId: string): Observable<any>{
    const url = this._directMember + `?displayId=0` + `&parentId=${parentId}`+ `&sponsorId=0`;
    return this.http.get<any>(url);
  }

  postActiveInactive(data: any): Observable<any>{
    return this.http.post(this._activeInactive ,data);
  }

  getDownlineMemberSearch(downlineDetails: DownlineSubmit): Observable<any>{
    const url = this._downlineMemberGenerate + `?displayId=${downlineDetails.displayId}`+`&viewUpline=${downlineDetails.viewUpline}`+`&viewDownline=${downlineDetails.viewDownline}`;
    return this.http.get<any>(url);
  }
}
