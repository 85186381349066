import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Docs } from 'src/app/mlm/dashboard/model/member.model';
import { DashboardService } from 'src/app/mlm/dashboard/services/dashboard.service';
import { ProfileService } from '../../services/profile.service';
import { CommonComponent } from 'src/app/theme/shared/common/common.component';

@Component({
  selector: 'app-kyc-update',
  templateUrl: './kyc-update.component.html',
  styleUrls: ['./kyc-update.component.scss']
})
export class KycUpdateComponent extends CommonComponent implements OnInit {
  ProfileImageGroup: FormGroup;
  AadhaarCardGroup: FormGroup;
  PanCardGroup: FormGroup;
  fileToUpload: any;
  formData: any;
  profilePicture: Docs;
  urlsProfilePicture: any;
  urlsAadhaarCard: any;
  urlsPanCard: any;
  constructor(
    private fb: FormBuilder,
    private profileService: ProfileService,
    private dashboardService: DashboardService
  ) {
    super();
    this.ProfileImageGroup = this.fb.group({
      profileImageGroup: new FormControl('', [Validators.required])
    });

    this.AadhaarCardGroup = this.fb.group({
      aadhaarCardGroup: new FormControl('', [Validators.required])
    });

    this.PanCardGroup = this.fb.group({
      panCardGroup: new FormControl('', [Validators.required])
    });
  }

  ngOnInit(): void {
    this.dashboardService.getUserDocsData().subscribe((val)=>{
      if(val){
        this.profilePicture = new Docs();
        val.data.forEach((element: Docs) => {
          if (element.doctype == "profile") {
            this.profilePicture.filelocation = element.filelocation;
          }
        })
      }
    })
  }

  submitProfileImage(){
    this.formData = new FormData();
    console.log(this.fileToUpload)
    this.formData.append('file', this.fileToUpload);
    this.formData.append('doctype', 'profile');
    this.profileService.postKycUploadData(this.formData).subscribe((val)=>{
      if(val){
        this.successSwal("Profile Picture Updated Successfully");
        window.location.reload();
      }
    })
  }

  submitAadhaarCard(){
    this.formData = new FormData();
    this.formData.append('file', this.fileToUpload);
    this.formData.append('doctype', 'other');
    this.profileService.postKycUploadData(this.formData).subscribe((val)=>{
      if(val){
        this.successSwal("File Uploaded Successfully");
        window.location.reload();
      }
    })
  }

  submitPanCard(){
    this.formData = new FormData();
    this.formData.append('file', this.fileToUpload);
    this.formData.append('doctype', 'other');
    this.profileService.postKycUploadData(this.formData).subscribe((val)=>{
      if(val){
        this.successSwal("File Uploaded Successfully");
        window.location.reload();
      }
    })
  }

  onChangeAadhaarCard(event: any){
    let file = event.target.files.item(0);
    if(event.target.files.item(0)){
      let reader = new FileReader();
        reader.onload = (e: any) => {
          this.urlsAadhaarCard= e.target.result;
        };
        reader.readAsDataURL(file);
      this.fileToUpload = event.target.files.item(0);
    }
  }

  onChangeProfileImage(event: any) {
    let file = event.target.files.item(0);
    if(event.target.files.item(0)){
      let reader = new FileReader();
        reader.onload = (e: any) => {
          this.urlsProfilePicture= e.target.result;
        };
        reader.readAsDataURL(file);
      this.fileToUpload = event.target.files.item(0);
    }
  }

  onChangePanCard(event: any){
    let file = event.target.files.item(0);
    if(event.target.files.item(0)){
      let reader = new FileReader();
        reader.onload = (e: any) => {
          this.urlsPanCard= e.target.result;
        };
        reader.readAsDataURL(file);
      this.fileToUpload = event.target.files.item(0);
    }
  }

  cancelProfileImage(){
    this.ProfileImageGroup.reset();
  }

  cancelAadhaarCard(){
    this.AadhaarCardGroup.reset();
  }

  cancelPanCard(){
    this.PanCardGroup.reset();
  }

}
