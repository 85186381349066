<div class="row">
    <div class="col-md-12 order-md-2">
        <div class="user-profile user-card mb-4">
            <div class="card-header border-0 p-0 pb-0">
                <div class="cover-img-block">
                    <img src="assets/images/profile/cover.jpeg" alt="" class="img-fluid">
                </div>
            </div>
            <div class="card-body py-0">
                <div class="user-about-block m-0">
                    <div class="row">
                        <div class="col-md-4 text-center mt-n5">
                            <div class="change-profile text-center">
                                <div class="dropdown w-auto d-inline-block" ngbDropdown placement="auto">
                                    <a class="dropdown-toggle" data-toggle="dropdown" ngbDropdownToggle aria-haspopup="true" aria-expanded="false">
                                        <div class="profile-dp">
                                            <div class="position-relative d-inline-block">
                                                <img class="img-radius img-fluid wid-100" src="assets/images/user/avatar-sample.jpg" alt="User image">
                                            </div>
                                            <div class="overlay">
                                                <span>change</span>
                                            </div>
                                        </div>
                                        <div class="certificated-badge">
                                            <i class="fas fa-certificate text-c-blue bg-icon"></i>
                                            <i class="fas fa-check front-icon text-white"></i>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <h5 class="mb-1">{{userInformations.name}}</h5>
                            <p class="mb-2 text-muted">{{userRole}}</p>
                        </div>
                        <div class="col-md-8 mt-md-4">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="clearfix"></div>
                                    <a href="mailto:demo@domain.com" class="mb-1 text-muted d-flex align-items-end text-h-primary"><i class="feather icon-mail mr-2 f-18"></i>{{userInformations.emailId}}</a>
                                    <div class="clearfix"></div>
                                    <a href="javascript:" class="mb-1 text-muted d-flex align-items-end text-h-primary"><i class="feather icon-phone mr-2 f-18"></i>{{userInformations.phNo}}</a>
                                </div>
                                <div class="col-md-6">
                                    <div class="media">
                                        <i class="feather icon-map-pin mr-2 mt-1 f-18"></i>
                                        <div class="media-body">
                                            <p class="mb-0 text-muted">{{userInformations.address}}</p>
                                            <p class="mb-0 text-muted">{{userInformations.pinCode}}</p>
                                            <p class="mb-0 text-muted">{{userInformations.stateName}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ul class="nav nav-tabs profile-tabs nav-fill" id="myTab" role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link text-reset" [ngClass]="{'active': this.activeTab === 'personalDetails'}" (click)="this.activeTab = 'personalDetails'" id="personalDetails-tab" data-toggle="tab" href="javascript:" role="tab" aria-controls="personalDetails" aria-selected="false"><i class="feather icon-user mr-2"></i>Personal Details</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link text-reset" [ngClass]="{'active': this.activeTab === 'contactDetails'}" (click)="this.activeTab = 'contactDetails'" id="contactDetails-tab" data-toggle="tab" href="javascript:" role="tab" aria-controls="contactDetails" aria-selected="false"><i class="feather icon-phone mr-2"></i>Contacts Details</a>
                                </li>
                                <!-- <li class="nav-item">
                            <a class="nav-link text-reset" [ngClass]="{'active': this.activeTab === 'otherInformations'}" (click)="this.activeTab = 'otherInformations'" id="otherInformations-tab" data-toggle="tab" href="javascript:" role="tab" aria-controls="otherInformations" aria-selected="false"><i class="feather icon-users mr-2"></i>Other Informations</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-reset" [ngClass]="{'active': this.activeTab === 'bankDetails'}" (click)="this.activeTab = 'bankDetails'" id="bankDetails-tab" data-toggle="tab" href="javascript:" role="tab" aria-controls="bankDetails" aria-selected="false"><i class="feather icon-file mr-2"></i>Bank Details</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-reset" [ngClass]="{'active': this.activeTab === 'nomineeDetails'}" (click)="this.activeTab = 'nomineeDetails'" id="nomineeDetails-tab" data-toggle="tab" href="javascript:" role="tab" aria-controls="nomineeDetails" aria-selected="false"><i class="feather icon-user mr-2"></i>Nominee Details</a>
                        </li> -->
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- profile body start -->
<div class="row">
    <div class="col-md-12 order-md-2">
        <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade" [ngClass]="{'show active': this.activeTab === 'personalDetails'}" id="personalDetails" role="tabpanel" aria-labelledby="personalDetails-tab">
                <div class="merge-card">
                    <app-card [hidHeader]="true" cardClass="mb-0" blockClass="d-flex align-items-center justify-content-between">
                        <h5 class="mb-0">Personal details</h5>
                    </app-card>
                    <app-card [hidHeader]="true" cardClass="borderless" blockClass="border-top pro-det-edit collapse show">
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label font-weight-bolder">Full Name</label>
                            <div class="col-sm-9">
                                {{userInformations.name}}
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label font-weight-bolder">Display Id</label>
                            <div class="col-sm-9">
                                {{userInformations.displayId}}
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label font-weight-bolder">Password</label>
                            <div class="col-sm-9">
                                {{userInformations.password}}
                            </div>
                        </div>
                        <!-- <div class="form-group row">
                            <label class="col-sm-3 col-form-label font-weight-bolder">Sponsor Id</label>
                            <div class="col-sm-9">
                                {{userInformations.sponsorID}}
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label font-weight-bolder">Parent Id</label>
                            <div class="col-sm-9">
                                {{userInformations.parentID}}
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label font-weight-bolder">Gender</label>
                            <div class="col-sm-9">
                                {{userInformations.gender}}
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label font-weight-bolder">Birth Date</label>
                            <div class="col-sm-9">
                                {{userInformations.dob}}
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label font-weight-bolder">Martail Status</label>
                            <div class="col-sm-9">
                                {{userInformations.maritalStatus}}
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label font-weight-bolder">Guardian</label>
                            <div class="col-sm-9">
                                {{userInformations.gurdian}}
                            </div>
                        </div> -->
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label font-weight-bolder">Location</label>
                            <div class="col-sm-9">
                                <p class="mb-0 text-muted">{{userInformations.address}}</p>
                                <p class="mb-0 text-muted">{{userInformations.pinCode}}</p>
                                <p class="mb-0 text-muted">{{userInformations.stateName}}</p>
                            </div>
                        </div>
                    </app-card>
                </div>
            </div>
            <div class="tab-pane fade" [ngClass]="{'show active': this.activeTab === 'contactDetails'}" id="contactDetails" role="tabpanel" aria-labelledby="contactDetails-tab">
                <div class="merge-card">
                    <app-card [hidHeader]="true" cardClass="mb-0" blockClass="d-flex align-items-center justify-content-between">
                        <h5 class="mb-0">Contact Information</h5>
                    </app-card>
                    <app-card [hidHeader]="true" cardClass="borderless" blockClass="border-top pro-det-edit collapse show">
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label font-weight-bolder">Mobile Number</label>
                            <div class="col-sm-9">
                                {{userInformations.phNo}}
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label font-weight-bolder">Email Address</label>
                            <div class="col-sm-9">
                                {{userInformations.emailId}}
                            </div>
                        </div>
                    </app-card>
                </div>
            </div>
            <div class="tab-pane fade" [ngClass]="{'show active': this.activeTab === 'otherInformations'}" id="otherInformations" role="tabpanel" aria-labelledby="otherInformations-tab">
                <div class="merge-card">
                    <app-card [hidHeader]="true" cardClass="mb-0" blockClass="d-flex align-items-center justify-content-between">
                        <h5 class="mb-0">Other Information</h5>
                    </app-card>
                    <app-card [hidHeader]="true" cardClass="borderless" blockClass="border-top pro-det-edit collapse show">
                        <!-- <div class="form-group row">
                            <label class="col-sm-3 col-form-label font-weight-bolder">Aadhaar No.</label>
                            <div class="col-sm-9">
                                {{userInformations.aadharNo}}
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label font-weight-bolder">Pan No.</label>
                            <div class="col-sm-9">
                                {{userInformations.panNo}}
                            </div>
                        </div> -->
                    </app-card>
                </div>
            </div>
            <div class="tab-pane fade" [ngClass]="{'show active': this.activeTab === 'bankDetails'}" id="bankDetails" role="tabpanel" aria-labelledby="bankDetails-tab">
                <div class="merge-card">
                    <app-card [hidHeader]="true" cardClass="mb-0" blockClass="d-flex align-items-center justify-content-between">
                        <h5 class="mb-0">Bank Details</h5>
                    </app-card>
                    <app-card [hidHeader]="true" cardClass="borderless" blockClass="border-top pro-det-edit collapse show">
                        <!-- <div class="form-group row">
                            <label class="col-sm-3 col-form-label font-weight-bolder">Bank Name</label>
                            <div class="col-sm-9">
                                {{userInformations.bankName}}
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label font-weight-bolder">IFSC Code</label>
                            <div class="col-sm-9">
                                {{userInformations.ifsc}}
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label font-weight-bolder">Account Holder Name</label>
                            <div class="col-sm-9">
                                {{userInformations.accountHolderName}}
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label font-weight-bolder">Account No.</label>
                            <div class="col-sm-9">
                                {{userInformations.accountNo}}
                            </div>
                        </div> -->
                    </app-card>
                </div>
            </div>
            <div class="tab-pane fade" [ngClass]="{'show active': this.activeTab === 'nomineeDetails'}" id="nomineeDetails" role="tabpanel" aria-labelledby="nomineeDetails-tab">
                <div class="merge-card">
                    <app-card [hidHeader]="true" cardClass="mb-0" blockClass="d-flex align-items-center justify-content-between">
                        <h5 class="mb-0">Nominee Details</h5>
                    </app-card>
                    <app-card [hidHeader]="true" cardClass="borderless" blockClass="border-top pro-det-edit collapse show">
                        <!-- <div class="form-group row">
                            <label class="col-sm-3 col-form-label font-weight-bolder">Nominee Name</label>
                            <div class="col-sm-9">
                                {{userInformations.nominee}}
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label font-weight-bolder">Nominee Relation</label>
                            <div class="col-sm-9">
                                {{userInformations.relation}}
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label font-weight-bolder">Nominee DOB</label>
                            <div class="col-sm-9">
                                {{userInformations.nomineeDob}}
                            </div>
                        </div> -->
                    </app-card>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- profile body end -->