<div class="row">
    <div class="col-sm-12 tab-card">
        <div class="row mb-n4">
            <div class="col-xl-4 col-md-6">
                <app-card [customHeader]="true" headerClass="border-0 p-2 pb-0" [options]="false" cardClass="user-card user-card-1" blockClass="pt-0 users-contact">
                    <div class="app-card-header">

                        <div class="cover-img-block">
                            <img src="assets/images/widget/Vedikmart_cover.jpg" alt="" class="img-fluid">
                        </div>
                    </div>
                    <div class="user-about-block text-center">
                        <div class="row align-items-end">
                            <div class="col text-left">
                                <h1 class="badge badge-success" *ngIf="userInformations?.isGreen">Green</h1>
                                <h1 class="badge badge-danger" *ngIf="!userInformations?.isGreen">Not Green</h1>
                            </div>
                            <div class="col">
                                <div class="position-relative d-inline-block">
                                    <img class="img-radius img-fluid wid-80" src="{{(profilePicture?.filelocation)?profilePicture.filelocation:'assets/images/user/avatar-sample.jpg'}}">
                                </div>
                            </div>
                            <div class="col"></div>
                        </div>
                    </div>
                    <div class="text-center">
                        <h6 class="mb-1" style="font-weight:'bold';">{{userInformations?.name}}</h6>
                        <p class="mb-0">Display ID : {{userInformations?.displayId}}</p>
                        <p class="mb-0">Sponsor ID : {{userInformations?.sponsorID}}</p>
                        <p class="mb-0">Joining Date : {{userInformations?.createdOn.substring(0,10)}}</p>
                        <!-- <p class=" mb-1">Valid Date : {{userInformations?.validDate.substring(0,10)}}</p> -->
                        <p class=" mb-2">Address : {{userInformations?.cityVillage}}</p>
                    </div>
                    <hr class="wid-80 b-wid-3">
                    <div class="row text-center buttons">
                        <div class="col ">
                            <!-- <h6 class="mb-1 ">37</h6> -->
                            <button (click)="exampleModalLong.show()" class="mb-0 btn-sm btn-success" style="color: black;">+Registration</button>
                        </div>
                        <div class="col ">
                            <!-- <h6 class="mb-1 ">2749</h6> -->
                            <button (click)="update()" class="mb-0 btn-sm btn-success" style="color: black;">Update</button>
                        </div>
                        
                    </div>
                    <div class="row text-center buttons">
                        <div class="col ">
                            <!-- <h6 class="mb-1 ">678</h6> -->
                            <button (click)="viewOffers()" class="mb-0 btn-sm btn-success" style="color: black;">View Offers</button>
                        </div>
                        <!-- <div class="col ">
                            <button (click)="printCard()" class="mb-0 btn-sm btn-success" style="color: black;">Print Card</button>
                        </div> -->
                    </div>
                    <div class="text-center wbsite" style="color: black;">
                        Website : www.vedikmart.com
                    </div>
                </app-card>
            </div>
            <div class="col-xl-4 col-md-6 ">
                <app-card [hidHeader]="true " blockClass="text-center ">
                    <div class="border border-primary">
                    <img src="assets/images/Rank/{{currentRankImg}}.png" alt=" " class="img-fluid w-50 ">
                    
                    <!-- <h4 class="mt-3 ">Rank : {{currentRank}}</h4> -->
                    <p class="mb-2 mt-3" *ngIf="directorRank; else noRank" style="font-weight: bold; color: #333; font-size: 1.2em;">{{directorRank}}</p>
                    
                    <ng-template #noRank>
                        <p class="mb-2 mt-3" style="font-weight: bold; color: #333; font-size: 1.2em;">    </p>
                    </ng-template>                 </div>
                        <br>
                    <div *ngIf="firstPurchaseRank?.rankName" class="border border-danger">
                        <img src="{{firstPurchaseRank.imgloc}}" class="me-2 " style="width: 80px; height: auto;">
                        <p class="lead">First Purchase Rank: {{firstPurchaseRank.rankName}}</p>
                    </div>
                    <!-- <p class="mb-2 " style="padding-bottom: 9px;">Current Month Self BV & Team BV</p> -->
                    <br>
                    <!-- <p><span class="badge badge-primary ">0 Sales</span> </p> -->
                    <app-card [hidHeader]="true " cardClass="flat-card " blockClass="p-0 ">
                        <div class="row-table ">
                            <div class="col-sm-6 card-body br ">
                                <div class="row ">
                                    <div class="col-sm-2 ">
                                        <!-- <i class="icon feather icon-eye text-c-green mb-1 d-block "></i> -->
                                        <i class="icon feather icon-file-text text-c-blue mb-1 d-block "></i>
                                    </div>
                                    <div class="col-sm-9 text-md-center ">
                                        <h5>{{monthlyRepurchaseStatData?.previousMonthBV}}</h5>
                                        <span>Previous Month BV</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 card-body ">
                                <div class="row ">
                                    <div class="col-sm-2 ">
                                        <!-- <i class="icon feather icon-music text-c-red mb-1 d-block "></i> -->
                                        <i class="icon feather icon-file-text text-c-blue mb-1 d-block "></i>
                                    </div>
                                    <div class="col-sm-9 text-md-center ">
                                        <h5>{{monthlyRepurchaseStatData?.currentMonthBV}}</h5>
                                        <span>Current Month BV</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row-table ">
                            <div class="col-sm-6 card-body br ">
                                <div class="row ">
                                    <div class="col-sm-2 ">
                                        <!-- <i class="icon feather icon-eye text-c-green mb-1 d-block "></i> -->
                                        <i class="icon feather icon-file-text text-c-blue mb-1 d-block "></i>
                                    </div>
                                    <div class="col-sm-9 text-md-center ">
                                        <h5>{{monthlyRepurchaseStatData?.totalLeftBV}}</h5>
                                        <span>Total Left BV</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 card-body ">
                                <div class="row ">
                                    <div class="col-sm-2 ">
                                        <!-- <i class="icon feather icon-music text-c-red mb-1 d-block "></i> -->
                                        <i class="icon feather icon-file-text text-c-blue mb-1 d-block "></i>
                                    </div>
                                    <div class="col-sm-9 text-md-center ">
                                        <h5>{{monthlyRepurchaseStatData?.totalRightBV}}</h5>
                                        <span>Total Right BV</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row-table ">
                            <div class="col-sm-6 card-body br ">
                                <div class="row ">
                                    <div class="col-sm-2 ">
                                        <i class="icon feather icon-file-text text-c-blue mb-1 d-block "></i>
                                    </div>
                                    <div class="col-sm-9 text-md-center ">
                                        <h5>{{monthlyRepurchaseStatData?.totalBV}}</h5>
                                        <span>Total BV</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 card-body ">
                                <div class="row ">
                                    <div class="col-sm-2 ">
                                        <!-- <i class="icon feather icon-mail text-c-yellow mb-1 d-block "></i> -->
                                        <i class="icon feather icon-file-text text-c-blue mb-1 d-block "></i>
                                    </div>
                                    <!-- <div class="col-sm-9 text-md-center ">
                                        <h5>{{unpaidCommissionValue}}</h5>
                                        <span>Unpaid Commission 2022</span>
                                    </div> -->
                                    <div class="col-sm-9 text-md-center ">
                                        <h5>xxx</h5>
                                        <span>XXXX</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </app-card>
                </app-card>
            </div>
            <div class="col-xl-4 col-md-6 ">
                <div class="card support-bar ">
                    <div class="card-body ">
                        <h2 class="m-0 ">{{dashboardCommissionSummary.totalCommission}}</h2>
                        <span style="font-weight:bold;">Total Income (Since January, 2023)</span>
                        <p class="mb-3 mt-3" style="font-weight:bold;">Payout Analysis : </p>
                    </div>
                    <app-apex-chart chartID="helpdesk-support-1-chart" [chartConfig]="this.chartDB.support3HACC"></app-apex-chart>
                    <div class="card-footer bg-success text-white ">
                        <div class="row text-center ">
                            <h6 class="m-0 text-white ">Last Commission : {{dashboardCommissionSummary.lastCommission}}</h6>
                        </div>
                        <div class="row text-center ">
                            <h6 class="m-0 text-white ">Total Paid : {{dashboardCommissionSummary.totalPaid}}</h6>
                        </div>
                        <div class="row text-center ">
                            <h6 class="m-0 text-white">Total Unpaid : {{dashboardCommissionSummary.totalUnpaid}}</h6>
                        </div>
                    </div>
                    <app-card [hidHeader]="true " cardClass="flat-card " blockClass="p-0 ">
                        <div class="row-table ">
                            <div class="col-sm-6 card-body br ">
                                <div class="row ">
                                    <div class="col-sm-2 ">
                                        <i class="icon feather icon-share-2 text-c-blue mb-1 d-block "></i>
                                    </div>
                                    <div class="col-sm-9 text-md-center ">
                                        <h5>{{wallet?.toFixed(2)}}</h5>
                                        <button (click)="walletAdd()" class="mb-0 btn-sm btn-success" style="color: black;">Add Wallet</button>
                                        <!-- <span (click)="walletAdd()">Add Wallet</span> -->
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 card-body ">
                                <div class="row ">
                                    <div class="col-sm-2 ">
                                        <i class="icon feather icon-wifi text-c-blue mb-1 d-block "></i>
                                    </div>
                                    <!-- <div class="col-sm-9 text-md-center ">
                                        <h5>xxx</h5>
                                        <span>Rewards Point</span>
                                    </div> -->
                                    <div class="col-sm-9 text-md-center ">
                                        <p class=" text-c-black" style=" color: black; font-size:10px; margin: 0px 0px 0px 0px;">Group Sales Income: <span style="color: blue;">{{dashboardCommissionSummary.groupSalesIncome}}</span></p>
                                        <p class="  text-c-black" style=" color: black; font-size:10px; margin: 0px 0px 0px 0px;">Sales Growth Income: <span style="color: blue;">{{dashboardCommissionSummary.salesGrowthIncome}}</span></p>
                                        <p class="  text-c-black" style=" color: black; font-size:10px; margin: 0px 0px 0px 0px;">Total Income: <span style="color: blue;">{{dashboardCommissionSummary.totalIncome}}</span></p>
                                        <span style="font-size:11px;">COMMISSION AMOUNT</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row-table ">
                            <div class="col-sm-6 card-body br ">
                                <div class="row ">
                                    <div class="col-sm-2 ">
                                        <i class="icon feather icon-rotate-ccw text-c-blue mb-1 d-block "></i>
                                    </div>
                                    <div class="col-sm-9 text-md-center ">
                                        <button (click)="todayJoiningCountModalLong.show()">{{todayBusinessNosData.todayJoiningCount}}</button><br>
                                        <span>Today's Joining</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 card-body ">
                                <div class="row ">
                                    <div class="col-sm-2 ">
                                        <i class="icon feather icon-shopping-cart text-c-blue mb-1 d-blockz "></i>
                                    </div>
                                    <div class="col-sm-9 text-md-center ">
                                        <button (click)="todayActivationCountModalLong.show()">{{todayBusinessNosData.todayActivationCount}}</button>
                                        <br>
                                        <span>Today's Activation</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </app-card>
                </div>
            </div>
        </div>
        <br>
        <div *ngIf="!isBlocked" class="row mb-n4">
            <div class="col-md-12 col-xl-3">
                <app-card [hidHeader]="true" cardClass="bg-c-yellow borderless">
                    <div class="row align-items-center">
                        <div class="col-8">
                            <h4>{{membersData[0]?.totalMember.toString() }}</h4>
                            <div class=" col-12">
                                <div class="row">
                                    <h4>(</h4>
                                    <h4 style="color: green;">
                                        {{membersData[0]?.totalActiveMember.toString() }}
                                    </h4>
                                    <h4>/</h4>
                                    <h4 style="color: red;">
                                        {{membersData[0]?.totalInactiveMember.toString()}}
                                    </h4>
                                    <h4>
                                        )
                                    </h4>
                                </div>
                            </div>
                            <h6 class="text-c-black" style="font-weight: bold;">Total Members</h6>
                        </div>
                        <div class="col-4 text-right">
                            <i class="feather icon-users f-28"></i>
                        </div>
                    </div>
                </app-card>
            </div>
            <div class="col-md-12 col-xl-3">
                <app-card [hidHeader]="true" cardClass="bg-c-green borderless">
                    <div class="row align-items-center">
                        <div class="col-8">
                            <h4>{{membersData[1]?.totalMember.toString() }}</h4>
                            <div class=" col-12">
                                <div class="row">
                                    <h4>(</h4>
                                    <h4 style="color: green;">
                                        {{membersData[1]?.totalActiveMember.toString() }}
                                    </h4>
                                    <h4>/</h4>
                                    <h4 style="color: red;">
                                        {{membersData[1]?.totalInactiveMember.toString()}}
                                    </h4>
                                    <h4>
                                        )
                                    </h4>
                                </div>
                            </div>
                            <h6 class="text-c-black" style="font-weight: bold;">Direct Members</h6>
                        </div>
                        <div class="col-4 text-right">
                            <i class="feather icon-users f-28"></i>
                        </div>
                    </div>
                </app-card>
            </div>
            <div class="col-md-12 col-xl-3">
                <app-card [hidHeader]="true" cardClass="bg-c-yellow borderless">
                    <div class="row align-items-center">
                        <div class="col-8">
                            <h4>{{membersData[2]?.totalMember.toString() }}</h4>
                            <div class=" col-12">
                                <div class="row">
                                    <h4>(</h4>
                                    <h4 style="color: green;">
                                        {{membersData[2]?.totalActiveMember.toString() }}
                                    </h4>
                                    <h4>/</h4>
                                    <h4 style="color: red;">
                                        {{membersData[2]?.totalInactiveMember.toString()}}
                                    </h4>
                                    <h4>
                                        )
                                    </h4>
                                </div>
                            </div>
                            <h6 class="text-c-black" style="font-weight: bold;">Left Members</h6>
                        </div>
                        <div class="col-4 text-right">
                            <i class="feather icon-users f-28"></i>
                        </div>
                    </div>
                </app-card>
            </div>
            <div class="col-md-12 col-xl-3">
                <app-card [hidHeader]="true" cardClass="bg-c-green borderless">
                    <div class="row align-items-center">
                        <div class="col-8">
                            <h4>{{membersData[3]?.totalMember.toString() }}</h4>
                            <div class=" col-12">
                                <div class="row">
                                    <h4>(</h4>
                                    <h4 style="color: green;">
                                        {{membersData[3]?.totalActiveMember.toString() }}
                                    </h4>
                                    <h4>/</h4>
                                    <h4 style="color: red;">
                                        {{membersData[3]?.totalInactiveMember.toString()}}
                                    </h4>
                                    <h4>
                                        )
                                    </h4>
                                </div>
                            </div>
                            <h6 class="text-c-black" style="font-weight: bold;">Right Members</h6>
                        </div>
                        <div class="col-4 text-right">
                            <i class="feather icon-users f-28"></i>
                        </div>
                    </div>
                </app-card>
            </div>
        </div>
        <br>
        <div class="row mb-n4 ">
            <div *ngIf="!isBlocked" class="col-md-12 col-xl-6 ">
                <app-card [hidHeader]="true " cardClass="bg-c-blue order-card borderless ">
                    <h6 style="font-weight: bold; color: black;">First Purchase</h6>
                    <p class="p-t-10 m-b-0  text-c-black" style="font-weight: bold; color: black;">Self Purchase BV: <span style="color: blue;">{{purchaseStatData.selfPurchase?.toFixed(0)}}</span></p>
                    <p class="p-t-10 m-b-0  text-c-black" style="font-weight: bold; color: black;">Current Month Left Purchase BV: <span style="color: blue;">{{purchaseStatData.leftPurchase?.toFixed(0)}}</span></p>
                    <p class="p-t-10 m-b-0  text-c-black" style="font-weight: bold; color: black;">Current Month Right Purchase BV: <span style="color: blue;">{{purchaseStatData.rightPurchase?.toFixed(0)}}</span></p>
                    <p class="p-t-10 m-b-0  text-c-black" style="font-weight: bold; color: black;">Total Self BV: <span style="color: blue;">{{purchaseStatData.totalBV?.toFixed(0)}}</span></p>
                    <p class="p-t-10 m-b-0  text-c-black" style="font-weight: bold; color: black;">Today Purchase BV: <span style="color: blue;">{{dailyPurchaseStatData.todayPurchasePrice?.toFixed(0)}}</span></p>
                    <p class="p-t-10 m-b-0  text-c-black" style="font-weight: bold; color: black;">Current 15 Day Purchase BV: <span style="color: blue;">{{dailyPurchaseStatData.firstHalfPurchasePrice?.toFixed(0)}}</span></p>
                    <p class="p-t-10 m-b-0  text-c-black" style="font-weight: bold; color: black;">Last 15 Day Purchase BV: <span style="color: blue;">{{dailyPurchaseStatData.lastHalfPurchasePrice?.toFixed(0)}}</span></p>
                    <p class="p-t-10 m-b-0  text-c-black" style="font-weight: bold; color: black;">This Month Total Purchase BV: <span style="color: blue;">{{monthlyPurchaseStatData.currentMonthPurchasePrice?.toFixed(0)}}</span></p>
                    <p class="p-t-10 m-b-0  text-c-black" style="font-weight: bold; color: black;">This Month Total Team BV: <span style="color: blue;">{{monthlyPurchaseStatData.currentMonthBV?.toFixed(0)}}</span></p>
                    <p class="p-t-10 m-b-0  text-c-black" style="font-weight: bold; color: black;">Last Month Total BV: <span style="color: blue;">{{monthlyPurchaseStatData.previousMonthBV?.toFixed(0)}}</span></p>
                    <i class="card-icon feather icon-users "></i>
                </app-card>
            </div>
            <div *ngIf="!isBlocked" class="col-md-12 col-xl-6 ">
                <app-card [hidHeader]="true " cardClass="bg-c-green order-card borderless ">
                    <h6 style="font-weight: bold; color: black;">Repurchase</h6>
                    <p class="p-t-10 m-b-0  text-c-black" style="font-weight: bold; color: black;">Self Purchase BV: <span style="color: blue;">{{repurchaseStatData.selfPurchase?.toFixed(0)}}</span> </p>
                    <p class="p-t-10 m-b-0  text-c-black" style="font-weight: bold; color: black;">Current Month Left BV: <span style="color: blue;">{{repurchaseStatData.leftPurchase?.toFixed(0)}}</span></p>
                    <p class="p-t-10 m-b-0  text-c-black" style="font-weight: bold; color: black;">Current Month Right BV: <span style="color: blue;">{{repurchaseStatData.rightPurchase?.toFixed(0)}}</span></p>
                    <p class="p-t-10 m-b-0  text-c-black" style="font-weight: bold; color: black;">Total Self BV: <span style="color: blue;">{{repurchaseStatData.totalBV?.toFixed(0)}}</span></p>
                    <p class="p-t-10 m-b-0  text-c-black" style="font-weight: bold; color: black;">Today Purchase: <span style="color: blue;">{{dailyRepurchaseStatData.todayPurchasePrice?.toFixed(0)}}</span></p>
                    <p class="p-t-10 m-b-0  text-c-black" style="font-weight: bold; color: black;">Current 15 Day Purchase: <span style="color: blue;">{{dailyRepurchaseStatData.firstHalfPurchasePrice?.toFixed(0)}}</span></p>
                    <p class="p-t-10 m-b-0  text-c-black" style="font-weight: bold; color: black;">Last 15 Day Purchase: <span style="color: blue;">{{dailyRepurchaseStatData.lastHalfPurchasePrice?.toFixed(0)}}</span></p>
                    <p class="p-t-10 m-b-0  text-c-black" style="font-weight: bold; color: black;">This Month Total Purchase: <span style="color: blue;">{{monthlyRepurchaseStatData.currentMonthPurchasePrice?.toFixed(0)}}</span></p>
                    <p class="p-t-10 m-b-0  text-c-black" style="font-weight: bold; color: black;">This Month Total Team BV: <span style="color: blue;">{{monthlyRepurchaseStatData.currentMonthBV?.toFixed(0)}}</span></p>
                    <p class="p-t-10 m-b-0  text-c-black" style="font-weight: bold; color: black;">Last Month Total BV: <span style="color: blue;">{{monthlyRepurchaseStatData.previousMonthBV?.toFixed(0)}}</span></p>
                    <i class="card-icon feather icon-radio "></i>
                </app-card>
            </div>
        </div>
        <br>
        <div class="row mb-n4 ">
            <!-- order start -->
            <div class="col-md-12 col-xl-4 ">
                <!-- <app-card [hidHeader]="true " cardClass="bg-c-yellow order-card borderless " *ngIf="members ">
                    <h6 class="text-white ">Members</h6>
                    <h2 class="text-white ">{{membersData[0].totalMember}}</h2>
                    <div class="row m-b-0 ">
                        <p class="m-l-15 m-r-5 m-b-0 ">Total {{membersData[0].totalMember}}({{membersData[0].totalActiveMember}}/{{membersData[0].totalInactiveMember}}) |</p>
                        <p class="m-r-5 m-b-0 ">Direct {{membersData[1].totalMember}}({{membersData[1].totalActiveMember}}/{{membersData[1].totalInactiveMember}}) |</p>
                        <p class="m-r-5 m-b-0 ">Left {{membersData[2].totalMember}}({{membersData[2].totalActiveMember}}/{{membersData[2].totalInactiveMember}}) |</p>
                        <p class="m-r-5 m-b-0 ">Right {{membersData[3].totalMember}}({{membersData[3].totalActiveMember}}/{{membersData[3].totalInactiveMember}})</p>
                    </div>
                    <i class="card-icon feather icon-filter "></i>
                </app-card> -->
                <app-card [hidHeader]="true " cardClass="widget-statstic-card card-border-c-yellow ">
                    <div class="card-header-left mb-3 ">
                        <h5 class="mb-0 ">First Purchase Commission</h5>
                        <p class="p-t-10 m-b-0 text-c-yellow">xxxxx</p>
                        <ul class="nav nav-tabs profile-tabs nav-fill" id="myTab" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link text-reset" [ngClass]="{'active': this.activeTab === 'thisMonth'}" (click)="this.activeTab = 'thisMonth'" id="thisMonth-tab" data-toggle="tab" href="javascript:" role="tab" aria-controls="thisMonth" aria-selected="false">This Month</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link text-reset" [ngClass]="{'active': this.activeTab === 'previousMonth'}" (click)="this.activeTab = 'previousMonth'" id="previousMonth-tab" data-toggle="tab" href="javascript:" role="tab" aria-controls="previousMonth" aria-selected="false">Previous Month</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link text-reset" [ngClass]="{'active': this.activeTab === 'totalCommission'}" (click)="this.activeTab = 'totalCommission'" id="totalCommission-tab" data-toggle="tab" href="javascript:" role="tab" aria-controls="totalCommission" aria-selected="false">Total Commission</a>
                            </li>
                        </ul>
                        <div class="tab-content" id="myTabContent">
                            <div class="tab-pane fade" [ngClass]="{'show active': this.activeTab === 'thisMonth'}" id="thisMonth" role="tabpanel" aria-labelledby="thisMonth-tab">
                                <div class="merge-card">
                                    <app-card [hidHeader]="true" cardClass="borderless">
                                        <p class="m-b-0  text-c-black" style="font-weight: bold; color: black;">Group Sales Income: <span style="color: blue;">{{firstPurchaseCommissionData?.groupSalesIncome}}</span></p>
                                        <p class="p-t-10 m-b-0  text-c-black" style="font-weight: bold; color: black;">Sales Growth Income: <span style="color: blue;">{{firstPurchaseCommissionData?.salesGrowthIncome}}</span></p>
                                        <p class="p-t-10 m-b-0  text-c-black" style="font-weight: bold; color: black;">Royalty Fund Achievers: <span style="color: blue;">{{firstPurchaseCommissionData?.royaltyIncome}}</span></p>
                                        <p class="p-t-10 m-b-0  text-c-black" style="font-weight: bold; color: black;">Travel Fund Achievers: <span style="color: blue;">{{firstPurchaseCommissionData?.travelIncome}}</span></p>
                                        <p class="p-t-10 m-b-0  text-c-black" style="font-weight: bold; color: black;">Car Fund Achievers: <span style="color: blue;">{{firstPurchaseCommissionData?.carIncome}}</span></p>
                                        <p class="p-t-10 m-b-0  text-c-black" style="font-weight: bold; color: black;">House Fund Achievers: <span style="color: blue;">{{firstPurchaseCommissionData?.houseIncome}}</span></p>
                                        <p class="p-t-10 m-b-0  text-c-black" style="font-weight: bold; color: black;">Sales Incentives: </p>
                                        <p class="p-t-10 m-b-0  text-c-black" style="font-weight: bold; color: black;">Others: </p>
                                    </app-card>
                                </div>
                            </div>
                            <div class="tab-pane fade" [ngClass]="{'show active': this.activeTab === 'previousMonth'}" id="previousMonth" role="tabpanel" aria-labelledby="previousMonth-tab">
                                <div class="merge-card">
                                    <app-card [hidHeader]="true" cardClass="borderless">
                                        <p class="m-b-0  text-c-black" style="font-weight: bold; color: black;">Group Sales Income: <span style="color: blue;">{{firstPurchaseCommissionData?.groupSalesIncome}}</span></p>
                                        <p class="p-t-10 m-b-0  text-c-black" style="font-weight: bold; color: black;">Sales Growth Income: <span style="color: blue;">{{firstPurchaseCommissionData?.salesGrowthIncome}}</span></p>
                                        <p class="p-t-10 m-b-0  text-c-black" style="font-weight: bold; color: black;">Royalty Fund Achievers: <span style="color: blue;">{{firstPurchaseCommissionData?.royaltyIncome}}</span></p>
                                        <p class="p-t-10 m-b-0  text-c-black" style="font-weight: bold; color: black;">Travel Fund Achievers: <span style="color: blue;">{{firstPurchaseCommissionData?.travelIncome}}</span></p>
                                        <p class="p-t-10 m-b-0  text-c-black" style="font-weight: bold; color: black;">Car Fund Achievers: <span style="color: blue;">{{firstPurchaseCommissionData?.carIncome}}</span></p>
                                        <p class="p-t-10 m-b-0  text-c-black" style="font-weight: bold; color: black;">House Fund Achievers: <span style="color: blue;">{{firstPurchaseCommissionData?.houseIncome}}</span></p>
                                        <p class="p-t-10 m-b-0  text-c-black" style="font-weight: bold; color: black;">Sales Incentives: </p>
                                        <p class="p-t-10 m-b-0  text-c-black" style="font-weight: bold; color: black;">Others: </p>
                                    </app-card>
                                </div>
                            </div>
                            <div class="tab-pane fade" [ngClass]="{'show active': this.activeTab === 'totalCommission'}" id="totalCommission" role="tabpanel" aria-labelledby="totalCommission-tab">
                                <div class="merge-card">
                                    <app-card [hidHeader]="true" cardClass="borderless">
                                        <p class="m-b-0  text-c-black" style="font-weight: bold; color: black;">Group Sales Income: <span style="color: blue;">{{firstPurchaseCommissionData?.groupSalesIncome}}</span></p>
                                        <p class="p-t-10 m-b-0  text-c-black" style="font-weight: bold; color: black;">Sales Growth Income: <span style="color: blue;">{{firstPurchaseCommissionData?.salesGrowthIncome}}</span></p>
                                        <p class="p-t-10 m-b-0  text-c-black" style="font-weight: bold; color: black;">Royalty Fund Achievers: <span style="color: blue;">{{firstPurchaseCommissionData?.royaltyIncome}}</span></p>
                                        <p class="p-t-10 m-b-0  text-c-black" style="font-weight: bold; color: black;">Travel Fund Achievers: <span style="color: blue;">{{firstPurchaseCommissionData?.travelIncome}}</span></p>
                                        <p class="p-t-10 m-b-0  text-c-black" style="font-weight: bold; color: black;">Car Fund Achievers: <span style="color: blue;">{{firstPurchaseCommissionData?.carIncome}}</span></p>
                                        <p class="p-t-10 m-b-0  text-c-black" style="font-weight: bold; color: black;">House Fund Achievers: <span style="color: blue;">{{firstPurchaseCommissionData?.houseIncome}}</span></p>
                                        <p class="p-t-10 m-b-0  text-c-black" style="font-weight: bold; color: black;">Sales Incentives: </p>
                                        <p class="p-t-10 m-b-0  text-c-black" style="font-weight: bold; color: black;">Others: </p>
                                    </app-card>
                                </div>
                            </div>
                        </div>
                    </div>
                    <i class="feather icon-sliders st-icon bg-c-yellow "></i>
                    <div class="text-left ">
                        <h3 class="d-inline-block ">xxxx</h3>
                        <i class="feather icon-arrow-up f-30 text-c-green "></i>
                        <span class="float-right bg-c-yellow ">xxxx</span>
                    </div>
                </app-card>
            </div>
            <div class="col-md-6 col-xl-4 ">
                <app-card [hidHeader]="true " cardClass="widget-statstic-card card-border-c-red ">
                    <div class="card-header-left mb-3 ">
                        <h5 class="mb-0 ">Re-purchase Commission</h5>
                        <p class="p-t-10 m-b-0 text-c-red ">xxxxxx</p>
                        <p class="p-t-10 m-b-0 text">Repurchase Group Sales Income</p>
                        <p class="m-b-0 text">Re-purchase Accumulative Income</p>
                        <p class="m-b-0 text">Achievement Pool Bonus</p>
                        <p class="m-b-0 text">Accumalation only for Director Rank</p>
                        <p class="m-b-0 text">Brand Ambassador Income</p>
                        <p class="m-b-0 text">Rewards</p>
                        <p class="m-b-0 text">Traver Fund</p>
                        <p class="m-b-0 text">Car Fund</p>
                        <div class="row m-b-0 text" style="padding-top: 10px;">
                            <span style="color: black; font-weight:bold;">This Month</span>
                            <span style="color: black; font-weight:bold;">Previous Month</span>
                        </div>
                    </div>
                    <i class="feather icon-users st-icon bg-c-red txt-lite-color "></i>
                    <div class="text-left ">
                        <h3 class="d-inline-block ">xxxx</h3>
                        <i class="feather icon-arrow-down text-c-red f-30 "></i>
                    </div>
                </app-card>
            </div>
            <div class="col-md-6 col-xl-4 ">
                <app-card [hidHeader]="true " cardClass="widget-statstic-card card-border-c-blue ">
                    <div class="card-header-left mb-3 ">
                        <h5 class="mb-0 ">Total Business</h5>
                        <p class="p-t-10 m-b-0 text-c-blue ">xxxxx</p>
                        <p class="p-t-10 m-b-0 text" style="font-weight: bold;">This Month First Purchase : </p>
                        <p class="m-b-0 text">Self BV - (Active / Inactive)</p>
                        <p class="m-b-0 text">Left BV</p>
                        <p class="m-b-0 text">Right BV</p>
                        <p class=" m-b-0 text" style="font-weight: bold;">This Month Re-Purchase : </p>
                        <p class="m-b-0 text">Self BV</p>
                        <p class="m-b-0 text">Left BV</p>
                        <p class="m-b-0 text">Right BV</p>
                        <div class="row m-b-0 text" style="padding-top: 10px;">
                            <span style="color: black; font-weight:bold;">This Month</span>
                            <span style="color: black; font-weight:bold;">Previous Month</span>
                        </div>


                    </div>
                    <i class="feather icon-shopping-cart st-icon bg-c-blue "></i>
                    <div class="text-left ">
                        <h3 class="d-inline-block ">xxxx</h3>
                        <i class="feather icon-arrow-up text-c-green f-30 "></i>
                    </div>
                </app-card>
            </div>
            <!-- order end -->
        </div>
        <br>
        <div class="row">
            <div class="col-sm-3">
                <app-card [hidHeader]="true" cardClass="bg-c-blue text-white widget-visitor-card borderless" blockClass="text-center">
                    <h6 class="text-white">Bus Booking</h6>
                    <button class="btn">Book Now</button>
                    <i class="feather icon-user-plus"></i>
                </app-card>
            </div>
            <div class="col-sm-3">
                <app-card [hidHeader]="true" cardClass="bg-c-green text-white widget-visitor-card borderless" blockClass="text-center">
                    <h6 class="text-white">Travel Booking</h6>
                    <button class="btn">Book Now</button>
                    <i class="feather icon-user-check"></i>
                </app-card>
            </div>
            <div class="col-sm-3" *ngIf="isAdmin">
                <app-card [hidHeader]="true" cardClass="bg-c-red text-white widget-visitor-card borderless" blockClass="text-center">
                    <h6 class="text-white">Recharge</h6>
                    <button class="btn" (click)="recharge()">Book Now</button>
                    <i class="feather icon-users"></i>
                </app-card>
            </div>
            <div class="col-sm-3" *ngIf="!isAdmin">
                <app-card [hidHeader]="true" cardClass="bg-c-red text-white widget-visitor-card borderless" blockClass="text-center">
                    <h6 class="text-white">Recharge</h6>
                    <button class="btn">Book Now</button>
                    <i class="feather icon-users"></i>
                </app-card>
            </div>
            <div class="col-sm-3">
                <app-card [hidHeader]="true" cardClass="bg-c-yellow text-white widget-visitor-card borderless" blockClass="text-center">
                    <h6 class="text-white">Electricity Bill</h6>
                    <button class="btn">Book Now</button>
                    <i class="feather icon-user-minus"></i>
                </app-card>
            </div>
        </div>
        <div class="row mb-n4 ">
            <div class="col-xl-6 col-md-6 ">
                <app-card headerClass="border-0 p-2 pb-0 " cardTitle="First Purchase Sales Incentives" [options]="false">
                    <table class="table table-striped table-responsive">
                        <thead>
                            <tr>
                                <th scope="col" *ngFor="let header of rewardsTableHeader">{{header}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let rewards of rewardsData; index as i ">
                                <th scope="row ">{{ rewards.rankName }}</th>
                                <td>{{ rewards.teamCount }}</td>
                                <td>
                                    <img [src]="rewards.imgloc" class="me-2 " style="width: 80px; height: auto;">
                                </td>
                                <td>{{ rewards.balancedAmount }}</td>
                                <td>
                                    <i *ngIf="rewards.achiver" class="fas fa-caret-up text-c-green f-24"></i>
                                    <i *ngIf="!rewards.achiver" class="fas fa-caret-down text-c-yellow f-24"></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </app-card>
            </div>

            <div class="col-xl-6 col-md-6 ">
                <app-card headerClass="border-0 p-2 pb-0 " headerClass="border-0 p-2 pb-0 " cardTitle="Repurchase Rank" [options]="false ">
                    <table class="table table-striped table-responsive">
                        <thead>
                            <tr>
                                <th scope="col ">No.</th>
                                <th scope="col ">Rank</th>
                                <th scope="col ">Percentage</th>
                                <th scope="col ">Team BV</th>
                                <th scope="col ">Self BV</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let repurchase of repurchaseTable; index as i ">
                                <th scope="row ">{{ repurchase.no }}</th>
                                <td>{{ repurchase.rank }}</td>
                                <td>{{ repurchase.percentage }}</td>
                                <td>{{ repurchase.teamBv }}</td>
                                <td>{{ repurchase.selfBv }}</td>
                            </tr>
                        </tbody>
                    </table>
                </app-card>
            </div>
        </div>
        <div class="row mb-n4">
            <div class="col-sm-12 ">
                <app-card headerClass="border-0 p-2 pb-0 " headerClass="border-0 p-2 pb-0 " cardTitle="Repurchase director Rank" [options]="false ">
                    <table class="table table-striped table-responsive">
                        <thead>
                            <tr>
                                <th scope="col ">RANK</th>
                                <th scope="col ">PBV</th>
                                <th scope="col ">1st Pool</th>
                                <th scope="col ">2nd Pool</th>
                                <th scope="col ">3rd Pool</th>
                                <th scope="col ">4th Pool</th>
                                <th scope="col ">5th Pool</th>
                                <th scope="col ">6th Pool</th>
                                <th scope="col ">Travel Fund</th>
                                <th scope="col ">Car Fund</th>
                                <th scope="col ">House Fund</th>
                                <th scope="col ">Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let director of directorTable; index as i ">
                                <th scope="row ">{{ director.rank }}</th>
                                <td>{{ director.pbv }}</td>
                                <td>{{ director.firstPool }}</td>
                                <td>{{ director.secondPool }}</td>
                                <td>{{ director.thirdPool }}</td>
                                <td>{{ director.fourthPool }}</td>
                                <td>{{ director.fifthPool }}</td>
                                <td>{{ director.sixthPool }}</td>
                                <td>{{ director.travelFund }}</td>
                                <td>{{ director.carFund }}</td>
                                <td>{{ director.houseFund }}</td>
                                <td>{{ director.total }}</td>
                            </tr>
                        </tbody>
                    </table>
                </app-card>
            </div>
        </div>
    </div>
</div>
<app-ui-modal #exampleModalLong [dialogClass]="'modal-xl'">
    <div class="app-modal-header">
        <h5>Add Member</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close" (click)="exampleModalLong.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
        <form [formGroup]="memberGroup" class="form">
            <div class="app-modal-body">
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label class="d-block">Sponsor ID <span style="color: red;">*</span></label>
                            <input type="number" class="form-control" [(ngModel)]="displayId" formControlName="sponsorId" placeholder="Sponsor ID" (keyup)="checkSponsorID($event)">
                            <p style="color: red;">{{showMessageSponsorID}}</p>
                            <div style="color: blue;" *ngIf="sponsorName">Name - <strong>{{ sponsorName }}</strong></div>
                        </div>
                    </div>
                    <!-- <div style="padding-top: 30px; ">
                        <button class="btn btn-warning" style="color:black;" (click)="checkSponsorID($event)">Validate</button>
                    </div> -->
                </div>
                <!-- <div class="row" *ngIf="gridView">
                    <div class="col-sm-12">
                        <table datatable id="report-table" class="table table-striped table-hover mb-0 table-responsive">
                            <thead>
                                <tr>
                                    <th *ngFor="let col of columns">
                                        {{col}}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let reports of allMemberDataBySponsorID">
                                    <td *ngFor="let col of index">
                                        <div *ngIf="col != 'positionEligible' && col != 'positionVacant' && col != 'isActive' && col != 'isGreen' && col != 'action'">
                                            {{reports[col]}}
                                        </div>
                                        <div *ngIf="col == 'positionVacant'">
                                            <div *ngIf="reports[col] == 'Left' ">
                                                <span class="badge badge-success inline-block">Left</span>
                                            </div>
                                            <div *ngIf="reports[col] == 'Right' ">
                                                <span class="badge badge-warning inline-block m-r-5">Right</span>
                                            </div>
                                            <div *ngIf="reports[col] == 'Both' ">
                                                <span class="badge badge-primary inline-block m-r-5">Both</span>
                                            </div>
                                        </div>
                                        <div *ngIf="col == 'positionEligible'">
                                            <div *ngIf="reports[col] == 'Left' ">
                                                <span class="badge badge-success inline-block">Left</span>
                                            </div>
                                            <div *ngIf="reports[col] == 'Right' ">
                                                <span class="badge badge-warning inline-block m-r-5">Right</span>
                                            </div>
                                        </div>
                                        <div *ngIf="col == 'isActive'" class="d-inline-block">
                                            <div class="custom-control custom-switch" *ngIf="reports[col] == 1 ">
                                                <input type="checkbox" class="custom-control-input" readonly checked>
                                                <label class="custom-control-label" for="customSwitch1"></label>
                                            </div>

                                            <div class="custom-control custom-switch" *ngIf="reports[col] == 0 ">
                                                <input type="checkbox" class="custom-control-input" readonly>
                                                <label class="custom-control-label" for="customSwitch1"></label>
                                            </div>
                                        </div>
                                        <div *ngIf="col == 'isGreen'">
                                            <div class="custom-control custom-switch" *ngIf="reports[col] == true ">
                                                <input type="checkbox" class="custom-control-input" readonly checked>
                                                <label class="custom-control-label"></label>
                                            </div>

                                            <div class="custom-control custom-switch" *ngIf="reports[col] == false ">
                                                <input type="checkbox" class="custom-control-input" readonly>
                                                <label class="custom-control-label"></label>
                                            </div>
                                        </div>
                                        <div *ngIf="col == 'action'">
                                            <button class="btn-sm btn-info" style="color:black;" (click)="getRowData(reports)">Select</button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div> -->
                <div *ngIf="!gridView">
                    <div class=" row">

                        <!-- <div class="col-sm-4">
                          <label>Position : <span style="color: red;">*</span></label>
                          <div class="col-sm-6">
                              <input id="left" type="radio" value="left" name="position" formControlName="position">
                              <label style="padding-left: 5px; " for="left ">Left</label>
                          </div>
                          <div class="col-sm-6">
                              <input id="right" type="radio" value="right " name="position" formControlName="position" checked>
                              <label style=" padding-left: 5px; " for="right">Right</label>
                          </div>
                      </div> -->
                        <!-- <div class="col-sm-4">
                            <div class="form-group">
                                <label class="d-block">Parent ID <span style="color: red;">*</span></label>
                                <input type="number" class="form-control" formControlName="parentId" placeholder="Parent ID" (keyup)="checkParentID($event)">
                                <p style=" color: red; ">{{showMessageParentID}}</p>
                            </div>
                        </div> -->
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="d-block">Position : <span style="color: red;">*</span></label>
                                <select class="form-control" formControlName="position">
                                  <option> </option>
                              <option *ngFor="let pos of position">{{pos}}</option>
                            </select>
                            </div>
                        </div>
                        <!-- <div class="col-sm-4">
                            <div class="form-group">
                                <label class="d-block">User ID <span style="color: red;">*</span></label>
                                <input type="text " class="form-control" formControlName="userId" placeholder="User ID" (keyup)="checkUserID($event)">
                                <p style="color: red; ">{{showMessageUserID}}</p>
                            </div>
                        </div> -->
                    </div>
                    <div class="row ">
                        <div class="col-sm-4 ">
                            <div class="form-group ">
                                <label class="d-block">Name <span style="color: red;">*</span></label>
                                <input type="text" class="form-control" formControlName="name" placeholder="Name">
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="d-block ">Phone Number <span style="color: red;">*</span></label>
                                <input type="number" class="form-control" formControlName="phno" placeholder="Phone Number">
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="d-block ">Email</label>
                                <input type="email" class="form-control" formControlName="email" placeholder="Email">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="d-block">Pincode</label>
                                <input type="number" class="form-control" formControlName="pincode" placeholder="Pincode">
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="d-block">State <span style="color: red;">*</span></label>
                                <select class="form-control" formControlName="state">
                                  <option> </option>
                         <option *ngFor="let states of allStatesData " value="{{states.stateCode}}">{{states.stateName}}</option>
                      </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="app-modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal " (click)="exampleModalLong.hide() ">Cancel</button>
                <button type="submit" *ngIf="!gridView " class="btn btn-primary " [disabled]="isSubmitting" (click)="submitMember() ">Submit</button>
            </div>
        </form>

    </div>
</app-ui-modal>

<app-ui-modal #todayActivationCountModalLong [dialogClass]="'modal-xl'">
    <div class="app-modal-header">
        <h5>Today's Activaion Member</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close" (click)="todayActivationCountModalLong.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
        <app-todays-activation></app-todays-activation>
    </div>
</app-ui-modal>

<app-ui-modal #todayJoiningCountModalLong [dialogClass]="'modal-xl'">
    <div class="app-modal-header">
        <h5>Today's Joining Member</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close" (click)="todayJoiningCountModalLong.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
        <app-todays-joining></app-todays-joining>
    </div>
</app-ui-modal>
