export class RepurchaseReportsTableData{
  displayId: string;
  name: string;
  position: string;
  orderDate: string;
  invoiceNo: string;
  totalBV: number;
  totalPrice: number;
  state: string;
  orderNo: string;
}

export class ItemsPurchaseReports{
  displayId: string;
  itemName: string;
  quantity: number;
  itemPrice: number;
  totalPrice: number;
  bv: number;
  totalBV: number;
  orderNo: string;
}

export class RepurchaseReportFilter{
  displayId: string;
  showDownline: boolean;
  repurchaseFrom: string;
  repurchaseTo: string;
  stateCode: string;
}
