<div class="auth-wrappers img-fluid">
    <div class="auth-content text-center">
        <img src="assets/images/logo-dark.png" alt="" class="img-fluid mb-4">
        <div class="card borderless">
            <div class="row align-items-center text-center">
                <div class="col-md-12">
                    <div class="card-body">
                        <h4 class="mb-3 f-w-400">Franchise / CNF Log In</h4>
                        <form class="login-form" [formGroup]="franchiseFormGroup" (ngSubmit)="login()">
                            <div class="input-group mb-3">
                                <input type="text" class="form-control" placeholder="Username" formControlName="username">
                            </div>
                            <div class="input-group mb-4">
                                <input [type]="fieldTextType ? 'text' : 'password'" class="form-control" placeholder="Password" formControlName="password">
                                <span class="input-group-text">
                                <i
                                  class="fa"
                                  [ngClass]="{
                                    'fa-eye-slash': !fieldTextType,
                                    'fa-eye': fieldTextType
                                  }"
                                  (click)="toggleFieldTextType()"
                                ></i>
                              </span>
                            </div>
                            <!-- <div class="form-group text-left mt-2">
                            <div class="checkbox checkbox-primary d-inline">
                                <input type="checkbox" name="checkbox-fill-1" id="checkbox-fill-a1" checked="">
                                <label for="checkbox-fill-a1" class="cr"> Save credentials</label>
                            </div>
                        </div> -->
                            <button type="submit" class="btn btn-block btn-success mb-4">Log In</button>
                            <!-- <p class="mb-2 text-muted">Forgot password? <a [routerLink]="['/auth/reset-password']" class="f-w-400">Reset</a></p>
                        <p class="mb-0 text-muted">Don’t have an account? <a [routerLink]="['/auth/signup']" class="f-w-400">Signup</a></p> -->
                            <a class="btn btn-warning" style="color: white;" [routerLink]="['/auth/signin-v2']">User Log In</a><br><br>
                            <a class="btn btn-primary" style="color: white;" href="https://vedikmart.com" target="_blank">Home
                      </a>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>