import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { UserAuthService } from 'src/app/mlm/auth/services/user-auth.service';
import { ViewCommissionService } from '../../services/view-commission.service';
import { WalletCommissionReport } from '../../model/view-commission.model';
import { CommonComponent } from 'src/app/theme/shared/common/common.component';

@Component({
  selector: 'app-wallet-report',
  templateUrl: './wallet-report.component.html',
  styleUrls: ['./wallet-report.component.scss'],
  providers: [DatePipe]
})
export class WalletReportComponent extends CommonComponent implements OnInit {
  walletListGroup: FormGroup;
  date: string;
  myDate = new Date();
  displayId: string = '';
  gridView: boolean = false;
  walletCommissionReport: WalletCommissionReport[] = [];
  columns = [
  'Display ID',
  'Name',
  'Admin Charge Amount',
  'Net Amount',
  'TDS Amount',
  'Total Amount',
  ];
  isAdmin: boolean = false;
  constructor(
    private fb: FormBuilder,
    private userAuthService: UserAuthService,
    private datePipe: DatePipe,
    private viewCommissionService: ViewCommissionService
  ) {
    super();
    this.walletListGroup = this.fb.group({
      displayId: new FormControl('', [Validators.required]),
      dateFrom: new FormControl('', [Validators.required]),
      dateTo: new FormControl('', [Validators.required])
    });
    this.date = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
  }

  ngOnInit(): void {
    this.displayId = this.userAuthService.getDisplayId();
    this.isAdmin = this.userAuthService.getRoles()==='ADMIN'?true:false;
  }

  submitWalletList(){
    this.viewCommissionService.getCommissionPaymentInWalletData(this.walletListGroup.controls['displayId'].value,this.walletListGroup.controls['dateFrom'].value,this.walletListGroup.controls['dateTo'].value).subscribe((val)=>{
      if(val){
        this.walletCommissionReport = val.data;
        this.gridView = true;
      }
    })
  }

  cancel(){

  }

}
