import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbCarouselModule, NgbDatepickerModule, NgbDropdownModule, NgbTabsetModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
import { SharedModule } from '../../theme/shared/shared.module';
import { ItemMasterComponent } from './components/item-master/item-master.component';
import { InventoryRoutingModule } from './inventory-routing.module';
import { ItemImageComponent } from './components/item-image/item-image.component';
import { StockEntryComponent } from '../purchase/components/stock-entry/stock-entry.component';
import { StockSearchComponent } from '../purchase/components/stock-search/stock-search.component';
import { CategoryUpdateComponent } from './components/category-update/category-update.component';
import { SubCategoryUpdateComponent } from './components/sub-category-update/sub-category-update.component';
import { ProductUpdateComponent } from './components/product-update/product-update.component';
import { UpdateItemComponent } from './components/update-item/update-item.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    NgbTabsetModule,
    NgbDropdownModule,
    NgbCarouselModule,
    NgbDatepickerModule,
    FormsModule,
    DataTablesModule,
    ReactiveFormsModule,
    InventoryRoutingModule
  ],
  declarations: [
    ItemMasterComponent,
    ItemImageComponent,
    StockEntryComponent,
    StockSearchComponent,
    CategoryUpdateComponent,
    SubCategoryUpdateComponent,
    ProductUpdateComponent,
    UpdateItemComponent,
  ]
})
export class InventoryModule { }
