import { Component, ElementRef, OnInit, VERSION, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CompanyMasterDetails, Invoice, InvoiceDetails, Orders, Product, PurchasedItemsDetails } from '../../model/purchase.model';
import { PurchaseService } from '../../services/purchase.service';
const pdfMake = require('pdfmake/build/pdfmake.js');
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { UserAuthService } from 'src/app/mlm/auth/services/user-auth.service';
import { DatePipe } from '@angular/common';

(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;

import jsPDF from 'jspdf';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import htmlToPdfmake from 'html-to-pdfmake';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { MembersService } from 'src/app/mlm/member/services/members.service';
import { AllStates } from 'src/app/mlm/member/model/allstates.model';
import { CommonComponent } from 'src/app/theme/shared/common/common.component';
@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
  providers: [DatePipe]
})
export class OrdersComponent extends CommonComponent implements OnInit {
  ordersFilterGroup: FormGroup;
  allOrdersData: Orders[] = [];
  companyMasterDetails: CompanyMasterDetails;
  invoiceDetails: InvoiceDetails;
  purchasedItemsDetails: PurchasedItemsDetails[] = [];
  displayId: string;
  date: string;
  myDate = new Date();
  grandTotal: number = 0;
  allStatesData: AllStates[] = [];
  stateNameHold: String;
  isAdmin: boolean = false;

  constructor(
    private purchaseService: PurchaseService,
    private fb: FormBuilder,
    private userAuthService: UserAuthService,
    private datePipe: DatePipe,
    private sanitizer: DomSanitizer,
    private memberService: MembersService
  ) {
    super();
    this.ordersFilterGroup = this.fb.group({
      displayId: new FormControl('', [Validators.required]),
      orderDateFrom: new FormControl('', [Validators.required]),
      orderDateTo: new FormControl('', [Validators.required])
    });
    this.date = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
  }

  ngOnInit(): void {
    this.displayId = this.userAuthService.getDisplayId();
    this.isAdmin = this.userAuthService.getRoles()==='ADMIN';
    this.purchaseService.getAllOrdersData(this.displayId,this.ordersFilterGroup.controls['orderDateFrom'].value,this.date,'').subscribe((val)=>{
      if(val){
        this.allOrdersData = val.data;
      }
    })
  }

  submitOrdersFilter(){
    if(this.isAdmin){
      if(this.ordersFilterGroup.controls['orderDateFrom'].value<=this.ordersFilterGroup.controls['orderDateTo'].value){
        this.purchaseService.getAllOrdersData(this.ordersFilterGroup.controls['displayId'].value,this.ordersFilterGroup.controls['orderDateFrom'].value,this.ordersFilterGroup.controls['orderDateTo'].value,'').subscribe((val)=>{
          if(val){
            this.allOrdersData = val.data;
          }
        })
      }else{
        this.dangerSwal("Please check Order Date From Date From or Order Date To is wrong");
      }
    }else{
      if(this.ordersFilterGroup.controls['orderDateFrom'].value<=this.ordersFilterGroup.controls['orderDateTo'].value){
        this.purchaseService.getAllOrdersData('0',this.ordersFilterGroup.controls['orderDateFrom'].value,this.ordersFilterGroup.controls['orderDateTo'].value,'').subscribe((val)=>{
          if(val){
            this.allOrdersData = val.data;
          }
        })
      }else{
        this.dangerSwal("Please check Order Date From Date From or Order Date To is wrong");
      }
    }


  }

  cancel(){
    this.ordersFilterGroup.reset();
  }

  invoice = new Invoice();

  invoiceDetailsGenerate(orderNo: string,displayId: string){
    this.companyMasterDetails = new CompanyMasterDetails()
    this.invoiceDetails = new InvoiceDetails();
    this.purchasedItemsDetails = [];

    this.purchaseService.getInvoice(orderNo,displayId).subscribe((val)=>{
      if(val){
        console.log(val.data.companyMaster);
        this.companyMasterDetails = val.data.companyMaster;
        this.invoiceDetails = val.data.invoice;
        this.grandTotal = val.data.totalPrice;
        if(this.invoiceDetails.stateCode){
          this.memberService.getAllStatesData().subscribe((value) => {
            this.allStatesData = value.data;
            this.allStatesData.forEach(element => {
              if(element.stateCode == this.invoiceDetails.stateCode){
                this.stateNameHold =  element.stateName;
              }
            });
          });
        }
        this.purchasedItemsDetails = val.data.purchasedItems;
      }
    });
    setTimeout(() => {
      this.downloadAsPDF();
    }, 1000);
  }

  generatePDF(action = 'open') {
    this.invoice.products = this.purchasedItemsDetails;
    console.log(this.invoice.products)
    let docDefinition = {
      content: [
        {
          text: this.companyMasterDetails.name,
          fontSize: 35,
          alignment: 'center',
          color: '#047886',
          bold: true,
        },
        {
          text: 'INVOICE',
          fontSize: 15,
          bold: true,
          alignment: 'center',
          decoration: 'underline',
          color: 'skyblue'
        },
        {
          text: 'Customer Details',
          style: 'sectionHeader'
        },
        {
          columns: [
            [
              {
                text: this.invoiceDetails.name,
                bold:true
              },
              { text: this.invoiceDetails.billingAddress },
              { text: this.invoiceDetails.shippingAddress },
            ],
            [
              {
                text: 'Date: ' + this.invoiceDetails.invoiceDate.substring(0,10),
                alignment: 'right'
              },
              {
                text: 'Bill No: ' + this.invoiceDetails.invoiceNo,
                alignment: 'right'
              }
            ]
          ]
        },
        {
          text: 'Order Details',
          style: 'sectionHeader'
        },
        {
          table: {
            headerRows: 1,
            widths: ['*', 'auto', 'auto', 'auto'],
              body: [
                ['Product', 'Price', 'Quantity', 'Amount'],
                ...this.invoice.products.map(p => ([p.prodName, p.itemPrice, p.qty, (p.itemPrice*p.qty).toFixed(2)])),
                [{text: 'Total Amount', colSpan: 3}, {}, {}, this.invoice.products.reduce((sum, p)=> sum + (p.itemPrice * p.qty), 0).toFixed(2)]
              ]

          }
        },
        {
          text: 'Additional Details',
          style: 'sectionHeader'
        },
        {
            text: this.invoice.additionalDetails,
            margin: [0, 0 ,0, 15]
        },
        {
          columns: [
            [{ qr: `${this.invoice.customerName}`, fit: '50' }],
            [{ text: 'Signature', alignment: 'right', italics: true}],
          ]
        },
        {
          text: 'Terms and Conditions',
          style: 'sectionHeader'
        },
        {
            ul: [
              'Order can be return in max 10 days.',
              'Warrenty of the product will be subject to the manufacturer terms and conditions.',
              'This is system generated invoice.',
            ],
        }
      ],
      styles: {
        sectionHeader: {
          bold: true,
          decoration: 'underline',
          fontSize: 14,
          margin: [0, 15,0, 15]
        }
      }
    };

    if(action==='download'){
      pdfMake.createPdf(docDefinition).download();
    }else if(action === 'print'){
      pdfMake.createPdf(docDefinition).print();
    }else{
      pdfMake.createPdf(docDefinition).open();
    }

  }

  addProduct(){
    this.invoice.products.push(new Product());
  }

  @ViewChild('pdfTable') pdfTable: ElementRef;

  public downloadAsPDF() {
    const doc = new jsPDF();

    const pdfTable = this.pdfTable.nativeElement;

    var html = htmlToPdfmake(pdfTable.innerHTML);

    const documentDefinition = { content: html };
    pdfMake.createPdf(documentDefinition).open();

  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }
}
