<div class="row btn-page" *ngIf="isAdmin && !noData">
    <div class="col-sm-12">
        <app-card cardTitle="Unpaid Commission Summary" [options]="false" *ngIf="gridView">
            <div class="row">
                <div class="col-md-3 col-xs-6 border-right">
                    <h3>{{totalIncome.toFixed(2)}}</h3>
                    <span>Total Income</span>
                </div>
                <div class="col-md-3 col-xs-6 border-right">
                    <h3>{{totalAdminChargeAmount.toFixed(2)}}</h3>
                    <span class="text-primary">Total Admin Charge Amount</span>
                </div>
                <div class="col-md-3 col-xs-6 border-right">
                    <h3>{{totalTDSAmount.toFixed(2)}}</h3>
                    <span class="text-success">Total TDS Amount</span>
                </div>
                <div class="col-md-3 col-xs-6 border-right">
                    <h3>{{totalNetAmount.toFixed(2)}}</h3>
                    <span class="text-info">Total Net Amount</span>
                </div>
            </div>
        </app-card>
    </div>
</div>

<div *ngIf="isAdmin">
    <app-card headerClass="border-0 p-2 pb-0 " cardTitle="Unpaid Commission Search " [options]="false">
        <form [formGroup]="unpaidCommissionGroup" class="form">
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="d-block">Frequency <span style="color: red;">*</span></label>
                        <select class="form-control" formControlName="isMonthly">
                        <option> </option>
                        <option>Bimonthly</option>
                        <option>Monthly</option>
                      </select>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="d-block">From Date - To Date <span style="color: red;">*</span></label>
                        <select class="form-control" formControlName="formDateToDate">
                              <option> </option>
                              <option *ngFor="let date of allDatesData ">{{date.fromDate}} - {{date.toDate}}</option>
                            </select>
                    </div>
                </div>
            </div>
            <hr>
            <div class="app-modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="cancel()">Cancel</button>
                <button type="submit" class="btn btn-primary" (click)="submitCommissionGenerated()">Submit</button>
            </div>
        </form>
    </app-card>
    <ng-container *ngIf="!loading; then thenTemplate;"></ng-container>
    <ng-template #thenTemplate>
        <ng-container *ngIf="isDataAvailable; else elseTemplate">
            <div class="row mb-n4" *ngIf="gridView">
                <div class="col-sm-12 ">
                    <app-card [customHeader]="true" headerClass="border-0 p-2 pb-0 " [options]="false " cardClass="card-datatable">
                        <div class="col-sm-12 text-right">
                            <button type="button" class="btn btn-primary btn-sm" (click)="exportexcel()">Export to Excel</button>
                        </div>
                        <div>
                            <input type="checkbox" [checked]="isAllCheckBoxChecked()" (change)="checkAllCheckBox($event)"> All Checked
                        </div>
                        <br>
                        <table id="report-table" datatable class="table table-striped table-hover mb-0 table-responsive">
                            <thead>
                                <tr *ngIf="all">
                                    <th *ngFor="let col of columnsAll">
                                        {{col}}
                                    </th>
                                </tr>
                                <tr> *ngIf="!all"
                                    <th *ngFor="let col of columns">
                                        {{col}}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of commssionGeneratedData; let i=index; ">
                                    <td><input type="checkbox" value="{{data.cheked}}" [(ngModel)]="data.checked"></td>
                                    <th scope="row " *ngIf="!all">{{ data.fromDate }}</th>
                                    <th scope="row " *ngIf="!all">{{ data.toDate }}</th>
                                    <th scope="row ">{{ data.displayId }}</th>
                                    <th scope="row ">{{ data.name }}</th>
                                    <th scope="row ">{{ data.panNo }}</th>
                                    <th scope="row ">{{ data.totalIncome.toFixed(2) }}</th>
                                    <th scope="row ">{{ data.adminchargeamout.toFixed(2) }}</th>
                                    <th scope="row ">{{ data.tdsamount.toFixed(2) }}</th>
                                    <th scope="row ">{{ data.netamount.toFixed(2) }}</th>
                                    <th scope="row "> <button class="btn-sm btn-info" style="color:black;" (click)="exampleModalLong.show()" (click)="unpaidDetailsGenerate(data)">View</button></th>
                                </tr>
                            </tbody>
                        </table>
                    </app-card>
                </div>
            </div>
            <div class="row mb-n4" *ngIf="gridView">
                <div class="col-sm-12 ">
                    <app-card [customHeader]="true " headerClass="border-0 p-2 pb-0 " [options]="false " cardClass="card-datatable">
                        <form [formGroup]="paymentMethodGroup" class="form">
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label class="d-block">Payment Method <span style="color: red;">*</span></label>
                                        <select class="form-control" formControlName="paymentMethod">
                        <option> </option>
                        <option>Wallet</option>
                        <option>Bank</option>
                      </select>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="app-modal-footer">
                                <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="cancelPayment()">Cancel</button>
                                <button class="btn btn-info" style="color:black;" (click)="payUnpaid()">Payment</button>
                            </div>
                        </form>
                    </app-card>
                </div>
            </div>
        </ng-container>
        <ng-template #elseTemplate>
            <div class="spinner" *ngIf="!noData">
                <h5>Loading...</h5>
                <div class="bounce1"></div>
                <div class="bounce2"></div>
                <div class="bounce3"></div>
            </div>
            <div *ngIf="noData">
                <h1 style="text-align: center;">No Data Available</h1>
            </div>
        </ng-template>
    </ng-template>

    <app-ui-modal #exampleModalLong [dialogClass]="'modal-xl'">
        <div class="app-modal-header">
            <h5>Unpaid Commission Details</h5>
            <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close" (click)="exampleModalLong.hide()"><span aria-hidden="true">&times;</span></button>
        </div>
        <div class="app-modal-body" *ngIf="unpaidDetailsGridView">
            <table id="report-table" datatable class="table table-striped table-hover mb-0 table-responsive" *ngIf="!isMonthly">
                <thead>
                    <tr>
                        <th *ngFor="let col of columnsDetailsBiMonthly">
                            {{col}}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of unpaidCommissionDetailsData; let i=index; ">
                        <th scope="row ">{{ data.commissionName }}</th>
                        <th scope="row ">{{ data.pairBVMatch }}</th>
                        <th scope="row ">{{ data.totalIncome.toFixed(2) }}</th>
                    </tr>
                </tbody>
            </table>
            <table id="report-table" datatable class="table table-striped table-hover mb-0 table-responsive" *ngIf="isMonthly">
                <thead>
                    <tr>
                        <th *ngFor="let col of columnsDetailsMonthly">
                            {{col}}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of unpaidCommissionDetailsData; let i=index; ">
                        <th scope="row ">{{ data.commissionName }}</th>
                        <th scope="row ">{{ data.pairBVMatch }}</th>
                        <th scope="row ">{{ data.unitIncome }}</th>
                        <th scope="row ">{{ data.totalIncome }}</th>
                    </tr>
                </tbody>
            </table>
        </div>
    </app-ui-modal>
</div>

<div *ngIf="!isAdmin">
    <ng-container *ngIf="!loading; then thenTemplate;"></ng-container>
    <ng-template #thenTemplate>
        <ng-container *ngIf="isDataAvailable; else elseTemplate">
            <div class="row mb-n4" *ngIf="gridView">
                <div class="col-sm-12 ">
                    <app-card [customHeader]="true " headerClass="border-0 p-2 pb-0 " [options]="false" cardClass="card-datatable">
                        <table datatable class="table table-striped table-hover mb-0 table-responsive">
                            <thead>
                                <tr>
                                    <th *ngFor="let col of columnsUnpaidCommissionUser">
                                        {{col}}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of unpaidCommissionUserData; let i=index; ">
                                    <th scope="row ">{{ data.displayId }}</th>
                                    <th scope="row ">{{ data.fromDate }}</th>
                                    <th scope="row ">{{ data.toDate }}</th>
                                    <th scope="row ">{{ data.commissionType }}</th>
                                    <th scope="row ">{{ data.incomePerPair }}</th>
                                    <th scope="row ">{{ data.pairMatch }}</th>
                                    <th scope="row ">{{ data.paymentStatus }}</th>
                                    <th scope="row ">{{ data.leftMemberCount }}</th>
                                    <th scope="row ">{{ data.rightMemberCount }}</th>
                                    <th scope="row ">{{ data.totalIncome.toFixed(2) }}</th>
                                </tr>
                            </tbody>
                        </table>
                    </app-card>
                </div>
            </div>
        </ng-container>
        <ng-template #elseTemplate>
            <div class="spinner">
                <h5>Loading...</h5>
                <div class="bounce1"></div>
                <div class="bounce2"></div>
                <div class="bounce3"></div>
            </div>
        </ng-template>
    </ng-template>
</div>
