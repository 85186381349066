import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoggerService } from '../../../theme/shared/log/logger.service';
import { environment } from '../../../../environments/environment';
import { AbstractHttpService } from '../../../theme/shared/base/abstract-http';
import { ApprovalBalanceRequest, FranchiseRequestApprovalReject, FundAdd, FundAddRequests, FundRequestsView, TransactionReport } from '../../wallet/model/wallet.model';

@Injectable({
  providedIn: 'root'
})
export class WalletService extends AbstractHttpService {
  private _saveBalanceReq : string = environment.baseUrl + "api/v1/saveBalanceReq";
  private _viewBalanceReq : string = environment.baseUrl + "api/v1/balancerequest/view";
  private _approveBalanceReq : string = environment.baseUrl + "api/v1/approveBalanceReq";
  private _transactionreport : string = environment.baseUrl + "api/v1/transactionreport";
  private _franchisebalancerequest : string = environment.baseUrl + "api/v1/franchise/balancerequest/pendingview?type=4";
  private _franchiseApprovedbalance : string = environment.baseUrl + "api/v1/franchise/balancerequest/approvedview?type=4";
  private _cnfebalancerequest : string = environment.baseUrl + "api/v1/franchise/balancerequest/pendingview?type=3";
  private _cnfApprovedbalance : string = environment.baseUrl + "api/v1/franchise/balancerequest/approvedview?type=3";
  private _approvereject : string = environment.baseUrl + "api/v1/franchise/balancerequest/approvereject";
  private _walletbalance : string = environment.baseUrl + "api/v1/walletbalance";
  private _franchisebalancerequertstatus : string = environment.baseUrl + "api/v1/franchisebalancerequertstatus";
  private _userwalletbalance : string = environment.baseUrl + "api/v1/memberwalletbalance";
  private _deleteuserwalletbalance : string = environment.baseUrl + "api/v1/deletememberwalletbalance";
  private _franchiseCommissionYearMonth : string = environment.baseUrl +"api/v1/franchiseCommissionSummaryYearandMonthWise";
  

  constructor(
    protected  http: HttpClient,
    protected  logger: LoggerService
    ) {
    super('WalletService', http, logger);
  };

  postFundAddData(fundAddPostForm: FundAdd) : Observable<any>{
    return this.http.post<FundAdd>(this._saveBalanceReq ,fundAddPostForm);
  }

  getFundAddDataRequestsView(): Observable<any>{
    return this.http.get<FundRequestsView>(this._viewBalanceReq);
  }

  postApprovalBalanceRequest(approvalForm: ApprovalBalanceRequest) : Observable<any>{
    return this.http.post<any>(this._approveBalanceReq ,approvalForm);
  }

  getTransactionReportData(displayId: string): Observable<any>{
    const url = this._transactionreport + `?displayId=${displayId}`;
    return this.http.get<TransactionReport>(url);
  }

  getFranchiseBalanceRequestsData(): Observable<any>{
    return this.http.get<TransactionReport>(this._franchisebalancerequest);
  }

  getCnfRequestsData(): Observable<any>{
    return this.http.get<TransactionReport>(this._cnfebalancerequest);
  }

  getFranchiseApprovedBalanceData(): Observable<any>{
    return this.http.get<TransactionReport>(this._franchiseApprovedbalance);
  }

  getCnfApprovedBalanceData(): Observable<any>{
    return this.http.get<TransactionReport>(this._cnfApprovedbalance);
  }

  postFranchiseApprovalBalanceRequest(franchiseApprovalForm: FranchiseRequestApprovalReject) : Observable<any>{
    return this.http.post<any>(this._approvereject,franchiseApprovalForm);
  }

  getWalletBalanceData(): Observable<any>{
    return this.http.get<any>(this._walletbalance);
  }

  getBalanceRequestStatusData(): Observable<any>{
    return this.http.get<any>(this._franchisebalancerequertstatus);
  }

  getUserWalletBalanceData(): Observable<any>{
    return this.http.get<any>(this._userwalletbalance);
  }

  deleteUserWalletBalance(displayId: number) : Observable<any>{
    const url = `${this._deleteuserwalletbalance}?displayId=${displayId}`;
    return this.http.delete<any>(url);
  }

  getFranchiseCommissionByYearMonth(yearno: string,months: string): Observable<any>{
    const url = this._franchiseCommissionYearMonth+`?yearno=${yearno}`+`&months=${months}`;
    return this.http.get<any>(url);
  }
}
