<app-card [hidHeader]="true">
    <h5>Item Entry</h5>
    <hr>
    <form [formGroup]="itemGroup" class="form">
        <div class="app-modal-body">
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="d-block">Category <span style="color: red;">*</span></label>
                        <input type="text" class="form-control" formControlName="category" placeholder="Category" list="category">
                        <datalist id="category">
                          <option *ngFor="let category of allCategoriesData">{{category.catName}}</option>
                       </datalist>
                        <p style="color: red;">{{showMessageCategory}}</p>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="d-block">Sub Category <span style="color: red;">*</span></label>
                        <input type="text" class="form-control" formControlName="subCategory" placeholder="Sub Category" list="subCategory">
                        <datalist id="subCategory">
                          <option *ngFor="let subCategory of allSubCategoriesData">{{subCategory.subCatName}}</option>
                       </datalist>
                        <p style="color: red;">{{showMessageSubCategory}}</p>
                    </div>
                </div>
            </div>
            <div>
                <div class=" row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label class="d-block">Product <span style="color: red;">*</span></label>
                            <input type="text" class="form-control" formControlName="product" placeholder="Product" list="product">
                            <datalist id="product">
                              <option *ngFor="let product of allProductsData ">{{product.prodName}}</option>
                           </datalist>
                            <p style=" color: red; ">{{showMessageProduct}}</p>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label class="d-block">Product Description</label>
                            <textarea class="form-control" formControlName="productDescription" rows="3" placeholder="Product Description"></textarea>
                        </div>
                    </div>
                </div>
                <div class=" row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label class="d-block">Item <span style="color: red;">*</span></label>
                            <input type="text" class="form-control" formControlName="item" placeholder="Item" list="items">
                            <datalist id="items">
                          <option *ngFor="let items of allItemsData">{{items.itemName}}</option>
                       </datalist>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label class="d-block">Item Description</label>
                            <textarea class="form-control" formControlName="itemDescription" rows="3" placeholder="Item Description"></textarea>
                        </div>
                    </div>
                </div>
                <div class="row ">
                    <div class="col-sm-4">
                        <div class="form-group ">
                            <label class="d-block">HSN</label>
                            <input type="text" class="form-control" formControlName="hsn" placeholder="HSN">
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group">
                            <label class="d-block ">MRP/Unit Price <span style="color: red;">*</span></label>
                            <input type="number" class="form-control" formControlName="mrp" placeholder="MRP/Unit Price">
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group">
                            <label class="d-block">GST Percentage <span style="color: red;">*</span></label>
                            <select class="form-control" formControlName="gstPercentage">
                              <option></option>
                              <option *ngFor="let gst of allGstData ">{{gst}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row ">
                    <div class="col-sm-6">
                        <div class="form-group ">
                            <label class="d-block">Distributer Price <span style="color: red;">*</span></label>
                            <input type="number" class="form-control" formControlName="distributerPrice" placeholder="Distributer Price">
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label class="d-block">BV <span style="color: red;">*</span></label>
                            <input type="number" class="form-control" formControlName="bv" placeholder="BV">
                        </div>
                    </div>
                    <!-- <div class="col-sm-4">
                        <label class="d-block">Item Image Upload</label>
                        <input type="file" class="form-control" formControlName="itemImageUpload">
                    </div> -->
                </div>
            </div>
        </div>
        <hr>
        <div class="app-modal-footer ">
            <button type=" button " class="btn btn-danger " data-dismiss="modal" (click)="cancel()">Cancel</button>
            <button type="submit " class="btn btn-primary " (click)="submitItem() ">Submit</button>
        </div>
    </form>
</app-card>