import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserAuthService } from 'src/app/mlm/auth/services/user-auth.service';
import { ExcelExportService } from 'src/app/mlm/report/services/excel-export.service';
import { CommonComponent } from 'src/app/theme/shared/common/common.component';
import { AllCommissions, AllCommissionsDetails, PayAllCommissions, PayAllUnpaidCommissionDataPost } from '../../../pay-commission/model/view-commission.model';
import { ViewCommissionService } from '../../../pay-commission/services/view-commission.service';

@Component({
  selector: 'app-commissions-tds',
  templateUrl: './commissions-tds.component.html',
  styleUrls: ['./commissions-tds.component.scss']
})
export class CommissionsTdsComponent extends CommonComponent implements OnInit {
  isAdmin: boolean = false;

  totalIncome : number = 0;
  totalAdminChargeAmount : number = 0;
  totalTDSAmount : number = 0;
  totalNetAmount : number = 0;

  allCommissionGroup: FormGroup;
  gridView: boolean;

  allCommissionsData: AllCommissions[] = [];
  noData: boolean;
  isDataAvailable: boolean = false;
  loading: boolean = true;
  showModal: boolean = false;
  allCommissionsDetailsData: AllCommissionsDetails[] = [];
  allCommissionsDetailsDataUnchecked: AllCommissionsDetails[] = [];
  finalAllCommissionsData: AllCommissions[ ] = [];

  columns = [
    "Checked",
    "Display Id",
    "Name",
    "Pan No",
    "Total Income",
    "Admin Charge",
    "TDS Charge",
    "Net Income",
    "Payable Amount",
    "Payment Date",
    "Payment Status",
    "Account No",
    "Bank Name",
    "IFSC",
    ""
  ];
  columnsFinal = [
    "Display Id",
    "Name",
    "Pan No",
    "Total Income",
    "Admin Charge",
    "TDS Charge",
    "Net Income",
    "Payable Amount",
    "Payment Date",
    "Payment Status",
    "Account No",
    "Bank Name",
    "IFSC"
  ]
  columnsDetails = [
    "Checked",
    "FromDate",
    "toDate",
    "displayId",
    "commissionName",
    "pairBVMatch",
    "unitIncome",
    "totalIncome",
    "adminCharge",
    "tdsCharge",
    "netIncome",
    "payableAmount",
  ];

  columnsDetailsUnchecked = [
    "FromDate",
    "toDate",
    "displayId",
    "commissionName",
    "pairBVMatch",
    "unitIncome",
    "totalIncome",
    "adminCharge",
    "tdsCharge",
    "netIncome",
    "payableAmount",
  ];

  months = [
    { name: 'January', value: 1 },
    { name: 'February', value: 2 },
    { name: 'March', value: 3 },
    { name: 'April', value: 4 },
    { name: 'May', value: 5 },
    { name: 'June', value: 6 },
    { name: 'July', value: 7 },
    { name: 'August', value: 8 },
    { name: 'September', value: 9 },
    { name: 'October', value: 10 },
    { name: 'November', value: 11 },
    { name: 'December', value: 12 }
  ];
  years = Array.from({ length: 10 }, (_, i) => new Date().getFullYear() - i);


  unpaidDetailsGridView: boolean = false;
  totalAmountDetails: number = 0;
  finalList: boolean = false;
  paymentList: AllCommissionsDetails[] = [];
  paymentMethodGroup: FormGroup;
  payUnpaidCommissionList: PayAllCommissions[] = [];
  payableAmountDetails: number = 0;
  unpaidDetailsGridViewUnchecked: boolean = false;

  constructor(
    private viewCommissionService: ViewCommissionService,
    private fb: FormBuilder,
    private userAuthService: UserAuthService,
    private excelExportService: ExcelExportService
  ) {
    super();
    this.allCommissionGroup = this.fb.group({
      displayId: new FormControl('', [Validators.required]),
      dateFrom: new FormControl('', [Validators.required]),
      dateTo: new FormControl('', [Validators.required]),
      isChecked: new FormControl(false, [Validators.required])
    });
    this.paymentMethodGroup = this.fb.group({
      paymentMethod: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.isAdmin = this.userAuthService.getRoles()==='ADMIN'?true:false;
    this.allCommissionGroup = this.fb.group({
      monthFrom: [null],
      yearFrom: [null]
    });
  }

  submitAllCommission(){
    const monthFrom = this.allCommissionGroup.controls['monthFrom'].value;
    const yearFrom = this.allCommissionGroup.controls['yearFrom'].value;

    if (!monthFrom || !yearFrom) {
      this.warningSwal("Please select month and year");
      return;
    }
    console.log(`Selected Month: ${monthFrom}`);
    console.log(`Selected Year: ${yearFrom}`);

    // Ensuring monthFrom is treated as an integer and adjusting for 0-based month index
    // const monthIndex = parseInt(monthFrom) - 1;
    // const firstDay = new Date(yearFrom, monthIndex, 1);
    // const lastDay = new Date(yearFrom, monthIndex + 1, 0);

    // const firstDayFormatted = firstDay.toISOString().split('T')[0];
    // const lastDayFormatted = lastDay.toISOString().split('T')[0];

    // console.log(`First Day: ${firstDayFormatted}`);
    // console.log(`Last Day: ${lastDayFormatted}`);

    if(monthFrom && yearFrom){
      this.totalIncome = 0;
      this.totalAdminChargeAmount = 0;
      this.totalTDSAmount = 0;
      this.totalNetAmount = 0;
      this.gridView = false;
      
      this.viewCommissionService.getAllCommissionByDate(monthFrom,yearFrom).subscribe((val) => {
          if (!val.message) {
            this.allCommissionsData = val.data;
            this.isDataAvailable = this.allCommissionsData?.length > 0;
            this.noData = this.allCommissionsData?.length === 0;
            this.allCommissionsData.forEach(element=>{
              element.checked = false;
              this.totalIncome = this.totalIncome + element.totalIncome;
              this.totalAdminChargeAmount = this.totalAdminChargeAmount + element.adminCharge;
              this.totalTDSAmount = this.totalTDSAmount + element.tdsCharge;
              this.totalNetAmount = this.totalNetAmount + element.netIncome;
            })
            this.allCommissionsDetailsDataUnchecked = [];
            this.finalAllCommissionsData = [];
            this.paymentList = [];
            this.payUnpaidCommissionList = [];
            this.gridView = true;
            this.loading = false;
          }else{
            this.warningSwal(val.message);
            this.gridView = false;
          }
          });
      
    }else{
      this.dangerSwal('Please select From Date or To Date');
    }
  }



  cancel(){
    this.allCommissionGroup.reset();
    this.gridView = false;
    this.isDataAvailable = false;
    this.loading = true;
  }

    // this.allCommissionsData.forEach(x => x.checked = ev.target.checked);
  checkAllCheckBox(ev: any) {
    this.gridView = false;
    this.finalList = false;
    setTimeout(() => {
      this.finalAllCommissionsData = [];
      this.allCommissionsData.forEach(element => {
        element.checked = ev.target.checked;
        if(element.checked){
          this.finalAllCommissionsData.push(element);
        }
      });
      this.gridView = true;
      this.finalList = true;
    }, 2000);
  }

  isAllCheckBoxChecked() {
    return this.allCommissionsData.every(p => p.checked);
  }

  show() {
    this.showModal = true;
  }

  unpaidDetailsGenerateUnchecked(data: AllCommissions){
    this.unpaidDetailsGridViewUnchecked = false;
    this.allCommissionsDetailsDataUnchecked = [];
    this.viewCommissionService.getAllCommissionDetailsData(this.allCommissionGroup.controls['dateFrom'].value,this.allCommissionGroup.controls['dateTo'].value,data.displayId).subscribe((val)=>{
      if(val){
        this.totalAmountDetails = 0;
        this.payableAmountDetails = 0;
        this.allCommissionsDetailsDataUnchecked = val.data;
        this.allCommissionsDetailsDataUnchecked.forEach(element=>{
          this.totalAmountDetails = this.totalAmountDetails + element.totalIncome;
          this.payableAmountDetails = this.payableAmountDetails + element.payableAmount;
        })
        this.unpaidDetailsGridViewUnchecked = true;
      }
    })
  }

  unpaidDetailsGenerate(data: AllCommissions){
    this.unpaidDetailsGridView = false;
    this.allCommissionsDetailsData = [];
    this.totalAmountDetails = 0;
    this.payableAmountDetails = 0;
    this.paymentList.forEach(element => {
      if(element.displayId == data.displayId){
        this.allCommissionsDetailsData.push(element);
      }
    });
    this.allCommissionsDetailsData.forEach(element=>{
      if(element.isChecked){
        this.totalAmountDetails = this.totalAmountDetails + element.totalIncome;
        this.payableAmountDetails = this.payableAmountDetails + element.payableAmount;
      }
    })
    this.unpaidDetailsGridView = true;
    this.show();
  }

  hide() {
    this.showModal = false;
  }

  finalListRefresh(event:any,data: AllCommissions){
    this.finalList = false;
    this.gridView = false;
    setTimeout(() => {
      if(event.target.checked){
        this.finalAllCommissionsData.push(data);
        this.viewCommissionService.getAllCommissionDetailsData(this.allCommissionGroup.controls['dateFrom'].value,this.allCommissionGroup.controls['dateTo'].value,data.displayId).subscribe((val)=>{
          if(val){
            val.data.forEach((element: AllCommissionsDetails) => this.paymentList.push(element));
          }
        })
        console.log("paymentList Insert->",this.paymentList);
      }else{
        let i=-1,m=0;
        this.finalAllCommissionsData.forEach(element => {
          i++;
          if(element.displayId == data.displayId){
            m=i;
          }
        });
        this.finalAllCommissionsData.splice(m,1);
        i=-1,m=0;
        let temp = this.paymentList;
        this.paymentList = [];
        temp.forEach(element => {
          i++;
          if(element.displayId != data.displayId){
            m=i;
            this.paymentList.push(element);
          }
        });
        console.log("paymentList Delete->",this.paymentList);
      }
      this.gridView = true;
      this.finalList = true;
    }, 1000);
  }

  detailsCheck(event: any,data: any,i: any){
    this.finalList = false;
    this.gridView = false;
    this.unpaidDetailsGridView = false;
    this.totalAmountDetails = 0;
    this.payableAmountDetails = 0;
    setTimeout(() => {
      if(event.target.checked){
        let totalPayableAmount = 0;
        this.allCommissionsDetailsData.forEach(element=>{
          if(element.isChecked){
            this.totalAmountDetails = this.totalAmountDetails + element.totalIncome;
            this.payableAmountDetails = this.payableAmountDetails + element.payableAmount;
            totalPayableAmount += element.payableAmount;
          }
        })
        this.finalAllCommissionsData.forEach(element => {
          if(element.displayId == data.displayId){
            element.payableAmount = totalPayableAmount;
          }
        });
      }else{
        let totalPayableAmount = 0;
        this.allCommissionsDetailsData.forEach(element=>{
          if(element.isChecked){
            this.totalAmountDetails = this.totalAmountDetails + element.totalIncome;
            this.payableAmountDetails = this.payableAmountDetails + element.payableAmount;
            totalPayableAmount += element.payableAmount;
          }
        })
        this.finalAllCommissionsData.forEach(element => {
          if(element.displayId == data.displayId){
            element.payableAmount = totalPayableAmount;
          }
        });
      }
      this.gridView = true;
      this.finalList = true;
      this.unpaidDetailsGridView = true;
    }, 1000);

  }

  payUnpaid(){
    if(this.paymentMethodGroup.controls['paymentMethod'].value === 'Wallet'){
      this.paymentList.forEach(element=>{
        if(element.isChecked){
          const payUnpaidCommssion = new PayAllCommissions();
          payUnpaidCommssion.displayId = element.displayId;
          payUnpaidCommssion.fromDate = element.fromDate;
          payUnpaidCommssion.toDate = element.toDate;
          payUnpaidCommssion.pairMatch = element.pairBVMatch;
          payUnpaidCommssion.commissionCode = element.commissionCode;
          payUnpaidCommssion.totalIncome = element.totalIncome;
          this.payUnpaidCommissionList.push(payUnpaidCommssion);
        }
      });
      let payUnpaidCommissionDataPost = new PayAllUnpaidCommissionDataPost();
      payUnpaidCommissionDataPost.paymentRequestDetails = this.payUnpaidCommissionList;
      this.viewCommissionService.postAllCommissionsData(payUnpaidCommissionDataPost,true).subscribe((val)=>{
        if(val.errorCode){
          this.dangerSwal(val.message);
          this.payUnpaidCommissionList = [];
        }else{
          this.successSwal("Transaction completed successfully through Wallet");
          this.payUnpaidCommissionList = [];
          this.paymentList = [];
          this.allCommissionsDetailsData = [];
          this.finalAllCommissionsData = [];
          this.gridView = false;
          this.isDataAvailable = false;
          this.loading = true;
        }
      },error =>{
        this.payUnpaidCommissionList = [];
        this.dangerSwal("Something Wrong");
      });
    }else if(this.paymentMethodGroup.controls['paymentMethod'].value === 'Bank'){
      this.paymentList.forEach(element=>{
        if(element.isChecked){
          const payUnpaidCommssion = new PayAllCommissions();
          payUnpaidCommssion.displayId = element.displayId;
          payUnpaidCommssion.fromDate = element.fromDate;
          payUnpaidCommssion.toDate = element.toDate;
          payUnpaidCommssion.pairMatch = element.pairBVMatch;
          payUnpaidCommssion.commissionCode = element.commissionCode;
          payUnpaidCommssion.totalIncome = element.totalIncome;
          this.payUnpaidCommissionList.push(payUnpaidCommssion);
        }
      });
      let payUnpaidCommissionDataPost = new PayAllUnpaidCommissionDataPost();
      payUnpaidCommissionDataPost.paymentRequestDetails = this.payUnpaidCommissionList;
      this.viewCommissionService.postAllCommissionsData(payUnpaidCommissionDataPost,false).subscribe((val)=>{
        if(val.errorCode){
          this.dangerSwal(val.message);
          this.payUnpaidCommissionList = [];
        }else{
          this.successSwal("Transaction completed successfully through Bank");
          this.payUnpaidCommissionList = [];
          this.paymentList = [];
          this.allCommissionsDetailsData = [];
          this.finalAllCommissionsData = [];
          this.gridView = false;
          this.isDataAvailable = false;
          this.loading = true;
        }
      },error =>{
        this.payUnpaidCommissionList = [];
        this.dangerSwal("Something Wrong");
      });
    }
    else{
      this.warningSwal("Please select a payment method");
    }
  }

  cancelPayment(){
    this.paymentMethodGroup.reset();
  }

  exportexcel(): void {
    this.excelExportService.exportExcel(
      this.allCommissionsData,
      'total_tds_report'
    );
  }
}