import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoggerService } from '../../../theme/shared/log/logger.service';
import { environment } from '../../../../environments/environment';
import { AbstractHttpService } from '../../../theme/shared/base/abstract-http';
import { CategoryUpdate, ItemId, ItemMasterData, ProductUpdate, SubCategoryUpdate } from '../model/item-master.model';

@Injectable({
  providedIn: 'root'
})
export class ItemMasterService extends AbstractHttpService {
  private _allCategories : string = environment.baseUrl + "api/v1/categories";
  private _allSubCategories : string = environment.baseUrl + "api/v1/subcategories";
  private _allProducts : string = environment.baseUrl + "api/v1/products";
  private _itemMasterData : string = environment.baseUrl + "api/v1/additem";
  private _allItems : string = environment.baseUrl + "api/v1/items";
  private _itemImageUpload : string = environment.baseUrl + "api/v1/imgaeupload/";
  private _itemImageGet : string = environment.baseUrl + "api/v1/item/docs/";
  private _itemsDetails : string = environment.baseUrl + "api/v1/allitems";
  private _gstDetails : string = environment.baseUrl + "api/v1/gst";
  private _categoryupdate : string = environment.baseUrl + "api/v1/categoryupdate";
  private _subcategoryupdate : string = environment.baseUrl + "api/v1/subcategoryupdate";
  private _productupdate : string = environment.baseUrl + "api/v1/productupdate";
  private _getcommoditybyitem : string = environment.baseUrl + "api/v1/getcommoditybyitem";

  constructor(
    protected  http: HttpClient,
    protected  logger: LoggerService
    ) {
    super('ItemMasterService', http, logger);
  };

  getAllCategoriesData(): Observable<any>{
    return this.http.get<any>(this._allCategories);
  }

  getAllSubCategoriesData(catId: any): Observable<any>{
    const url = this._allSubCategories + `?catId=${catId}`;
    return this.http.get<any>(url);
  }

  getAllProductsData(catId: any,subCatId: any): Observable<any>{
    const url = this._allProducts + `?catId=${catId}`+`&subCatId=${subCatId}`;
    return this.http.get<any>(url);
  }

  getAllItemsData(catId: any,subCatId: any,prodId: any): Observable<any>{
    const url = this._allItems + `?catId=${catId}`+`&subCatId=${subCatId}`+`&prodId=${prodId}`;
    return this.http.get<any>(url);
  }

  postItemMasterData(itemMasterPostForm: ItemMasterData) : Observable<any>{
    return this.http.post<any>(this._itemMasterData ,itemMasterPostForm);
  }

  postItemImageUploadData(formData: any,itemId: string) : Observable<any>{
    const url = this._itemImageUpload + itemId;
    return this.http.post(url,formData);
  }

  getItemImageUploadData(itemId: string): Observable<any>{
    const url = this._itemImageGet + `${itemId}`;
    return this.http.get<any>(url);
  }

  getItemsData(): Observable<any>{
    return this.http.get<any>(this._itemsDetails);
  }

  getGSTData(): Observable<any>{
    return this.http.get<any>(this._gstDetails);
  }

  postCategoryUpdateData(formData: CategoryUpdate) : Observable<any>{
    return this.http.post(this._categoryupdate,formData);
  }

  postSubCategoryUpdateData(formData: SubCategoryUpdate) : Observable<any>{
    return this.http.post(this._subcategoryupdate,formData);
  }

  postProductUpdateData(formData: ProductUpdate) : Observable<any>{
    return this.http.post(this._productupdate,formData);
  }

  postGetCommodityByItemData(formData: ItemId) : Observable<any>{
    return this.http.post(this._getcommoditybyitem,formData);
  }
}
