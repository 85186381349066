import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { UserAuthService } from 'src/app/mlm/auth/services/user-auth.service';
import {
  DownlineReportFilter,
  DownlineReportsTableData,
} from '../../../report/model/downline-reports-table.model';
import { DownlineService } from '../../../report/services/downline.service';
import { ExcelExportService } from '../../../report/services/excel-export.service';
import * as XLSX from 'xlsx';
import { CommonComponent } from 'src/app/theme/shared/common/common.component';
@Component({
  selector: 'app-todays-activation',
  templateUrl: './todays-activation.component.html',
  styleUrls: ['./todays-activation.component.scss'],
})
export class TodaysActivationComponent extends CommonComponent implements OnInit {
  fromDate: NgbDateStruct;
  toDate: NgbDateStruct;
  gridView: boolean = false;
  isAdmin: boolean = false;
  columnsAdmin = [
    'Display ID',
    'Joining Date',
    'Name',
    'Phone Number',
    'Email',
    'Parent Name',
    'Sponser Name',
    'Position',
    // 'Is Active',
    'Is Green',
    'Green On',
    'State'
  ];
  indexAdmin = [
    'displayId',
    'createdOn',
    'name',
    'phNo',
    'emailId',
    'parentName',
    'sponsorName',
    'parentIdWisePosition',
    // 'isActive',
    'isGreen',
    'greenOn',
    'stateName'
  ];
  downlineReportsTableData: DownlineReportsTableData[] = [];
  displayId: string;
  fileName = 'ExcelSheet.xlsx';
  loading: boolean = true;
  isDataAvailable: boolean;
  todayDate: Date;
  constructor(
    private downlineService: DownlineService,
    private userAuthService: UserAuthService,
    public fb: FormBuilder,
    private excelExportService: ExcelExportService
  ) {
    super();
    this.isDataAvailable = this.downlineReportsTableData?.length > 0;
  }

  ngOnInit() {
    this.isAdmin = this.userAuthService.getRoles() === 'ADMIN' ? true : false;
    this.displayId = this.userAuthService.getDisplayId();
    this.todayDate = new Date();
    this.firstShow();
  }

  firstShow() {
    const searchData = new DownlineReportFilter();
    searchData.displayId = this.displayId;
    searchData.greenFrom = this.todayDate;
    searchData.greenTo = this.todayDate;
    this.downlineReportsTableData = [];
    this.gridView = false;
    this.downlineService.getDownlineTableData(searchData).subscribe((value) => {
      if (value) {
        this.downlineReportsTableData = value.data.downlineReports;
        this.isDataAvailable = this.downlineReportsTableData?.length > 0;
        this.gridView = true;
        this.loading = false;
      }
    });
  }

  exportexcel(): void {
    this.excelExportService.exportExcel(
      this.downlineReportsTableData,
      'today activations'
    );
  }
}
