<ng-container *ngIf="!loading; then thenTemplate; else elseTemplate"></ng-container>
<ng-template #thenTemplate>
    <ng-container *ngIf="isDataAvailable; else elseTemplate">
        <div class="row btn-page" *ngIf="gridView">
            <div class="col-sm-12 btn-page">
                <app-card [hidHeader]="true" cardClass="card-datatable" cardTitle="View Commission">
                    <h5>Recharge Product Master</h5>
                    <hr>
                    <!-- <div class="col-sm-12 text-right">
                        <button type="button" class="btn btn-primary btn-sm" (click)="exportexcel()">Export to Excel</button>
                    </div> -->
                    <div class="table-responsive">
                        <table datatable id="excel-table" class="table table-striped table-hover mb-0 table-responsive">
                            <thead>
                                <tr>
                                    <th *ngFor="let col of columns">
                                        {{col}}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of productMasterTableData; let i=index; ">
                                    <th scope="row ">{{ data?.serviceType }}</th>
                                    <th scope="row ">{{ data?.serviceName }}</th>
                                    <th>
                                        <div class="switch switch-primary d-inline m-r-10">
                                            <input type="checkbox" id="{{data.id}}" [checked]="data?.isActive" (change)="changeIsActiveStatus(data)">
                                            <label for="{{data.id}}" class="cr"></label>
                                        </div>
                                    </th>
                                    <th scope="row">
                                        <input type="number" 
                                               step="0.01" 
                                               min="0" 
                                               placeholder="Commission Percentage" 
                                               class="form-control" 
                                               [value]="data?.commissionPercentage" 
                                               (keyup)="commissionPercentageChange($event.target.value, data)"
                                               onkeypress="return isNumberKey(event)" 
                                               ondragstart="return false;" 
                                               ondrop="return false;">
                                    </th>
                                    <th scope="row">
                                        <button type="button" class="btn btn-primary btn-sm" (click)="updateProductMaster(data)">Update</button>
                                    </th>
                            </tbody>
                        </table>
                    </div>
                </app-card>
            </div>
        </div>
    </ng-container>
    <ng-template #elseTemplate>
        <div class="spinner" *ngIf="!noData">
            <h5>Loading...</h5>
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
        </div>
        <div *ngIf="noData">
            <h1 style="text-align: center;">No Data Available</h1>
        </div>
    </ng-template>
</ng-template>
<ng-template #elseTemplate>
</ng-template>
