<div class="row btn-page">
    <div class="col-sm-12">
        <app-card cardTitle="View Paid Commission" [options]="false" *ngIf="gridView">
            <div class="row">
                <div class="col-md-2 col-xs-6 border-right">
                    <h3>{{totalAmount}}</h3>
                    <span>Total Amount</span>
                </div>
                <div class="col-md-2 col-xs-6 border-right">
                    <h3>{{adminCharges}}</h3>
                    <span class="text-primary">Admin Charges</span>
                </div>
                <div class="col-md-2 col-xs-6 border-right">
                    <h3>{{tdsCharges}}</h3>
                    <span class="text-success">TDS Charges</span>
                </div>
                <div class="col-md-2 col-xs-6 border-right">
                    <h3>{{netAmount}}</h3>
                    <span class="text-danger">Net Amount</span>
                </div>
            </div>
        </app-card>
    </div>
</div>
<div class="row">
    <div class="col-sm-12">
        <app-card cardTitle="Paid Commission" [options]="false">
            <form [formGroup]="FilterFormGroup" class="form">
                <div class="app-modal-body">
                    <div class="row">
                        <div class="col-sm-8">
                            <div class="form-group">
                                <label class="d-block">Display Id</label>
                                <input type="number" class="form-control" formControlName="displayId" placeholder="Display Id">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="d-block">Commission From Date</label>
                                <input type="date" class="form-control" formControlName="commissionFrom" placeholder="Commission From Date">
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="d-block">Commission To Date</label>
                                <input type="date" class="form-control" formControlName="commissionTo" placeholder="Commission To Date">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="d-block">Payment From Date</label>
                                <input type="date" class="form-control" formControlName="paymentFrom" placeholder="Payment From Date">
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="d-block">Payment To Date</label>
                                <input type="date" class="form-control" formControlName="paymentTo" placeholder="Payment To Date">
                            </div>
                        </div>
                    </div>
                </div>
                <hr>
                <div class="app-modal-footer ">
                    <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="reset()">Cancel</button>
                    <button type="submit" class="btn btn-primary" (click)="search()">Search</button>
                </div><br>
                <p style="color: rgb(55, 0, 255);">Paid commission is showing from 2023-04-01</p>
            </form>
        </app-card>
    </div>
</div>

<ng-container *ngIf="!loading; then thenTemplate; else elseTemplate"></ng-container>
<ng-template #thenTemplate>
    <ng-container *ngIf="isDataAvailable; else elseTemplate">
        <div class="row btn-page" *ngIf="gridView">
            <div class="col-sm-12 btn-page">
                <app-card [hidHeader]="true" cardClass="card-datatable" cardTitle="View Commission">
                    <div class="col-sm-12 text-right">
                        <button type="button" class="btn btn-primary btn-sm" (click)="exportexcel()">Export to Excel</button>
                    </div>
                    <div class="table-responsive">
                        <table datatable id="excel-table" class="table table-striped table-hover mb-0 table-responsive">
                            <thead>
                                <tr>
                                    <th *ngFor="let col of columns">
                                        {{col}}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of paidCommissionsTableData; let i=index; ">
                                    <th scope="row ">{{ data?.fromDate }}</th>
                                    <th scope="row ">{{ data?.toDate }}</th>
                                    <th scope="row ">{{ data?.displayId }}</th>
                                    <th scope="row ">{{ data?.name }}</th>
                                    <th scope="row ">{{ data?.panNo }}</th>
                                    <th scope="row ">{{ data?.commissionName }}</th>
                                    <th scope="row ">{{ data?.pairBVMatch }}</th>
                                    <th scope="row ">{{ data?.totalAmount }}</th>
                                    <th scope="row ">{{ data?.adminChargeAmount }}</th>
                                    <th scope="row ">{{ data?.tdsAmount }}</th>
                                    <th scope="row ">{{ data?.netAmount }}</th>
                                    <th scope="row ">{{ data?.paymentDate }}</th>
                                    <th scope="row ">{{ data?.paymentType }}</th>
                                    <th scope="row ">{{ data?.bankName }}</th>
                                    <th scope="row ">{{ data?.accountNo }}</th>
                                    <th scope="row ">{{ data?.ifsc }}</th>
                            </tbody>
                        </table>
                    </div>
                </app-card>
            </div>
        </div>
    </ng-container>
    <ng-template #elseTemplate>
        <div class="spinner" *ngIf="!noData">
            <h5>Loading...</h5>
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
        </div>
        <div *ngIf="noData">
            <h1 style="text-align: center;">No Data Available</h1>
        </div>
    </ng-template>
</ng-template>
<ng-template #elseTemplate>
</ng-template>