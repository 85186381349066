<div class="auth-wrapper img-fluid align-items-stretch aut-bg-img">
    <div class="container">
        <div class="row align-items-center justify-content-center min-vh-100">

            <!-- LEFT SIDE - FORM -->
            <div class="col-lg-6 col-md-7 col-12 text-center text-md-start">
                <div class="auth-side-form p-4">
                    <div class="auth-content">
                        <img src="assets/images/logo-dark.png" alt="Logo" class="img-fluid mb-4" style="max-width: 200px;">
                        <div class="bg-white rounded p-4 shadow">
                            <form class="login-form" [formGroup]="formGroup" (ngSubmit)="login()">
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control" placeholder="Username" formControlName="username">
                                </div>
                                <div class="input-group mb-4">
                                    <input [type]="fieldTextType ? 'text' : 'password'" class="form-control" placeholder="Password" formControlName="password">
                                    <span class="input-group-text">
                                        <i class="fa" [ngClass]="{ 'fa-eye-slash': !fieldTextType, 'fa-eye': fieldTextType }" (click)="toggleFieldTextType()"></i>
                                    </span>
                                </div>
                                <button class="btn btn-success w-100 mb-3" type="submit">Sign In</button>
                            </form>
                            <div class="text-center">
                                <button class="btn btn-danger w-100 mb-2" (click)="registrationModalLong.show()">Don’t have an account? Sign Up</button>
                                <button class="btn btn-secondary w-100 mb-2" (click)="forgotPasswordModalLong.show()">Forgot Password</button>
                                <a class="btn btn-warning w-100 text-white mb-2" [routerLink]="['/auth/franchise-signin']">Franchise / CNF Log In</a>
                                <a class="btn btn-primary w-100 text-white" href="https://vedikmart.com" target="_blank">Home</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- RIGHT SIDE - IMAGE -->
            <div class="col-lg-6 col-md-5 col-12 d-flex justify-content-center justify-content-lg-end my-4 my-lg-0">
                <img src="https://s3.ap-south-1.amazonaws.com/publicimages.vedikmart.com/offer-banner.jpg" 
                     alt="Offer Banner" 
                     class="img-fluid rounded shadow-lg"
                     style="max-width: 80%; height: auto;">
            </div>

        </div>
    </div>
</div>


<app-ui-modal #registrationModalLong [dialogClass]="'modal-xl'">
    <div class="app-modal-header">
        <h5>Add Member</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close" (click)="registrationModalLong.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
        <form [formGroup]="memberGroup" class="form">
            <div class="app-modal-body">
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label class="d-block">Sponsor ID <span style="color: red;">*</span></label>
                            <input type="number" class="form-control" formControlName="sponsorId" placeholder="Sponsor ID" (keyup)="checkSponsorID($event)">
                            <p style="color: red;">{{showMessageSponsorID}}</p>
                            <div style="color: blue;" *ngIf="sponsorName">Name - <strong>{{ sponsorName }}</strong></div>
                        </div>
                    </div>
                    
                    <!-- <div style="padding-top: 30px; ">
                    <button class="btn btn-warning" style="color:black;" (click)="checkSponsorID($event)">Validate</button>
                </div> -->
                </div>
                <!-- <div class="row" *ngIf="gridView">
                <div class="col-sm-12">
                    <table datatable id="report-table" class="table table-striped table-hover mb-0 table-responsive">
                        <thead>
                            <tr>
                                <th *ngFor="let col of columns">
                                    {{col}}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let reports of allMemberDataBySponsorID">
                                <td *ngFor="let col of index">
                                    <div *ngIf="col != 'positionEligible' && col != 'positionVacant' && col != 'isActive' && col != 'isGreen' && col != 'action'">
                                        {{reports[col]}}
                                    </div>
                                    <div *ngIf="col == 'positionVacant'">
                                        <div *ngIf="reports[col] == 'Left' ">
                                            <span class="badge badge-success inline-block">Left</span>
                                        </div>
                                        <div *ngIf="reports[col] == 'Right' ">
                                            <span class="badge badge-warning inline-block m-r-5">Right</span>
                                        </div>
                                        <div *ngIf="reports[col] == 'Both' ">
                                            <span class="badge badge-primary inline-block m-r-5">Both</span>
                                        </div>
                                    </div>
                                    <div *ngIf="col == 'positionEligible'">
                                        <div *ngIf="reports[col] == 'Left' ">
                                            <span class="badge badge-success inline-block">Left</span>
                                        </div>
                                        <div *ngIf="reports[col] == 'Right' ">
                                            <span class="badge badge-warning inline-block m-r-5">Right</span>
                                        </div>
                                    </div>
                                    <div *ngIf="col == 'isActive'" class="d-inline-block">
                                        <div class="custom-control custom-switch" *ngIf="reports[col] == 1 ">
                                            <input type="checkbox" class="custom-control-input" readonly checked>
                                            <label class="custom-control-label" for="customSwitch1"></label>
                                        </div>

                                        <div class="custom-control custom-switch" *ngIf="reports[col] == 0 ">
                                            <input type="checkbox" class="custom-control-input" readonly>
                                            <label class="custom-control-label" for="customSwitch1"></label>
                                        </div>
                                    </div>
                                    <div *ngIf="col == 'isGreen'">
                                        <div class="custom-control custom-switch" *ngIf="reports[col] == true ">
                                            <input type="checkbox" class="custom-control-input" readonly checked>
                                            <label class="custom-control-label"></label>
                                        </div>

                                        <div class="custom-control custom-switch" *ngIf="reports[col] == false ">
                                            <input type="checkbox" class="custom-control-input" readonly>
                                            <label class="custom-control-label"></label>
                                        </div>
                                    </div>
                                    <div *ngIf="col == 'action'">
                                        <button class="btn-sm btn-info" style="color:black;" (click)="getRowData(reports)">Select</button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div> -->
                <div *ngIf="!gridView">
                    <div class=" row">

                        <!-- <div class="col-sm-4">
                      <label>Position : <span style="color: red;">*</span></label>
                      <div class="col-sm-6">
                          <input id="left" type="radio" value="left" name="position" formControlName="position">
                          <label style="padding-left: 5px; " for="left ">Left</label>
                      </div>
                      <div class="col-sm-6">
                          <input id="right" type="radio" value="right " name="position" formControlName="position" checked>
                          <label style=" padding-left: 5px; " for="right">Right</label>
                      </div>
                  </div> -->
                        <!-- <div class="col-sm-4">
                            <div class="form-group">
                                <label class="d-block">Parent ID <span style="color: red;">*</span></label>
                                <input type="number" class="form-control" formControlName="parentId" placeholder="Parent ID" (keyup)="checkParentID($event)">
                                <p style=" color: red; ">{{showMessageParentID}}</p>
                            </div>
                        </div> -->
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="d-block">Position : <span style="color: red;">*</span></label>
                                <select class="form-control" formControlName="position">
                              <option> </option>
                          <option *ngFor="let pos of positionReg">{{pos}}</option>
                        </select>
                            </div>
                        </div>
                        <!-- <div class="col-sm-4">
                            <div class="form-group">
                                <label class="d-block">User ID <span style="color: red;">*</span></label>
                                <input type="text " class="form-control" formControlName="userId" placeholder="User ID" (keyup)="checkUserID($event)">
                                <p style="color: red; ">{{showMessageUserID}}</p>
                            </div>
                        </div> -->
                    </div>
                    <div class="row ">
                        <div class="col-sm-4 ">
                            <div class="form-group ">
                                <label class="d-block">Name <span style="color: red;">*</span></label>
                                <input type="text" class="form-control" formControlName="name" placeholder="Name">
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="d-block ">Phone Number <span style="color: red;">*</span></label>
                                <input type="number" class="form-control" formControlName="phno" placeholder="Phone Number">
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="d-block ">Email</label>
                                <input type="email" class="form-control" formControlName="email" placeholder="Email">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="d-block">Pincode</label>
                                <input type="number" class="form-control" formControlName="pincode" placeholder="Pincode">
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="d-block">State <span style="color: red;">*</span></label>
                                <select class="form-control" formControlName="state">
                              <option> </option>
                     <option *ngFor="let states of allStatesData " value="{{states.stateCode}}">{{states.stateName}}</option>
                  </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="app-modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="registrationModalLong.hide()">Cancel</button>
                <button type="submit" *ngIf="!gridView " class="btn btn-primary" [disabled]="isSubmitting" (click)="submitMember()">Submit</button>
            </div>
        </form>

    </div>
</app-ui-modal>
<app-ui-modal #forgotPasswordModalLong [dialogClass]="'modal-md'">
    <div class="app-modal-header">
        <h5>Forgot Password</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close" (click)="forgotPasswordModalLong.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
        <form [formGroup]="forgotPasswordGroup" class="form">
            <div class="app-modal-body">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label class="d-block">Display ID <span style="color: red;">*</span></label>
                        <input type="number" class="form-control" formControlName="displayId" placeholder="Display ID">
                    </div>
                </div>
                <div class="col-sm-12">
                    <div class="form-group">
                        <label class="d-block">Last 4 Digits of Phone Number <span style="color: red;">*</span></label>
                        <input type="string" class="form-control" formControlName="phno" placeholder="Please enter (Like: XXXXXX1234)">
                    </div>
                </div>
            </div>
            <div class="app-modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal " (click)="forgotPasswordModalLong.hide() ">Cancel</button>
                <button type="submit" class="btn btn-primary " (click)="submitForgotPassword()">Submit</button>
            </div>
        </form>
    </div>
</app-ui-modal>