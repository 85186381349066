import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminComponent } from '../theme/layout/admin/admin.component';
import { AuthGuard } from './auth/guard/auth.guard';
import { DashboardComponent } from './dashboard/components/dashboard/dashboard.component';
import { CommissionGenerateComponent } from './commission-generate/commission-generate.component';

const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
        canActivate: [AuthGuard],
      },
      {
        path: 'home/dashboard',
        component: DashboardComponent,
        pathMatch: 'full',
        canActivate: [AuthGuard],
      },
      {
        path: 'home/report',
        loadChildren: () =>
          import('./report/report.module').then((module) => module.ReportModule),
      },
      {
        path: 'home/inventory',
        loadChildren: () =>
          import('./inventory/inventory.module').then((module) => module.InventoryModule),
      },
      {
        path: 'home/profile',
        loadChildren: () =>
          import('./profile/profile.module').then((module) => module.ProfileModule),
      },
      {
        path: 'home/member',
        loadChildren: () =>
          import('./member/member.module').then((module) => module.MemberModule),
      },
      {
        path: 'home/wallet',
        loadChildren: () =>
          import('./wallet/wallet.module').then((module) => module.WalletModule),
      },
      {
        path: 'home/purchase',
        loadChildren: () =>
          import('./purchase/purchase.module').then((module) => module.PurchaseModule),
      },
      {
        path: 'home/view-commission',
        loadChildren: () =>
          import('./pay-commission/pay-commission.module').then((module) => module.PayCommissionModule),
      },
      {
        path: 'home/commission-generate',
        component: CommissionGenerateComponent,
        pathMatch: 'full',
        canActivate: [AuthGuard],
      },
      {
        path: 'home/franchise',
        loadChildren: () =>
          import('./franchise/franchise.module').then((module) => module.FranchiseModule),
      },
      {
        path: 'home/franchise-purchase',
        loadChildren: () =>
          import('./franchise-purchase/franchise-purchase.module').then((module) => module.FranchisePurchaseModule),
      },
      {
        path: 'home/cnf',
        loadChildren: () =>
          import('./cnf/cnf.module').then((module) => module.CnfModule),
      },
      {
        path: 'home/stock',
        loadChildren: () =>
          import('./stock/stock.module').then((module) => module.StockModule),
      },
      {
        path: 'home/recharge',
        loadChildren: () =>
          import('./recharge/recharge.module').then((module) => module.RechargeModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MlmRoutingModule {}
