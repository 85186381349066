export class PushRequest{
	connectionType: string;
	serviceProvider: string;
	rechargeType: string;
	phNo: number;
	amount: number;
	productId: string;
}

export class Type{
  name: string;
}

export class KeyValue{
	id: string;
	name: string;
}
  
