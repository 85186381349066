<div class="row btn-page">
    <div class="col-sm-12">
        <app-card [hidHeader]="true">
            <div class="row">
                <div class="col-lg-5">
                    <div id="carouselExampleCaptions" class="carousel slide carousel-fade" data-ride="carousel">
                        <div class="carousel-inner">
                            <div class="carousel-item" [ngClass]="{'active': viewImage === item.id}" *ngFor="let item of itemImages">
                                <img src="{{item.filelocation}}" class="d-block w-100" alt="Product images">
                            </div>
                        </div>
                        <ol class="carousel-indicators position-relative">
                            <li data-target="#carouselExampleCaptions" data-slide-to="0" class="w-25 h-auto active" *ngFor="let item of itemImages" (click)="this.viewImage = item.id" [ngClass]="{'active': viewImage === item.id}">
                                <img src="{{item.filelocation}}" class="d-block wid-50" alt="Product images">
                            </li>
                        </ol>
                    </div>
                </div>
                <div class="col-lg-7">
                    <!-- <h6 class="text-muted">Jolyecart logistic</h6> -->
                    <br><br>
                    <h3 class="mt-0">{{itemDetails.itemName}} <a href="javascript: void(0);" class="text-muted"><i class="mdi mdi-square-edit-outline ml-2"></i></a> </h3>
                    <!-- <p class="mb-1">Added Date: 09/12/2018</p> -->
                    <div class="mt-3">
                        <!-- <h5><span class="badge badge-success">Instock</span></h5> -->
                    </div>
                    <div class="mt-4">
                        <h6>Distributer Price:</h6>
                        <h3>{{itemDetails.distributorPrice?.toFixed(2)}}
                            <!-- <del class="text-muted font-weight-normal h5">
                               <small>$322.53</small></del> <small class="text-success h5">86% off</small> -->
                        </h3>
                        <!-- <h5><span class="badge badge-success">3.8 <i class="feather icon-star-on"></i></span> 179 ratings and 43 reviews</h5> -->
                    </div>
                    <div class="mt-4">
                        <ul class="list-unstyled">
                            <li>
                                <div class="media">
                                    <!-- <i class="fas fa-tag text-success mr-2 mt-2"></i> -->
                                    <div class="media-body">
                                        <strong class="">Category : </strong>
                                        <span>{{itemDetails.catName}}</span>
                                        <!-- <a href="javascript:">T&amp;C</a> -->
                                    </div>
                                </div>
                            </li>
                            <li class="mt-2">
                                <div class="media">
                                    <!-- <i class="fas fa-tag text-success mr-2 mt-2"></i> -->
                                    <div class="media-body">
                                        <strong class="">Sub Category : </strong>
                                        <span>{{itemDetails.subCatName}}</span>
                                        <!-- <a href="javascript:">T&amp;C</a> -->
                                    </div>
                                </div>
                            </li>
                            <li class="mt-2">
                                <div class="media">
                                    <!-- <i class="fas fa-tag text-success mr-2 mt-2"></i> -->
                                    <div class="media-body">
                                        <strong class="">Product : </strong>
                                        <span>{{itemDetails.prodName}}</span>
                                        <!-- <a href="javascript:">T&amp;C</a> -->
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <form [formGroup]="qtyGroup" class="form">
                        <div class="col-sm-6">
                            <h6>Quantity</h6>
                            <div class="form-group">
                                <input type="number" min="1" [ngModel]="1" class="form-control" formControlName="qty">
                            </div>
                        </div>
                        <br><br>
                        <div class="row">
                            <div class="col-sm-6 text-sm-right mt-md-0 mt-2">
                                <button type="button" class="btn btn-block btn-lg btn-warning" (click)="submitQty(itemDetails)"><i class="fas fa-cart-plus mr-1"></i> Add to cart</button>
                            </div>
                            <div class="col-sm-6 text-sm-right mt-md-0 mt-2">
                                <button type="submit" class="btn btn-block btn-lg btn-danger" (click)="buyNow()"><i class="fas fa-cart-plus mr-1"></i>Buy Now</button>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="col-lg-12">
                    <div class="mt-4">
                        <h6>Description:</h6>
                        <p>{{itemDetails.itemDesc}}</p>
                        <div class="w-100">
                            <div class="row mb-2">
                                <div class="col-4">Category</div>
                                <div class="col-8">{{itemDetails.catName}}</div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-4">Sub Category</div>
                                <div class="col-8">{{itemDetails.subCatName}}</div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-4">Product</div>
                                <div class="col-8">{{itemDetails.prodName}}</div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-4">Item</div>
                                <div class="col-8">{{itemDetails.itemName}}</div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-4">MRP</div>
                                <div class="col-8">{{itemDetails.mrp?.toFixed(2)}}</div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-4">BV</div>
                                <div class="col-8">{{itemDetails.bv}}</div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-4">HSN</div>
                                <div class="col-8">{{itemDetails.hsn}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="table-responsive">
                <table id="report-table" class="table mt-4">
                    <thead class="thead-light">
                        <tr>
                            <th>Seller</th>
                            <th>Price</th>
                            <th>Stock</th>
                            <th>Revenue</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Octroyee mall USA</td>
                            <td>$139.50</td>
                            <td>
                                <div class="mb-0">
                                    <span>478 </span>
                                    <div class="progress">
                                        <div class="progress-bar bg-success" role="progressbar" style="width: 56%;" aria-valuenow="56" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>
                            </td>
                            <td>$1,89,547</td>
                        </tr>
                        <tr>
                            <td>Chromin - Brazil</td>
                            <td>$149.99</td>
                            <td>
                                <div class="mb-0">
                                    <span>73 </span>
                                    <div class="progress">
                                        <div class="progress-bar bg-danger" role="progressbar" style="width: 16%;" aria-valuenow="16" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>
                            </td>
                            <td>$87,245</td>
                        </tr>
                        <tr>
                            <td>Milan - Navsari</td>
                            <td>$106.87</td>
                            <td>
                                <div class="mb-0">
                                    <span>781 </span>
                                    <div class="progress">
                                        <div class="progress-bar bg-success" role="progressbar" style="width: 72%;" aria-valuenow="72" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>
                            </td>
                            <td>$5,87,478</td>
                        </tr>
                        <tr>
                            <td>Xendrasn - Japan</td>
                            <td>$239.66</td>
                            <td>
                                <div class="mb-0">
                                    <span>815 </span>
                                    <div class="progress">
                                        <div class="progress-bar bg-success" role="progressbar" style="width: 89%;" aria-valuenow="89" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>
                            </td>
                            <td>$55,781</td>
                        </tr>
                    </tbody>
                </table>
            </div> -->

        </app-card>
    </div>
</div>