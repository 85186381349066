import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ItemMasterService } from '../../services/item-master.service';
import { CommonComponent } from 'src/app/theme/shared/common/common.component';

@Component({
  selector: 'app-product-update',
  templateUrl: './product-update.component.html',
  styleUrls: ['./product-update.component.scss']
})
export class ProductUpdateComponent extends CommonComponent implements OnInit {
  updateProductGroup: FormGroup;
  constructor(
    private fb: FormBuilder,
    private itemMasterService: ItemMasterService
  ) {
    super();
    this.updateProductGroup = this.fb.group({
      catId: new FormControl('', [Validators.required]),
      subCatId: new FormControl('', [Validators.required]),
      prodId: new FormControl('', [Validators.required]),
      prodName: new FormControl('', [Validators.required]),
      prodDesc: new FormControl('', [Validators.required])
    });
  }

  ngOnInit(): void {
  }

  submitUpdateProduct(){

  }

  cancel(){

  }

}
