import { Component, ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserAuthService } from 'src/app/mlm/auth/services/user-auth.service';
import { AllStates } from 'src/app/mlm/member/model/allstates.model';
import { DetailsDisplayId } from 'src/app/mlm/member/model/details-displayid.model';
import { MemberRegistration, MemberRegistrationDetailsBySponsorID } from 'src/app/mlm/member/model/member-registration.model';
import { MembersService } from 'src/app/mlm/member/services/members.service';
import { DownlineService } from 'src/app/mlm/report/services/downline.service';
import { WalletService } from 'src/app/mlm/wallet/services/wallet.service';
import { ChartDB } from '../../../../fack-db/chart-data';
import { DashboardCommissionSummary, FirstPurchaseCommission, RewardsTable, TodayBusinessNosData } from '../../model/dashboard.model';
import { DailyPurchaseStatData, DailyRepurchaseStatData, Docs, Member, MonthlyPurchaseStatData, MonthlyRepurchaseStatData, PurchaseStatData, RepurchaseStatData } from '../../model/member.model';
import { DashboardService } from '../../services/dashboard.service';
import { runInThisContext } from 'vm';
import { IAlbum, Lightbox, LightboxEvent, LightboxConfig, IEvent, LIGHTBOX_EVENT } from 'ngx-lightbox';
import { Subscription } from 'rxjs';
import { CommonComponent } from 'src/app/theme/shared/common/common.component';
interface RewardTable {
  no: number;
  rank: string;
  reward: string;
  bv: string;
  balanced: string;
}

interface RepurchaseTable {
  no: number;
  rank: string;
  percentage: string;
  teamBv: string;
  selfBv: string;
}

interface DirectorTable {
  rank: string;
  pbv: string;
  firstPool: string;
  secondPool: string;
  thirdPool: string;
  fourthPool: string;
  fifthPool: string;
  sixthPool: string;
  travelFund: string;
  carFund: string;
  houseFund: string;
  total: string;
}

const rewardTable: RewardTable[] = [
  {
    no: 1,
    rank: 'Champion',
    reward: 'assets/images/gifts/1.png',
    bv: '65 thousand BV',
    balanced: '₹2,500',
  },
  {
    no: 2,
    rank: 'Rising Star',
    reward: 'assets/images/gifts/2.png',
    bv: '2.5 Lakh BV',
    balanced: '₹5,000',
  },
  {
    no: 3,
    rank: 'Super',
    reward: 'assets/images/gifts/3.png',
    bv: 'Star 5 Lakh BV',
    balanced: '₹10,000',
  },
  {
    no: 4,
    rank: 'Gold',
    reward: 'assets/images/gifts/4.png',
    bv: '10 Lakh BV',
    balanced: '₹15,000',
  },
  {
    no: 5,
    rank: 'Platinum',
    reward: 'assets/images/gifts/5.png',
    bv: '25 Lakh BV',
    balanced: '₹25,000',
  },
  {
    no: 6,
    rank: 'Novel',
    reward: 'assets/images/gifts/6.png',
    bv: '50 Lakh BV',
    balanced: '₹60,000',
  },
  {
    no: 7,
    rank: 'Over Achiever',
    reward: 'assets/images/gifts/7.png',
    bv: '1 Crore BV',
    balanced: 'Foreign Trip',
  },
  {
    no: 8,
    rank: 'Universal Leadership',
    reward: 'assets/images/gifts/8.png',
    bv: '2 Crore BV',
    balanced: '₹8,00,000',
  },
  {
    no: 9,
    rank: 'Brand Ambassador',
    reward: 'assets/images/gifts/9.png',
    bv: '4 Crore BV',
    balanced: '₹25,00,000',
  },
  {
    no: 10,
    rank: 'Pinnacle',
    reward: 'assets/images/gifts/10.png',
    bv: '10 Crore BV',
    balanced: '₹50,00,000',
  },
];

const repurchaseTable: RepurchaseTable[] = [
  {
    no: 1,
    rank: 'Distributor',
    percentage: '7%',
    teamBv: '1 - 10,000',
    selfBv: '100 Self BV',
  },
  {
    no: 2,
    rank: 'Semi Star Distributor',
    percentage: '11%',
    teamBv: '10,001 - 25,000',
    selfBv: '150 Self BV',
  },
  {
    no: 3,
    rank: 'Star Distributor',
    percentage: '15%',
    teamBv: '25,001 - 60,000',
    selfBv: '200 Self BV',
  },
  {
    no: 4,
    rank: 'Double Star Distributor',
    percentage: '18%',
    teamBv: '60,001 - 1,00,000',
    selfBv: '300 Self BV',
  },
  {
    no: 5,
    rank: 'Crown Distributor',
    percentage: '21%',
    teamBv: '1,00,001 - 1,60,000',
    selfBv: '450 Self BV',
  },
  {
    no: 6,
    rank: 'Star Crown Distributor',
    percentage: '25%',
    teamBv: '1,60,001 - above',
    selfBv: '500 Self BV',
  },
];

const directorTable: DirectorTable[] = [
  {
    rank: 'Leading Director',
    pbv: '1000',
    firstPool: '4%',
    secondPool: '',
    thirdPool: '',
    fourthPool: '',
    fifthPool: '',
    sixthPool: '',
    travelFund: '4%',
    carFund: '',
    houseFund: '',
    total: '8%',
  },
  {
    rank: 'Olive Director',
    pbv: '1000',
    firstPool: '4%',
    secondPool: '3%',
    thirdPool: '',
    fourthPool: '',
    fifthPool: '',
    sixthPool: '',
    travelFund: '4%',
    carFund: '',
    houseFund: '',
    total: '11%',
  },
  {
    rank: 'Royal Director',
    pbv: '1000',
    firstPool: '4%',
    secondPool: '3%',
    thirdPool: '2%',
    fourthPool: '',
    fifthPool: '',
    sixthPool: '',
    travelFund: '4%',
    carFund: '3%',
    houseFund: '',
    total: '16%',
  },
  {
    rank: 'Crimson Director',
    pbv: '2000',
    firstPool: '4%',
    secondPool: '3%',
    thirdPool: '2%',
    fourthPool: '1%',
    fifthPool: '',
    sixthPool: '',
    travelFund: '4%',
    carFund: '3%',
    houseFund: '',
    total: '17%',
  },
  {
    rank: 'Jewel Director',
    pbv: '2000',
    firstPool: '4%',
    secondPool: '3%',
    thirdPool: '2%',
    fourthPool: '1%',
    fifthPool: '1%',
    sixthPool: '',
    travelFund: '4%',
    carFund: '3%',
    houseFund: '2%',
    total: '20%',
  },
  {
    rank: 'Chief Director',
    pbv: '2000',
    firstPool: '4%',
    secondPool: '3%',
    thirdPool: '2%',
    fourthPool: '1%',
    fifthPool: '1%',
    sixthPool: '1%',
    travelFund: '4%',
    carFund: '3%',
    houseFund: '2%',
    total: '21%',
  },
]

@Component({
  selector: 'app-user-dashboard',
  templateUrl: './user-dashboard.component.html',
  styleUrls: ['./user-dashboard.component.scss']
})
export class UserDashboardComponent extends CommonComponent implements OnInit {
  public chartDB: any;
  sponsorName: string;
  rewardTable = rewardTable;
  repurchaseTable = repurchaseTable;
  directorTable = directorTable;
  membersData: Member[] = [];
  repurchaseStatData : RepurchaseStatData;
  dailyRepurchaseStatData: DailyRepurchaseStatData;
  monthlyRepurchaseStatData: MonthlyRepurchaseStatData;

  purchaseStatData : PurchaseStatData;
  dailyPurchaseStatData: DailyPurchaseStatData;
  monthlyPurchaseStatData: MonthlyPurchaseStatData;

  members: boolean = false;
  isBlocked: boolean = false;
  userInformations: DetailsDisplayId;

  showModal: boolean = false;
  memberGroup: FormGroup;
  searchForm: FormGroup;
  activeInactiveForm: FormGroup;
  positionValue: string;
  allStatesData: AllStates[] = [];
  allMemberDataBySponsorID: MemberRegistrationDetailsBySponsorID[] = [];
  showMessageSponsorID: string = '';
  showMessageParentID: string = '';
  showMessageDisplayID: string = '';
  showMessageUserID: string = '';
  gridView: boolean = false;
  columns = ['Downline ID','Downline Name','Position Vacant','Position Eligible','Is Active','Is Green','Contact','Action'];
  index = ['displayId','name','positionVacant','positionEligible','isActive','isGreen','phNo','action'];
  isCheckedLeft: boolean = false;
  isCheckedRight: boolean = false;
  isCheckedBoth: boolean = false;
  isSubmitting: boolean = false;
  displayId: number;
  wallet: number;
  isAdmin: boolean = false;
  totalMembers: string = '';
  directMembers: string = '';
  leftMembers: string = '';
  rightMembers: string = '';
  docsData: Docs;
  profilePicture: Docs;
  position = ['Left','Right'];
  rewardsData: RewardsTable[]=[];
  rewardsTableHeader = ['Rank Name','Team Count','Rewards','Balanced Amount','Achiever'];
  firstPurchaseCommissionData: FirstPurchaseCommission;
  todayBusinessNosData: TodayBusinessNosData;
  dashboardCommissionSummary: DashboardCommissionSummary;
  unpaidCommissionValue: number = 0;

  public activeTab: string;

  public editProfile: boolean;
  public editProfileIcon: string;

  public editContact: boolean;
  public editContactIcon: string;

  public editOtherInfo: boolean;
  public editOtherInfoIcon: string;

  public albums: Array<IAlbum>;
  private subscription: Subscription;
  currentRankImg: string = '';
  directorRank: string = '';
  firstPurchaseRank: RewardsTable;

  constructor(private el: ElementRef,
    private dashboardService: DashboardService,
    private userAuthService: UserAuthService,
    private memberService: MembersService,
    private router: Router,
    public fb: FormBuilder,
    private walletService: WalletService,
    private lightbox: Lightbox,
    private lightboxEvent: LightboxEvent,
    private lighboxConfig: LightboxConfig,
    ) {
    super();
    this.activeTab = 'thisMonth';

    this.editProfile = false;
    this.editProfileIcon = 'icon-edit';

    this.editContact = false;
    this.editContactIcon = 'icon-edit';

    this.editOtherInfo = false;
    this.editOtherInfoIcon = 'icon-edit';

    this.albums = [];
    for (let i = 1; i <= 6; i++) {
      const album = {
        src: 'assets/images/light-box/l' + i + '.jpg',
        caption: 'Image ' + i + ' caption here',
        thumb: 'assets/images/light-box/sl' + i + '.jpg'
      };

      this.albums.push(album);
    }
    lighboxConfig.fadeDuration = 1;

    this.dashboardService.getMemberData().subscribe((value) => {
      if (value) {
        this.membersData = value.data;
        this.members = true;
        console.log(this.membersData);}
    });
    this.chartDB = ChartDB;

    this.memberGroup = this.fb.group({
      sponsorId: new FormControl('', [Validators.required]),
      parentId: new FormControl('', [Validators.required]),
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required]),
      phno: new FormControl('', [Validators.required]),
      pincode: new FormControl('', [Validators.required]),
      // userId: new FormControl('', [Validators.required]),
      position: new FormControl('', [Validators.required],),
      state: new FormControl('', [Validators.required]),
    });

    this.repurchaseStatData = new RepurchaseStatData();
    this.dailyRepurchaseStatData = new DailyRepurchaseStatData();
    this.monthlyRepurchaseStatData = new MonthlyRepurchaseStatData();
    this.firstPurchaseCommissionData = new FirstPurchaseCommission();
    this.todayBusinessNosData = new TodayBusinessNosData();
    this.purchaseStatData = new PurchaseStatData();
    this.dailyPurchaseStatData = new DailyPurchaseStatData();
    this.monthlyPurchaseStatData = new MonthlyPurchaseStatData();
    this.dashboardCommissionSummary = new DashboardCommissionSummary();
  }

  ngOnInit(): void {
    this.memberService.getverifyMemberInfoDisplayId(parseInt(this.userAuthService.getDisplayId())).subscribe((val)=>{
      if(val){
        this.userInformations = val.data;
        console.log(this.userInformations);
      }
    })

    this.dashboardService.isBlocked().subscribe(
      (val) => {
        if (val) {
          this.isBlocked = val.data;
        }
      }
    );
    

    this.dashboardService.getMemberMonthlyRepurchaseStatData().subscribe((val)=>{
      if(val){
        //this.monthlyRepurchaseStatData.directorRank = "Olive Director";
        this.monthlyRepurchaseStatData.currentMonthBV = val.data.currentMonthBV ? val.data.currentMonthBV : 0;
        this.monthlyRepurchaseStatData.totalBV = val.data.totalBV ? val.data.totalBV : 0;
        this.monthlyRepurchaseStatData.totalRightBV = val.data.totalRightBV ? val.data.totalRightBV : 0;
        this.monthlyRepurchaseStatData.totalLeftBV = val.data.totalLeftBV ? val.data.totalLeftBV : 0;
        this.monthlyRepurchaseStatData.previousMonthBV = val.data.previousMonthBV ? val.data.previousMonthBV : 0;
        // if (val.data.directorRank) {
        //   this.currentRankImg = "Director";
        //   this.directorRank = val.data.directorRank;
        // } else 
        if(this.monthlyRepurchaseStatData.totalBV >= 1 && this.monthlyRepurchaseStatData.totalBV <= 10000){
          this.currentRankImg = "Distributor";
        }else if(this.monthlyRepurchaseStatData.totalBV >= 10001 && this.monthlyRepurchaseStatData.totalBV <= 25000){
          this.currentRankImg = "SemiStarDistributor";
        }else if(this.monthlyRepurchaseStatData.totalBV >= 25001 && this.monthlyRepurchaseStatData.totalBV <= 60000){
          this.currentRankImg = "StarDistributor";
        }else if(this.monthlyRepurchaseStatData.totalBV >= 60001 && this.monthlyRepurchaseStatData.totalBV <= 100000){
          this.currentRankImg = "DoubleStarDistributor";
        }else if(this.monthlyRepurchaseStatData.totalBV >= 100001 && this.monthlyRepurchaseStatData.totalBV <= 160000){
          this.currentRankImg = "CrownDistributor";
        }else if(this.monthlyRepurchaseStatData.totalBV >= 160001){
          this.currentRankImg = "StarCrownDistributor";
        }else{
          this.currentRankImg = "Distributor";
        }
      }
    })

    this.memberService.getAllStatesData().subscribe((value) => {
      this.allStatesData = value.data;
      console.log(this.allStatesData);
    });

    this.displayId = parseInt(this.userAuthService.getDisplayId());

    // this.memberGroup.controls['position'].disable();

    this.walletService.getFundAddDataRequestsView().subscribe((val)=>{
      if(val){
        console.log(val.data.walletBalance);
        this.wallet = val.data.walletBalance;
      }
    })

    if(this.userAuthService.getRoles()==="ADMIN"){
      this.isAdmin = true;
    }

    this.dashboardService.getMemberRepurchaseStatData().subscribe((val)=>{
      if(val){
        this.repurchaseStatData = val.data;
      }
    })

    this.dashboardService.getMemberDailyRepurchaseStatData().subscribe((val)=>{
      if(val){
        this.dailyRepurchaseStatData = val.data;
      }
    })



    this.dashboardService.getMemberPurchaseStatData().subscribe((val)=>{
      if(val){
        this.purchaseStatData = val.data;
      }
    })

    this.dashboardService.getMemberDailyPurchaseStatData().subscribe((val)=>{
      if(val){
        this.dailyPurchaseStatData = val.data;
      }
    })

    this.dashboardService.getMemberMonthlyPurchaseStatData().subscribe((val)=>{
      if(val){
        this.monthlyPurchaseStatData = val.data;
      }
    })

    this.dashboardService.getUserDocsData().subscribe((val)=>{
      if(val){
        this.profilePicture = new Docs();
        val.data.forEach((element: Docs) => {
          if (element.doctype == "profile") {
            this.profilePicture.filelocation = element.filelocation;
          }
        })
      }
    })

    function checkAchiever(achiever:RewardsTable) {
      return achiever.achiver == true;
    }

    this.dashboardService.getRewardsTableData().subscribe((val)=>{
      if(val){
        this.rewardsData = val.data;
        if (this.rewardsData && this.rewardsData.length > 0) {
        console.log(this.rewardsData);
          var achieverRewards:RewardsTable[] = this.rewardsData.filter(checkAchiever);
          console.log(achieverRewards);
          if (achieverRewards && achieverRewards.length > 0) {
            this.firstPurchaseRank = achieverRewards[achieverRewards.length - 1];
          }
        }
      }
    })

    this.dashboardService.getFirstPurchaseCommissionData().subscribe((val)=>{
      if(val.data){
        this.firstPurchaseCommissionData = val.data;
      }
    })

    this.dashboardService.getTodayBusinessNosData().subscribe((val)=>{
      if(val){
        console.log(val.data);
        this.todayBusinessNosData = val.data;
      }
    })

    this.dashboardService.getDashboardCommissionSummaryData().subscribe((val)=>{
      if(val){
        console.log(val.data);
        this.dashboardCommissionSummary = val.data;
      }
    })

    this.dashboardService.getDashboardUnpaidCommissionValue().subscribe((val)=>{
      if(val.data){
        this.unpaidCommissionValue = val.data;
      }
    })

  }

  ngAfterViewInit() {
    const video = document.getElementById('offerVideo') as HTMLVideoElement;
    
    if (video) {
      video.muted = true; // Ensure the video is muted
      video.play().catch(error => {
        console.error('Autoplay blocked:', error);
      });
    }
  }

  kycApprove(){
    this.router.navigateByUrl("/home/profile/kyc-verification");
  }

  viewOffers(){
    this.router.navigateByUrl("/home/report/view-offers");
  }

  printCard() {
    const imageUrl = this.profilePicture?.filelocation ? this.profilePicture.filelocation : 'assets/images/user/avatar-sample.jpg';
    const printContents = `
      <div class="row">
        <div class="col-sm-12 tab-card">
          <div class="row mb-n4">
            <div class="col-xl-4 col-md-6">
             <app-card [customHeader]="true" headerClass="border-0 p-2 pb-0" [options]="false" cardClass="user-card user-card-1" blockClass="pt-0 users-contact">
                <div class="app-card-header">
                <div class="cover-img-block" style="width: 25%; height:25%; margin: auto; display: block">
                    <img src="assets/images/widget/Vedikmart_cover.jpg" alt="" class="img-fluid">
                  </div>
                </div>
                <div class="user-about-block text-center">
                  <div class="row align-items-end">
                    <div class="col text-left">
                    </div>
                    <div class="col">
                      <div class="position-relative d-inline-block">
                        <img class="img-radius img-fluid wid-80" src="${imageUrl}">
                      </div>
                    </div>
                    <div class="col"></div>
                  </div>
                </div>
                <div class="text-center">
                  <h6 class="mb-1" style="font-weight: bold;">${this.userInformations?.name}</h6>
                  <p class="mb-0">Display ID : ${this.userInformations?.displayId}</p>
                  <p class="mb-0">Sponsor ID : ${this.userInformations?.sponsorID}</p>
                  <p class="mb-0">Joining Date : ${this.userInformations?.createdOn.substring(0, 10)}</p>
                  <p class=" mb-2">Address : ${this.userInformations?.cityVillage}</p>
                </div>
                <hr class="wid-80 b-wid-3">
                <div class="text-center wbsite" style="color: black;">
                  Website : www.vedikmart.com
                </div>
              </app-card>
            </div>
          </div>
        </div>
      </div>
    `;
    const originalContents = document.body.innerHTML;
  
    document.body.innerHTML = printContents;
  
    window.print();
  
    document.body.innerHTML = originalContents;
  }

  update(){
    this.router.navigateByUrl("/home/profile/update-profile");
  }

  submitMember() {
    if (this.isSubmitting) {
      return;
    }
    this.isSubmitting = true;
    console.log(this.memberGroup.value);
    const memberPostForm = new MemberRegistration();
    memberPostForm.sponsorID = this.memberGroup.controls['sponsorId'].value;
    memberPostForm.parentID = this.memberGroup.controls['sponsorId'].value;
    memberPostForm.name = this.memberGroup.controls['name'].value;
    memberPostForm.emailId = this.memberGroup.controls['email'].value;
    memberPostForm.phNo = this.memberGroup.controls['phno'].value;
    memberPostForm.pinCode = this.memberGroup.controls['pincode'].value;
    // memberPostForm.userName = this.memberGroup.controls['userId'].value;
    memberPostForm.isleft = ((this.memberGroup.controls['position'].value)=="Left")?true:false;
    memberPostForm.stateCode = this.memberGroup.controls['state'].value;

    if(this.showMessageSponsorID.length == 0 && this.memberGroup.controls['sponsorId'].value){
      //if(this.showMessageParentID.length == 0 && this.memberGroup.controls['parentId'].value){
        this.memberService.postRegistrationData(memberPostForm).subscribe((val) => {
          if (val.data) {
            console.log('Data Updated');
            const name = val.data.name;
            const displayId = val.data.displayId;
            const msg = 'Hi' + name + 'Welcome to Vedikmart\n' + 'Your ID is :' + displayId;
            this.successSwal(msg);
            // alert('Registration completed successfully!');
            this.memberGroup.reset();
            this.isSubmitting = false;
          } else {
            if(val.message){
              this.warningSwal(val.message);
              this.isSubmitting = false;
            }else{
              this.dangerSwal('Please fill the mandatory fields');
              // alert("Please fill the mandatory fields");
              this.isSubmitting = false;
            }
          }
        });
      // }else{
      //   this.dangerSwal('Please enter desire Parent ID');
      //   // alert("Please enter desire Parent ID");
      // }
    }else{
      this.dangerSwal('Please enter desire Sponsor ID');
      this.isSubmitting = false;
      // alert("Please enter desire Sponsor ID");
    }
  }

  show() {
    this.showModal = true;
  }

  hide() {
    this.showModal = false;
  }

  checkSponsorID(event: any) {
    this.sponsorName = '';
    let sponsorId = this.memberGroup.controls['sponsorId'].value;
    // if(displayId){
    //   this.memberService.getverifyMemberInfoDisplayId(displayId).subscribe((val) => {
    //     if (val.data) {
    //       console.log("matched");
    //       this.showMessageSponsorID ='';
    //       this.memberService.getRgistrationEligibleSponsorOrg(displayId).subscribe((value) => {
    //         this.gridView = true;
    //         this.allMemberDataBySponsorID = value.data;
    //         console.log(this.allMemberDataBySponsorID);
    //       });
    //     }
    //     else{
    //       this.showMessageSponsorID = "Please enter desire Sponsor ID";
    //       this.gridView = false;
    //     }
    //   });
    // }
    if (sponsorId) {
      this.memberService
        .getverifyMemberInfoDisplayId(sponsorId)
        .subscribe((val) => {
          if (val.data) {
            console.log('matched');
            this.showMessageSponsorID = '';
            this.sponsorName = val.data.name;
          } else {
              this.showMessageSponsorID = 'Please enter desire Sponsor ID';
          }
        });
    }
  }

  checkParentID(event: any){
    let parentId = this.memberGroup.controls['parentId'].value;
    if(parentId){
      this.memberService.getverifyMemberInfoDisplayId(parentId).subscribe((val) => {
        if (val.data) {
          console.log("matched");
          this.showMessageParentID ='';
        }
        else{
          this.showMessageParentID = "Please enter desire Parent ID";
        }
      });
    }
  }

  checkUserID(event: any){
    let userId = this.memberGroup.controls['userId'].value;
    if(userId){
      this.memberService.getverifyMemberInfoNameDuplication(userId).subscribe((val) => {
        if (val.data) {
          console.log("matched");
          this.showMessageUserID = "User ID already exists";
        }
        else{
          this.showMessageUserID ='';
        }
      });
    }
  }

  getRowData(reports: any){
    this.gridView = false;
    this.memberGroup.controls['parentId'].setValue(reports.displayId);
    if(reports.positionVacant == 'Left'){
      this.isCheckedLeft = true;
      this.isCheckedRight = false;
      this.isCheckedBoth = false;
    }else if(reports.positionVacant == 'Right'){
      this.isCheckedLeft = false;
      this.isCheckedRight = true;
      this.isCheckedBoth = false;
    }else{
      this.isCheckedLeft = false;
      this.isCheckedRight = false;
      this.isCheckedBoth = true;
    }

    if(reports.positionEligible === 'Left'){
      this.memberGroup.controls['position'].setValue("Left");
    }else if(reports.positionEligible === 'Right'){
      this.memberGroup.controls['position'].setValue("Right");
    }
  }

  walletAdd(){
    this.router.navigate(['/home/wallet/fund-add']);
  }

  open(index: number): void {
    this.subscription = this.lightboxEvent.lightboxEvent$.subscribe((event: IEvent) => this._onReceivedEvent(event));
    this.lightbox.open(this.albums, index, { wrapAround: true, showImageNumberLabel: true });
  }

  private _onReceivedEvent(event: IEvent): void {
    if (event.id === LIGHTBOX_EVENT.CLOSE) {
      this.subscription.unsubscribe();
    }
  }

  recharge(){
    this.router.navigateByUrl("/home/recharge/recharge");
  }
}
