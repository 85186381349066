<div class="row btn-page">
    <div class="col-md-6 col-sm-12 mb-4">
        <app-card [cardTitle]="'Welcome CNF - ' + franchiseName + '!'" [options]="false" *ngIf="gridView">
            <div class="card-body text-center py-4">
                <i class="fa fa-id-card fa-3x text-success mb-3"></i>
                <p class="mb-2 fs-5 text-dark">
                    Your CNF ID is: <b class="text-primary">{{ displayId }}</b>
                </p>
                <!-- Purchase Button -->
                <div class="row text-center buttons">
                    <div class="col">
                        <button (click)="pendingStocks()" class="mb-0 btn-sm btn-success custom-btn">View Pending Franchise Stock Requests</button>
                    </div>
                </div>
            </div>
        </app-card>
    </div>

    <!-- Wallet Balance Card -->
    <div class="col-md-6 col-sm-12 mb-4">
        <app-card cardTitle="Your Wallet Balance" [options]="false" *ngIf="gridView">
            <div class="card-body text-center py-4">
                <i class="fa fa-wallet fa-3x text-success mb-3"></i>
                <h3 class="text-success fw-bold mb-2">
                    ₹{{ walletBalance ? walletBalance.toFixed(2) : '0.00' }}
                </h3>    
                <!-- Conditional Warning Message -->
                <div *ngIf="walletBalance < 1000" class="text-danger fw-bold mt-2">
                    Warning: Your wallet balance is low.
                </div>
                <div class="row text-center buttons mt-3">
                    <div class="col">
                        <button (click)="addWallet()" class="mb-0 btn-sm btn-success custom-btn">Add Wallet Balance</button>
                    </div>
                    <div class="col">
                        <button (click)="addStock()" class="mb-0 btn-sm btn-success custom-btn">Add More Stocks</button>
                    </div>
                </div>  
            </div>
        </app-card>
    </div>
</div>

<!-- Data Table Section -->
<ng-container *ngIf="!loading; then thenTemplate; else elseTemplate"></ng-container>
<ng-template #thenTemplate>
    <ng-container *ngIf="isDataAvailable; else noDataTemplate">
        <div class="mb-4">
            <div class="col-sm-12">
                <app-card [hidHeader]="true" cardClass="card-datatable shadow-lg rounded">
                    <div class="row align-items-center mb-3 mx-0">
                        <div class="col-sm-6">
                            <h5 class="text-primary fw-bold">Your Available Stock</h5>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table id="excel-table" class="table table-striped table-hover mb-0 table-bordered">
                            <thead class="table-light">
                                <tr>
                                    <th>Item Image</th>
                                    <th>Item Name</th>
                                    <th>Total Stock Quantity</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of allItemsDetailsData; let i=index;">
                                    <td>
                                        <img [src]="item?.imageUrl" class="img-fluid rounded shadow-sm" style="width: 80px; height: auto;">
                                    </td>
                                    <td>{{ item?.name }}</td>
                                    <td>{{ item?.quantity }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </app-card>
            </div>
        </div>
    </ng-container>
</ng-template>

<!-- Loading and No Data Templates -->
<ng-template #elseTemplate>
    <div class="d-flex justify-content-center align-items-center" style="height: 150px;">
        <div class="spinner">
            <h5 class="text-primary mb-3">Loading...</h5>
            <div class="bounce1 bg-primary"></div>
            <div class="bounce2 bg-primary"></div>
            <div class="bounce3 bg-primary"></div>
        </div>
    </div>
</ng-template>

<ng-template #noDataTemplate>
    <div class="text-center py-5">
        <h1 class="text-muted">No Data Available</h1>
    </div>
</ng-template>
