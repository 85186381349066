import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbCarouselModule, NgbDatepickerModule, NgbDropdownModule, NgbTabsetModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
import { SharedModule } from '../../theme/shared/shared.module';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { ArchwizardModule } from 'angular-archwizard';
import { FranchiseRoutingModule } from './franchise-routing.module';
import { FranchiseRequestComponent } from '../franchise/components/franchise-request/franchise-request.component';
import { FranchiseRequestStatusComponent } from './components/franchise-request-status/franchise-request-status.component';
import { FranchiseRequestApprovalComponent } from './components/franchise-request-approval/franchise-request-approval.component';
import { FranchiseMembersComponent } from './components/franchise-members/franchise-members.component';
import { FranchiseMembersDetailsComponent } from './components/franchise-members-details/franchise-members-details.component';
@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    NgbTabsetModule,
    NgbDropdownModule,
    NgbCarouselModule,
    NgbDatepickerModule,
    FormsModule,
    DataTablesModule,
    ReactiveFormsModule,
    FranchiseRoutingModule,
    ArchwizardModule,
    NgxQRCodeModule
  ],
  declarations: [
    FranchiseRequestComponent,
    FranchiseRequestStatusComponent,
    FranchiseRequestApprovalComponent,
    FranchiseMembersComponent,
    FranchiseMembersDetailsComponent
  ]
})
export class FranchiseModule { }
