import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Docs } from 'src/app/mlm/dashboard/model/member.model';
import { ItemsDetails } from 'src/app/mlm/purchase/model/purchase.model';
import { ItemMasterService } from '../../services/item-master.service';
import { CommonComponent } from 'src/app/theme/shared/common/common.component';

@Component({
  selector: 'app-item-image',
  templateUrl: './item-image.component.html',
  styleUrls: ['./item-image.component.scss'],
})
export class ItemImageComponent extends CommonComponent implements OnInit {
  formData: any;
  urls = new Array<string>();
  ItemImageGroup: FormGroup;
  fileToUpload: any;
  selectedFiles: any[];
  selFiles: FileList;
  files: any;
  myFiles: string[] = [];
  itemImages= new Array<string>();
  imagesView: boolean = false;
  allItemsData: ItemsDetails[]=[];
  constructor(
    private itemMasterService: ItemMasterService,
    public fb: FormBuilder
  ) {
    super();
    this.ItemImageGroup = this.fb.group({
      itemId: new FormControl('', [Validators.required]),
      itemImage: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.itemMasterService.getItemsData().subscribe((val)=>{
      if(val){
        this.allItemsData = val.data;
      }
    })
  }

  getFileDetails(e) {
    this.urls = [];
    this.files = e.target.files;
    if (this.files) {
      for (let file of this.files) {
        let reader = new FileReader();
        reader.onload = (e: any) => {
          this.urls.push(e.target.result);
        };
        reader.readAsDataURL(file);
      }
    }
    for (var i = 0; i < e.target.files.length; i++) {
      this.myFiles.push(e.target.files[i]);
    }
  }

  getItemId(itemName: string){
    let itemId: any;
    if(this.allItemsData){
      this.allItemsData.forEach(element=>{
        if(element.itemName === itemName){
          itemId = element.itemId;
        }
      })
    }
    return itemId;
 }

  submitItemImage() {
    if(this.ItemImageGroup.controls['itemId'].value && this.ItemImageGroup.controls['itemImage'].value){
      this.formData = new FormData();
      this.formData.append('imagetype', 'details');
      this.formData.append(
        'itemId',
        this.ItemImageGroup.controls['itemId'].value
      );
      for (var i = 0; i < this.myFiles.length; i++) {
        this.formData.append('file', this.myFiles[i]);
      }
      this.itemMasterService
        .postItemImageUploadData(
          this.formData,
          this.getItemId(this.ItemImageGroup.controls['itemId'].value)
        )
        .subscribe((val) => {
          if (val) {
            this.successSwal('Item Images Updated Successfully');
            this.urls = [];
            this.myFiles = [];
            this.ItemImageGroup.reset();
          }
        });
    }else{
      this.warningSwal("Required field is empty");
    }

  }

  cancelItemImage() {
    this.urls = [];
    this.myFiles = [];
    this.ItemImageGroup.reset();
    this.imagesView = false;
  }

  checkPreviousImages($event){
    if(this.ItemImageGroup.controls['itemId'].value){
      this.itemImages = [];
      this.itemMasterService.getItemImageUploadData(this.getItemId(this.ItemImageGroup.controls['itemId'].value)).subscribe((val)=>{
        if(val){
          this.imagesView = true;
          val.data.forEach((element: any) => {
            if (element.imagetype == "details") {
              this.itemImages.push(element);
            }
          })
        }
      })
    }else{
      this.dangerSwal("Please choose item name");
    }
    console.log(this.itemImages);
  }
}
