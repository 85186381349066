export class ProductMasterTableData {
  serviceType: string;
  serviceName: string;
  id: number;
  isActive: boolean;
  rechargeType: string;
  serviceProvider: string;
  commissionPercentage: number;
}

export class UpdateProductMaster{
    id: number;
    connectionType: string;
    serviceProvider: string;
    rechargeType: string;
    commissionPercentage: number;
    isActive: boolean;
}
