export class RewardsTable {
  balancedAmount: string;
  enteredAt: string;
  enteredBy: string;
  rankName: string;
  teamCount: number;
  achiver: boolean;
  imgloc: string;
}

export class FirstPurchaseCommission {
  displayId: number;
  groupSalesIncome: number;
  salesGrowthIncome: number;
  royaltyIncome: number;
  travelIncome: number;
  carIncome: number;
  houseIncome: number;
}

export class TodayBusinessNosData {
  todayActivationCount: number;
  todayJoiningCount: number;
}

export class DashboardCommissionSummary{
  displayId: number;
  groupSalesIncome: number;
  salesGrowthIncome: number;
  totalIncome: number;
  totalCommission: number;
  totalUnpaid: number;
  totalPaid: number;
  lastCommission: number;
  lastCommissionDate: string;
}
