<ng-container *ngIf="!item.hidden">
    <li *ngIf="themeLayout === 'horizontal'" (mouseenter)="navCollapse($event)" class="nav-item pcoded-hasmenu" [routerLinkActive]="['active']">
        <a [routerLinkActive]="['active']" href="javascript:" class="nav-link">
            <ng-container *ngTemplateOutlet="itemContent"></ng-container>
        </a>
        <ng-container *ngTemplateOutlet="subMenuContent"></ng-container>
    </li>
    <li *ngIf="themeLayout === 'vertical'" class="nav-item pcoded-hasmenu" [routerLinkActive]="['active']">
        <a [routerLinkActive]="['active']" href="javascript:" class="nav-link" (click)="navCollapse($event)">
            <ng-container *ngTemplateOutlet="itemContent"></ng-container>
        </a>
        <ng-container *ngTemplateOutlet="subMenuContent"></ng-container>
    </li>
    <ng-template #itemContent>
        <span class="pcoded-micon" *ngIf="item.icon">
        <i class="{{item.icon}}"></i>
      </span>
        <span class="pcoded-mtext">{{item.title}}
        <span *ngIf="item.badge && themeLayout === 'horizontal'" class="badge label" [ngClass]="item.badge.type">
          {{item.badge.title}}
        </span>
        </span>
        <span *ngIf="item.badge && themeLayout === 'vertical'" class="pcoded-badge badge" [ngClass]="item.badge.type">
        {{item.badge.title}}
      </span>
    </ng-template>
    <ng-template #subMenuContent>
        <ul class="pcoded-submenu" [routerLinkActive]="['active']">
            <ng-container *ngFor="let item of item.children">
                <app-nav-group *ngIf="item.type=='group'" [item]="item"></app-nav-group>
                <app-nav-collapse *ngIf="item.type=='collapse'" [item]="item"></app-nav-collapse>
                <app-nav-item *ngIf="item.type=='item' && item.id!='item-master' && item.id!='item-image'
                && item.id!='bi-module' && item.id!='gst-monthwise' && item.id!='commissions-tds' && item.id!='gst-statewise'
                && item.id!='firstpurchase-rank' && item.id!='stock-entry' && item.id!='stock-search'
                && item.id!='category-update' && item.id!='sub-category-update' && item.id!='product-update'
                && item.id!='purchase-delete' && item.id!='franchise-request-approval'
                && item.id!='franchise-balance-requests' && item.id!='cnf-balance-requests'
                && item.id!='balance-request-status' && item.id!='payment-status-report'
                && item.id!='monthwise-commission' && item.id!='all-commissions'
                && item.id!='franchise-members' && item.id!='product-master'
                && item.id!='add-cnf' && item.id!='cnf-members'
                && item.id!='view-balance' && item.id!='shop'
                && item.id!='cart' && item.id!='orders' && item.id!='paid-commission'
                && item.id!='member-wallet-balance'
                && !isAdmin && !isFranchise && !isCnf"
                            [item]="item"></app-nav-item>

                <app-nav-item *ngIf="item.type=='item'
                && item.id!='franchise-balance-requests'
                && item.id!='cnf-balance-requests'
                && item.id!='member-wallet-balance'
                && isFranchise && !isCnf" [item]="item"></app-nav-item>

                <app-nav-item *ngIf="item.type=='item'
                && item.id!='add-cnf' && item.id!='cnf-members'
                && item.id!='franchise-balance-requests'
                && item.id!='cnf-balance-requests'
                && item.id!='member-wallet-balance'
                && !isFranchise && isCnf" [item]="item"></app-nav-item>

                <app-nav-item *ngIf="item.type=='item'
                && item.id!='balance-request-status'
                && item.id!='add-stock'
                && isAdmin && !isFranchise && !isCnf" [item]="item"></app-nav-item>
            </ng-container>
        </ul>
    </ng-template>
</ng-container>
