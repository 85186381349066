import { Component, OnInit } from '@angular/core';
import { RechargeService } from '../../services/recharge.service';
import { CommonComponent } from 'src/app/theme/shared/common/common.component';

@Component({
  selector: 'app-view-balance',
  templateUrl: './view-balance.component.html',
  styleUrls: ['./view-balance.component.scss']
})
export class ViewBalanceComponent extends CommonComponent implements OnInit {
  currentBalance: number;
  currentDatetime: string;

  constructor(
    private rechargeService: RechargeService
  ) {
    super();
  }

  ngOnInit(): void {
    this.rechargeService.getBalanceData().subscribe((val) => {
      if (val?.data?.data && Array.isArray(val.data.data) && val.data.data.length > 0) {
        this.currentBalance = val.data.data[0].balance; // Access the first element
      }
    });
  }  

}
