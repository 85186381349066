import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbCarouselModule, NgbDatepickerModule, NgbDropdownModule, NgbTabsetModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
import { SharedModule } from '../../theme/shared/shared.module';
import { FranchisePurchaseRoutingModule } from './franchise-purchase-routing.module';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { ArchwizardModule } from 'angular-archwizard';
import { CartComponent } from '../franchise-purchase/components/cart/cart.component';
import { CheckoutComponent } from '../franchise-purchase/components/checkout/checkout.component';
import { ItemDetailsComponent } from '../franchise-purchase/components/item-details/item-details.component';
import { PurchaseComponent } from '../franchise-purchase/components/purchase/purchase.component';
import { ShopComponent } from '../franchise-purchase/components/shop/shop.component';
import { PurchaseReportComponent } from './components/purchase-report/purchase-report.component';
import { AmountToWordPipe } from './pipes/amount-to-word.pipe';
import { MultiPurchaseComponent } from './components/multi-purchase/multi-purchase.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    NgbTabsetModule,
    NgbDropdownModule,
    NgbCarouselModule,
    NgbDatepickerModule,
    FormsModule,
    DataTablesModule,
    ReactiveFormsModule,
    FranchisePurchaseRoutingModule,
    ArchwizardModule,
    NgxQRCodeModule
  ],
  declarations: [
    ShopComponent,
    CartComponent,
    ItemDetailsComponent,
    CheckoutComponent,
    PurchaseComponent,
    PurchaseReportComponent,
    AmountToWordPipe,
    MultiPurchaseComponent
  ]
})
export class FranchisePurchaseModule { }
