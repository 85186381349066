import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserAuthService } from 'src/app/mlm/auth/services/user-auth.service';
import { ExcelExportService } from 'src/app/mlm/report/services/excel-export.service';
import { ViewCommissionService } from '../../services/view-commission.service';
import { CommissionGenrateDates, PayUnpaidCommission, PayUnpaidCommissionDataPost, UnpaidCommissionDetails, UnpaidCommssionGenerateData } from '../../model/view-commission.model';
import { DownlineService } from 'src/app/mlm/report/services/downline.service';
import { PaymentStatusForm, PaymentStatusReportTableData } from 'src/app/mlm/report/model/payment-status-report.model';
import { CommonComponent } from 'src/app/theme/shared/common/common.component';

@Component({
  selector: 'app-monthwise-commission',
  templateUrl: './monthwise-commission.component.html',
  styleUrls: ['./monthwise-commission.component.scss']
})
export class MonthwiseCommissionComponent extends CommonComponent implements OnInit {
  unpaidCommissionGroup: FormGroup;
  paymentMethodGroup: FormGroup;
  allDatesData: CommissionGenrateDates[] = [];
  paymentStatusReportTableData: PaymentStatusReportTableData[] = [];
  paymentStatusReportTableDataSecondary: PaymentStatusReportTableData[] = [];
  noData: boolean;
  loading: boolean = true;
  isDataAvailable: boolean;
  gridView: boolean = false;
  fileName = 'ExcelSheet.xlsx';
  columns = [
    'Checked',
    'Display Id',
    'Name',
    'Total Amount',
    'Admin Charge',
    'TDS',
    'Net Amount',
    'Payment Date',
    'Payment Status',
    'Account No',
    'Bank Name',
    'IFSC',
    'Action'
  ];
  totalIncome : number = 0;
  totalAdminChargeAmount : number = 0;
  totalTDSAmount : number = 0;
  totalNetAmount : number = 0;
  all: boolean = false;
  error: boolean = false;
  unpaidCommissionDetailsData: UnpaidCommissionDetails[] = [];
  unpaidDetailsGridView: boolean = false;
  columnsDetailsBiMonthly = [
    'Commission Name',
    'Number of Pair',
    'Amount'
  ];
  columnsDetailsMonthly = [
    'Commission Name',
    'Number of Pair/BV',
    'Unit Income',
    'Total Income'
  ];
  showModal: boolean = false;
  allChecked: boolean = false;
  gridViewSecondary: boolean = false;
  fromDate: string = '';
  toDate: string = '';
  payUnpaidCommissionList: PayUnpaidCommission[] = [];

  constructor(
    private viewCommissionService: ViewCommissionService,
    private fb: FormBuilder,
    private userAuthService: UserAuthService,
    private excelExportService: ExcelExportService,
    private downlineService: DownlineService
  ) {
    super();
    this.unpaidCommissionGroup = this.fb.group({
      fromDateToDate: new FormControl('', [Validators.required]),
    });
    this.paymentMethodGroup = this.fb.group({
      paymentMethod: new FormControl('', [Validators.required]),
    });
    this.isDataAvailable = this.paymentStatusReportTableData?.length > 0;
  }

  ngOnInit(): void {
    this.viewCommissionService.getCommissionGenerateDateData('true').subscribe((val)=>{
      if(val.data){
        this.allDatesData = val.data;
      }
    })
  }

  submitCommissionGenerated(){
      if(this.unpaidCommissionGroup.controls['fromDateToDate'].value){
        const paymentStatusReportFormData = new PaymentStatusForm();
        paymentStatusReportFormData.displayId = 0;
        paymentStatusReportFormData.fromDate = this.unpaidCommissionGroup.controls['fromDateToDate'].value.substring(0,10);
        paymentStatusReportFormData.toDate = this.unpaidCommissionGroup.controls['fromDateToDate'].value.substring(13,23);
        this.fromDate = this.unpaidCommissionGroup.controls['fromDateToDate'].value.substring(0,10);
        this.toDate = this.unpaidCommissionGroup.controls['fromDateToDate'].value.substring(13,23);
        paymentStatusReportFormData.status = false;
        this.paymentStatusReportTableData = [];
        this.gridView = false;
        this.gridViewSecondary = false;
        this.downlineService.getPaymentStatusData(paymentStatusReportFormData).subscribe((val)=>{
          if(val){
            this.paymentStatusReportTableData = val.data;
            this.paymentStatusReportTableData.forEach(element=>{
              element.checked = false;
              this.totalIncome = this.totalIncome + element.totalIncome;
              this.totalAdminChargeAmount = this.totalAdminChargeAmount + element.adminCharge;
              this.totalTDSAmount = this.totalTDSAmount + element.tdsAmount;
              this.totalNetAmount = this.totalNetAmount + element.netAmount;
            })
            this.noData = this.paymentStatusReportTableData?.length === 0;
            this.isDataAvailable = this.paymentStatusReportTableData?.length > 0;
            this.gridView = true;
            this.gridViewSecondary = true;
          }
          this.loading = false;
        })
      }else{
        this.warningSwal("Please select a Date Range from the list");
      }
  }

  cancel(){
    this.unpaidCommissionGroup.reset();
    this.gridView = false;
  }

  checkAllCheckBox(ev: any) {
    this.gridViewSecondary = false;
    this.paymentStatusReportTableData.forEach(x => x.checked = ev.target.checked)
    setTimeout(() => {
      if(ev.target.checked){
        this.paymentStatusReportTableDataSecondary = this.paymentStatusReportTableData;
        // this.paymentStatusReportTableDataSecondary = [];
        this.gridViewSecondary = true;
      }else{
        this.gridViewSecondary = false;
        this.paymentStatusReportTableDataSecondary = [];
      }
    }, 1000);
  }

  isAllCheckBoxChecked() {
    return this.paymentStatusReportTableData.every(p => p.checked);
  }

  exportexcel(): void {
    this.excelExportService.exportExcel(
      this.paymentStatusReportTableData,
      'monthwise commission'
    );
  }

  cancelPayment(){
    this.paymentMethodGroup.reset();
  }

  show() {
    this.showModal = true;
  }

  unpaidDetailsGenerate(data: UnpaidCommssionGenerateData){
    this.unpaidDetailsGridView = false;
    this.unpaidCommissionDetailsData = [];
    console.log(data);
    this.viewCommissionService.getAllUnpaidCommissionDetailsData(data.displayId,this.fromDate,this.toDate).subscribe((val)=>{
      if(val){
        this.unpaidCommissionDetailsData = val.data;
        this.unpaidDetailsGridView = true;
      }
    })
    this.show();
  }

  hide() {
    this.showModal = false;
  }

  onCheckboxChangeSecondary(event: any,data: any,i: any) {
    this.gridView = false;
    let temp = this.paymentStatusReportTableDataSecondary;
    this.paymentStatusReportTableDataSecondary=[];
    setTimeout(() => {
      if (!event.target.checked) {
        this.paymentStatusReportTableDataSecondary=temp;
        this.paymentStatusReportTableDataSecondary.splice(i, 1);
        this.paymentStatusReportTableData.forEach(element => {
          if(element.displayId == data.displayId){
            element.checked = false;
          }
        });
      }
      this.gridView = true;
      // if(this.paymentStatusReportTableDataSecondary.length>0){
      //   this.gridViewSecondary = true;
      // }else{
      //   this.gridViewSecondary = false;
      // }
    }, 1000);
  }

  onCheckboxChange(event: any,data: any) {
    this.gridView = false;
    let tempSecondary = this.paymentStatusReportTableDataSecondary;
    let temp = this.paymentStatusReportTableData;
    this.paymentStatusReportTableDataSecondary=[];
    setTimeout(() => {
      if (event.target.checked) {
        this.paymentStatusReportTableDataSecondary=tempSecondary;
        this.paymentStatusReportTableData=temp;
        this.paymentStatusReportTableDataSecondary.push(data);
        this.gridViewSecondary = true;
      } else {
        let i=-1,m=0;
        this.paymentStatusReportTableDataSecondary=tempSecondary;
        this.paymentStatusReportTableData=temp;
        this.paymentStatusReportTableDataSecondary.forEach(element => {
          i++;
          if(element.displayId == data.displayId){
            m=i;
          }
        });
        this.paymentStatusReportTableDataSecondary.splice(m, 1);
      }
      // if(this.paymentStatusReportTableDataSecondary.length>0){
      //   this.gridViewSecondary = true;
      // }else{
      //   this.gridViewSecondary = false;
      // }
      this.gridView = true;
    }, 1000);
  }

  payUnpaid(){
    if(this.paymentMethodGroup.controls['paymentMethod'].value === 'Wallet'){
      this.paymentStatusReportTableData.forEach(element=>{
        if(element.checked === true){
          const payUnpaidCommssion = new PayUnpaidCommission();
          payUnpaidCommssion.displayId = element.displayId.toString();
          payUnpaidCommssion.paymentType = 'wallet';
          payUnpaidCommssion.fromDate = this.fromDate;
          payUnpaidCommssion.toDate = this.toDate;
          this.payUnpaidCommissionList.push(payUnpaidCommssion);
        }
      });
      let payUnpaidCommissionDataPost = new PayUnpaidCommissionDataPost();
      payUnpaidCommissionDataPost.payUnpaidCommission = this.payUnpaidCommissionList;
      this.viewCommissionService.postAllUnpaidCommissionData(payUnpaidCommissionDataPost).subscribe((val)=>{
        if(val.errorCode){
          this.error  = true;
          this.dangerSwal(val.message);
          this.payUnpaidCommissionList = [];
        }else{
          this.successSwal("Transaction completed successfully through Wallet");
          this.payUnpaidCommissionList = [];
          this.viewCommissionService.getCommissionGenerateDateData('true').subscribe((val)=>{
            if(val.data){
              this.allDatesData = val.data;
            }
          })
          this.gridView = false;
        }
      });
    }else if(this.paymentMethodGroup.controls['paymentMethod'].value === 'Bank'){
      this.paymentStatusReportTableData.forEach(element=>{
        if(element.checked === true){
          const payUnpaidCommssion = new PayUnpaidCommission();
          payUnpaidCommssion.displayId = element.displayId.toString();
          payUnpaidCommssion.paymentType = 'bank';
          payUnpaidCommssion.fromDate = this.fromDate;
          payUnpaidCommssion.toDate = this.toDate;
          this.payUnpaidCommissionList.push(payUnpaidCommssion);
        }
      });
      let payUnpaidCommissionDataPost = new PayUnpaidCommissionDataPost();
      payUnpaidCommissionDataPost.payUnpaidCommission = this.payUnpaidCommissionList;
      this.viewCommissionService.postAllUnpaidCommissionData(payUnpaidCommissionDataPost).subscribe((val)=>{
        if(val.errorCode){
          this.error  = true;
          this.dangerSwal(val.message);
          this.payUnpaidCommissionList = [];
        }else{
          this.successSwal("Transaction completed successfully through Bank");
          this.payUnpaidCommissionList = [];
          this.viewCommissionService.getCommissionGenerateDateData('true').subscribe((val)=>{
            if(val.data){
              this.allDatesData = val.data;
            }
          })
          this.gridView = false;
        }
      });
    }
    else{
      this.warningSwal("Please select a payment method");
    }
  }
}
