import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ItemMasterService } from 'src/app/mlm/inventory/services/item-master.service';
import { threadId } from 'worker_threads';
import { ItemsDetails, StockEntry, UnitDetails } from '../../model/purchase.model';
import { PurchaseService } from '../../services/purchase.service';
import { CommonComponent } from 'src/app/theme/shared/common/common.component';

@Component({
  selector: 'app-stock-entry',
  templateUrl: './stock-entry.component.html',
  styleUrls: ['./stock-entry.component.scss']
})
export class StockEntryComponent extends CommonComponent implements OnInit {
  stockEntryGroup: FormGroup;
  allItemsData: ItemsDetails[]=[];
  unitDetails: UnitDetails[]=[];
  isItemId: boolean = true;
  constructor(
    public fb: FormBuilder,
    private purchaseService: PurchaseService,
    private itemMasterService: ItemMasterService
  ) {
    super();
    this.stockEntryGroup = this.fb.group({
      itemCount: new FormControl('', [Validators.required]),
      itemId: new FormControl('', [Validators.required]),
      price: new FormControl('', [Validators.required]),
      unitId: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.stockEntryGroup.controls['itemId'].valueChanges.subscribe((_val) => {
      let itemId = '';
      this.allItemsData.forEach(element => {
        if(element.itemName == this.stockEntryGroup.controls['itemId'].value){
          itemId = element.itemId;
        }
      });
      this.purchaseService.getAllUnitsData(itemId).subscribe((val)=>{
        if(val){
          this.unitDetails = val.data;
        }
      })
    })

    this.itemMasterService.getItemsData().subscribe((val)=>{
      if(val){
        this.allItemsData = val.data;
      }
    })

  }

  submitStockEntry(){
    const stockEntry = new StockEntry();
    stockEntry.itemCount = this.stockEntryGroup.controls['itemCount'].value;
    this.allItemsData.forEach(element => {
      if(element.itemName == this.stockEntryGroup.controls['itemId'].value){
        stockEntry.itemId = element.itemId;
      }
    });
    stockEntry.price = this.stockEntryGroup.controls['price'].value;
    this.unitDetails.forEach(element => {
      if(element.unitName == this.stockEntryGroup.controls['unitId'].value){
        stockEntry.unitId = element.unitId;
      }
    });
    this.purchaseService.postStockEntryData(stockEntry).subscribe((val)=>{
      if(val){
        this.successSwal("Stock Entry Given Successfully");
        this.stockEntryGroup.reset();
      }
    })
  }

  cancel(){
    this.stockEntryGroup.reset();
  }


}
