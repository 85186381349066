<app-card headerClass="border-0 p-2 pb-0 " cardTitle="Sales Incentives" [options]="false">
    <table class="table table-striped table-responsive">
        <thead>
            <tr>
                <th scope="col" *ngFor="let header of categoryHeader">{{header}}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let category of allCategoriesData; index as i ">
                <td>{{ category.catId }}</td>
                <td>{{ category.catName }}</td>
                <td><button class="btn-sm btn-info" (click)="selectCategory(category.catId,category.catName)">Action</button></td>
        </tbody>
    </table>
</app-card>

<app-card [hidHeader]="true" *ngIf="isUpdateCategoryFormShow">
    <h5>Update Category</h5>
    <hr>
    <form [formGroup]="updateCategoryGroup" class="form">
        <div class="app-modal-body">
            <div class="row ">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="d-block ">Category Id</label>
                        <input type="text" class="form-control" formControlName="catId" placeholder="Category Id" readonly>
                    </div>
                </div>
                <div class="col-sm-6 ">
                    <div class="form-group ">
                        <label class="d-block">Category Name</label>
                        <input type="text" class="form-control" formControlName="catName" placeholder="Category Name">
                    </div>
                </div>
            </div>
            <hr>
            <div class="app-modal-footer ">
                <button type=" button " class="btn btn-danger " data-dismiss="modal" (click)="cancel()">Cancel</button>
                <button type="submit " class="btn btn-primary " (click)="submitUpdateCategory() ">Submit</button>
            </div>
        </div>
    </form>
</app-card>
