export class StockDetail {
    itemId: string;
    qty: number;
    unitPrice: number | null;
    itemName: string;
  }
  
  export class Stock {
    displayId: number;
    stockRequestId: number;
    loginId: number;
    status: string;
    totalAmount: number;
    userType: string;
    createdOn: Date;
    userName: string;
    comments: string;
    stockDetails: StockDetail[];
  }
  
  export class PendingStocksResponse {
    data: Stock[];
    message: string;
    errorCode: string;
  }
  

  export class ApproveRejectStockRequest {
    stockRequestId: number;
    isApproved: number;
    adminDisplayId: number;
    comments: string;
  }  

  export class StockBalance {
    itemId: string;
    name: string;
    imageUrl: string;
    quantity: number;
    commissionPerc: number;
    mrp: number;
  }

  export class FranchiseCommission {
    month: Date;
    totalamount: number;
    monthcategory: string;
  }