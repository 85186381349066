import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbCarouselModule, NgbDatepickerModule, NgbDropdownModule, NgbTabsetModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
import { SharedModule } from '../../theme/shared/shared.module';
import {ArchwizardModule} from 'angular-archwizard';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { RechargeComponent } from '../recharge/components/recharge/recharge.component';
import { RechargeRoutingModule } from '../recharge/recharge-routing.module';
import { ProductMasterComponent } from './components/product-master/product-master.component';
import { ViewBalanceComponent } from './components/view-balance/view-balance.component';
import { TransactionEnquiryComponent } from './components/transaction-enquiry/transaction-enquiry.component';
import { RechargeReportComponent } from './components/recharge-report/recharge-report.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    NgbTabsetModule,
    NgbDropdownModule,
    NgbCarouselModule,
    NgbDatepickerModule,
    DataTablesModule,
    FormsModule,
    ReactiveFormsModule,
    RechargeRoutingModule,
    ArchwizardModule,
    NgxQRCodeModule
  ],
  declarations: [
    RechargeComponent,
    ProductMasterComponent,
    ViewBalanceComponent,
    TransactionEnquiryComponent,
    RechargeReportComponent
  ]
})
export class RechargeModule { }
