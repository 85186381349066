import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable, SkipSelf } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, retry, tap } from 'rxjs/operators';
import { LoggerService } from '../log/logger.service';
import { CommonResponse } from '../common-response/common-response';
import { isNullOrUndefined } from '../util/app-util';


@Injectable()
export abstract class AbstractHttpService {
  private issuesList = new Array<string>();
  protected constructor(
    @Inject('className') protected className: string,
    @SkipSelf() protected http: HttpClient,
    protected logger: LoggerService) {
    this.className = className;
    this.issuesList = ['Annual Business Plan-0', 'Co-op Sales', 'CPO/Remarketing', 'CX360 Record Health', 'CX360 Survey Health', 'Dealer Financials', 'Dealer Loyalty', 'Dealer Risk Assessment', 'Inventory & Ordering', 'Lead Management', 'Marketing', 'MBEP Training', 'MCVP', 'MCVP Vehicle Expiring', 'Other', 'Owner Loyalty', 'RDR information', 'Registration Market Share', 'Retail Sales', 'Sales Customer Experience (MBEP 2.1 Index)', 'SPI', 'Training', 'Accessory Business', 'Annual Business Plan', 'Co-op Service', 'FIRFT', 'Missed Recall Tiers', 'MPC (PartsEye) Utilization', 'Parts Purchase Loyalty', 'Parts Sales', 'Recalls', 'Repair Orders', 'Service Customer Experience (MBEP 2.1 Index)', 'Service Retention/FYSL', 'Shop Capacity', 'X-Time Service Scheduling', 'Dealer Dev Deficiencies Identified', 'Dealer Staffing', 'Facility', 'NDAC Engagement', 'Network Activity', 'UMX', 'MBEP Performance', 'Digital Service Utilization' , 'Digital Service Performance', 'New Employee Orientation/Training', 'Parts Inventory Management', 'Warranty Performance' , 'ALL ISSUE TYPES'];
  }

  protected handleError<T>(uri: string, result?: T): (error: any) => Observable<T> {
    return (error: any): Observable<T> => {
      this.logger.error(this.className, 'http request failed:', uri);
      this.logger.error(this.className, 'error', error);
      this.logger.error(this.className, 'response', JSON.stringify(result));
      return result != null ? of(result) : of();
    };
  }

  // showMessage(message: string | any): void {
  //   const config: MatSnackBarConfig = {
  //     verticalPosition: 'top',
  //     horizontalPosition: 'center',
  //     duration: 5000,
  //   };
  //   this.snackBar.open(message || '', '', config);
  // }

  /**
   * Calculate the expired time for Authentication token
   * @param authTokenExpires AuthToken Expires
   * @param expireTime Expire Time from config
   * @returns Date
   */
  protected getCalculatedAuthTokenExpires(authTokenExpires: any, expireTime: number): Date {
    if (isNullOrUndefined(authTokenExpires)) {
      authTokenExpires = new Date(new Date().getTime() + (expireTime * 60 * 1000));
    }
    return new Date(authTokenExpires);
  }

  makeApiUrl(config: any, rootUrl: string, rootMainService: string, apiTobeHit: string): string {
    return `${config[rootUrl]}/${config[rootMainService]}/${config[apiTobeHit]}`;
  }

  // postCallReturnObject<T, E>(url: string, bodyData: E): Observable<T> {

  // }

  /**
   * Generic class for making post call and return type boolean
   * @param url Url to be called
   * @param bodyData post body data
   * @param logMsg logger messages
   */
  protected postCallReturnBoolean<T, E>(url: string, bodyData: E, retryTime: number, logMsg: string, httpOptions?: { headers: HttpHeaders }): Observable<boolean> {
    return this.http.post<CommonResponse<T>>(url, bodyData, httpOptions).pipe(
      retry(retryTime),
      map((data: CommonResponse<T>) => this.mapTheResultForBoolean<T>(data)),
      tap((t: boolean) => {
        return this.logger.info(this.className, logMsg, t);
      }),
      catchError(this.handleError<boolean>(url))
    );
  }

  protected postCallReturnObject<T, E>(url: string, bodyData: E, retryTime: number, logMsg: string, httpOptions?: { headers: HttpHeaders }): Observable<T> {
    return this.http.post<CommonResponse<T>>(url, bodyData, httpOptions).pipe(
      retry(retryTime),
      map((data: CommonResponse<T>) => this.mapTheResultForObject<T>(data)),
      tap((t: T) => {
        return this.logger.info(this.className, logMsg);
      }),
      catchError(this.handleError<T>(url))
    );
  }

  protected postCallReturnList<T, E>(url: string, bodyData: E, retryTime: number, logMsg: string, httpOptions?: { headers: HttpHeaders }): Observable<T[]> {
    return this.http.post<CommonResponse<T>>(url, bodyData, httpOptions).pipe(
      retry(retryTime),
      map((data: CommonResponse<T>) => this.mapTheResultForList<T>(data)),
      tap((t: T[]) => {
        return this.logger.info(this.className, logMsg);
      }),
      catchError(this.handleError<T[]>(url))
    );
  }

  /**
   * Generic class for making get call and return type boolean
   * @param url Url to be called
   * @param logMsg Logger message
   */
  protected getCallReturnBoolean<T>(url: string, retryTime: number, logMsg: string, httpOptions?: { headers: HttpHeaders }): Observable<boolean> {
    return this.http.get<CommonResponse<T>>(url, httpOptions).pipe(
      retry(retryTime),
      map((data: CommonResponse<T>) => this.mapTheResultForBoolean<T>(data)),
      tap((t: boolean) => {
        return this.logger.info(this.className, logMsg);
      }),
      catchError(this.handleError<boolean>(url))
    );
  }

  // getCallReturnObject<T>(url: string, retryTime: number, logMsg: string, httpOptions?: { headers: HttpHeaders }): Observable<T> {
  //   return this.http.get<CommonResponse<T>>(url, httpOptions).pipe(
  //     retry(retryTime),
  //     map((data: CommonResponse<T>) => this.mapTheResultForObject<T>(data)),
  //     tap((t: T) => {
  //       return this.logger.info(this.className, logMsg, t);
  //     }),
  //     catchError(this.handleError<T>(url))
  //   );
  // }

  protected getCallReturnObject<T>(url: string, retryTime: number, logMsg: string, httpOptions?: { headers: HttpHeaders }): Observable<T> {
    return this.http.get<CommonResponse<T>>(url, httpOptions).pipe(
      retry(retryTime),
      map((data: CommonResponse<T>) => this.mapTheResultForObject<T>(data)),
      tap((t: T) => {
        return this.logger.info(this.className, logMsg);
      }),
      catchError(this.handleError<T>(url))
    );
  }

  protected getCallReturnList<T>(url: string, retryTime: number, logMsg: string, httpOptions?: { headers: HttpHeaders }): Observable<T[]> {
    return this.http.get<CommonResponse<T>>(url, httpOptions).pipe(
      retry(retryTime),
      map((data: CommonResponse<T>) => this.mapTheResultForList<T>(data)),
      tap((t: T[]) => {
        return this.logger.info(this.className, logMsg);
      }),
      catchError(this.handleError<T[]>(url))
    );
  }

  protected deleteCallReturnBoolean<T>(url: string, retryTime: number, logMsg: string, httpOptions?: { headers: HttpHeaders }): Observable<boolean> {
    return this.http.delete<CommonResponse<T>>(url, httpOptions).pipe(
      retry(retryTime),
      map((data: CommonResponse<T>) => this.mapTheResultForBoolean<T>(data)),
      tap((t: boolean) => {
        return this.logger.info(this.className, logMsg);
      }),
      catchError(this.handleError<boolean>(url))
    );
  }

  protected deleteCallReturnList<T>(url: string, retryTime: number, logMsg: string, httpOptions?: { headers: HttpHeaders }): Observable<T[]> {
    return this.http.delete<CommonResponse<T>>(url, httpOptions).pipe(
      retry(retryTime),
      map((data: CommonResponse<T>) => this.mapTheResultForList<T>(data)),
      tap((t: T[]) => {
        return this.logger.info(this.className, logMsg);
      }),
      catchError(this.handleError<T[]>(url))
    );
  }

  protected patchCallReturnObject<T, E>(url: string, bodyData: E, retryTime: number, logMsg: string, httpOptions?: { headers: HttpHeaders }): Observable<T> {
    return this.http.patch<CommonResponse<T>>(url, bodyData, httpOptions).pipe(
      retry(retryTime),
      map((data: CommonResponse<T>) => this.mapTheResultForObject<T>(data)),
      tap((t: T) => {
        return this.logger.info(this.className, logMsg);
      }),
      catchError(this.handleError<T>(url))
    );
  }
  /**
   * Map the result in common chunk
   * @param data Common Response
   */
  private mapTheResultForBoolean<T>(data: CommonResponse<T>): boolean {
    if (data.success) {
      return data.success;
    }
    // this.showMessage(data.error ?? '');
    return false;
  }

  /**
   * Map the result in common chunk
   * @param data Common Response
   */
  private mapTheResultForObject<T>(data: CommonResponse<T>): T {
    if (data.success) {
      return data.result as T;
    }
    // this.showMessage(data.error ?? 'Response have error');
    return null as unknown as T;
  }

  /**
   * Map the result in common chunk
   * @param data Common Response
   */
  private mapTheResultForList<T>(data: CommonResponse<T>): T[] {
    if (data.success) {
      return data.result as T[];
    }
    // this.showMessage(data.error ?? 'Result having error');
    return [] as T[];
  }

  // generateFilterDataUrl(paramList: LocationHandlerFilterData): string {
  //   const params = paramList?.location?.filter((data: null) => data != null) ?? [];
  //   const marketParams = paramList?.issues?.filter((data: null) => data != null) ?? [];
  //   let filterUrl: string = '';
  //   if (marketParams?.length > 0 ) {
  //     // if (marketParams.length == 1) {
  //     //   filterUrl = `&mdaCd=${marketParams[0].key}`;
  //     // } else if (marketParams.length == 2) {
  //     //   const dealerCode = marketParams[1]?.key;
  //     //   filterUrl = `&mdaCd=${marketParams[0].key}&dlrCd=${dealerCode}`;
  //     // }
  //   } else {

  //   }
  //   if (params.length == 2) {
  //     filterUrl = `&rgnCd=${params[1]}`;
  //   } else if (params.length == 3) {
  //     filterUrl = `&rgnCd=${params[1]}&zoneCd=${params[2]}`;
  //   } else if (params.length == 4) {
  //     filterUrl = `&rgnCd=${params[1]}&zoneCd=${params[2]}&districtCd=${params[3]}`;
  //   } else if (params.length == 5) {
  //     const dealerCode = params[4]?.split('-')[0];
  //     filterUrl = `&rgnCd=${params[1]}&zoneCd=${params[2]}&districtCd=${params[3]}&dlrCd=${dealerCode}`;
  //   }
  //   return filterUrl;
  // }

  // protected handleFilterUrl(url: string, locationFilterData: LocationHandlerFilterData, isLocation: boolean = false, isIssues: boolean = false, isTiming: boolean = false, dealerId?: string): string {
  //   let isFirst: boolean = true;
  //   if (isLocation) {
  //     const params = locationFilterData?.location?.filter((data: null) => data != null) ?? [];
  //     if (params.length == 2) {
  //       url += `${isFirst ? '?': '&'}regionId=${params[1]}`;
  //       isFirst = false;
  //     } else if (params.length == 3) {
  //       url += `${isFirst ? '?': '&'}regionId=${params[1]}&zoneId=${params[2]}`;
  //       isFirst = false;
  //     } else if (params.length == 4) {
  //       url += `${isFirst ? '?': '&'}regionId=${params[1]}&zoneId=${params[2]}&districtId=${params[3]}`;
  //       isFirst = false;
  //     }
  //     if (params.length == 5 || dealerId) {
  //       const dealerCode = isNullOrUndefined(dealerId) ? params[4]?.split('-')[0] : dealerId;
  //       url += isFirst ? `?regionId=${params[1]}&zoneId=${params[2]}&districtId=${params[3]}&dealerId=${dealerCode}`: `&dealerId=${dealerCode?.trim()}`;
  //       isFirst = false;
  //     }
  //   }
  //   if (isIssues) {
  //     if ((locationFilterData?.issues ?? []).length > 0) {
  //       if(locationFilterData.issues?.indexOf('ALL ISSUE TYPES') !== -1) {
  //         url += `${isFirst ? '?': '&'}issues=${encodeURIComponent(this.issuesList.filter(value => value !== 'ALL ISSUE TYPES').join(','))}`
  //         isFirst = false;
  //       } else {
  //         url += `${isFirst ? '?': '&'}issues=${encodeURIComponent(locationFilterData.issues?.join(','))}`;
  //         isFirst = false;
  //       }
  //     }
  //   }

  //   if (isTiming) {
  //     url += `${isFirst ? '?': '&'}startOf=${locationFilterData.timing?.from}&endOf=${locationFilterData.timing?.to}`;
  //     isFirst = false;
  //   }
  //   return url;
  // }

}

