import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { UserAuthService } from 'src/app/mlm/auth/services/user-auth.service';
import { AllStates } from 'src/app/mlm/member/model/allstates.model';
import { DetailsDisplayId } from 'src/app/mlm/member/model/details-displayid.model';
import { MembersService } from 'src/app/mlm/member/services/members.service';
import {
  CartItems,
  Purchase,
  PurchasedItems,
} from '../../model/purchase.model';
import { PurchaseService } from '../../services/purchase.service';
import { AuthService } from 'src/app/mlm/auth/services/auth.service';
import { MemberRegistrationDetailsBySponsorID } from 'src/app/mlm/member/model/member-registration.model';
import { CommonComponent } from 'src/app/theme/shared/common/common.component';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
})
export class CheckoutComponent extends CommonComponent implements OnInit {
  allCartsData: CartItems[] = [];
  itemsTotals = [];
  subTotal: number = 0;
  checkoutGroup: FormGroup;
  allStatesData: AllStates[] = [];
  userInformations: DetailsDisplayId;
  userDetails: MemberRegistrationDetailsBySponsorID[] = [];
  cartIds = [];
  purchasedItems: PurchasedItems[] = [];
  showMessageSponsorID: string = '';
  allMemberDataBySponsorID: MemberRegistrationDetailsBySponsorID[] = [];
  displayId: string = '';
  userName: string;
  stateName : any;
  stateCode: string;

  constructor(
    public fb: FormBuilder,
    private purchaseService: PurchaseService,
    private router: Router,
    private memberService: MembersService,
    private userAuthService: UserAuthService,
    private authService: AuthService
  ) {
    super();
    this.checkoutGroup = this.fb.group({
      name: new FormControl('', [Validators.required]),
      cityVillage: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      pincode: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required]),
      phNo: new FormControl('', [Validators.required]),
    });

    this.userInformations = new DetailsDisplayId();
  }

  ngOnInit(): void {
    this.displayId = this.userAuthService.getDisplayId();

    this.memberService.getAllStatesData().subscribe((value) => {
      if(value.data){
        this.allStatesData = value.data;
      }
    });
    this.memberService.getverifyMemberInfoDisplayId(parseInt(this.userAuthService.getDisplayId())).subscribe((val)=>{
      if(val){
        this.userInformations = val.data;
        setTimeout(() => {
          this.allStatesData.forEach(element => {
            if(element.stateCode == this.userInformations.stateCode){
              this.stateName =  element.stateName;
            }
          });
        }, 2000);
        this.stateCode = val.data.stateCode;
      }
    })
    this.purchaseService.getAllCartData(this.displayId).subscribe((val) => {
      if (val) {
        this.allCartsData = val.data;
        for (let i = 0; i < this.allCartsData.length; i++) {
          let price =
            this.allCartsData[i].qty * this.allCartsData[i].distributorPrice;
          this.subTotal = this.subTotal + price;
          this.cartIds.push(this.allCartsData[i].cartId);
          const temp = new PurchasedItems();
          temp.itemId = this.allCartsData[i].itemId;
          temp.qty = this.allCartsData[i].qty;
          this.purchasedItems.push(temp);
        }
      }
    });

    console.log(this.purchasedItems);


    this.userName = this.userAuthService.getUserName();

    this.checkoutGroup.controls['state'].valueChanges.subscribe((val) => {
      if (val) {
        this.allStatesData.forEach(element => {
          if(element.stateName == this.checkoutGroup.controls['state'].value){
            this.stateCode = element.stateCode;
          }
        });
      }
    })
  }

  checkout() {
    const purchase = new Purchase();
    purchase.displayId = parseInt(this.displayId);
    purchase.loginId = parseInt(this.userAuthService.getDisplayId());
    purchase.paymentMode = 'Wallet';
    purchase.enteredBy = this.userAuthService.getDisplayId();
    purchase.totalPrice = this.subTotal;
    // purchase.isFirstPurchase = true;
    purchase.phNo = this.checkoutGroup.controls['phNo'].value?this.checkoutGroup.controls['phNo'].value:'';
    purchase.cityVillage = this.checkoutGroup.controls['cityVillage'].value?this.checkoutGroup.controls['cityVillage'].value:'';
    purchase.stateCode = this.stateCode;
    purchase.shippingAddress =((this.checkoutGroup.controls['address'].value == null) ? ' ' : this.checkoutGroup.controls['address'].value + ',') +
      ((this.checkoutGroup.controls['cityVillage'].value == null) ? ' ' : 'City/Village - ' + this.checkoutGroup.controls['cityVillage'].value + ',') +
      ((this.checkoutGroup.controls['state'].value == null) ? ' ' : 'State - ' + this.checkoutGroup.controls['state'].value + ',') +
      ((this.checkoutGroup.controls['pincode'].value == null) ? ' ' : 'Pincode - ' + this.checkoutGroup.controls['pincode'].value);

    purchase.pinCode = this.checkoutGroup.controls['pincode'].value?this.checkoutGroup.controls['pincode'].value:'';
    purchase.cartIds = this.cartIds;
    (purchase.gstType = 'IGST'),
      (purchase.purchasedItems = this.purchasedItems);
    console.log(purchase);
    if(this.checkoutGroup.controls['state'].value){
      this.purchaseService.postPurchaseCartData(purchase).subscribe((val) => {
        if (val.errorCode) {
          this.warningSwal(val.message);
        } else {
          this.successSwal('Order successfully placed');
          this.router.navigateByUrl('/home/purchase/orders');
        }
      });
    }else{
      this.dangerSwal("Please select a state from the list");
    }
  }

  checkSponsorID(event: any) {
    let displayId = this.checkoutGroup.controls['displayId'].value;
    if (displayId) {
      this.authService
        .getRgistrationEligibleSponsorOrg(parseInt(this.displayId))
        .subscribe((value) => {
          this.allMemberDataBySponsorID = value.data;
          console.log(this.allMemberDataBySponsorID);
          this.userDetails = this.allMemberDataBySponsorID.filter(
            (s) => s.displayId == displayId
          );
          if (this.userDetails.length > 0) {
            this.showMessageSponsorID = '';
            console.log(this.userDetails)
            this.userInformations.name = this.userDetails[0].name;
            this.userInformations.emailId = this.userDetails[0].emailId;
            this.userInformations.address = this.userDetails[0].address;
            this.userInformations.phNo = parseInt(this.userDetails[0].phNo);
            this.userInformations.cityVillage = this.userDetails[0].cityVillage;
            this.userInformations.stateCode = this.userDetails[0].stateCode?.toString();
            this.userInformations.pinCode = this.userDetails[0].pinCode?.toString();


          } else {
            this.showMessageSponsorID = 'Please enter desired Display ID';
            this.checkoutGroup.reset();
          }
        });
    } else {
      this.showMessageSponsorID = 'Please enter desired Display ID';
    }
  }
}
