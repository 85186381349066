import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { UserAuthService } from 'src/app/mlm/auth/services/user-auth.service';
import { PaidCommissionTableData } from 'src/app/mlm/pay-commission/model/view-commission.model';
import { ViewCommissionService } from 'src/app/mlm/pay-commission/services/view-commission.service';
import { CommonComponent } from 'src/app/theme/shared/common/common.component';
import { ExcelExportService } from '../../services/excel-export.service';
import { DownlineService } from '../../services/downline.service';
import { TdsReportShowData, TdsReportTableData } from '../../model/tds-report-table.model';
const pdfMake = require('pdfmake/build/pdfmake.js');
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import jsPDF from 'jspdf';
(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;
import htmlToPdfmake from 'html-to-pdfmake';

@Component({
  selector: 'app-tds-report',
  templateUrl: './tds-report.component.html',
  styleUrls: ['./tds-report.component.scss']
})
export class TdsReportComponent extends CommonComponent implements OnInit {
  isAdmin: boolean = false;
  tdsAmount: number = 0;
  netAmount: number = 0;
  FilterFormGroup: FormGroup;
  fileName = 'ExcelSheet.xlsx';
  loading: boolean = true;
  isDataAvailable: boolean;
  noData: boolean;
  gridView: boolean = false;
  columns = [
    'Action',
    'Display Id',
    'Deductee Name',
    'Deductee PAN',
    'Financial Year',
    'State Name',
    'Net Amount',
    'TDS Amount',
  ];
  tdsReportTableData: TdsReportTableData[] = [];
  years = [
    '',
    '2022-2023',
    '2023-2024',
    '2024-2025',
    '2025-2026',
    '2026-2027',
    '2027-2028',
    '2028-2029',
    '2029-2030',
    '2030-2031',
    '2031-2032',
    '2032-2033',
    '2033-2034'
  ]
  displayId: string;
  tdsReportShowData: TdsReportShowData;
  constructor(
    private userAuthService: UserAuthService,
    public fb:FormBuilder,
    private excelExportService: ExcelExportService,
    private downlineService: DownlineService
  ) {
    super();
    this.FilterFormGroup = this.fb.group({
      displayId: new FormControl('', [Validators.required]),
      financialYear: new FormControl('', [Validators.required]),
    });
    this.isDataAvailable = this.tdsReportTableData?.length > 0;
    this.tdsReportShowData = new TdsReportShowData();
  }

  ngOnInit(): void {
    this.isAdmin = this.userAuthService.getRoles()==='ADMIN'?true:false;
    this.displayId = this.userAuthService.getDisplayId();
  }

  search(){
    if(this.FilterFormGroup.controls['financialYear'].value){
      this.gridView = false;
      this.netAmount = 0;
      this.tdsAmount = 0;
      const displayId = this.FilterFormGroup.controls['displayId'].value?this.FilterFormGroup.controls['displayId'].value:0;
      const fromYear = this.FilterFormGroup.controls['financialYear'].value.substring(0,4);
      const toYear = this.FilterFormGroup.controls['financialYear'].value.substring(5,9);
      const financialyear = fromYear + '-' + this.FilterFormGroup.controls['financialYear'].value.substring(7,9);
      const fromDate = fromYear + '-04-01';
      const toDate = toYear + '-03-31'
      this.downlineService.getSearchTdsReportDetailsData(displayId,financialyear,fromDate,toDate).subscribe((val)=>{
        if(!val.errorCode){
          this.tdsReportTableData = val.data;
          val.data.forEach(element => {
            this.netAmount += element.netAmount;
            this.tdsAmount += element.tdsAmount;
          });
          this.isDataAvailable = this.tdsReportTableData?.length > 0;
          this.noData = this.tdsReportTableData?.length === 0;
          this.gridView = this.tdsReportTableData?.length > 0;
        }
      });
      this.loading = false;
    }else{
      this.warningSwal("Please select a Financial Year");
    }

  }

  clear(){
    if(this.isAdmin){
      this.FilterFormGroup.reset();
      this.gridView = false;
    }else{
      this.FilterFormGroup.controls['financialYear'].setValue('');
      this.gridView = false;
    }
  }

  exportexcel(): void {
    const tdsReportExcelExport = this.tdsReportTableData;
    // tdsReportExcelExport.forEach(element => {
    //   delete element.netAmount;
    // });
    this.excelExportService.exportExcel(
      tdsReportExcelExport,
      'tds-report'
    );
  }

  tdsCertificateGenerate(data: TdsReportTableData){
      const fromYear = data.financialYear?.substring(0,4);
      const toYear = data.financialYear?.substring(0,2) + data.financialYear?.substring(5,7);
      const fromDate = fromYear + '-04-01';
      const toDate = toYear + '-03-31'
      this.downlineService.getShowTdsReportData(data.displayId.toString(),data.financialYear,fromDate,toDate).subscribe((value)=>{
        if(!value.errorCode){
          this.tdsReportShowData = value.data[0];
        }
      });

      setTimeout(() => {
        this.downloadAsPDF();
      }, 2000);
  }

  @ViewChild('pdfTable') pdfTable: ElementRef;
  public downloadAsPDF() {
    const doc = new jsPDF();

    const pdfTable = this.pdfTable.nativeElement;

    var html = htmlToPdfmake(pdfTable.innerHTML);

    const documentDefinition = { content: html };
    pdfMake.createPdf(documentDefinition).open();
  }
}
