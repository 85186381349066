<app-card [hidHeader]="true">
    <h5>Search Direct Member</h5>
    <hr>
    <form [formGroup]="searchData" class="form">
        <div class="app-modal-body">
            <div class="col-sm-6">
                <div class="form-group">
                    <label class="d-block">Type <span style="color: red;">*</span></label>
                    <select class="form-control" formControlName="type">
                  <option>Order No</option>
                  <option>Invoice No</option>
                </select>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="form-group">
                    <label class="d-block ">Value</label>
                    <input type="string" class="form-control" formControlName="value" placeholder="Value">
                </div>
            </div>
        </div>
        <hr>
        <div class="app-modal-footer">
            <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="cancel()">Cancel</button>
            <button type="submit" class="btn btn-primary" (click)="submit()">Submit</button>
        </div>
    </form>
</app-card>
<ng-container *ngIf="!loading; then thenTemplate; else elseTemplate"></ng-container>
<ng-template #thenTemplate>
    <ng-container *ngIf="isDataAvailable; else elseTemplate">
        <div class="row btn-page" *ngIf="gridView">
            <div class="col-sm-12 btn-page">
                <app-card [hidHeader]="true" cardClass="card-datatable">
                    <div class="col-sm-12 text-right">
                        <button type="button" class="btn btn-primary btn-sm" (click)="exportexcel()">Export to Excel</button>
                    </div>
                    <div class="table-responsive">
                        <table datatable id="excel-table" class="table table-striped table-hover mb-0 table-responsive">
                            <thead>
                                <tr>
                                    <th *ngFor="let col of columns">
                                        {{col}}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of purchaseDeleteGridData; let i=index; ">
                                    <th scope="row ">{{ data?.displayId }}</th>
                                    <th scope="row ">{{ data?.name }}</th>
                                    <th scope="row ">{{ data?.orderDate }}</th>
                                    <th scope="row ">{{ data?.orderNo }}</th>
                                    <th scope="row ">{{ data?.invoiceNo }}</th>
                                    <th scope="row ">{{ data?.itemName }}</th>
                                    <th scope="row ">{{ data?.qty }}</th>
                                    <th scope="row ">{{ data?.totalPrice }}</th>
                                    <th scope="row ">{{ data?.totalBV }}</th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <br>
                    <div class="app-modal-footer col-sm-12 text-right">
                        <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="delete()">Delete</button>
                    </div>
                </app-card>
            </div>
        </div>
    </ng-container>
    <ng-template #elseTemplate>
        <div class="spinner" *ngIf="!noData">
            <h5>Loading...</h5>
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
        </div>
        <div *ngIf="noData">
            <h1 style="text-align: center;">No Data Available</h1>
        </div>
    </ng-template>
</ng-template>
<ng-template #elseTemplate>
</ng-template>
