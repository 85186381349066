<div class="row mb-n5" style="text-align: center;">
    <div class="col-md-12 col-xl-3">
        <app-card [hidHeader]="true" cardClass="bg-c-green borderless">
            <h6 class="text-c-black" style="font-weight: bold;">Display ID : {{displayIdInformations?.displayId}}</h6>
        </app-card>
    </div>

    <div class="col-md-12 col-xl-3">
        <app-card [hidHeader]="true" cardClass="bg-c-green borderless">
            <h6 class="text-c-black" style="font-weight: bold;">Name : {{displayIdInformations?.name}}</h6>
        </app-card>
    </div>

    <div class="col-md-12 col-xl-3">
        <app-card [hidHeader]="true" cardClass="bg-c-green borderless">
            <h6 class="text-c-black" style="font-weight: bold;">Parent ID : {{displayIdInformations?.parentID}}</h6>
        </app-card>
    </div>

    <div class="col-md-12 col-xl-3">
        <app-card [hidHeader]="true" cardClass="bg-c-green borderless">
            <h6 class="text-c-black" style="font-weight: bold;">Position : {{(displayIdInformations?.isleft === true)?'Left':'Right'}}</h6>
        </app-card>
    </div>
</div>
<br><br>
<ng-container *ngIf="isDataAvailable; else elseTemplate">
    <div class="row">
        <div class="col-md-8">
            <div class="card table-card">
                <div class="card-header">
                    <h5>My Cart</h5>
                </div>
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table table-borderless mb-0">
                            <tbody>
                                <tr *ngFor="let item of allCartsData">
                                    <td>
                                        <div class=" d-inline-block align-middle text-center ">
                                            <img src="{{item.image}}" alt="contact-img " title="contact-img " class="rounded " height="90 " />
                                            <br>
                                            <div class="btn-group btn-group-sm " role="group " aria-label="button groups sm ">
                                                Qty : {{item.qty}}
                                            </div>
                                        </div>
                                        <div class="d-inline-block align-middle m-l-10 ">
                                            <a href="javascript: " class="text-body ">
                                                <h5 class="mb-1 ">{{item.itemName}}</h5>
                                            </a>
                                            <p class="text-muted mb-1 ">{{item.itemDesc}}</p>
                                            <p class="text-muted mb-1 ">BV : {{item.bv}}</p>
                                            <h4>{{item.distributorPrice?.toFixed(2)}}</h4>
                                        </div>
                                    </td>
                                    <td class="text-right ">
                                        <div class="text-left d-inline-block ">
                                            <!-- <h6 class="my-2 ">Delivery by Sat Aug 17 | <span class=" ">$50</span></h6>
                                        <small class="text-muted ">10 Days Replacement Policy</small> -->
                                            <button (click)="removeItem(item)" class="text-muted text-h-danger m-l-10 mb-1 ">Remove</button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="text-right p-r-20 ">
                        <button class="btn btn-warning " (click)="checkout()">Checkout</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="col-md-4 ">
        <app-card cardTitle="Price Details " [options]="false " [isCardFooter]="true " footerClass="py-3 ">
            <h6 class="mb-2 ">Price (3 items) <span class="float-right ">$2800</span></h6>
            <h6 class="mb-2 ">Delivery Charges <span class="float-right ">$100</span></h6>
            <h6 class="mb-2 ">TAX / VAT <span class="float-right ">$0</span></h6>
            <hr>
            <h6 class="mb-0 ">Payable Amount <span class="float-right ">$2900</span></h6>
            <div class="app-card-footer ">
                <h6 class="mb-0 text-success ">Your Total Savings on this order $550</h6>
            </div>
        </app-card>
        <div class="media ">
            <i class="fas fa-shield-alt h3 m-r-10 text-muted "></i>
            <div class="media-body ">
                <h6 class="mt-0 text-muted "> Safe and Secure Payments.Easy returns.100% Authentic products.</h6>
            </div>
        </div>
    </div> -->
        <div class="col-md-4">
            <app-card cardTitle="Order Summary" [options]="false" blockClass="p-0">
                <ul class="list-group list-group-flush">
                    <li class="list-group-item py-0" *ngFor="let item of allCartsData">
                        <div class="table-responsive">
                            <table class="table table-borderless mb-0">
                                <tbody>
                                    <tr>
                                        <td>
                                            <img src="{{item.image}}" alt="" title="contact-img" class="rounded mr-2" height="48" />
                                            <p class="m-0 d-inline-block align-middle">
                                                <a href="javascript:" class="text-body font-weight-semibold">{{item.itemName}}</a>
                                                <br>
                                                <small>{{item.qty}} x {{item.distributorPrice?.toFixed(2)}}</small>
                                            </p>
                                        </td>
                                        <td class="text-right">
                                            {{(item.qty * item.distributorPrice)?.toFixed(2)}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </li>
                </ul>
                <div class="py-2 p-10">
                    <div class="table-responsive">
                        <table class="table table-borderless mb-0 w-auto table-sm float-right text-right">
                            <tbody>
                                <tr>
                                    <td>
                                        <h6 class="m-0">Sub Total:</h6>
                                    </td>
                                    <td>
                                        {{subTotal.toFixed(2)}}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6 class="m-0">Shipping:</h6>
                                    </td>
                                    <td>
                                        FREE
                                    </td>
                                </tr>
                                <tr class="border-top">
                                    <td>
                                        <h5 class="m-0">Total:</h5>
                                    </td>
                                    <td class="font-weight-semibold">
                                        {{subTotal.toFixed(2)}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </app-card>
        </div>
    </div>
</ng-container>
<ng-template #elseTemplate>
    <h1 style="text-align: center;">Your Cart Is Empty</h1>
</ng-template>