<ng-container *ngIf="!loading; then thenTemplate; else elseTemplate"></ng-container>
<ng-template #thenTemplate>
    <ng-container *ngIf="isDataAvailable; else elseTemplate">
        <div class="row btn-page" *ngIf="gridView">
            <div class="col-sm-12 btn-page">
                <app-card [hidHeader]="true" cardClass="card-datatable">
                    <h5>Balance Request Status</h5>
                    <div class="col-sm-12 text-right">
                        <button type="button" class="btn btn-primary btn-sm" (click)="exportexcel()">Export to Excel</button>
                    </div>
                    <div class="table-responsive">
                        <table datatable id="excel-table" class="table table-striped table-hover mb-0 table-responsive">
                            <thead>
                                <tr>
                                    <th *ngFor="let col of columns">
                                        {{col}}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of balanceRequestStatusData; let i=index; ">
                                    <th scope="row">{{ data?.displayId }}</th>
                                    <th scope="row">{{ data?.name }}</th>
                                    <th scope="row">{{ data?.requestedAmount }}</th>
                                    <th scope="row">{{ data?.requestCreatedOn?.substring(0,10) }}</th>
                                    <th scope="row">{{ data?.approvedAmount }}</th>
                                    <th scope="row">{{ data?.actionTakenOn?.substring(0,10) }}</th>
                                    <th scope="row">{{ data?.approvalStatus }}</th>
                                    <th scope="row">{{ data?.actionTakenBy }}</th>
                                    <th scope="row">{{ data?.remark }}</th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </app-card>
            </div>
        </div>
    </ng-container>
    <ng-template #elseTemplate>
        <div class="spinner " *ngIf="!noData ">
            <h5>Loading...</h5>
            <div class="bounce1 "></div>
            <div class="bounce2 "></div>
            <div class="bounce3 "></div>
        </div>
        <div *ngIf="noData ">
            <h1 style="text-align: center; ">No Data Available</h1>
        </div>
    </ng-template>
</ng-template>
<ng-template #elseTemplate>
</ng-template>