import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbCarouselModule, NgbDatepickerModule, NgbDropdownModule, NgbTabsetModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
import { SharedModule } from '../../theme/shared/shared.module';
import { ProfileRoutingModule } from './profile-routing.module';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { KycUpdateComponent } from './components/kyc-update/kyc-update.component';
import { KycVerificationComponent } from './components/kyc-verification/kyc-verification.component';
import { UpdateProfileComponent } from './components/update-profile/update-profile.component';
import { ViewProfileComponent } from './components/view-profile/view-profile.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    NgbTabsetModule,
    NgbDropdownModule,
    NgbCarouselModule,
    NgbDatepickerModule,
    FormsModule,
    DataTablesModule,
    ReactiveFormsModule,
    ProfileRoutingModule
  ],
  declarations: [
    ChangePasswordComponent,
    KycUpdateComponent,
    KycVerificationComponent,
    UpdateProfileComponent,
    ViewProfileComponent
  ]
})
export class ProfileModule { }
