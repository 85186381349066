export class Member {
  reportType: string;
  totalMember: number;
  totalInactiveMember: number;
  totalActiveMember: number;
}

export class RepurchaseStatData {
  selfPurchase: number;
  leftPurchase: number;
  rightPurchase: number;
  totalBV: number;
}

export class DailyRepurchaseStatData {
  todayPurchasePrice: number;
  firstHalfPurchasePrice: number;
  lastHalfPurchasePrice: number;
}

export class MonthlyRepurchaseStatData {
  currentMonthPurchasePrice: number;
  currentMonthBV: number;
  previousMonthBV: number;
  totalLeftBV: number;
  totalRightBV: number;
  totalBV: number;
}

export class PurchaseStatData {
  selfPurchase: number;
  leftPurchase: number;
  rightPurchase: number;
  totalBV: number;
}

export class DailyPurchaseStatData {
  todayPurchasePrice: number;
  firstHalfPurchasePrice: number;
  lastHalfPurchasePrice: number;
}

export class MonthlyPurchaseStatData {
  currentMonthPurchasePrice: number;
  currentMonthBV: number;
  previousMonthBV: number;
  totalLeftBV: number;
  totalRightBV: number;
  totalBV: number;
}

export class Docs {
  id: number;
  displayid: number;
  documentid: string;
  filelocation: string;
  filename: string;
  doctype: string;
  uploadedat: string;
}
