export class CommissionGenrateDates {
  fromDate: string;
  toDate: string;
}

export class UnpaidCommssionGenerateData {
  checked: boolean;
  adminchargeamout: number;
  commisionid: string;
  commisiontype: string;
  displayId: string;
  fromDate: string;
  name: string;
  panNo: string;
  netamount: number;
  tdsamount: number;
  toDate: string;
  totalIncome: number;
  userName: string;
  ifsc: string;
  bankName: string;
  accountHolderName: string;
  accountNo: number;
}

export class PayUnpaidCommission {
  displayId: string;
  toDate: string;
  fromDate: string;
  paymentType: string;
  ismonthly: boolean;
}

export class PayUnpaidCommissionDataPost {
  payUnpaidCommission: PayUnpaidCommission[];
  static payUnpaidCommission: any;
}

export class UnpaidCommissionDetails {
  displayId: string;
  fromDate: string;
  incomePerPair: string;
  isGenerated: boolean;
  isPermanent: boolean;
  leftMemberCount: number;
  pairMatch: number;
  rightMemberCount: number;
  toDate: string;
  totalIncome: number;
  type: string;
}

export class MakingPayUnpaidCommissionList {
  commissiontaymentdtos: Array<PayUnpaidCommission>;
  ismonthly: boolean;
}

export class BankCommissionReport {
  adminchargeamount: number;
  createdby: string;
  createdon: string;
  displayid: number;
  fromDate: string;
  name: string;
  netamount: number;
  tdsamount: number;
  toDate: string;
  totalamount: number;
}

export class WalletCommissionReport {
  adminchargeamount: number;
  createdby: string;
  createdon: string;
  displayid: number;
  fromDate: string;
  name: string;
  netamount: number;
  tdsamount: number;
  toDate: string;
  totalamount: number;
}

export class UnpaidCommissionUser {
  commissionType: string;
  displayId: number;
  eligibilityStatus: string;
  fromDate: string;
  incomePerPair: number;
  leftCF: number;
  leftMemberCount: number;
  pairMatch: number;
  paymentStatus: string;
  rightCF: number;
  rightMemberCount: number;
  toDate: string;
  totalIncome: number;
}

export class AllCommissions {
  checked: boolean;
  accountNo: string;
  adminCharge: number;
  bankName: string;
  displayId: number;
  ifsc: string;
  name: string;
  panNo: string;
  netIncome: number;
  payableAmount: number;
  paymentDate: string;
  paymentStatus: string;
  tdsCharge: number;
  totalIncome: number;
}

export class AllCommissionsDetails {
  isChecked: boolean;
  commissionCode: string;
  fromDate: string;
  toDate: string;
  displayId: number;
  commissionName: string;
  pairBVMatch: number;
  unitIncome: string;
  totalIncome: number;
  adminCharge: number;
  tdsCharge: number;
  netIncome: number;
  payableAmount: number;
}

export class PayAllCommissions {
  displayId: number;
  toDate: string;
  fromDate: string;
  pairMatch: number;
  commissionCode: string;
  totalIncome: number;
}

export class MakingPayAllCommissionsList {
  paymentRequestDetails: Array<PayAllCommissions>;
}

export class PayAllUnpaidCommissionDataPost {
  paymentRequestDetails: PayAllCommissions[];
}

export class PaidCommissionTableData{
  fromDate: string;
  toDate: string;
  displayId: number;
  name: string;
  panNo: string;
  commissionName: string;
  pairBVMatch: number;
  totalAmount: number;
  adminChargeAmount: number;
  tdsAmount: number;
  netAmount: number;
  paymentDate: string;
  paymentType: string;
  bankName: string;
  accountNo: string;
  ifsc: string;
}
