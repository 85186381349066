export class FranchiseRequest {
  name: string;
  phNo: string;
  stateCode: string;
  pinCode: string;
  address: string;
  description: string;
  mlmMemberId: number;
}

export class FranchiseApproval {
  requestId: string;
  displayId: number;
  name: string;
  stateName: string;
  mlmMemberName: string;
  phNo: string;
  mlmmemberId: number;
}

export class FranchiseApproveReject {
  displayId: number;
  mlmMemberId: number;
  phNo: string;
  type: number;
  isApproved: number;
  remark: string;
}

export class FranchisesTableData {
  displayId: number;
  name: string;
  phNo: string;
  emailId: null;
  activeOn: string;
  mlmMemberId: number;
  mlmMemberName: string;
  walletBalance: number;
}

export class FranchiseDetails{
  activeOn: string;
  address: string;
  cnfId: number;
  cnfName: string;
  displayId: number;
  emailId: string;
  mlmMemberId: number;
  mlmMemberName: string;
  name: string;
  password: string;
  phNo: string;
  pinCode: string;
  stateName: string;
}
