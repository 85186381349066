import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserAuthService } from 'src/app/mlm/auth/services/user-auth.service';
import { ApprovalBalanceRequest, FundRequestsView } from '../../model/wallet.model';
import { WalletService } from '../../services/wallet.service';
import { CommonComponent } from 'src/app/theme/shared/common/common.component';

@Component({
  selector: 'app-fund-requests',
  templateUrl: './fund-requests.component.html',
  styleUrls: ['./fund-requests.component.scss']
})
export class FundRequestsComponent extends CommonComponent implements OnInit {
  viewTableApproved: FundRequestsView[] = [];
  viewTableRequests: FundRequestsView[] = [];
  approveReqGroup: FormGroup;
  isAdmin: boolean = false;
  columns = ['Date','Name','Display Id','Request Id','Requested Amount','Approved Amount','Description','Comments','Is Approved'];
  gridView: boolean = false;

  constructor(
    private walletService: WalletService,
    public fb: FormBuilder,
    private userAuthService: UserAuthService
  ) {
    super();
    this.approveReqGroup = this.fb.group({
      amount: new FormControl('', [Validators.required]),
      rejectionClause: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.walletService.getFundAddDataRequestsView().subscribe((val)=>{
      if(val){
        val.data.balanceRequests.forEach(element => {
          if(element.isApproved===1){
            this.viewTableApproved.push(element);
          }else{
            this.viewTableRequests.push(element);
          }
        });
        this.gridView = true;
      }
    })

    this.isAdmin = this.userAuthService.getRoles() === 'ADMIN';
  }

  approve(fundRequests: any,amount: number,rejectionClause: string){
      const approvalFormValues = new ApprovalBalanceRequest();
      approvalFormValues.amount = amount;
      approvalFormValues.rejectionClause = rejectionClause;
      approvalFormValues.description = fundRequests.description;
      approvalFormValues.displayId = fundRequests.displayId;
      // approvalFormValues.instrument = fundRequests.instrument;
      approvalFormValues.isApproved = 1;
      approvalFormValues.requestId = fundRequests.requestId;
      console.log(approvalFormValues);
      this.walletService.postApprovalBalanceRequest(approvalFormValues).subscribe((val) => {
        if (val) {
          this.viewTableApproved = [];
          this.viewTableRequests = [];
          this.gridView = false;
          console.log("Succsessfully Completed");
          this.walletService.getFundAddDataRequestsView().subscribe((val)=>{
            if(val){
              val.data.balanceRequests.forEach(element => {
                if(element.isApproved){
                  this.viewTableApproved.push(element);
                }else{
                  this.viewTableRequests.push(element);
                }
              });
              this.gridView = true;
            }
          })
        }
      });
  }

}
