<app-card headerClass="border-0 p-2 pb-0 " cardTitle="Vedik Power Offer Status" [options]="false">
  <div *ngIf="!noData">
    <table class="table table-striped table-responsive">
      <thead>
        <tr class="bg-blue">
          <td>Display ID</td>
          <td>Month</td>
          <td>Total BV</td>
          <td>Pending BV</td>
          <td>Achieved</td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of vedikPowerAchiever">
          <td>{{item?.displayId}}</td>
          <td>{{item?.month}}</td>
          <td>{{item?.totalBV}}</td>
          <td>{{!item?.achieved ? item.pendingBV : 'N/A'}}</td>
          <td>
            <span *ngIf="item?.achieved; else notAchieved" style="color: green;">&#x2714;</span>
            <ng-template #notAchieved><span style="color: red;">&times;</span></ng-template>
          </td>
        </tr>
      </tbody>
    </table> 
  </div>
   
  <div *ngIf="noData">
    <span style="text-align: center; color: red;">You are not eligible for Vedik power offer yet. Please make sure you have self repurchase BV. </span>
</div>
</app-card>



<app-card headerClass="border-0 p-2 pb-0 " cardTitle="Goa Tour Offer Status" [options]="false">
  <div *ngIf="!noSpecialData">
    <table class="table table-striped table-responsive">
      <thead>
        <tr class="bg-blue">
          <td>Display ID</td>
          <td>Month</td>
          <td>Rank</td>
          <td>Target</td>
          <td>Total Purchase BV</td>
          <td>Achieved</td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of specialOfferAchiever">
          <td>{{item?.displayId}}</td>
          <td>{{item?.month}}</td>
          <td>{{item?.rank}}</td>
          <td>{{item?.target}}</td>
          <td>{{item?.pairs}}</td>
          <td>
            <span *ngIf="item?.achieved; else notAchieved" style="color: green;">&#x2714;</span>
            <ng-template #notAchieved><span style="color: red;">&times;</span></ng-template>
          </td>
        </tr>
      </tbody>
    </table> 
  </div>
   
  <div *ngIf="noSpecialData">
    <span style="text-align: center; color: red;">You are not eligible for this offer yet.</span>
</div>
</app-card>





<div class="container">
  <div class="row">
    <div class="image-item">
      <img class="center" style="height: 100%;width: 100%;" src="../../../../../assets/images/offers/firstpurchase.jpg" alt="../../../../../assets/images/offers/firstpurchase.jpg">
    </div>
    <div class="image-item">
      <img class="center" style="height: 100%;width: 100%;" src="../../../../../assets/images/offers/vedikpower.jpg" alt="../../../../../assets/images/offers/vedikpower.jpg">
    </div>
  </div>

</div>
<br/><br/>
