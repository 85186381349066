import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbCarouselModule, NgbDatepickerModule, NgbDropdownModule, NgbTabsetModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
import { SharedModule } from '../../theme/shared/shared.module';
import { ShopComponent } from './components/shop/shop.component';
import { PurchaseRoutingModule } from './purchase-routing.module';
import { ItemDetailsComponent } from './components/item-details/item-details.component';
import { CartComponent } from './components/cart/cart.component';
import { OrdersComponent } from './components/orders/orders.component';
import { CheckoutComponent } from './components/checkout/checkout.component';
import {ArchwizardModule} from 'angular-archwizard';
import { PurchaseReportComponent } from './components/purchase-report/purchase-report.component';
import { StockEntryComponent } from './components/stock-entry/stock-entry.component';
import { StockSearchComponent } from './components/stock-search/stock-search.component';
import { AmountToWordPipe } from './pipes/amount-to-word.pipe';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { PurchaseDeleteComponent } from './components/purchase-delete/purchase-delete.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    NgbTabsetModule,
    NgbDropdownModule,
    NgbCarouselModule,
    NgbDatepickerModule,
    DataTablesModule,
    FormsModule,
    ReactiveFormsModule,
    PurchaseRoutingModule,
    ArchwizardModule,
    NgxQRCodeModule
  ],
  declarations: [
    ShopComponent,
    ItemDetailsComponent,
    CartComponent,
    OrdersComponent,
    CheckoutComponent,
    PurchaseReportComponent,
    AmountToWordPipe,
    PurchaseDeleteComponent
  ]
})
export class PurchaseModule { }
