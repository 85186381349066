
<ng-container *ngIf="!loading; then thenTemplate; else elseTemplate">
</ng-container>
<ng-template #thenTemplate>
    <ng-container *ngIf="isDataAvailable; else elseTemplate">
        <div>
            <div class="col-sm-12">
                <app-card [hidHeader]="true" cardClass="card-datatable">
                    <!-- <div class="col-sm-12 text-right">
                        <button type="button" class="btn btn-primary btn-sm" (click)="exportexcel()">Export to Excel</button>
                    </div> -->
                    <div class="row align-items-center m-l-0">
                        <div class="col-sm-6 text-left">
                            <h5>Your Available Stock</h5>
                        </div>
                    </div>
                    <br>
                    <div class="table-responsive">
                        <table datatable id="excel-table" class="table table-striped table-hover mb-0 table-responsive">
                            <thead>
                                <tr>
                                    <th>Item Image</th>
                                    <th>Item Name</th>
                                    <th>Total Stock Quantity</th>
                                    <th>DP</th>
                                    <th>BV</th>
                                    <th>MRP</th>
                                    <th>TOTAL PRICE</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of allItemsDetailsData; let i=index; ">
                                    <th>
                                        <img [src]="item?.imageUrl" class="me-2 " style="width: 80px; height: auto;">
                                    </th>
                                    <th scope="row ">{{ item?.name }}</th>
                                    <th scope="row ">{{ item?.quantity }}</th>
                                    <th scope="row ">{{ item?.dp }}</th>
                                    <th scope="row ">{{ item?.bv }}</th>
                                    <th scope="row ">{{ item?.mrp }}</th>
                                    <th scope="row">{{ item?.quantity * item?.dp }}</th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <hr>
                    <!-- <div>
                        <label>
                          <input type="checkbox" [value]="isChecked" (change)="onCheckboxChange($event)">
                        Is Shipping Same As Correspondense Address
                      </label>
                    </div> -->
                </app-card>
            </div>
        </div>
    </ng-container>
    <ng-template #elseTemplate>
        <div class="spinner" *ngIf="!noData">
            <h5>Loading...</h5>
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
        </div>
        <div *ngIf="noData">
            <h1 style="text-align: center;">No Data Available</h1>
        </div>
    </ng-template>
</ng-template>
<ng-template #elseTemplate>
</ng-template>