import { Component, OnInit } from '@angular/core';
import { CommonComponent } from 'src/app/theme/shared/common/common.component';
import {
  BalanceRequestStatus,
  FranchiseBalanceRequests,
  FranchiseRequestApprovalReject,
} from '../../model/wallet.model';
import { WalletService } from '../../services/wallet.service';
import 'sweetalert2/src/sweetalert2.scss';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-balance-request-status',
  templateUrl: './balance-request-status.component.html',
  styleUrls: ['./balance-request-status.component.scss'],
})
export class BalanceRequestStatusComponent
  extends CommonComponent
  implements OnInit
{
  balanceRequestStatusData: BalanceRequestStatus[] = [];
  isDataAvailable: boolean;
  gridView: boolean = false;
  fileName = 'ExcelSheet.xlsx';
  loading: boolean = true;
  columns = [
    'Display ID',
    'Name',
    'Request Amount',
    'Requested On',
    'Approved Amount',
    'Action On',
    'Approval Status',
    'Action Taken By',
    'Remarks'
  ];
  noData: boolean;
  constructor(private walletService: WalletService) {
    super();
  }

  ngOnInit(): void {
    this.walletService.getBalanceRequestStatusData().subscribe((val) => {
      if (val) {
        this.balanceRequestStatusData = val.data;
        this.isDataAvailable = this.balanceRequestStatusData?.length > 0;
        this.noData = this.balanceRequestStatusData?.length === 0;
        this.gridView = true;
        this.loading = false;
      }
    });
  }
}
