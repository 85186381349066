import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ProfileService } from '../../services/profile.service';
import { ChangePassword } from '../../model/profile.model';
import { CommonComponent } from 'src/app/theme/shared/common/common.component';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent extends CommonComponent implements OnInit {
  changePasswordGroup: FormGroup;
  displayId: string;
  constructor(
    public fb: FormBuilder,
    private profileService: ProfileService
  ) {
    super();
  }

  ngOnInit(): void {
    this.changePasswordGroup = this.fb.group({
      displayId: new FormControl('', [Validators.required]),
      oldPassword: new FormControl('', [Validators.required]),
      newPassword: new FormControl('', [Validators.required]),
      retypeNewPassword: new FormControl('', [Validators.required]),
    });

    this.changePasswordGroup.controls['displayId'].setValue(localStorage.getItem('displayId'));
  }

  submitNewPassword(){
    const changePasswordForm = new ChangePassword();
    if(this.changePasswordGroup.controls['newPassword'].value === this.changePasswordGroup.controls['retypeNewPassword'].value){
      changePasswordForm.displayId = this.changePasswordGroup.controls['displayId'].value;
      changePasswordForm.oldPassword = this.changePasswordGroup.controls['oldPassword'].value;
      changePasswordForm.newPassword = this.changePasswordGroup.controls['newPassword'].value;
      changePasswordForm.retypeNewPassword = this.changePasswordGroup.controls['retypeNewPassword'].value;
      this.profileService.postChangePasswordData(changePasswordForm).subscribe((val) => {
        if (val.data) {
          this.successSwal('Password updated successfully!');
          this.changePasswordGroup.reset();
          this.changePasswordGroup.controls['displayId'].setValue(localStorage.getItem('displayId'));
        }else{
          this.warningSwal(val.message);
          this.changePasswordGroup.reset();
          this.changePasswordGroup.controls['displayId'].setValue(localStorage.getItem('displayId'));
        }
      });
    }else{
      this.dangerSwal("Retype password is not macthed with new passsword");
    }
  }

  cancel(){
    this.changePasswordGroup.reset();
    this.changePasswordGroup.controls['displayId'].setValue(localStorage.getItem('displayId'));
  }

}
