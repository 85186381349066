import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cnf-stock-request',
  templateUrl: './cnf-stock-request.component.html',
  styleUrls: ['./cnf-stock-request.component.scss']
})
export class CnfStockRequestComponent implements OnInit {
  pendingBalanceloading: boolean = false;
  approvedBalanceloading: boolean = false;
  isDataAvailable: boolean = false;
  noData: boolean = true;

  constructor() { }

  ngOnInit(): void {
  }

}
