<div class="row">
    <div class="col-sm-12">
        <app-card cardTitle="Search First Purchase Rank by Year and Month" [options]="false">
            <form [formGroup]="FilterFormGroup" class="form">
                <div class="app-modal-body">
                    <div class="row">
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="d-block">Year <span style="color: red;">*</span></label>
                                <select class="form-control" formControlName="year">
                                  <option> </option>
                                  <option *ngFor="let year of years">{{year}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="d-block">Month <span style="color: red;">*</span></label>
                                <select class="form-control" formControlName="month">
                                    <option *ngFor="let month of monthList" [value]="month.item_id">{{ month.item_text }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <hr>
                <div class="app-modal-footer ">
                    <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="reset()">Cancel</button>
                    <button type="submit" class="btn btn-primary" (click)="search()">Search</button>
                </div>
            </form>
        </app-card>
    </div>
</div>

<ng-container *ngIf="!loading; then thenTemplate; else elseTemplate"></ng-container>
<ng-template #thenTemplate>
    <ng-container *ngIf="isDataAvailable; else elseTemplate">
        <div class="row btn-page">
            <div class="col-sm-12 btn-page">
                <app-card [hidHeader]="true" cardClass="card-datatable">
                    <span style="font-weight: bold; color: black;">First Purchase Rank</span>
                    <div class="col-sm-12 text-right">
                        <button type="button" class="btn btn-primary btn-sm" (click)="exportexcel()">Export to Excel</button>
                    </div>
                    <div class="table-responsive">
                        <table datatable id="excel-table" class="table table-striped table-hover mb-0 table-responsive">
                            <thead>
                                <tr>
                                    <th>Display Id</th>
                                    <th>Name</th>
                                    <th>Rank</th>
                                    <th>Date</th>
                                    <th>Rank Change</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of firstPurchaseRank; let i=index; ">
                                    <td scope="row ">{{ data?.displayId }}</td>
                                    <td scope="row ">{{ data?.name }}</td>
                                    <td scope="row ">{{ data?.rank }}</td>
                                    <td scope="row ">{{ data?.date?.split('T')[0] }}</td>
                                    <td>
                                        <span *ngIf="data?.rankChanged === true; else notAchieved" style="color: green;">&#x2714;</span>
                                        <ng-template #notAchieved><span style="color: red;">&times;</span></ng-template>
                                    </td>                                
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </app-card>
            </div>
        </div>
    </ng-container>
    <ng-template #elseTemplate>
        <div class="spinner" *ngIf="!noData">
            <h5>Loading...</h5>
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
        </div>
        <div *ngIf="noData">
            <h1 style="text-align: center;">No Data Available</h1>
        </div>
    </ng-template>
</ng-template>
<ng-template #elseTemplate>
</ng-template>