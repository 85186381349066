import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { AllStates } from '../../member/model/allstates.model';
import {
  MemberRegistration,
  MemberRegistrationDetailsBySponsorID,
} from '../../member/model/member-registration.model';
import { MembersService } from '../../member/services/members.service';
import { DownlineService } from '../../report/services/downline.service';
import { AuthService } from '../services/auth.service';
import { UserAuthService } from '../services/user-auth.service';
import 'sweetalert2/src/sweetalert2.scss';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { CommonComponent } from 'src/app/theme/shared/common/common.component';

@Component({
  selector: 'app-auth-signin-v2',
  templateUrl: './auth-signin-v2.component.html',
  styleUrls: ['./auth-signin-v2.component.scss'],
})
export class AuthSigninV2Component extends CommonComponent implements OnInit {
  formGroup: FormGroup;
  position = 'bottom-right';
  title: string;
  msg: string;
  showClose = true;
  theme = 'bootstrap';
  type = 'default';
  closeOther = false;
  showModal: boolean = false;
  sponsorName: string;

  memberGroup: FormGroup;
  gridView: boolean = false;
  isSubmitting: boolean = false;

  showMessageSponsorID: string = '';
  showMessageParentID: string = '';
  showMessageDisplayID: string = '';
  showMessageUserID: string = '';
  columns = ['Downline ID','Downline Name','Position Vacant','Position Eligible','Is Active','Is Green','Contact','Action'];
  index = ['displayId','name','positionVacant','positionEligible','isActive','isGreen','phNo','action'];
  isCheckedLeft: boolean = false;
  isCheckedRight: boolean = false;
  isCheckedBoth: boolean = false;
  allMemberDataBySponsorID: MemberRegistrationDetailsBySponsorID[] = [];
  allStatesData: AllStates[] = [];
  fieldTextType: boolean;
  positionReg = ['Left','Right'];
  forgotPasswordGroup: FormGroup;
  constructor(
    private authService: AuthService,
    private userAuthService: UserAuthService,
    private router: Router,
    private fb: FormBuilder
  ) {
    super();
    this.memberGroup = this.fb.group({
      sponsorId: new FormControl('', [Validators.required]),
      parentId: new FormControl('', [Validators.required]),
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required]),
      phno: new FormControl('', [Validators.required]),
      pincode: new FormControl('', [Validators.required]),
      // userId: new FormControl('', [Validators.required]),
      position: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
    });

    this.forgotPasswordGroup = this.fb.group({
      displayId: new FormControl('', [Validators.required]),
      phno: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit() {
    this.initForm();

    this.authService.getAllStatesData().subscribe((val) => {
      if (val) {
        this.allStatesData = val.data;
      }
    });
  }

  initForm() {
    this.formGroup = new FormGroup({
      username: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
    });
  }

  ngAfterViewInit() {
    const video = document.getElementById('offerVideo') as HTMLVideoElement;
    
    if (video) {
      video.muted = true; // Ensure the video is muted
      video.play().catch(error => {
        console.error('Autoplay blocked:', error);
      });
    }
  }  

  login() {
    if (this.formGroup.valid) {
      this.authService.login(this.formGroup.value).subscribe(
        (result) => {
          if (result) {
            this.userAuthService.setToken(result.data.token);
            this.userAuthService.setRoles(result.data.roleName);
            this.userAuthService.setDisplayId(result.data.displayId);
            this.userAuthService.setUserName(result.data.userName);
            this.userAuthService.setToken(result.data.token);
            this.userAuthService.setRoles(result.data.roleName);
            this.userAuthService.setDisplayId(result.data.displayId);
            this.userAuthService.setUserName(result.data.userName);
            this.successSwal('Welcome to Vedikmart !');
            this.router.navigate(['/home/dashboard']);
          }
        },
        (error) => {
          this.router.navigate(['/auth/signin-v2']);
          this.dangerSwal('Please enter correct User Id or Password');
        }
      );
    }
  }

  show() {
    this.showModal = true;
  }

  hide() {
    this.showModal = false;
  }

  checkSponsorID(event: any) {
    this.sponsorName = '';
    let sponsorId = this.memberGroup.controls['sponsorId'].value;
    // if (displayId) {
    //   this.authService
    //     .getverifyMemberInfoDisplayId(displayId)
    //     .subscribe((val) => {
    //       if (val.data) {
    //         console.log('matched');
    //         this.showMessageSponsorID = '';
    //         this.authService
    //           .getRgistrationEligibleSponsorOrg(displayId)
    //           .subscribe((value) => {
    //             this.gridView = true;
    //             this.allMemberDataBySponsorID = value.data;
    //             console.log(this.allMemberDataBySponsorID);
    //           });
    //       } else {
    //         this.showMessageSponsorID = 'Please enter desire Sponsor ID';
    //         this.gridView = false;
    //       }
    //     });
    // }
    if (sponsorId) {
      this.authService
        .getverifyMemberInfoDisplayId(sponsorId)
        .subscribe((val) => {
          if (val.data) {
            console.log('matched');
            this.showMessageSponsorID = '';
            this.sponsorName = val.data.name;
          } else {
              this.showMessageSponsorID = 'Please enter desire Sponsor ID';
          }
        });
    }
  }

  checkParentID(event: any) {
    let parentId = this.memberGroup.controls['parentId'].value;
    if (parentId) {
      this.authService
        .getverifyMemberInfoDisplayId(parentId)
        .subscribe((val) => {
          if (val.data) {
            console.log('matched');
            this.showMessageParentID = '';
          } else {
            this.showMessageParentID = 'Please enter desire Parent ID';
          }
        });
    }
  }

  checkUserID(event: any) {
    let userId = this.memberGroup.controls['userId'].value;
    if (userId) {
      this.authService
        .getverifyMemberInfoNameDuplication(userId)
        .subscribe((val) => {
          if (val.data) {
            console.log('matched');
            this.showMessageUserID = 'User ID already exists';
          } else {
            this.showMessageUserID = '';
          }
        });
    }
  }

  getRowData(reports: any) {
    this.gridView = false;
    this.memberGroup.controls['parentId'].setValue(reports.displayId);
    if (reports.positionVacant == 'Left') {
      this.isCheckedLeft = true;
      this.isCheckedRight = false;
      this.isCheckedBoth = false;
    } else if (reports.positionVacant == 'Right') {
      this.isCheckedLeft = false;
      this.isCheckedRight = true;
      this.isCheckedBoth = false;
    } else {
      this.isCheckedLeft = false;
      this.isCheckedRight = false;
      this.isCheckedBoth = true;
    }

    if (reports.positionEligible === 'Left') {
      this.memberGroup.controls['position'].setValue('Left');
    } else if (reports.positionEligible === 'Right') {
      this.memberGroup.controls['position'].setValue('Right');
    }
  }

  submitMember() {
    if (this.isSubmitting) {
      return;
    }
    this.isSubmitting = true;
    console.log(this.memberGroup.value);
    const memberPostForm = new MemberRegistration();
    memberPostForm.sponsorID = this.memberGroup.controls['sponsorId'].value;
    memberPostForm.parentID = this.memberGroup.controls['sponsorId'].value;
    memberPostForm.name = this.memberGroup.controls['name'].value;
    memberPostForm.emailId = this.memberGroup.controls['email'].value;
    memberPostForm.phNo = this.memberGroup.controls['phno'].value;
    memberPostForm.pinCode = this.memberGroup.controls['pincode'].value;
    // memberPostForm.userName = this.memberGroup.controls['userId'].value;
    memberPostForm.isleft = this.memberGroup.controls['position'].value == 'Left' ? true : false;
    memberPostForm.stateCode = this.memberGroup.controls['state'].value;
    if(this.showMessageSponsorID.length == 0 && this.memberGroup.controls['sponsorId'].value){
      //if(this.showMessageParentID.length == 0 && this.memberGroup.controls['parentId'].value){
        this.authService.postRegistrationData(memberPostForm).subscribe((val) => {
          if (val.data) {
            console.log('Data Updated');
            const name = val.data.name;
            const displayId = val.data.displayId;
            const msg = 'Hi' + name + 'Welcome to Vedikmart\n' + 'Your ID is :' + displayId;
            this.successSwal(msg);
            this.isSubmitting = false;
            // alert('Registration completed successfully!');
            this.memberGroup.reset();
          } else {
            if(val.message){
              this.warningSwal(val.message);
              this.isSubmitting = false;
            }else{
              this.dangerSwal('Please fill the mandatory fields');
              // alert("Please fill the mandatory fields");
              this.isSubmitting = false;
            }
          }
        });
      // }else{
      //   this.dangerSwal('Please enter desire Parent ID');
      //   // alert("Please enter desire Parent ID");
      // }
    }else{
      this.dangerSwal('Please enter desire Sponsor ID');
      this.isSubmitting = false;
      // alert("Please enter desire Sponsor ID");
    }

  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  submitForgotPassword(){
    const displayId = this.forgotPasswordGroup.controls['displayId'].value;
    const phNo = this.forgotPasswordGroup.controls['phno'].value;
    if(phNo.length==4){
      this.authService.getForgotPassword(displayId,phNo).subscribe((val)=>{
        if(!val.errorCode){
          this.successSwal('Password has been sent successfully to your registered mobile number');
          this.forgotPasswordGroup.reset();
        }else {
          this.dangerSwal(val.message);
        }
      });
    }else{
      this.warningSwal('Please enter last four digit from your phone number');
    }
  }

}
