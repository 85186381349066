import { Component, OnInit } from '@angular/core';
import { DownlineService } from '../../services/downline.service';
import { CommonComponent } from 'src/app/theme/shared/common/common.component';
import { UserAuthService } from 'src/app/mlm/auth/services/user-auth.service';
import { SpecialOfferAchiever, VedikPowerAchiever } from '../../model/offer-data.model';

@Component({
  selector: 'app-view-offers',
  templateUrl: './view-offers.component.html',
  styleUrls: ['./view-offers.component.scss']
})
export class ViewOffersComponent extends CommonComponent implements OnInit {

  displayId: string;
  noData: boolean = false;
  noSpecialData: boolean = false;
  vedikPowerAchiever: VedikPowerAchiever[] = [];
  specialOfferAchiever: SpecialOfferAchiever[] = [];

  constructor(private userAuthService: UserAuthService,
    private downlineService: DownlineService) {
    super();
    this.noData = false;
    this.noSpecialData = false;
   }

  ngOnInit(): void {
    this.displayId = this.userAuthService.getDisplayId();
    this.downlineService.getVedikPowerAchievers(this.displayId).subscribe((val)=>{
      if(!val.errorCode) {
        this.vedikPowerAchiever = val.data;
        console.log(this.vedikPowerAchiever);
        this.noData = ! this.vedikPowerAchiever || this.vedikPowerAchiever.length <= 0;
      }
    });

    this.downlineService.getSpecialOffers(this.displayId).subscribe((val)=>{
      if(!val.errorCode) {
        this.specialOfferAchiever = val.data;
        console.log(this.specialOfferAchiever);
        this.noSpecialData = ! this.specialOfferAchiever || this.specialOfferAchiever.length <= 0;
      }
    });
    
    
  }

}
