import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {IAlbum, IEvent, Lightbox, LIGHTBOX_EVENT, LightboxConfig, LightboxEvent} from 'ngx-lightbox';
import {Subscription} from 'rxjs';
import { UserAuthService } from 'src/app/mlm/auth/services/user-auth.service';
import { AllStates } from 'src/app/mlm/member/model/allstates.model';
import { DetailsDisplayId } from 'src/app/mlm/member/model/details-displayid.model';
import { MembersService } from 'src/app/mlm/member/services/members.service';
import { CommonComponent } from 'src/app/theme/shared/common/common.component';

@Component({
  selector: 'app-view-profile',
  templateUrl: './view-profile.component.html',
  styleUrls: ['./view-profile.component.scss']
})
export class ViewProfileComponent extends CommonComponent implements OnInit {
  public activeTab: string;
  public hasSuperAccess: boolean;

  public editProfile: boolean;
  public editProfileIcon: string;

  public editContact: boolean;
  public editContactIcon: string;

  public editOtherInfo: boolean;
  public editOtherInfoIcon: string;

  public albums: Array<IAlbum>;
  private subscription: Subscription;

  userInformations: DetailsDisplayId;
  userRole: string = '';

  displayId: any;
  allStatesData: AllStates[] = [];
  stateNameHold: String;

  constructor(
    private lightbox: Lightbox,
    private lightboxEvent: LightboxEvent,
    private lighboxConfig: LightboxConfig,
    private memberService: MembersService,
    private userAuthService:UserAuthService,
    private router: Router
    ) {
    super();
    this.activeTab = 'personalDetails';

    this.editProfile = false;
    this.editProfileIcon = 'icon-edit';

    this.editContact = false;
    this.editContactIcon = 'icon-edit';

    this.editOtherInfo = false;
    this.editOtherInfoIcon = 'icon-edit';

    this.albums = [];
    for (let i = 1; i <= 6; i++) {
      const album = {
        src: 'assets/images/light-box/l' + i + '.jpg',
        caption: 'Image ' + i + ' caption here',
        thumb: 'assets/images/light-box/sl' + i + '.jpg'
      };

      this.albums.push(album);
    }
    lighboxConfig.fadeDuration = 1;

    this.userInformations = new DetailsDisplayId();
  }

  ngOnInit() {
    this.hasSuperAccess = this.userAuthService.getDisplayId()==='10001000512';
    this.memberService.getAllStatesData().subscribe((value) => {
      this.allStatesData = value.data;
      console.log(this.allStatesData)
    });

    this.displayId = this.router.url.split('/')?.[5];
    this.memberService.getverifyMemberInfoDisplayId(this.displayId).subscribe((val)=>{
      if(val){
        this.userInformations = val.data;
        this.allStatesData.forEach(element => {
          if(element.stateCode == this.userInformations.stateCode){
            this.stateNameHold =  element.stateName;
          }
        });
      }
    })

    this.userRole = (this.userAuthService.getRoles()==="ADMIN")?'Admin':'User';
  }

  open(index: number): void {
    this.subscription = this.lightboxEvent.lightboxEvent$.subscribe((event: IEvent) => this._onReceivedEvent(event));
    this.lightbox.open(this.albums, index, { wrapAround: true, showImageNumberLabel: true });
  }

  private _onReceivedEvent(event: IEvent): void {
    if (event.id === LIGHTBOX_EVENT.CLOSE) {
      this.subscription.unsubscribe();
    }
  }

}
