import { Component, OnInit } from '@angular/core';
import { RechargeService } from '../../services/recharge.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserAuthService } from 'src/app/mlm/auth/services/user-auth.service';
import { CommonComponent } from 'src/app/theme/shared/common/common.component';
import { RechargeReportTableData } from '../../model/recharge-report.model';

@Component({
  selector: 'app-recharge-report',
  templateUrl: './recharge-report.component.html',
  styleUrls: ['./recharge-report.component.scss']
})

export class RechargeReportComponent extends CommonComponent implements OnInit {
  rechargeReportGroup: FormGroup;
  isAdmin: boolean = false;
  isDataAvailable: boolean;
  gridView: boolean = false;
  fileName = 'ExcelSheet.xlsx';
  loading: boolean = true;
  columns = [
    'Request Id',
    'Login Id',
    'Connection Type',
    'Service Provider',
    'Recharge Type',
    'Circle',
    'Account No.',
    'STD Code',
    'Mobile No.',
    'Amount',
    'Commission',
    'Wallet Balance',
    'Reharge Status',
    'Ref. No.',
    'Timestamp'
  ];
  noData: boolean;
  rechargeReportTableData: RechargeReportTableData[] = [];

  constructor(
    private rechargeService: RechargeService,
    private userAuthService: UserAuthService,
    private fb: FormBuilder,
  ) {
    super();
    this.rechargeReportGroup = this.fb.group({
      displayId: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit(): void {
    if(this.userAuthService.getRoles()=='USER'){
      this.rechargeReportGroup.controls['displayId'].setValue(this.userAuthService.getDisplayId());
    }
    this.isAdmin = this.userAuthService.getRoles()=='ADMIN'?true:false;
  }

  submitRechargeReport(){
    const displayId = this.rechargeReportGroup.controls['displayId'].value?this.rechargeReportGroup.controls['displayId'].value:0;
    this.gridView = false;
    this.rechargeService.getRechargeReportData(displayId).subscribe((val)=>{
      if(val){
        this.rechargeReportTableData = val.data;
        this.noData = this.rechargeReportTableData?.length === 0;
        this.isDataAvailable = this.rechargeReportTableData?.length > 0;
        this.gridView = true;
      }
      this.loading = false;
    },error =>{
      this.dangerSwal("Something went wrong please try again after sometime");
    });
  }

  cancel(){
    this.rechargeReportGroup.reset();
    if(!this.isAdmin){
      this.rechargeReportGroup.controls['displayId'].setValue(this.userAuthService.getDisplayId());
    }
    this.gridView = false;
  }
}
