<div *ngIf="!isBlocked" class="row btn-page">
    <div class="col-sm-12">
        <app-card cardTitle="Downline Report Summary" [options]="false" *ngIf="gridView">
            <div class="row">
                <div class="col-md-2 col-xs-6 border-right">
                    <h3>{{leftMember}}</h3>
                    <span>Left Member</span>
                </div>
                <div class="col-md-2 col-xs-6 border-right">
                    <h3>{{rightMember}}</h3>
                    <span class="text-primary">Right Member</span>
                </div>
                <div class="col-md-2 col-xs-6 border-right">
                    <h3>{{greenMember}}</h3>
                    <span class="text-success">Green Member</span>
                </div>
                <div class="col-md-2 col-xs-6 border-right">
                    <h3>{{redMember}}</h3>
                    <span class="text-danger">Red Member</span>
                </div>
                <div class="col-md-2  col-xs-6 border-right">
                    <h3>{{totalMember}}</h3>
                    <span class="text-info">Total Member</span>
                </div>
            </div>
        </app-card>
    </div>
</div>
<div *ngIf="!isBlocked" class="row">
    <div class="col-sm-12">
        <app-card cardTitle="Filter Downline Report" [options]="false">
            <form [formGroup]="FilterFormGroup" class="form">
                <div class="app-modal-body">
                    <div class="row">
                        <div class="col-sm-8">
                            <div class="form-group">
                                <label class="d-block">Display Id</label>
                                <input type="number" class="form-control" [ngModel]="displayId" formControlName="displayId" placeholder="Display Id" [readOnly]="!isAdmin">
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="d-block">State <span style="color: red;">*</span></label>
                                <select class="form-control" formControlName="state">
                                  <option>All</option>
                                  <option *ngFor="let states of allStatesData ">{{states.stateName}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-3">
                            <div class="form-group">
                                <label class="d-block">Joining Date From</label>
                                <input type="date" class="form-control" [ngModel]="fromJoiningDate | date:'yyyy-MM-dd'" formControlName="dojFrom" placeholder="Joining Date From">
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="form-group">
                                <label class="d-block">Joining Date To</label>
                                <input type="date" class="form-control" [ngModel]="toJoiningDate | date:'yyyy-MM-dd'" formControlName="dojTo" placeholder="Joining Date To">
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="form-group">
                                <label class="d-block">Activation Date From</label>
                                <input type="date" class="form-control" formControlName="greenFrom" placeholder="Activation Date From">
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="form-group">
                                <label class="d-block">Activation Date To</label>
                                <input type="date" class="form-control" formControlName="greenTo" placeholder="Activation Date To">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-3">
                            <div class="form-group">
                                <label class="d-block">Parent ID</label>
                                <input type="text" class="form-control" formControlName="parentId" placeholder="Parent ID">
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="form-group">
                                <label class="d-block">Sponsor ID</label>
                                <input type="text" class="form-control" formControlName="sponsorId" placeholder="Sponsor ID">
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="form-group">
                                <label class="d-block">Status</label>
                                <select class="form-control" formControlName="isGreen">
                                      <option *ngFor="let stats of status">{{stats}}</option>
                                   </select>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="form-group">
                                <label class="d-block">Position</label>
                                <select class="form-control" formControlName="isRelativePositionLeft">
                                      <option *ngFor="let pos of position">{{pos}}</option>
                                   </select>
                            </div>
                        </div>
                    </div>
                </div>
                <hr>
                <div class="app-modal-footer ">
                    <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="reset()">Cancel</button>
                    <button type="submit" class="btn btn-primary" (click)="search()">Search</button>
                </div>
            </form>
        </app-card>
    </div>
</div>
<ng-container *ngIf="!loading; then thenTemplate; else elseTemplate"></ng-container>
<ng-template #thenTemplate>
    <ng-container *ngIf="isDataAvailable && !isBlocked; else elseTemplate">
        <div class="row btn-page" *ngIf="gridView && isAdmin">
            <div class="col-sm-12 btn-page">
                <app-card [hidHeader]="true" cardClass="card-datatable">
                    <div class="col-sm-12 text-right">
                        <button type="button" class="btn btn-primary btn-sm" (click)="exportexcel()">Export to Excel</button>
                    </div>
                    <div class="table-responsive">
                        <table datatable id="excel-table" class="table table-striped table-hover mb-0 table-responsive">
                            <thead>
                                <tr>
                                    <th *ngFor="let col of columnsAdmin">
                                        {{col}}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let reports of downlineReportsTableData">
                                    <td *ngFor="let col of indexAdmin">
                                        <div *ngIf="col != 'isLeft' && col != 'isActive' && col != 'isGreen'">
                                            {{reports[col]}}
                                        </div>
                                        <div *ngIf="col == 'isLeft'">
                                            <div *ngIf="reports[col] == true ">
                                                <span class="badge badge-success inline-block">Left</span>
                                            </div>
                                            <div *ngIf="reports[col] == false ">
                                                <span class="badge badge-warning inline-block m-r-5">Right</span>
                                            </div>
                                        </div>
                                        <!-- <div *ngIf="col == 'isActive'" class="d-inline-block">
                                            <div class="custom-control custom-switch" *ngIf="reports[col] == 1 ">
                                                <input type="checkbox" class="custom-control-input" readonly checked>
                                                <label class="custom-control-label" for="customSwitch1"></label>
                                            </div>

                                            <div class="custom-control custom-switch" *ngIf="reports[col] == 0 ">
                                                <input type="checkbox" class="custom-control-input" readonly>
                                                <label class="custom-control-label" for="customSwitch1"></label>
                                            </div>
                                        </div> -->
                                        <div *ngIf="col == 'isGreen'">
                                            <div class="custom-control custom-switch" *ngIf="reports[col] == true ">
                                                <input type="checkbox" class="custom-control-input" readonly checked>
                                                <label class="custom-control-label"></label>
                                            </div>

                                            <div class="custom-control custom-switch" *ngIf="reports[col] == false ">
                                                <input type="checkbox" class="custom-control-input" readonly>
                                                <label class="custom-control-label"></label>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </app-card>
            </div>
        </div>

        <div class="row btn-page" *ngIf="gridView && !isAdmin">
            <div class="col-sm-12 btn-page">
                <app-card [hidHeader]="true" cardClass="card-datatable">
                    <button class="btn btn-primary" (click)="exportexcel()">Export to Excel</button>
                    <div class="table-responsive">
                        <table datatable id="excel-table" class="table table-striped table-hover mb-0 table-responsive">
                            <thead>
                                <tr>
                                    <th *ngFor="let col of columnsUser">
                                        {{col}}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let reports of downlineReportsTableData">
                                    <td *ngFor="let col of indexUser">
                                        <div *ngIf="col != 'isLeft' && col != 'isActive' && col != 'isGreen'">
                                            {{reports[col]}}
                                        </div>
                                        <div *ngIf="col == 'isLeft'">
                                            <div *ngIf="reports[col] == true ">
                                                <span class="badge badge-success inline-block">Left</span>
                                            </div>
                                            <div *ngIf="reports[col] == false ">
                                                <span class="badge badge-warning inline-block m-r-5">Right</span>
                                            </div>
                                        </div>
                                        <!-- <div *ngIf="col == 'isActive'" class="d-inline-block">
                                            <div class="custom-control custom-switch" *ngIf="reports[col] == 1 ">
                                                <input type="checkbox" class="custom-control-input" readonly checked>
                                                <label class="custom-control-label" for="customSwitch1"></label>
                                            </div>

                                            <div class="custom-control custom-switch" *ngIf="reports[col] == 0 ">
                                                <input type="checkbox" class="custom-control-input" readonly>
                                                <label class="custom-control-label" for="customSwitch1"></label>
                                            </div>
                                        </div> -->
                                        <div *ngIf="col == 'isGreen'">
                                            <div class="custom-control custom-switch" *ngIf="reports[col] == true ">
                                                <input type="checkbox" class="custom-control-input" readonly checked>
                                                <label class="custom-control-label"></label>
                                            </div>
                                            <div class="custom-control custom-switch" *ngIf="reports[col] == false ">
                                                <input type="checkbox" class="custom-control-input" readonly>
                                                <label class="custom-control-label"></label>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </app-card>
            </div>
        </div>
    </ng-container>
    <ng-template #elseTemplate>
        <div class="spinner" *ngIf="!noData">
            <h5>Loading...</h5>
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
        </div>
        <div *ngIf="noData">
            <h1 style="text-align: center;">No Data Available</h1>
        </div>
    </ng-template>
</ng-template>
<ng-template #elseTemplate>

</ng-template>