import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FundAdd } from '../../../../../wallet/model/wallet.model';
import { WalletService } from '../../../../../wallet/services/wallet.service';
import { Location } from '@angular/common';
import { CommonComponent } from 'src/app/theme/shared/common/common.component';

@Component({
  selector: 'app-add-fund',
  templateUrl: './add-fund.component.html',
  styleUrls: ['./add-fund.component.scss']
})
export class AddFundComponent extends CommonComponent implements OnInit {
  displayId: any;
  fundAddGroup: FormGroup;
  constructor(
    public fb: FormBuilder,
    private router: Router,
    private walletService: WalletService,
    private location: Location
  )
  {
    super();
    this.fundAddGroup = this.fb.group({
      displayId: new FormControl('', [Validators.required]),
      amount: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),
      instrument: new FormControl('', [Validators.required]),
    });
   }

  ngOnInit(): void {
    this.displayId = this.router.url.split('/')?.[5];
  }

  cancel(){
    this.location.back();
  }

  submitFundAdd(){
    const addFundPostForm = new FundAdd();
    addFundPostForm.amount = this.fundAddGroup.controls['amount'].value;
    addFundPostForm.description = this.fundAddGroup.controls['description'].value;
    // addFundPostForm.instrument = this.fundAddGroup.controls['instrument'].value;

    this.walletService.postFundAddData(addFundPostForm).subscribe((value)=>{
      if(value){
        this.successSwal("Fund Request Submitted Successfully");
        this.fundAddGroup.reset();
      }
    })
  }
}
