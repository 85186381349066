import {Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import {NgbDropdownConfig} from '@ng-bootstrap/ng-bootstrap';
import { Docs } from 'src/app/mlm/dashboard/model/member.model';
import { DashboardService } from 'src/app/mlm/dashboard/services/dashboard.service';
import {UserAuthService} from '../../../../../mlm/auth/services/user-auth.service';

@Component({
  selector: 'app-nav-right',
  templateUrl: './nav-right.component.html',
  styleUrls: ['./nav-right.component.scss'],
  providers: [NgbDropdownConfig]
})
export class NavRightComponent implements OnInit {
  userName: string = '';
  profilePicture: Docs;
  constructor(
    private userAuthService: UserAuthService,
    private router: Router,
    private dashboardService: DashboardService
  ) { }

  ngOnInit() {
    this.userName = this.userAuthService.getUserName().toUpperCase();
    this.dashboardService.getUserDocsData().subscribe((val)=>{
      if(val){
        this.profilePicture = new Docs();
        val.data.forEach((element: Docs) => {
          if (element.doctype == "profile") {
            this.profilePicture.filelocation = element.filelocation;
          }
        })
      }
    })
   }

  public isLoggedIn(){
    return this.userAuthService.isLoggedIn();
  }

  public logOut(){
    localStorage.clear();
    this.router.navigate(['/auth/signin-v2']);
  }
}
