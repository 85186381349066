import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ViewCommissionService } from '../pay-commission/services/view-commission.service';
import { DatePipe } from '@angular/common';
import { UserAuthService } from 'src/app/mlm/auth/services/user-auth.service';
import { CommonComponent } from 'src/app/theme/shared/common/common.component';

@Component({
  selector: 'app-commission-generate',
  templateUrl: './commission-generate.component.html',
  styleUrls: ['./commission-generate.component.scss'],
  providers: [DatePipe]
})
export class CommissionGenerateComponent extends CommonComponent implements OnInit {
  commissionGeneratedGroup: FormGroup;
  date: string;
  myDate = new Date();

  constructor(
    private fb: FormBuilder,
    private datePipe: DatePipe,
  ) {
    super();
    this.commissionGeneratedGroup = this.fb.group({
      displayId: new FormControl('', [Validators.required]),
      dateFrom: new FormControl('', [Validators.required]),
      dateTo: new FormControl('', [Validators.required])
    });
    this.date = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
  }

  ngOnInit(): void {
  }

  submitCommissionList(){
  }

  cancel(){

  }

}
