import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  CompanyMasterDetails,
  Invoice,
  InvoiceDetails,
  Orders,
  PurchasedItemsDetails,
} from '../../model/purchase.model';
import { PurchaseService } from '../../services/purchase.service';
const pdfMake = require('pdfmake/build/pdfmake.js');
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { UserAuthService } from 'src/app/mlm/auth/services/user-auth.service';
import { MembersService } from 'src/app/mlm/member/services/members.service';
import { AllStates } from 'src/app/mlm/member/model/allstates.model';
import jsPDF from 'jspdf';
(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;
import htmlToPdfmake from 'html-to-pdfmake';
import { CommonComponent } from 'src/app/theme/shared/common/common.component';

@Component({
  selector: 'app-purchase-report',
  templateUrl: './purchase-report.component.html',
  styleUrls: ['./purchase-report.component.scss'],
})
export class PurchaseReportComponent extends CommonComponent implements OnInit {
  displayId: FormGroup;
  allOrdersData: Orders[] = [];
  columns = [
    'Display Id',
    'Customer Name',
    'Purchase Invoice No.',
    'Date',
    'Total Purchase Amount',
    'Total BV',
    'State',
    'Invoice Download',
  ];
  columnsNotAdmin = [
    'Customer Name',
    'Purchase Invoice No.',
    'Date',
    'Total Purchase Amount',
    'Total BV',
    'State',
    'Invoice Download',
  ];
  companyMasterDetails: CompanyMasterDetails;
  invoiceDetails: InvoiceDetails;
  purchasedItemsDetails: PurchasedItemsDetails[] = [];
  displayID: any;
  gridView: boolean = false;
  isAdmin: boolean = false;
  allStatesData: AllStates[] = [];
  stateNameHold: String;
  grandTotal: number = 0;
  stateCode: string = '';

  constructor(
    private purchaseService: PurchaseService,
    private fb: FormBuilder,
    private userAuthService: UserAuthService,
    private memberService: MembersService
  ) {
    super();
    this.displayId = this.fb.group({
      displayId: new FormControl('', [Validators.required]),
      orderDateFrom: new FormControl('', [Validators.required]),
      orderDateTo: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.isAdmin = this.userAuthService.getRoles() === 'ADMIN' ? true : false;
    this.displayID = this.userAuthService.getDisplayId();
    this.memberService.getAllStatesData().subscribe((value) => {
      this.allStatesData = value.data;
      console.log(this.allStatesData);
    });
  }

  submitDisplayId() {
    this.gridView = false;
    this.allStatesData.forEach((element) => {
      if (element.stateName == this.displayId.controls['state'].value) {
        this.stateCode = element.stateCode;
      }
    });

    if (
      this.displayId.controls['orderDateFrom'].value <=
      this.displayId.controls['orderDateTo'].value
    ) {
      this.displayID = this.displayId.controls['displayId'].value;
      this.purchaseService
        .getAllOrdersData(
          this.displayId.controls['displayId'].value
            ? this.displayId.controls['displayId'].value
            : 0,
          this.displayId.controls['orderDateFrom'].value?this.displayId.controls['orderDateFrom'].value:'',
          this.displayId.controls['orderDateTo'].value?this.displayId.controls['orderDateTo'].value:'',
          this.stateCode
        )
        .subscribe((val) => {
          if (!val.message) {
            this.allOrdersData = val.data;
            console.log(this.allOrdersData);
            this.gridView = true;
          }else{
            this.warningSwal(val.message);
            this.gridView = false;
          }
        });
    } else {
      this.dangerSwal('Please check Order Date From or Order Date To is wrong');
    }

    // else {
    //   if (
    //     this.displayId.controls['orderDateFrom'].value <=
    //     this.displayId.controls['orderDateTo'].value
    //   ) {
    //     this.purchaseService
    //       .getAllOrdersData('0',
    //         this.displayId.controls['orderDateFrom'].value,
    //         this.displayId.controls['orderDateTo'].value,
    //         this.stateCode
    //       )
    //       .subscribe((val) => {
    //         if (val) {
    //           this.allOrdersData = val.data;
    //           console.log(this.allOrdersData);
    //           this.gridView = true;
    //         }
    //       });
    //   } else {
    //     alert(
    //       'Please check Order Date From Date From or Order Date To is wrong'
    //     );
    //   }
    // }
  }

  cancel() {
    this.displayId.reset();
    this.displayId.controls['state'].setValue('All');
    this.displayId.controls['displayId'].setValue(
      this.userAuthService.getDisplayId()
    );
    this.allOrdersData = [];
    this.gridView = false;
  }

  invoice = new Invoice();

  invoiceDetailsGenerate(orderNo: string,displayId: string) {
    this.companyMasterDetails = new CompanyMasterDetails();
    this.invoiceDetails = new InvoiceDetails();
    this.purchasedItemsDetails = [];

    this.purchaseService.getInvoice(orderNo,displayId).subscribe((val) => {
      if (val) {
        console.log(val.data.companyMaster);
        this.companyMasterDetails = val.data.companyMaster;
        this.invoiceDetails = val.data.invoice;
        this.grandTotal = val.data.totalPrice;
        if (this.invoiceDetails.stateCode) {
          this.memberService.getAllStatesData().subscribe((value) => {
            this.allStatesData = value.data;
            this.allStatesData.forEach((element) => {
              if (element.stateCode == this.invoiceDetails.stateCode) {
                this.stateNameHold = element.stateName;
              }
            });
          });
        }
        this.purchasedItemsDetails = val.data.purchasedItems;
      }
    });
    setTimeout(() => {
      this.downloadAsPDF();
    }, 2000);
  }

  generatePDF(action = 'open') {
    this.invoice.products = this.purchasedItemsDetails;
    console.log(this.invoice.products);
    let docDefinition = {
      content: [
        {
          text: this.companyMasterDetails.name,
          fontSize: 35,
          alignment: 'center',
          color: '#047886',
          bold: true,
        },
        {
          text: 'INVOICE',
          fontSize: 15,
          bold: true,
          alignment: 'center',
          decoration: 'underline',
          color: 'skyblue',
        },
        {
          text: 'Customer Details',
          style: 'sectionHeader',
        },
        {
          columns: [
            [
              {
                text: this.invoiceDetails.name,
                bold: true,
              },
              { text: this.invoiceDetails.billingAddress },
              { text: this.invoiceDetails.shippingAddress },
            ],
            [
              {
                text:
                  'Date: ' + this.invoiceDetails.invoiceDate.substring(0, 10),
                alignment: 'right',
              },
              {
                text: 'Bill No: ' + this.invoiceDetails.invoiceNo,
                alignment: 'right',
              },
            ],
          ],
        },
        {
          text: 'Order Details',
          style: 'sectionHeader',
        },
        {
          table: {
            headerRows: 1,
            widths: ['*', 'auto', 'auto', 'auto'],
            body: [
              ['Product', 'Price', 'Quantity', 'Amount'],
              ...this.invoice.products.map((p) => [
                p.prodName,
                p.itemPrice,
                p.qty,
                (p.itemPrice * p.qty).toFixed(2),
              ]),
              [
                { text: 'Total Amount', colSpan: 3 },
                {},
                {},
                this.invoice.products
                  .reduce((sum, p) => sum + p.itemPrice * p.qty, 0)
                  .toFixed(2),
              ],
            ],
          },
        },
        {
          text: 'Additional Details',
          style: 'sectionHeader',
        },
        {
          text: this.invoice.additionalDetails,
          margin: [0, 0, 0, 15],
        },
        {
          columns: [
            [{ qr: `${this.invoice.customerName}`, fit: '50' }],
            [{ text: 'Signature', alignment: 'right', italics: true }],
          ],
        },
        {
          text: 'Terms and Conditions',
          style: 'sectionHeader',
        },
        {
          ul: [
            'Order can be return in max 10 days.',
            'Warrenty of the product will be subject to the manufacturer terms and conditions.',
            'This is system generated invoice.',
          ],
        },
      ],
      styles: {
        sectionHeader: {
          bold: true,
          decoration: 'underline',
          fontSize: 14,
          margin: [0, 15, 0, 15],
        },
      },
    };

    if (action === 'download') {
      pdfMake.createPdf(docDefinition).download();
    } else if (action === 'print') {
      pdfMake.createPdf(docDefinition).print();
    } else {
      pdfMake.createPdf(docDefinition).open();
    }
  }

  @ViewChild('pdfTable') pdfTable: ElementRef;
  public downloadAsPDF() {
    const doc = new jsPDF();

    const pdfTable = this.pdfTable.nativeElement;

    var html = htmlToPdfmake(pdfTable.innerHTML);

    const documentDefinition = { content: html };
    pdfMake.createPdf(documentDefinition).open();
  }
}
