import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserAuthService } from 'src/app/mlm/auth/services/user-auth.service';
import { FundAdd } from '../../model/wallet.model';
import { WalletService } from '../../services/wallet.service';
import { CommonComponent } from 'src/app/theme/shared/common/common.component';

@Component({
  selector: 'app-fund-add',
  templateUrl: './fund-add.component.html',
  styleUrls: ['./fund-add.component.scss']
})
export class FundAddComponent extends CommonComponent implements OnInit {
  fundAddGroup: FormGroup;
  isAdmin: boolean = false;
  displayId: string = '';
  constructor(
    public fb: FormBuilder,
    private walletService: WalletService,
    private userAuthService: UserAuthService
  )
  {
    super();
    this.fundAddGroup = this.fb.group({
      displayId: new FormControl('', [Validators.required]),
      amount: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),
      instrument: new FormControl('', [Validators.required]),
    });
   }

  ngOnInit(): void {
    this.isAdmin = (this.userAuthService.getRoles()==='ADMIN')?true:false;
    this.displayId = this.userAuthService.getDisplayId();
  }

  cancel(){
    this.fundAddGroup.reset();
  }

  submitFundAdd(){
    const addFundPostForm = new FundAdd();
    addFundPostForm.displayId = this.fundAddGroup.controls['displayId'].value;
    addFundPostForm.amount = this.fundAddGroup.controls['amount'].value;
    addFundPostForm.description = this.fundAddGroup.controls['description'].value;
    // addFundPostForm.instrument = this.fundAddGroup.controls['instrument'].value;
    if(this.isAdmin){
      addFundPostForm.isApproved = 1;
    }else{
      addFundPostForm.isApproved = 0;
    }
    this.walletService.postFundAddData(addFundPostForm).subscribe((value)=>{
      if(value){
        if(this.isAdmin){
          this.successSwal("Fund Added Successfully");
        }else{
          this.successSwal("Fund Request Submitted Successfully");
        }
        this.fundAddGroup.reset();
        this.displayId = this.userAuthService.getDisplayId();
      }
    })
  }
}
