import { Component, OnInit } from '@angular/core';
import { UserAuthService } from 'src/app/mlm/auth/services/user-auth.service';
import { TransactionReport } from '../../model/wallet.model';
import { WalletService } from '../../services/wallet.service';
import { CommonComponent } from 'src/app/theme/shared/common/common.component';

@Component({
  selector: 'app-transaction-report',
  templateUrl: './transaction-report.component.html',
  styleUrls: ['./transaction-report.component.scss']
})
export class TransactionReportComponent extends CommonComponent implements OnInit {
  transactionReportData: TransactionReport;
  gridView: boolean = false;
  columns = ['Display Id','Transaction Date','Amount','Transaction Type'];
  columnsFranchise = ['Display Id','Amount','Transaction Date','Transaction Type','Order No.','Particulars'];
  displayId: string = '';
  walletBalance: number;
  isFranchise: boolean = false;
  constructor(
    private walletService: WalletService,
    private userAuthService: UserAuthService
  ) {
    super();
  }

  ngOnInit(): void {
    this.displayId = this.userAuthService.getDisplayId();
    this.isFranchise = this.userAuthService.getRoles() == 'FRANCHISE' ? true : false;
    this.walletService.getTransactionReportData(this.displayId).subscribe((val)=>{
      if(val){
        this.transactionReportData = val.data;
        this.gridView = true;
      }
    })
    this.walletService.getWalletBalanceData().subscribe((val)=>{
      if(val){
        this.walletBalance = val.data;
      }
    })
  }

}
