import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { UserAuthService } from '../services/user-auth.service';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private userAuthService: UserAuthService,
    private router : Router
  ){

  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(req.headers.get('NO_AUTH')=== 'true'){
      return next.handle(req.clone());
    }
    if(localStorage.getItem('jwtToken')){
      const token = this.userAuthService.getToken();
      req = this.addToken(req,token);
    }

    return next.handle(req).pipe(
      catchError(
        (err:HttpErrorResponse) =>{
          console.log(err.status)
          if(err.status === 401 ){
            this.router.navigate(['/auth/signin-v2']);
          }else if(err.status === 403 ){
            this.router.navigate(['/forbidden']);
          }
          return throwError("Something Is Wrong");
        }
      )
    );
  }

  private addToken( request : HttpRequest<any>,token : string ){
    return request.clone({
      setHeaders:{
        Authorization : `Bearer ${token}`
      }
    })
  }

}
