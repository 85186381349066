<ng-container *ngIf="!item.hidden">
    <li class="nav-item pcoded-menu-caption"><label>{{item.title}}</label></li>
    <ng-container *ngFor="let item of item.children">
        <!-- this block is only for admin -->
        <app-nav-collapse *ngIf="item.type=='collapse' && isAdmin && !isFranchise 
        && !isCnf && item.id!='franchise-purchase'"
         [item]="item"></app-nav-collapse>

         <app-nav-item *ngIf="item.type=='item' && isAdmin && !isFranchise && !isCnf 
        && item.id!='franchise-purchase'"
        [item]="item"></app-nav-item>

         <!-- this block is only for members -->
        <app-nav-collapse *ngIf="item.type=='collapse' && item.id!='inventory' 
        && item.id!='cnf' && item.id!='stock' && !isAdmin && !isFranchise && !isCnf 
        && item.id!='franchise-purchase'" [item]="item"></app-nav-collapse>

        

        <app-nav-item *ngIf="item.type=='item' && !isAdmin && item.id!='commission-generate' 
        && !isFranchise && !isCnf && item.id!='franchise-purchase'" [item]="item"></app-nav-item>

        <app-nav-collapse *ngIf="item.type=='collapse' && item.id=='franchise-purchase' 
        && isFranchise" [item]="item"></app-nav-collapse>

        <app-nav-collapse *ngIf="item.type=='collapse' 
        && item.id=='cnf' && isCnf" [item]="item"></app-nav-collapse>

        <!--This is only for franchise and cnf -->
        <app-nav-collapse *ngIf="item.type=='collapse' 
        && (item.id=='stock' || item.id=='wallet') && (isFranchise || isCnf)" [item]="item">
        </app-nav-collapse>
    </ng-container>
</ng-container>
