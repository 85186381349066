import { Component, OnInit } from '@angular/core';
import { FranchiseApproval, FranchiseApproveReject } from '../../model/franchise.model';
import { FranchiseService } from '../../services/franchise.service';
import { CommonComponent } from 'src/app/theme/shared/common/common.component';
import 'sweetalert2/src/sweetalert2.scss';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-franchise-request-approval',
  templateUrl: './franchise-request-approval.component.html',
  styleUrls: ['./franchise-request-approval.component.scss']
})
export class FranchiseRequestApprovalComponent extends CommonComponent implements OnInit {
  isDataAvailable: boolean;
  gridView: boolean = false;
  franchiseApproval: FranchiseApproval[] = [];
  fileName = 'ExcelSheet.xlsx';
  loading: boolean = true;
  columns = [
    'Request ID',
    'Display ID',
    'Name',
    'Phone Number',
    'State Name',
    'Member ID',
    'Member Name',
    'Action'
  ];
  noData: boolean;
  constructor(
    private franchiseService: FranchiseService
  ) {
    super();
    this.isDataAvailable = this.franchiseApproval?.length > 0;
  }
  ngOnInit(): void {
    this.franchiseService.getFindInactiveFranchise().subscribe((val)=>{
      this.gridView = false;
      if(val.data){
        this.franchiseApproval = val.data;
        this.isDataAvailable = this.franchiseApproval?.length > 0;
        this.noData = this.franchiseApproval?.length === 0;
        this.gridView = true;
      }
      this.loading = false;
    })
  }

  isApprove(data: FranchiseApproval){
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to approve the Franchise Request',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true
    }).then((willDelete) => {
        if (willDelete.dismiss) {
          Swal.fire('', 'Franchise Request is not approved', 'error');
        } else {
          const approve = new FranchiseApproveReject();
            approve.displayId = data.displayId;
            approve.isApproved = 1;
            approve.mlmMemberId = data.mlmmemberId;
            approve.phNo = data.phNo;
            approve.type = 4;
            approve.remark = '';
              this.franchiseService.postAdminApproval(approve).subscribe((val)=>{
                if(val.message){
                  this.dangerSwal(val.message);
                }else{
                  Swal.fire('', 'Franchise Request approved successfully !', 'success');
                  this.franchiseApproval = [];
                  this.franchiseService.getFindInactiveFranchise().subscribe((val)=>{
                    this.gridView = false;
                    if(val.data){
                      this.franchiseApproval = val.data;
                      this.isDataAvailable = this.franchiseApproval?.length > 0;
                      this.noData = this.franchiseApproval?.length === 0;
                      this.gridView = true;
                    }
                    this.loading = false;
                  })
                }
              });
            }
    });


  }

  isReject(data: FranchiseApproval){
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to reject the Franchise Request',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true
    }).then((willDelete) => {
        if (willDelete.dismiss) {
          Swal.fire('', 'Franchise Request is not rejected', 'error');
        } else {
          Swal.fire({
            text: 'Remarks:',
            input: 'text',
          }).then((result) => {
            if (result.value) {
              const reject = new FranchiseApproveReject();
              reject.displayId = data.displayId;
              reject.isApproved = 0;
              reject.mlmMemberId = data.mlmmemberId;
              reject.phNo = data.phNo;
              reject.type = 4;
              reject.remark = result.value;
              this.franchiseService.postAdminApproval(reject).subscribe((val)=>{
                if(val.message){
                  this.dangerSwal(val.message);
                }else{
                  Swal.fire('', 'Franchise Request rejected successfully !', 'success');
                  this.franchiseApproval = [];
                  this.franchiseService.getFindInactiveFranchise().subscribe((val)=>{
                    this.gridView = false;
                    if(val.data){
                      this.franchiseApproval = val.data;
                      this.isDataAvailable = this.franchiseApproval?.length > 0;
                      this.noData = this.franchiseApproval?.length === 0;
                      this.gridView = true;
                    }
                    this.loading = false;
                  })
                }
              })
            }else{
              this.warningSwal("Rejection reason is required");
            }
          });
        }
      });



  }

}
