<app-card headerClass="border-0 p-2 pb-0 " cardTitle="Item Image Upload" [options]="false">
    <form [formGroup]="ItemImageGroup" class="form">
        <div class="row">
            <div class="col-sm-6">
                <div class="form-group">
                    <label class="d-block">Item <span style="color: red;">*</span></label>
                    <select class="form-control" formControlName="itemId">
                    <option> </option>
           <option *ngFor="let item of allItemsData ">{{item.itemName}}</option>
        </select>
                </div>
            </div>
            <div style="padding-top: 30px; ">
                <button class="btn btn-warning" style="color:black;" (click)="checkPreviousImages($event)">Old Images</button>
            </div>
        </div>
        <br>
        <div class="col-sm-12">
            <label class="d-block">Item Images</label>
            <img *ngFor="let url of urls" [src]="url" class="rounded mb-3" width="180">
            <input class="form-control" formControlName="itemImage" type="file" multiple (change)="getFileDetails($event)">
        </div>

        <hr>
        <div class="app-modal-footer">
            <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="cancelItemImage()">Cancel</button>
            <button type="submit" class="btn btn-primary" (click)="submitItemImage()">Submit</button>
        </div>
    </form>
    <br>
</app-card>

<app-card headerClass="border-0 p-2 pb-0 " cardTitle="Item Old Images" [options]="false" *ngIf="imagesView">
    <div class="col-sm-12" *ngIf="itemImages.length>0">
        <img *ngFor="let images of itemImages" class="rounded mb-3" width="180" [src]="images?.filelocation">
    </div>
    <div *ngIf="itemImages.length<=0">
        <H5>Images not found</H5>
    </div>
</app-card>