import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../auth/guard/auth.guard';
import { MemberTreeComponent } from '../member-tree/components/member-tree/member-tree.component';
import { AddFundComponent } from './components/downline-member/components/add-fund/add-fund.component';
import { DirectMemberComponent } from './components/direct-member/direct-member.component';
import { DownlineMemberComponent } from './components/downline-member/downline-member.component';
import { TransactionReportComponent } from './components/downline-member/components/transaction-report/transaction-report.component';
import { UpdateProfileComponent } from './components/downline-member/components/update-profile/update-profile.component';
import { ViewProfileComponent } from './components/downline-member/components/view-profile/view-profile.component';
import { ShopComponent } from './components/downline-member/components/shop/shop.component';
import { ItemDetailsComponent } from './components/downline-member/components/item-details/item-details.component';
import { CartComponent } from './components/downline-member/components/cart/cart.component';
import { CheckoutComponent } from './components/downline-member/components/checkout/checkout.component';
import { DownlineMemberTreeComponent } from './components/downline-member/components/downline-member-tree/downline-member-tree.component';

const routes: Routes = [
  {
    path: 'downline-member',
    component: DownlineMemberComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'direct-member',
    component: DirectMemberComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'tree',
    component: MemberTreeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'downline-member/tree/:displayId',
    component: DownlineMemberTreeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'downline-member/view-profile/:displayId',
    component: ViewProfileComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'downline-member/update-profile/:displayId',
    component: UpdateProfileComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'downline-member/add-fund/:displayId',
    component: AddFundComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'downline-member/transaction-report/:displayId',
    component: TransactionReportComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'downline-member/shop/:displayId',
    component: ShopComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'downline-member/shop/:displayId/item/:itemId',
    component: ItemDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'downline-member/cart/:displayId',
    component: CartComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'downline-member/checkout/:displayId',
    component: CheckoutComponent,
    canActivate: [AuthGuard],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MemberRoutingModule {}
