import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserAuthService } from 'src/app/mlm/auth/services/user-auth.service';
import { AllStates } from 'src/app/mlm/member/model/allstates.model';
import { MembersService } from 'src/app/mlm/member/services/members.service';
import { DownlineService } from '../../services/downline.service';
import { ExcelExportService } from '../../services/excel-export.service';
import { GstStateWiseFilter, GstStatewiseReportTableData } from '../../model/gst-statewise-report.model';
import { CommonComponent } from 'src/app/theme/shared/common/common.component';
@Component({
  selector: 'app-gst-statewise',
  templateUrl: './gst-statewise.component.html',
  styleUrls: ['./gst-statewise.component.scss']
})
export class GstStatewiseComponent extends CommonComponent implements OnInit {
  FilterFormGroup: FormGroup;
  allStatesData: AllStates[] = [];
  isDataAvailable: boolean;
  gridView: boolean = false;
  gstReportsStatewiseTableData: GstStatewiseReportTableData[] = [];
  fileName = 'ExcelSheet.xlsx';
  loading: boolean = true;
  columns = [
    'State Name',
    'Total Price',
    'Total GST'
  ];
  noData: boolean;

  constructor(
    private downlineService: DownlineService,
    public fb: FormBuilder,
    private excelExportService: ExcelExportService,
    private memberService: MembersService
  ) {
    super();
    this.FilterFormGroup = this.fb.group({
      state: new FormControl('', [Validators.required]),
      purchaseFrom: new FormControl('', [Validators.required]),
      purchaseTo: new FormControl('', [Validators.required]),
    });
    this.isDataAvailable = this.gstReportsStatewiseTableData?.length > 0;
  }

  ngOnInit(): void {
    this.memberService.getAllStatesData().subscribe((value) => {
      this.allStatesData = value.data;
      console.log(this.allStatesData);
    });
    this.firstShow();
  }

  firstShow() {}

  search(){
    const searchData = new GstStateWiseFilter();
    searchData.purchaseFrom = this.FilterFormGroup.controls['purchaseFrom'].value;
    searchData.purchaseTo = this.FilterFormGroup.controls['purchaseTo'].value;
    this.allStatesData.forEach((element) => {
      if (element.stateName == this.FilterFormGroup.controls['state'].value) {
        searchData.stateCode = element.stateCode;
      }
    });
    if (!searchData.stateCode) {
      searchData.stateCode = '';
    }
    if (
      this.FilterFormGroup.controls['purchaseFrom'].value <=
      this.FilterFormGroup.controls['purchaseTo'].value
    ) {
      this.gstReportsStatewiseTableData = [];
      this.gridView = false;
      this.downlineService
        .getGstStateWiseDetails(
          searchData.purchaseFrom,
          searchData.purchaseTo,
          searchData.stateCode
        )
        .subscribe((value) => {
          this.gstReportsStatewiseTableData = value.data;
          this.isDataAvailable = this.gstReportsStatewiseTableData?.length > 0;
          this.noData = this.gstReportsStatewiseTableData?.length === 0;
          this.gridView = true;
        });
      this.loading = false;
    } else {
      this.dangerSwal('Please check Purchase Date From or Purchase Date To is wrong');
    }
  }

  reset(){
    this.FilterFormGroup.reset();
    this.FilterFormGroup.controls['state'].setValue('All');
    this.gridView = false;
  }

  exportexcel(): void {
    this.excelExportService.exportExcel(
      this.gstReportsStatewiseTableData,
      'statewise-gst-report'
    );
  }
}
