import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbCarouselModule, NgbDatepickerModule, NgbDropdownModule, NgbTabsetModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
import { SharedModule } from '../../theme/shared/shared.module';
import { PayCommissionRoutingModule } from './pay-commission-routing.module';

import { UnpaidCommissionComponent } from './components/unpaid-commission/unpaid-commission.component';
import { PaidCommissionComponent } from './components/paid-commission/paid-commission.component';
import { BankListComponent } from './components/bank-list/bank-list.component';
import { WalletReportComponent } from './components/wallet-report/wallet-report.component';
import { CommissionGenerateComponent } from '../commission-generate/commission-generate.component';
import { MonthwiseCommissionComponent } from './components/monthwise-commission/monthwise-commission.component';
import { AllCommissionsComponent } from './components/all-commissions/all-commissions.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    NgbTabsetModule,
    NgbDropdownModule,
    NgbCarouselModule,
    NgbDatepickerModule,
    FormsModule,
    DataTablesModule,
    ReactiveFormsModule,
    PayCommissionRoutingModule
  ],
  declarations: [
    UnpaidCommissionComponent,
    PaidCommissionComponent,
    BankListComponent,
    WalletReportComponent,
    MonthwiseCommissionComponent,
    AllCommissionsComponent,
  ]
})
export class PayCommissionModule { }
