import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserAuthService } from 'src/app/mlm/auth/services/user-auth.service';
import { StockBalance } from 'src/app/mlm/stock/stock.model';
import { StockService } from 'src/app/mlm/stock/stock.service';
import { WalletService } from 'src/app/mlm/wallet/services/wallet.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';


@Component({
  selector: 'app-cnf-dashboard',
  templateUrl: './cnf-dashboard.component.html',
  styleUrls: ['./cnf-dashboard.component.scss']
})
export class CnfDashboardComponent implements OnInit {

  displayId: string = '';
  walletBalance: number;
  gridView: boolean = false;
  isFranchise: boolean = false;
  franchiseName: string;
  loading: boolean = true;
  isCnf: boolean = false;
  isDataAvailable: boolean;
  allItemsDetailsData: StockBalance[] = [];

  constructor(private walletService: WalletService,
    private userAuthService: UserAuthService,
    private router: Router,
    private stockService: StockService) { }

  ngOnInit(): void {
    this.displayId = this.userAuthService.getDisplayId();
    this.isCnf = this.userAuthService.getRoles() === 'CNF' ? true : false;
    this.isFranchise = this.userAuthService.getRoles() === 'CNF' ? true : false;
    this.franchiseName = this.userAuthService.getUserName();
    
    this.walletService.getWalletBalanceData().subscribe((val)=>{
      if(val){
        this.walletBalance = val.data;
        this.gridView = true;
      }
    })
    // call stockService.getSTockBalance() and assign the response to this.allItemsDetailsData
    this.stockService.getSTockBalance().subscribe((response: any) => {
      if (response.data) {
        this.allItemsDetailsData = response.data;
        this.loading = false;
      } else {
        Swal.fire('Error', 'Error in fetching stock details', response.message);
      }
      
    });
    this.isDataAvailable = true;
    if (this.isCnf || this.isFranchise) {
      this.loading = false;
    }
  }


  pendingStocks(){
    this.router.navigateByUrl("/home/stock/stock-requests");
  }

  addWallet(){
    this.router.navigateByUrl("/home/wallet/fund-add");
  }

  addStock(){
    this.router.navigateByUrl("/home/stock/add-stock");
  }

}
