<app-card [hidHeader]="true">
    <h5>Update Product</h5>
    <hr>
    <form [formGroup]="updateProductGroup" class="form">
        <div class="app-modal-body">
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="d-block ">Category Id</label>
                        <input type="text" class="form-control" formControlName="catId" placeholder="Category Id">
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group ">
                        <label class="d-block">Sub Category ID</label>
                        <input type="text" class="form-control" formControlName="subCatId" placeholder="Sub Category ID">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6 ">
                    <div class="form-group">
                        <label class="d-block">Product Id</label>
                        <input type="text" class="form-control" formControlName="prodId" placeholder="Product Id">
                    </div>
                </div>
                <div class="col-sm-6 ">
                    <div class="form-group">
                        <label class="d-block">Product Name</label>
                        <input type="text" class="form-control" formControlName="prodName" placeholder="Product Name">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label class="d-block">Product Description</label>
                        <textarea class="form-control" formControlName="prodDesc" rows="3" placeholder="Product Description"></textarea>
                    </div>
                </div>
            </div>
            <hr>
            <div class="app-modal-footer ">
                <button type=" button " class="btn btn-danger " data-dismiss="modal" (click)="cancel()">Cancel</button>
                <button type="submit " class="btn btn-primary " (click)="submitUpdateProduct() ">Submit</button>
            </div>
        </div>
    </form>
</app-card>
