import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PushRequest, Type } from '../../model/recharge.model';
import { RechargeService } from '../../services/recharge.service';
import { CommonComponent } from 'src/app/theme/shared/common/common.component';

@Component({
  selector: 'app-recharge',
  templateUrl: './recharge.component.html',
  styleUrls: ['./recharge.component.scss']
})
export class RechargeComponent extends CommonComponent implements OnInit {
  rechargeRequest: FormGroup;
  allConnectionTypeData: Type[] = [];
  allServiceProviderData: Type[] = [];
  allRechargeTypeData: Type[] = [];
  loading: boolean = false;
  constructor(
    private rechargeService: RechargeService,
    private fb: FormBuilder,
  ) {
    super();
    this.rechargeRequest = this.fb.group({
      connectionType: new FormControl('', [Validators.required]),
      serviceProvider: new FormControl('', [Validators.required]),
      rechargeType: new FormControl('', [Validators.required]),
      mobileNo: new FormControl('', [Validators.required]),
      amount: new FormControl('', [Validators.required]),
      vc: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.rechargeService.getConnectionTypeData().subscribe((val)=>{
      if(val){
        this.allConnectionTypeData = val.data;
      }
    })

    this.rechargeRequest.controls['connectionType'].valueChanges.subscribe((val) => {
      if (val) {
        this.rechargeService.getServiceProviderData(this.rechargeRequest.controls['connectionType'].value).subscribe((val)=>{
          if(val){
            this.allServiceProviderData = val.data;
            this.allRechargeTypeData = [];
          }
        })
      }
    })

    this.rechargeRequest.controls['serviceProvider'].valueChanges.subscribe((val) => {
      if (val) {
        this.rechargeService.getRechargeTypeData(this.rechargeRequest.controls['connectionType'].value,this.rechargeRequest.controls['serviceProvider'].value).subscribe((val)=>{
          if(val){
            this.allRechargeTypeData = val.data;
          }
        })
      }
    })
  }

  submitRecharge(){
    if(this.rechargeRequest.controls['connectionType'].value){
      if(this.rechargeRequest.controls['serviceProvider'].value){
        if(this.rechargeRequest.controls['rechargeType'].value){
          if(this.rechargeRequest.controls['mobileNo'].value){
            if(this.rechargeRequest.controls['amount'].value){
              const pushRequest = new PushRequest();
              pushRequest.connectionType = this.rechargeRequest.controls['connectionType'].value;
              pushRequest.serviceProvider = this.rechargeRequest.controls['serviceProvider'].value;
              pushRequest.rechargeType = this.rechargeRequest.controls['rechargeType'].value;
              pushRequest.phNo = this.rechargeRequest.controls['mobileNo'].value;
              pushRequest.amount = this.rechargeRequest.controls['amount'].value;
              console.log(pushRequest);
              this.loading = true;
              let flag = false;
              this.rechargeService.postRechargePushRequest(pushRequest).subscribe((val)=>{
                if(!val.errorCode){
                  if (val.data.description.toLowerCase()!='pending') {
                    let msg = val.data.description + '\nRequest ID:' + val.data.requestid + '\nMobile Number:' + val.data.mobileNumber  + '\nAmount:' + val.data.amount + '\nDate Time:' + val.data.date + '\nReference No.:' + val.data.oprefno;
                    this.successSwal(msg);
                    this.loading = false;
                    this.rechargeRequest.reset();
                    this.allServiceProviderData = [];
                    this.allRechargeTypeData = [];
                    flag=true;
                  }else if(val.data.description.toLowerCase()=='pending'){
                    let msg = "Transaction will be successful within 30 minutes. Please keep patience!" + '\nRequest ID:' + val.data.requestid + '\nMobile Number:' + val.data.mobileNumber  + '\nAmount:' + val.data.amount + '\nDate Time:' + val.data.date + '\nReference No.:' + val.data.oprefno;
                    this.successSwal(msg);
                    this.loading = false;
                    this.rechargeRequest.reset();
                    this.allServiceProviderData = [];
                    this.allRechargeTypeData = [];
                    flag=true;
                  }
                  setTimeout(function(){
                  //In the timer function that gets executed after 200 seconds, check // the flag value. If we got the response already it would have been // set to true. Otherwise false
                      if(flag==false){
                        this.warningSwal("Your recharge is pending");
                        this.loading = false;
                        this.rechargeRequest.reset();
                        this.allServiceProviderData = [];
                        this.allRechargeTypeData = [];
                      }
                  }, 200000);
                }else{
                  this.warningSwal(val.message);
                  this.loading = false;
                }
              },
              (error) => {
                this.dangerSwal('Something went wrong please try after sometime');
                this.loading = false;
              }
            );
            }else{
              this.warningSwal("Please enter the Amount");
            }
          }else{
            this.warningSwal("Please enter Phone No.");
          }
        }else{
          this.warningSwal("Please select a Recharge Type from the list");
        }
      }else{
        this.warningSwal("Please select a Service Provider from the list");
      }
    }else{
      this.warningSwal("Please select a Connection Type from the list");
    }
  }

  cancel(){
    this.rechargeRequest.reset();
    this.allServiceProviderData = [];
    this.allRechargeTypeData = [];
  }
}
