export class ProductMasterTableData {
  commissionPercentage: number;
  connectionType: string;
  id: number;
  isActive: boolean;
  rechargeType: string;
  serviceProvider: string;
}

export class UpdateProductMaster{
    connectionType: string;
    serviceProvider: string;
    rechargeType: string;
    commissionPercentage: number;
    isActive: boolean;
}
