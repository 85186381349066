export class GstStatewiseReportTableData {
  stateName: string;
  year: string;
  month: string;
  totalPrice: number;
  totalGST: number;
}

export class FirstPurchaseRank {
  id: string;
  displayId: number;
  name: string;
  rank: string;
  date: string;
  rankChanged: boolean;
}

export class GstStateMonthwiseReportTableData {
  year: string;
  month: string;
  productName: string;
  quantity: number;
  totalPrice: number;
  totalGST: number;
  gstPercentage: number;
  stateName: string;
}

export class GstStateWiseFilter{
  purchaseFrom: string;
  purchaseTo: string;
  stateCode: string
}
