import { Component, OnInit } from '@angular/core';
import { ItemwiseStock } from '../../purchase/model/purchase.model';
import { UserAuthService } from '../../auth/services/user-auth.service';
import { StockService } from '../stock.service';
import { CommonComponent } from 'src/app/theme/shared/common/common.component';
import { StockBalance } from '../stock.model';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-stock-report',
  templateUrl: './stock-report.component.html',
  styleUrls: ['./stock-report.component.scss']
})
export class StockReportComponent extends CommonComponent implements OnInit {
  loading: boolean = true;
  isCnf: boolean = false;
  isFranchise: boolean = false;
  isDataAvailable: boolean;
  allItemsDetailsData: StockBalance[] = [];


  constructor(private userAuthService: UserAuthService,
    private stockService: StockService) {
      super();
      this.isCnf = this.userAuthService.getRoles() === 'CNF' ? true : false;
      this.isFranchise = this.userAuthService.getRoles() === 'CNF' ? true : false;
     }

  ngOnInit(): void {
    // call stockService.getSTockBalance() and assign the response to this.allItemsDetailsData
    this.stockService.getSTockBalance().subscribe((response: any) => {
      if (response.data) {
        this.allItemsDetailsData = response.data;
        this.loading = false;
      } else {
        Swal.fire('Error', 'Error in fetching stock details', response.message);
      }
      
    });
    this.isDataAvailable = true;
    if (this.isCnf || this.isFranchise) {
      this.loading = false;
    }

  }

}
