import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoggerService } from '../../../theme/shared/log/logger.service';
import { environment } from '../../../../environments/environment';
import { AbstractHttpService } from '../../../theme/shared/base/abstract-http';
import { AddCnf } from '../model/cnf.model';

@Injectable({
  providedIn: 'root'
})
export class CnfService extends AbstractHttpService {
  private _addcnf : string = environment.baseUrl + "api/v1/addcnf";
  private _showactivecnf : string = environment.baseUrl + "api/v1/cnf/showactivecnf";



  constructor(
    protected  http: HttpClient,
    protected  logger: LoggerService
    ) {
    super('CnfService', http, logger);
  };

  postAddCnfData(formData: AddCnf) : Observable<any>{
    return this.http.post(this._addcnf,formData);
  }

  getCnfData() : Observable<any>{
    return this.http.get(this._showactivecnf);
  }
}
