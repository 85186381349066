export class FundAdd {
  amount: number;
  description: string;
  // instrument: string;
  displayId: number;
  id: number;
  isApproved: number;
  rejectionClause: string;
  requestId: string;
}

export class FundAddRequests {
  amount: number;
  description: string;
  displayId: number;
  id: number;
  // instrument: string;
  isApproved: number;
  rejectionClause: string;
  requestId: string;
}

export class FundRequestsView {
  id: number;
  displayId: number;
  requestId: string;
  amount: number;
  approvedAmount: number;
  description: string;
  // instrument: string;
  createdOn: string;
  createdBy: string;
  isApproved: boolean;
  approvedOn: string;
  approvedBy: string;
  rejectionClause: string;
  userId: string;
}

export class ApprovalBalanceRequest {
  amount: number;
  description: string;
  displayId: number;
  id: number;
  // instrument: string;
  isApproved: number;
  rejectionClause: string;
  requestId: string;
}

export class TransactionReport {
  amount: number;
  displayId: number;
  transactiondate: string;
  transactiontype: string;
  source: string;
  orderno: string;
}

export class FranchiseBalanceRequests {
  createdOn: string;
  approvedOn: string;
  description: string;
  displayId: number;
  name: string;
  phNo: string;
  requestId: string;
  requestedAmount: number;
}

export class FranchiseRequestApprovalReject {
  displayId: number;
  amount: number;
  isApproved: number;
  rejectionClause: string;
  requestId: string;
}

export class BalanceRequestStatus {
  displayId: number;
  name: string;
  requestedAmount: number;
  requestCreatedOn: string;
  approvedAmount: number;
  actionTakenOn: string;
  approvalStatus: string;
  actionTakenBy: string;
  remark: string;
}

export class WalletBalance {
  displayId: number;
  name: string;
  walletBalance: number;
}


export class FranchiseCommission {
  year: number;
  month: string;
  totalamount: number;
}