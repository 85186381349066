import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserAuthService } from 'src/app/mlm/auth/services/user-auth.service';
import { FranchisesTableData } from 'src/app/mlm/franchise/model/franchise.model';
import { FranchiseService } from 'src/app/mlm/franchise/services/franchise.service';
import { MembersService } from 'src/app/mlm/member/services/members.service';
import { CommonComponent } from 'src/app/theme/shared/common/common.component';
import { CnfService } from '../../services/cnf.service';

@Component({
  selector: 'app-cnf-members',
  templateUrl: './cnf-members.component.html',
  styleUrls: ['./cnf-members.component.scss']
})
export class CnfMembersComponent extends CommonComponent implements OnInit {

  searchForm: FormGroup;
  positionValue: string;

  gridView: boolean = false;

  isCheckedLeft: boolean = false;
  isCheckedRight: boolean = false;
  isCheckedBoth: boolean = false;

  isAdmin: boolean = false;
  franchiseTableData: FranchisesTableData[] = [];

  displayId: number;
  userName: string = '';

  loading: boolean = false;
  isDataAvailable: boolean = false;
  viewFilter: boolean = false;
  position = ['Left', 'Right'];
  searchList: FranchisesTableData[] = [];

  constructor(
    private memberService: MembersService,
    public fb: FormBuilder,
    private cnfService: CnfService,
    private userAuthService: UserAuthService,
    private router: Router
  ) {
    super();
    this.searchForm = this.fb.group({
      search: new FormControl('', [Validators.required]),
    });
   }

  ngOnInit(): void {
    this.displayId = parseInt(this.userAuthService.getDisplayId());

    if (this.userAuthService.getRoles() === 'ADMIN') {
      this.isAdmin = true;
    }

    this.userName = this.userAuthService.getUserName().toUpperCase();
    this.downlineMembers();
  }

  downlineMembers() {
    this.loading = true;
    this.viewFilter = false;
    this.cnfService.getCnfData()
      .subscribe((value) => {
        this.franchiseTableData = value.data;
        this.isDataAvailable = this.franchiseTableData?.length > 0;
        this.loading = false;
        this.viewFilter = true;
      });
  }

  submitSearch() {
    const temp = this.searchForm.controls['search'].value.toLowerCase();
    this.franchiseTableData = [];
    this.loading = true;
    this.cnfService.getCnfData().subscribe((value) => {
        value.data.forEach((element) => {
          if (!element.name.toLowerCase().search(temp)) {
            this.franchiseTableData.push(element);
          }
        });
    });
    this.loading = false;
  }

  reset() {
    this.searchForm.reset();
    this.downlineMembers();
  }


  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
  }

}
