import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserAuthService } from 'src/app/mlm/auth/services/user-auth.service';
import {
  DownlineReportFilter,
  DownlineReportsTableData,
} from 'src/app/mlm/report/model/downline-reports-table.model';
import { DownlineService } from 'src/app/mlm/report/services/downline.service';
import { CommonComponent } from 'src/app/theme/shared/common/common.component';

@Component({
  selector: 'app-direct-member',
  templateUrl: './direct-member.component.html',
  styleUrls: ['./direct-member.component.scss'],
})
export class DirectMemberComponent extends CommonComponent implements OnInit {
  downlineReportsTableData: DownlineReportsTableData[] = [];
  loading: boolean = true;
  isDataAvailable: boolean;
  gridView: boolean = false;
  temp: any;
  isAdmin: boolean = false;
  status = ['All', 'Active', 'Inactive'];
  position = ['All', 'Left', 'Right'];
  columnsAdmin = [
    'Display ID',
    'Name',
    'Phone Number',
    'Email',
    'Sponsor ID',
    'Sponsor Name',
    'Parent ID',
    'Parent Name',
    'Position',
    'Is Green',
    'Green On',
    'Joining Date'
  ];
  columnsUser = [
  'Display ID',
  'Name',
  'Sponsor ID',
  'Sponsor Name',
  'Parent ID',
  'Parent Name',
  'Position',
  'Is Green',
  'Green On',
  'Joining Date'
];
  indexAdmin = [
    'displayId',
    'name',
    'phNo',
    'emailId',
    'sponsorID',
    'sponsorName',
    'parentID',
    'parentName',
    'parentIdWisePosition',
    'isGreen',
    'greenOn',
    'createdOn'
  ];
  indexUser = [
    'displayId',
    'name',
    'sponsorID',
    'sponsorName',
    'parentID',
    'parentName',
    'parentIdWisePosition',
    'isGreen',
    'greenOn',
    'createdOn'
  ];
  searchDirectMember: FormGroup;

  constructor(
    private userAuthService: UserAuthService,
    private downlineService: DownlineService,
    private fb:FormBuilder
  ) {
    super();
    this.searchDirectMember = this.fb.group({
      displayId: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.isAdmin = this.userAuthService.getRoles() === 'ADMIN' ? true : false;

    const searchData = new DownlineReportFilter();
    searchData.displayId = this.userAuthService.getDisplayId();
    searchData.dojFrom = this.temp;
    searchData.dojTo = this.temp;
    searchData.greenFrom = this.temp;
    searchData.greenTo = this.temp;
    searchData.sponsorId = this.userAuthService.getDisplayId();
    this.downlineService.getDownlineTableData(searchData).subscribe((value) => {
      this.downlineReportsTableData = value.data.downlineReports;
      this.isDataAvailable = this.downlineReportsTableData?.length > 0;
      this.gridView = true;
    });
    this.loading = false;
    this.searchDirectMember.controls['displayId'].setValue(this.userAuthService.getDisplayId());
  }

  cancel(){
    this.searchDirectMember.controls['displayId'].setValue(this.userAuthService.getDisplayId());
  }

  submitDisplayId(){
    if(this.searchDirectMember.controls['displayId'].value){
      this.gridView = false;
      this.loading = true;
      this.downlineReportsTableData = [];
      const searchData = new DownlineReportFilter();
      searchData.displayId = this.searchDirectMember.controls['displayId'].value;
      searchData.dojFrom = this.temp;
      searchData.dojTo = this.temp;
      searchData.greenFrom = this.temp;
      searchData.greenTo = this.temp;
      searchData.sponsorId = this.searchDirectMember.controls['displayId'].value;
      this.downlineService.getDownlineTableData(searchData).subscribe((value) => {
        this.downlineReportsTableData = value.data.downlineReports;
        this.isDataAvailable = true;
        if(this.downlineReportsTableData?.length == 0){
          this.warningSwal("Direct Member is not found")
        }else{
          this.gridView = true;
        }
      });
      this.loading = false;
    }
    else{
      this.dangerSwal("Please enter a Display ID");
    }
  }
}
