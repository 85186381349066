import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbCarouselModule, NgbDatepickerModule, NgbDropdownModule, NgbTabsetModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
import { SharedModule } from '../../theme/shared/shared.module';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { ArchwizardModule } from 'angular-archwizard';
import { CnfRoutingModule } from './cnf-routing.module';
import { AddCnfComponent } from '../cnf/components/add-cnf/add-cnf.component';
import { CnfMembersComponent } from './components/cnf-members/cnf-members.component';
import { CnfStockRequestComponent } from './components/cnf-stock-request/cnf-stock-request.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    NgbTabsetModule,
    NgbDropdownModule,
    NgbCarouselModule,
    NgbDatepickerModule,
    FormsModule,
    DataTablesModule,
    ReactiveFormsModule,
    CnfRoutingModule,
    ArchwizardModule,
    NgxQRCodeModule
  ],
  declarations: [
    AddCnfComponent,
    CnfMembersComponent,
    CnfStockRequestComponent
  ]
})
export class CnfModule { }
