<ng-container *ngIf="!walletBalanceLoading; then thenTemplate; else elseTemplate"></ng-container>
<ng-template #thenTemplate>
    <ng-container *ngIf="isDataAvailable; else noDataTemplate">
        <div class="row">
            <div class="col-sm-12">
                <app-card [hidHeader]="true" cardClass="card-datatable">
                    <h5>User Wallet Balance</h5>
                    <div class="text-right mb-3">
                        <button type="button" class="btn btn-primary btn-sm" (click)="exportexcel()">Export to Excel</button>
                    </div>
                    <div class="table-responsive">
                        <table datatable id="excel-table" class="table table-striped table-hover mb-0">
                            <thead>
                                <tr>
                                    <th>Display ID</th>
                                    <th>Name</th>
                                    <th>Wallet Balance</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of walletBalanceData; let i = index">
                                    <td>{{ data?.displayId }}</td>
                                    <td>{{ data?.name }}</td>
                                    <td>{{ data?.walletBalance }}</td>
                                    <th scope="row">
                                        <div>
                                            <button class="btn-sm btn-danger" style="color:black;" (click)="deleteBalance(data)">Delete Balance</button>
                                        </div>
                                    </th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </app-card>
            </div>
        </div>
    </ng-container>
    <ng-template #noDataTemplate>
        <div class="text-center">
            <h5>No Data Available</h5>
        </div>
    </ng-template>
</ng-template>
<ng-template #elseTemplate>
    <div class="text-center">
        <h5>Loading...</h5>
        <div class="spinner">
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
        </div>
    </div>
</ng-template>