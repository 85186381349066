export class MemberRegistration {
  userName: string;
  name: string;
  phNo: string;
  stateCode: string;
  parentID: number;
  isleft: boolean;
  sponsorID: number;
  pinCode: string;
  emailId: string;
}

export class MemberRegistrationDetailsBySponsorID {
  aadharNo: number;
  acNumber: number;
  acholderName: string;
  address: string;
  bankName: string;
  childCount: number;
  cityVillage: string;
  createdOn: string;
  displayId: number;
  dob: Date;
  gender: string;
  gurdian: string;
  id: number;
  ifsc: string;
  isActive: boolean;
  isGreen: boolean;
  isleft: boolean;
  maritalStatus: boolean;
  name: string;
  nominee: string;
  nomineeDob: Date;
  panNo: string;
  parentID: number;
  password: string;
  phNo: string;
  pinCode: number;
  positionVacant: string;
  relation: string;
  sponsorID: number;
  stateCode: number;
  userName: string;
  emailId: string;
}
