export class AllCategories {
  id: number;
  catId: string;
  catName: String;
}

export class AllSubCategories {
  id: number;
  catId: string;
  subCatId: string;
  subCatName: String;
}

export class AllProducts {
  id: number;
  catId: string;
  subCatId: string;
  prodId: string;
  prodName: String;
}

export class ItemMasterData {
  catId: string;
  catName: string;
  subCatId: string;
  subCatName: string;
  prodId: string;
  prodName: string;
  prodDesc: string;
  itemId: string;
  itemName: string;
  itemDesc: string;
  hsn: number;
  mrp: number;
  distributorPrice: number;
  bv: number;
  pv: number;
  gstPercentage: number;
}

export class GstData {
  gstId: string;
  gstPercentage: number;
}

export class CategoryUpdate {
  catId: string;
  catName: string;
}

export class SubCategoryUpdate {
  catId: string;
  subCatId: string;
  subCatName: string;
}

export class ProductUpdate {
  catId: string;
  subCatId: string;
  prodId: string;
  prodName: string;
  prodDesc: string;
}

export class ItemId {
  itemId: string;
}
