import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { UserAuthService } from 'src/app/mlm/auth/services/user-auth.service';
import {
  AllCategories,
  AllSubCategories,
  AllProducts,
} from 'src/app/mlm/inventory/model/item-master.model';
import { ItemMasterService } from 'src/app/mlm/inventory/services/item-master.service';
import { ItemsDetails, SearchItems } from '../../../../../purchase/model/purchase.model';
import { PurchaseService } from '../../../../../purchase/services/purchase.service';
import { Router } from '@angular/router';
import { MembersService } from 'src/app/mlm/member/services/members.service';
import { DetailsDisplayId } from 'src/app/mlm/member/model/details-displayid.model';
import { CommonComponent } from 'src/app/theme/shared/common/common.component';

@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.scss'],
})

export class ShopComponent extends CommonComponent implements OnInit {
  searchForm: FormGroup;
  allCategoriesData: AllCategories[] = [];
  allSubCategoriesData: AllSubCategories[] = [];
  allProductsData: AllProducts[] = [];
  allItemsDetailsData: ItemsDetails[] = [];
  allItemsDetailsDataOptions: ItemsDetails[] = [];
  categoryId: string = '';
  subCategoryId: string = '';
  productId: string = '';
  itemId: string = '';
  isAdmin: boolean = false;
  displayId: string;
  displayIdInformations: DetailsDisplayId;
  constructor(
    private itemMasterService: ItemMasterService,
    public fb: FormBuilder,
    private purchaseService: PurchaseService,
    private userAuthService: UserAuthService,
    private router: Router,
    private memberService: MembersService
  ) {
    super();
    this.searchForm = this.fb.group({
      category: new FormControl('', [Validators.required]),
      subCategory: new FormControl('', [Validators.required]),
      product: new FormControl('', [Validators.required]),
      item: new FormControl('', [Validators.required]),
      isActive: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.displayId = this.router.url.split('/')?.[5];
    this.memberService.getverifyMemberInfoDisplayId(this.displayId).subscribe((val)=>{
      if(val){
        this.displayIdInformations = val.data;
        console.log(this.displayIdInformations);
      }
    })
    this.itemMasterService.getAllCategoriesData().subscribe((value) => {
      if (value) {
        console.log('Categories:', value.data);
        this.allCategoriesData = value.data;
      }
    });

    this.isAdmin = this.userAuthService.getRoles() === 'ADMIN' ? true : false;

    const search = new SearchItems();
    search.catId = '';
    search.subCatId = '';
    search.prodId = '';
    search.itemId = '';
    search.isActive = this.isAdmin ? null : true;
    this.purchaseService.getAllItemsData(search).subscribe((val) => {
      if (val) {
        this.allItemsDetailsData = val.data;
        this.allItemsDetailsData.forEach(element => {
          this.itemMasterService.getItemImageUploadData(element.itemId).subscribe((val)=>{
            if(val){
              element.image = val.data[0].filelocation;
            }
          })
        });
      }
    });

    this.searchForm.controls['category'].valueChanges.subscribe((val) => {
      if (val) {
        for (let i = 0; i < this.allCategoriesData.length; i++) {
          if (this.allCategoriesData[i].catName === val) {
            this.itemMasterService
              .getAllSubCategoriesData(this.allCategoriesData[i].catId)
              .subscribe((value) => {
                if (value) {
                  this.allSubCategoriesData = value.data;
                  this.categoryId = this.allCategoriesData[i].catId;
                }
              });
          }
        }
      }
      this.allItemsDetailsDataOptions = [];
      for (let j = 0; j < this.allItemsDetailsData.length; j++) {
        if (
          this.allItemsDetailsData[j].catName ===
          this.searchForm.controls['category'].value
        ) {
          this.allItemsDetailsDataOptions.push(this.allItemsDetailsData[j]);
        }
      }
    });

    this.searchForm.controls['subCategory'].valueChanges.subscribe((val) => {
      if (val) {
        for (let i = 0; i < this.allSubCategoriesData.length; i++) {
          if (this.allSubCategoriesData[i].subCatName === val) {
            this.itemMasterService
              .getAllProductsData(
                this.categoryId,
                this.allSubCategoriesData[i].subCatId
              )
              .subscribe((value) => {
                if (value) {
                  this.allProductsData = value.data;
                  this.subCategoryId = this.allSubCategoriesData[i].subCatId;
                }
              });
          }
        }
      }
      this.allItemsDetailsDataOptions = [];
      for (let j = 0; j < this.allItemsDetailsData.length; j++) {
        if (
          this.allItemsDetailsData[j].subCatName ===
          this.searchForm.controls['subCategory'].value
        ) {
          this.allItemsDetailsDataOptions.push(this.allItemsDetailsData[j]);
        }
      }
    });

    this.searchForm.controls['product'].valueChanges.subscribe((val) => {
      if (val) {
        for (let i = 0; i < this.allProductsData.length; i++) {
          if (this.allProductsData[i].prodName === val) {
            this.productId = this.allProductsData[i].prodId;
          }
        }
      }
      this.allItemsDetailsDataOptions = [];
      for (let j = 0; j < this.allItemsDetailsData.length; j++) {
        if (
          this.allItemsDetailsData[j].prodName ===
          this.searchForm.controls['product'].value
        ) {
          this.allItemsDetailsDataOptions.push(this.allItemsDetailsData[j]);
        }
      }
    });

    this.searchForm.controls['item'].valueChanges.subscribe((val) => {
      if (val) {
        for (let i = 0; i < this.allItemsDetailsDataOptions.length; i++) {
          if (this.allItemsDetailsDataOptions[i].itemName === val) {
            this.itemId = this.allItemsDetailsDataOptions[i].itemId;
          }
        }
      }
    });

    this.searchForm.reset();
  }

  submitSearch() {
    const search = new SearchItems();
    search.catId = this.categoryId;
    search.subCatId = this.subCategoryId;
    search.prodId = this.productId;
    search.itemId = this.itemId;
    search.isActive = !this.isAdmin
      ? true
      : this.searchForm.controls['isActive'].value === 'Both'
      ? null
      : this.searchForm.controls['isActive'].value === 'Active'
      ? true
      : this.searchForm.controls['isActive'].value === null
      ? true
      : false;
    console.log(search);
    this.purchaseService.getAllItemsData(search).subscribe((val) => {
      if (val) {
        console.log(val.data);
        this.allItemsDetailsData = val.data;
        this.allItemsDetailsData.forEach(element => {
          this.itemMasterService.getItemImageUploadData(element.itemId).subscribe((val)=>{
            if(val){
              element.image = val.data[0].filelocation;
            }
          })
        });
        this.categoryId = '';
        this.subCategoryId = '';
        this.productId = '';
        this.itemId = '';
      }
    });
  }

  encodeUrlComponent(url: string) {
    // return encodeURIComponent(url);
    return url ? url.replace('/', '~') : '';
  }
}
