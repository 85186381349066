import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoggerService } from 'src/app/theme/shared/log/logger.service';
import { environment } from 'src/environments/environment';
import { AbstractHttpService } from '../../../theme/shared/base/abstract-http';
import { DownlineReportFilter } from '../model/downline-reports-table.model';
import { PaymentStatusForm } from '../model/payment-status-report.model';

@Injectable({
  providedIn: 'root'
})
export class DownlineService extends AbstractHttpService {
  private _downlineTable : string = environment.baseUrl +"api/v1/downlinereport";
  private _biModule : string = environment.baseUrl +"api/v1/memberstat";
  private _repurchaseTable : string = environment.baseUrl +"api/v1/repurchasereports";
  private _repurchaseTableInfo : string = environment.baseUrl +"api/v1/repurchaseitemsdetails";
  private _gstreportstatewise : string = environment.baseUrl +"api/v1/gstreportstatewise";
  private _gstreportmonthwise : string = environment.baseUrl +"api/v1/gstreportmonthwise";
  private _gstreportstatemonthwise : string = environment.baseUrl +"api/v1/gstreportstateandmonthwise";
  private _viewcommissions : string = environment.baseUrl +"api/v1/viewcommissions";
  private _paymentStatusData : string = environment.baseUrl +"api/v1/getpaymentstatusdata";
  private _showtdsreport : string = environment.baseUrl +"api/v1/showtdsreport";
  private _searchtdsreport : string = environment.baseUrl +"api/v1/searchtdsreport";
  private _vedikPowerAchievers : string = environment.baseUrl +"api/v1/vedikPowerAchievers";
  private _specialOffers : string = environment.baseUrl +"api/v1/specialOffers";
  private _FirstPurchaseRank : string = environment.baseUrl +"api/v1/allAchievers";

  constructor(
    protected  http: HttpClient,
    protected  logger: LoggerService
    ) {
    super('DownlineService', http, logger);
  }

  getDownlineTableData(downlineReportFilterData: DownlineReportFilter): Observable<any>{
    return this.http.post<any>(this._downlineTable,downlineReportFilterData);
  }

  getBiModule(displayId: string): Observable<any>{
    const url = this._biModule + `?displayId=${displayId}`;
    return this.http.get<any>(url);
  }

  getRepurchaseTableData(displayId: string,showDownline: boolean,repurchaseFrom: string,repurchaseTo: string,stateCode: string): Observable<any>{
    const url = this._repurchaseTable+`?displayId=${displayId}`+`&showDownline=${showDownline}`+`&repurchaseFrom=${repurchaseFrom}`+`&repurchaseTo=${repurchaseTo}`+`&stateCode=${stateCode}`;
    return this.http.get<any>(url);
  }

  getRepurchaseTableDataInfo(displayId: string,orderNo: string): Observable<any>{
    const url = this._repurchaseTableInfo+`?displayId=${displayId}`+`&orderNo=${orderNo}`;
    return this.http.get<any>(url);
  }

  getGstMonthWiseDetails(yearno: string,months: string): Observable<any>{
    const url = this._gstreportmonthwise+`?yearno=${yearno}`+`&months=${months}`;
    return this.http.get<any>(url);
  }

  getFirstPurchaseRankReport(year: number, month: number): Observable<any>{
    const url = this._FirstPurchaseRank+`?year=${year}`+`&month=${month}`;
    return this.http.get<any>(url);
  }

  getGstStateAndMonthWiseDetails(yearno: string,months: string): Observable<any>{
    const url = this._gstreportstatemonthwise+`?yearno=${yearno}`+`&months=${months}`;
    return this.http.get<any>(url);
  }

  getGstStateWiseDetails(purchaseFrom: string,purchaseTo: string,stateCode: string): Observable<any>{
    const url = this._gstreportstatewise+`?purchaseFrom=${purchaseFrom}`+`&purchaseTo=${purchaseTo}`+`&stateCode=${stateCode}`;
    return this.http.get<any>(url);
  }

  getViewCommissionDetails(displayId: string): Observable<any>{
    const url = this._viewcommissions+`?id=${displayId}`
    return this.http.get<any>(url);
  }

  getPaymentStatusData(paymentReportFormDetails: PaymentStatusForm): Observable<any>{
    const url = this._paymentStatusData+`?displayid=${paymentReportFormDetails.displayId}` +`&fromdt=${paymentReportFormDetails.fromDate}`+`&todt=${paymentReportFormDetails.toDate}`+`&status=${paymentReportFormDetails.status}`;
    return this.http.get<any>(url);
  }

  getShowTdsReportData(displayId: string,financialyear: string,fromdt: string,todt: string): Observable<any>{
    const url = this._showtdsreport+`?displayid=${displayId}` +`&financialyear=${financialyear}` +`&fromdt=${fromdt}`+`&todt=${todt}`;
    return this.http.get<any>(url);
  }

  getSearchTdsReportDetailsData(displayId: string,financialyear: string,fromdt: string,todt: string): Observable<any>{
    const url = this._searchtdsreport+`?displayid=${displayId}` +`&financialyear=${financialyear}` +`&fromdt=${fromdt}`+`&todt=${todt}`;
    return this.http.get<any>(url);
  }

  getVedikPowerAchievers(displayId: string): Observable<any>{
    const url = this._vedikPowerAchievers+`?displayId=${displayId}`;
    return this.http.get<any>(url);
  }

  getSpecialOffers(displayId: string): Observable<any>{
    const url = this._specialOffers+`?displayId=${displayId}`;
    return this.http.get<any>(url);
  }

}
