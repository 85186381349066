import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TransactionReport } from 'src/app/mlm/wallet/model/wallet.model';
import { WalletService } from 'src/app/mlm/wallet/services/wallet.service';
import { CommonComponent } from 'src/app/theme/shared/common/common.component';

@Component({
  selector: 'app-transaction-report',
  templateUrl: './transaction-report.component.html',
  styleUrls: ['./transaction-report.component.scss']
})
export class TransactionReportComponent extends CommonComponent implements OnInit {
  displayId: any;
  gridView: boolean = false;
  transactionReportData: TransactionReport;
  columns = ['Display Id','Transaction Date','Amount','Transaction Type'];

  constructor(
    private router: Router,
    private walletService: WalletService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.displayId = this.router.url.split('/')?.[5];
    this.walletService.getTransactionReportData(this.displayId).subscribe((val)=>{
      if(val){
        this.transactionReportData = val.data;
        this.gridView = true;
      }
    })
  }

}
