import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DownlineService } from '../../services/downline.service';
import { ExcelExportService } from '../../services/excel-export.service';
import { ViewCommissionTableData } from '../../model/view-commission-table-model';
import { UserAuthService } from 'src/app/mlm/auth/services/user-auth.service';
import { CommonComponent } from 'src/app/theme/shared/common/common.component';
@Component({
  selector: 'app-view-commission',
  templateUrl: './view-commission.component.html',
  styleUrls: ['./view-commission.component.scss']
})
export class ViewCommissionComponent extends CommonComponent implements OnInit {
  isDataAvailable: boolean;
  gridView: boolean = false;
  fileName = 'ExcelSheet.xlsx';
  loading: boolean = true;
  columns = [
    'Display ID',
    'From Date',
    'To Date',
    'Commission Type',
    'Purchase Type',
    'Commission Amount',
    'Payment Status'
  ];

  viewCommissionTableData: ViewCommissionTableData[] = [];
  isAdmin: boolean = false;
  displayId: string = '';
  displayIdForm: FormGroup;
  noData: boolean;
  totalIncome: number;
  totalUnpaid: number;
  totalPaid: number;

  constructor(
    private downlineService: DownlineService,
    private userAuthService: UserAuthService,
    public fb: FormBuilder,
    private excelExportService: ExcelExportService
  ) {
    super();
    this.displayIdForm = this.fb.group({
      displayId: new FormControl('', [Validators.required]),
    });
    this.isDataAvailable = this.viewCommissionTableData?.length > 0;
  }

  ngOnInit(): void {
    this.isAdmin = this.userAuthService.getRoles() === 'ADMIN' ? true : false;
    this.displayId = this.userAuthService.getDisplayId();

    if(!this.isAdmin){
      this.viewCommissionTableData = [];
      this.gridView = false;
      this.downlineService.getViewCommissionDetails(this.displayId).subscribe((val)=>{
        if(val){
          this.viewCommissionTableData = val.data;
          this.noData = this.viewCommissionTableData?.length === 0;
          this.isDataAvailable = this.viewCommissionTableData?.length > 0;
          this.gridView = true;
          if (!this.noData) {
            this.totalIncome = this.viewCommissionTableData.reduce((sum, item) => sum + item.commissionAmount, 0);
            this.totalPaid = this.calculateCommissionSum(this.viewCommissionTableData, 'Paid');
            this.totalUnpaid = this.calculateCommissionSum(this.viewCommissionTableData, 'Unpaid');
          }
        }
        this.loading = false;
      })
    }
  }

  exportexcel(): void {
    this.excelExportService.exportExcel(
      this.viewCommissionTableData,
      'view-commission-report'
    );
  }

  submitSearch(){
    this.viewCommissionTableData = [];
    this.gridView = false;
    this.downlineService.getViewCommissionDetails(this.displayIdForm.controls['displayId'].value).subscribe((val)=>{
      if(val.data){
        this.viewCommissionTableData = val.data;
        this.isDataAvailable = this.viewCommissionTableData?.length > 0;
        this.noData = this.viewCommissionTableData?.length === 0;
        this.gridView = true;
        if (!this.noData) {
          this.totalIncome = this.viewCommissionTableData.reduce((sum, item) => sum + item.commissionAmount, 0);
          this.totalPaid = this.calculateCommissionSum(this.viewCommissionTableData, 'Paid');
          this.totalUnpaid = this.calculateCommissionSum(this.viewCommissionTableData, 'Unpaid');
        }
      }
      this.loading = false;
    })
  }

  reset(){
    this.displayIdForm.reset();
    this.gridView = false;
  }

  calculateCommissionSum(data: ViewCommissionTableData[], paymentStatus: string) {
    return data
      .filter(item => item.paymentStatus === paymentStatus)
      .reduce((sum, item) => sum + item.commissionAmount, 0);
  };

}
