<app-card [hidHeader]="true">
    <h5>Update Password</h5>
    <hr>
    <form [formGroup]="changePasswordGroup" class="form">
        <div class="app-modal-body">
            <div class="row ">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="d-block ">Display Id</label>
                        <input type="number" class="form-control" formControlName="displayId" placeholder="Display Id" readonly>
                    </div>
                </div>
                <div class="col-sm-6 ">
                    <div class="form-group ">
                        <label class="d-block">Old Password</label>
                        <input type="password" class="form-control" formControlName="oldPassword" placeholder="Old Password">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="d-block ">New Password</label>
                        <input type="password" class="form-control" formControlName="newPassword" placeholder="New Password">
                    </div>
                </div>
                <div class="col-sm-6 ">
                    <div class="form-group ">
                        <label class="d-block">Retype New Password</label>
                        <input type="password" class="form-control" formControlName="retypeNewPassword" placeholder="Retype New Password">
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <div class="app-modal-footer ">
            <button type=" button " class="btn btn-danger " data-dismiss="modal" (click)="cancel()">Cancel</button>
            <button type="submit " class="btn btn-primary " (click)="submitNewPassword() ">Submit</button>
        </div>
    </form>
</app-card>
