import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminRoleGuard } from '../auth/guard/admin-role.guard';
import { RechargeComponent } from '../recharge/components/recharge/recharge.component';
import { AuthGuard } from '../auth/guard/auth.guard';
import { ProductMasterComponent } from './components/product-master/product-master.component';
import { RechargeReportComponent } from './components/recharge-report/recharge-report.component';
import { ViewBalanceComponent } from './components/view-balance/view-balance.component';
import { TransactionEnquiryComponent } from './components/transaction-enquiry/transaction-enquiry.component';

const routes: Routes = [
  {
    path: 'recharge',
    component: RechargeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'product-master',
    component: ProductMasterComponent,
    canActivate: [AdminRoleGuard],
  },
  {
    path: 'recharge-report',
    component: RechargeReportComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'view-balance',
    component: ViewBalanceComponent,
    canActivate: [AdminRoleGuard],
  },
  {
    path: 'transaction-enquiry',
    component: TransactionEnquiryComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RechargeRoutingModule {}
