<!-- <form *ngIf="selectedNode" (ngSubmit)="onCommitForm()">
    Name: <input name="name" [(ngModel)]="data.name" /> Boss: <input name="parent" [(ngModel)]="data.parent" />
    <button type="submit">Commit</button>
</form> -->
<div class="row" *ngIf="selectedNode">
    <div class="col-sm-12 tab-card">
        <div class="row mb-n4">
            <div class="col-xl-4 col-md-6">
                <app-card [customHeader]="true" headerClass="border-0 p-2 pb-0" [options]="false" cardClass="user-card user-card-1" blockClass="pt-0 users-contact">
                    <div class="app-card-header">
                        <div class="cover-img-block">
                            <img src="assets/images/widget/Vedikmart_cover.jpg" alt="" class="img-fluid">
                        </div>
                    </div>
                    <div class="user-about-block text-center">
                        <div class="row align-items-end">
                            <div class="col text-left">
                                <h1 class="badge badge-success" *ngIf="data?.isGreen">Green</h1>
                                <h1 class="badge badge-danger" *ngIf="!data?.isGreen">Not Green</h1>
                            </div>
                            <div class="col">
                                <div class="position-relative d-inline-block">
                                    <img class="img-radius img-fluid wid-80" src="assets/images/user/avatar-sample.jpg" alt="User image">
                                </div>
                            </div>
                            <div class="col"></div>
                        </div>
                    </div>
                    <div class="text-center">
                        <h6 class="mb-1">{{data.name}}</h6>
                        <p class="mb-0">Display ID : {{data.displayId}}</p>
                        <p class="mb-0">Joining Date : {{data.doj.substring(0,10)}}</p>
                        <p class=" mb-1">Valid Date : 22-05-22</p>
                        <p class=" mb-2">Address : Kolkata</p>
                    </div>
                    <hr class="wid-80 b-wid-3">
                    <div class="row text-center buttons">
                        <div class="col ">
                            <!-- <h6 class="mb-1 ">37</h6> -->
                            <p class="mb-0 ">Profile</p>
                        </div>
                        <div class="col ">
                            <!-- <h6 class="mb-1 ">2749</h6> -->
                            <p class="mb-0 ">Update</p>
                        </div>
                        <div class="col ">
                            <!-- <h6 class="mb-1 ">678</h6> -->
                            <p class="mb-0 ">KYC Approve</p>
                        </div>
                    </div>
                    <div class="row text-center buttons">
                        <div class="col ">
                            <!-- <h6 class="mb-1 ">37</h6> -->
                            <button type="button" class="btn btn-primary btn-sm" (click)="exampleModalLong.show()"><i class="feather icon-plus "></i>Registration</button>
                        </div>
                        <div class="col ">
                            <!-- <h6 class="mb-1 ">2749</h6> -->
                            <button type="button" class="btn btn-primary btn-sm" (click)="treeRestructure(data)">Goto Tree</button>
                        </div>
                    </div>
                    <div class="text-center wbsite">
                        Website : www.vedikmart.com
                    </div>
                </app-card>
            </div>
        </div>
    </div>
</div>
<app-ui-modal #exampleModalLong [dialogClass]="'modal-xl'">
    <div class="app-modal-header">
        <h5>Add Member</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close" (click)="exampleModalLong.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
        <form [formGroup]="memberGroup" class="form">
            <div class="app-modal-body">
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label class="d-block">Sponsor ID <span style="color: red;">*</span></label>
                            <input type="number" class="form-control" [(ngModel)]="displayId" formControlName="sponsorId" placeholder="Sponsor ID" (keyup)="checkSponsorID($event)">
                            <p style="color: red;">{{showMessageSponsorID}}</p>
                            <div style="color: blue;" *ngIf="sponsorName">Name - <strong>{{ sponsorName }}</strong></div>
                        </div>
                    </div>
                    <!-- <div style="padding-top: 30px; ">
                    <button class="btn btn-warning" style="color:black;" (click)="checkSponsorID($event)">Validate</button>
                </div> -->
                </div>
                <!-- <div class="row" *ngIf="gridView">
                <div class="col-sm-12">
                    <table datatable id="report-table" class="table table-striped table-hover mb-0 table-responsive">
                        <thead>
                            <tr>
                                <th *ngFor="let col of columns">
                                    {{col}}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let reports of allMemberDataBySponsorID">
                                <td *ngFor="let col of index">
                                    <div *ngIf="col != 'positionEligible' && col != 'positionVacant' && col != 'isActive' && col != 'isGreen' && col != 'action'">
                                        {{reports[col]}}
                                    </div>
                                    <div *ngIf="col == 'positionVacant'">
                                        <div *ngIf="reports[col] == 'Left' ">
                                            <span class="badge badge-success inline-block">Left</span>
                                        </div>
                                        <div *ngIf="reports[col] == 'Right' ">
                                            <span class="badge badge-warning inline-block m-r-5">Right</span>
                                        </div>
                                        <div *ngIf="reports[col] == 'Both' ">
                                            <span class="badge badge-primary inline-block m-r-5">Both</span>
                                        </div>
                                    </div>
                                    <div *ngIf="col == 'positionEligible'">
                                        <div *ngIf="reports[col] == 'Left' ">
                                            <span class="badge badge-success inline-block">Left</span>
                                        </div>
                                        <div *ngIf="reports[col] == 'Right' ">
                                            <span class="badge badge-warning inline-block m-r-5">Right</span>
                                        </div>
                                    </div>
                                    <div *ngIf="col == 'isActive'" class="d-inline-block">
                                        <div class="custom-control custom-switch" *ngIf="reports[col] == 1 ">
                                            <input type="checkbox" class="custom-control-input" readonly checked>
                                            <label class="custom-control-label" for="customSwitch1"></label>
                                        </div>

                                        <div class="custom-control custom-switch" *ngIf="reports[col] == 0 ">
                                            <input type="checkbox" class="custom-control-input" readonly>
                                            <label class="custom-control-label" for="customSwitch1"></label>
                                        </div>
                                    </div>
                                    <div *ngIf="col == 'isGreen'">
                                        <div class="custom-control custom-switch" *ngIf="reports[col] == true ">
                                            <input type="checkbox" class="custom-control-input" readonly checked>
                                            <label class="custom-control-label"></label>
                                        </div>

                                        <div class="custom-control custom-switch" *ngIf="reports[col] == false ">
                                            <input type="checkbox" class="custom-control-input" readonly>
                                            <label class="custom-control-label"></label>
                                        </div>
                                    </div>
                                    <div *ngIf="col == 'action'">
                                        <button class="btn-sm btn-info" style="color:black;" (click)="getRowData(reports)">Select</button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div> -->
                <div *ngIf="!gridView">
                    <div class=" row">

                        <!-- <div class="col-sm-4">
                      <label>Position : <span style="color: red;">*</span></label>
                      <div class="col-sm-6">
                          <input id="left" type="radio" value="left" name="position" formControlName="position">
                          <label style="padding-left: 5px; " for="left ">Left</label>
                      </div>
                      <div class="col-sm-6">
                          <input id="right" type="radio" value="right " name="position" formControlName="position" checked>
                          <label style=" padding-left: 5px; " for="right">Right</label>
                      </div>
                  </div> -->
                        <!-- <div class="col-sm-4">
                            <div class="form-group">
                                <label class="d-block">Parent ID <span style="color: red;">*</span></label>
                                <input type="number" class="form-control" [(ngModel)]="data.displayId" formControlName="parentId" placeholder="Parent ID" (keyup)="checkParentID($event)">
                                <p style=" color: red; ">{{showMessageParentID}}</p>
                            </div>
                        </div> -->
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="d-block">Position : <span style="color: red;">*</span></label>
                                <select class="form-control" formControlName="position">
                              <option> </option>
                          <option *ngFor="let pos of position">{{pos}}</option>
                        </select>
                            </div>
                        </div>
                        <!-- <div class="col-sm-4">
                            <div class="form-group">
                                <label class="d-block">User ID <span style="color: red;">*</span></label>
                                <input type="text " class="form-control" formControlName="userId" placeholder="User ID" (keyup)="checkUserID($event)">
                                <p style="color: red; ">{{showMessageUserID}}</p>
                            </div>
                        </div> -->
                    </div>
                    <div class="row ">
                        <div class="col-sm-4 ">
                            <div class="form-group ">
                                <label class="d-block">Name <span style="color: red;">*</span></label>
                                <input type="text" class="form-control" formControlName="name" placeholder="Name">
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="d-block ">Phone Number <span style="color: red;">*</span></label>
                                <input type="number" class="form-control" formControlName="phno" placeholder="Phone Number">
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="d-block ">Email</label>
                                <input type="email" class="form-control" formControlName="email" placeholder="Email">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="d-block">Pincode</label>
                                <input type="number" class="form-control" formControlName="pincode" placeholder="Pincode">
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="d-block">State <span style="color: red;">*</span></label>
                                <select class="form-control" formControlName="state">
                              <option> </option>
                     <option *ngFor="let states of allStatesData " value="{{states.stateCode}}">{{states.stateName}}</option>
                  </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="app-modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal " (click)="exampleModalLong.hide() ">Cancel</button>
                <button type="submit" *ngIf="!gridView " class="btn btn-primary " (click)="submitMember() ">Submit</button>
            </div>
        </form>

    </div>
</app-ui-modal>
