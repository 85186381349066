import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoggerService } from 'src/app/theme/shared/log/logger.service';
import { environment } from 'src/environments/environment';
import { AbstractHttpService } from 'src/app/theme/shared/base/abstract-http';
import { StockRequest } from '../purchase/model/purchase.model';
import { ApproveRejectStockRequest, Stock } from './stock.model';

@Injectable({
  providedIn: 'root'
})
export class StockService extends AbstractHttpService {
  private _stockPurchase: string = environment.baseUrl + "api/v1/stockpurchase";
  private _pendingRequests: string = environment.baseUrl + "api/v1/pendingstockrequests";
  private _pendingFranchiseRequests: string = environment.baseUrl + "api/v1/pendingfranchisestockrequests";
  private _approveReject: string = environment.baseUrl + "api/v1/stockrequestapprovereject";
  private _stockBalance: string = environment.baseUrl + "api/v1/stockbalance";
  private _allStockRequests: string = environment.baseUrl + "api/v1/allStockrequests";
  private _updateDeliveryStatus: string = environment.baseUrl + "api/v1/updateProductDeliveryStatus";


  constructor(protected  http: HttpClient,
    protected  logger: LoggerService) {
      super('PurchaseService', http, logger);
     }
     
     postStockPurchase(purchaseCartData: StockRequest): Observable<any>{
      return this.http.post<any>(this._stockPurchase, purchaseCartData);
    }

    postApproveReject(approveReject: ApproveRejectStockRequest) {
      return this.http.post<any>(this._approveReject, approveReject)
    }

    getPendingStocks(): Observable<any>{
      return this.http.get<any>(this._pendingRequests);
    }

    getAllStockRequests(type: number): Observable<any>{
      return this.http.get<any>(this._allStockRequests + `?type=${type}`);
    }


    getPendingFranchiseStocks(): Observable<any>{
      return this.http.get<any>(this._pendingFranchiseRequests);
    }

    getSTockBalance(): Observable<any>{
      return this.http.get<any>(this._stockBalance);
    }

    updateDeliveryStatus(stock: Stock) {
      return this.http.post<any>(this._updateDeliveryStatus, stock)
    }

}
