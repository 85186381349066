export class DownlineReportsTableData {
  createdOn: string;
  displayId: number;
  emailId: string;
  greenOn: string;
  isActive: boolean;
  isGreen: boolean;
  isLeft: boolean;
  name: string;
  parentID: string;
  parentName: string;
  phNo: string;
  sponsorID: string;
  sponsorName: string;
  userName: string;
  parentIdWisePosition: string;
  stateName: string;
  constructor(
    createdOn: string,
    displayId: number,
    emailId: string,
    greenOn: string,
    isActive: boolean,
    isGreen: boolean,
    isLeft: boolean,
    name: string,
    parentID: string,
    parentName: string,
    phNo: string,
    sponsorID: string,
    sponsorName: string,
    userName: string,
    parentIdWisePosition: string,
    stateName: string
  ) {
    this.createdOn = createdOn;
    this.displayId = displayId;
    this.emailId = emailId;
    this.greenOn = greenOn;
    this.isActive = isActive;
    this.isGreen = isGreen;
    this.isLeft = isLeft;
    this.name = name;
    this.parentID = parentID;
    this.parentName = parentName;
    this.phNo = phNo;
    this.sponsorID = sponsorID;
    this.sponsorName = sponsorName;
    this.userName = userName;
    this.parentIdWisePosition = parentIdWisePosition;
  }
}

export class DownlineReportFilter {
  displayId: string;
  dojFrom: Date;
  dojTo: Date;
  greenFrom: Date;
  greenTo: Date;
  greenOn: string;
  isGreen: string;
  isRelativePositionLeft: string;
  parentId: string;
  sponsorId: string;
  createdOn: string;
  stateCode: string;
}
