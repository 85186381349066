import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { CommonComponent } from 'src/app/theme/shared/common/common.component';
import { UserAuthService } from '../../auth/services/user-auth.service';
import { ItemMasterService } from '../../inventory/services/item-master.service';
import { AllStates } from '../../member/model/allstates.model';
import { DetailsDisplayId } from '../../member/model/details-displayid.model';
import { MembersService } from '../../member/services/members.service';
import { ItemwiseStock, PurchasedItems, ItemsDetails, Purchase, StockRequest } from '../../purchase/model/purchase.model';
import { PurchaseService } from '../../purchase/services/purchase.service';
import { WalletService } from '../../wallet/services/wallet.service';
import { StockService } from '../stock.service';

@Component({
  selector: 'app-add-stock',
  templateUrl: './add-stock.component.html',
  styleUrls: ['./add-stock.component.scss']
})
export class AddStockComponent extends CommonComponent implements OnInit {
  displayIdForm: FormGroup;
  showMessageSponsorName: string = '';
  showMessageSponsorID: string = '';
  searchMemberInformation: DetailsDisplayId;
  validMemberSearch: boolean = true;
  columns = [
    'Item Image',
    'Item Name',
    'MRP',
    'Quantity',
    'Sub Total'
  ];
  columnsConfirmation = [
    'Sl. No.',
    'Item Name',
    'Quantity',
    'Total BV',
    'Sub Total'
  ];
  isAdmin: boolean = false;
  isFranchise: boolean = false;
  allItemsDetailsData: ItemwiseStock[] = [];
  isDataAvailable: boolean;
  noData: boolean;
  gridView: boolean = false;
  loading: boolean = true;
  walletBalance: number = 0;
  itemsTotalPrice: number = 0;
  outstandingBalance: number = 0;
  checkoutGroup: FormGroup;
  showModal: boolean = false;
  allStatesData: AllStates[] = [];
  purchasedItems: PurchasedItems[] = [];
  displayId: string;
  userName: string;
  subTotal: number = 0;
  bvTotal: number = 0;
  stateName : any;
  stateCode: string;
  isChecked: boolean = true;
  disableSubmit: boolean = true;
  confirmationTableShow = false;
  allItemsDetailsDataConfirmation: ItemwiseStock[] = [];
  total: number = 0;
  discount: number = 0;
  grandTotal: number = 0;
  constructor(
    public fb: FormBuilder,
    public memberService: MembersService,
    private userAuthService: UserAuthService,
    private purchaseService: PurchaseService,
    private stockService: StockService,
    private itemMasterService: ItemMasterService,
    private walletService: WalletService
  ) {
    super();
    this.displayIdForm = this.fb.group({
      displayId: new FormControl('', [Validators.required]),
    });

    this.isDataAvailable = this.allItemsDetailsData?.length > 0;

    this.checkoutGroup = this.fb.group({
      name: new FormControl('', [Validators.required]),
      cityVillage: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      pincode: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required]),
      phNo: new FormControl('', [Validators.required]),
    });
    this.searchMemberInformation = new DetailsDisplayId();
  }

  ngOnInit(): void {
    this.isAdmin = this.userAuthService.getRoles() === 'ADMIN' ? true : false;
    this.isFranchise = this.userAuthService.getRoles() === 'FRANCHISE' ? true : false;

    this.walletService.getWalletBalanceData().subscribe((val)=>{
      if(val){
        this.walletBalance = val.data;
        this.outstandingBalance = val.data;
      }
    })

    this.memberService.getAllStatesData().subscribe((value) => {
      this.allStatesData = value.data;
    });
    this.purchaseService.getItemwiseStockData().subscribe((val)=>{
      if(val){
        this.allItemsDetailsData = val.data;
        this.isDataAvailable = this.allItemsDetailsData?.length > 0;
        this.noData = this.allItemsDetailsData?.length === 0;
        this.gridView = true;
        this.allItemsDetailsData.forEach(element => {
          element.qty = 0;
          element.subTotal = 0;
        });
      }
      this.loading = false;
    })

    this.userName = this.userAuthService.getUserName();

    this.checkoutGroup.controls['state'].valueChanges.subscribe((val) => {
      if (val) {
        this.allStatesData.forEach(element => {
          if(element.stateName == this.checkoutGroup.controls['state'].value){
            this.stateCode = element.stateCode;
          }
        });
      }
    })
  }

  submitSearch(){
    if (this.displayIdForm.controls['displayId'].value) {
      this.displayId = this.displayIdForm.controls['displayId'].value;
      this.memberService
        .getverifyMemberInfoDisplayId(this.displayIdForm.controls['displayId'].value)
        .subscribe((val) => {
          if (val.data) {
            setTimeout(() => {
              this.allStatesData.forEach(element => {
                if(element.stateCode == this.searchMemberInformation.stateCode){
                  this.stateName =  element.stateName;
                }
              });
            }, 2000);
            this.stateCode = val.data.stateCode;
            this.validMemberSearch = true;
          } else {
              this.dangerSwal('Please enter desire Display ID');
              this.validMemberSearch = false;
          }
        });
    }

  }

  reset(){
    this.displayIdForm.reset();
    this.validMemberSearch = false;
  }

  qtyAdd(value:number,item:ItemsDetails){
    this.itemsTotalPrice = 0;
    this.bvTotal = 0;
    this.allItemsDetailsData.forEach(element => {
      if(element.itemId == item.itemId){
        element.qty = value;
        element.subTotal = value * element.distributorPrice;
        element.totalBV = value * element.bv;
      }
    });
    this.allItemsDetailsData.forEach(element => {
      this.itemsTotalPrice += element.subTotal;
    });

    this.outstandingBalance = this.walletBalance - this.itemsTotalPrice;
  }

  orderSummary(){
    this.allItemsDetailsDataConfirmation = [];
    this.confirmationTableShow = false;
    this.total = 0;
    this.discount = 0;
    this.grandTotal = 0;
      this.allItemsDetailsData.forEach(element => {
      if(element.qty > 0) {
        let price = element.qty * element.distributorPrice;
        
        let discount = this.isFranchise ? 0 : price * 0.025; // 2.5% discount
        let priceAfterDiscount = price - discount;
        this.total += price;
        this.discount += discount;
        this.grandTotal += priceAfterDiscount;
        
        this.allItemsDetailsDataConfirmation.push(element);
      }
    });
    if (this.allItemsDetailsDataConfirmation.length > 0) {
      this.disableSubmit = false;
    }
    console.log(this.allItemsDetailsDataConfirmation);
    this.confirmationTableShow = true;
  }

  placeOrder(){
    this.subTotal = 0;
    this.allItemsDetailsData.forEach(element => {
      if(element.qty > 0){
        let price = element.qty * element.distributorPrice;
          this.subTotal = this.subTotal + price;
          const temp = new PurchasedItems();
          temp.itemId = element.itemId;
          temp.qty = element.qty;
          this.purchasedItems.push(temp);
      }
    });
    if(this.isChecked){
      const stockReq = new StockRequest();
      stockReq.displayId = parseInt(this.displayId);
      stockReq.totalAmount = this.grandTotal;
      stockReq.stockDetails = this.purchasedItems;
      console.log(stockReq);
      this.stockService.postStockPurchase(stockReq).subscribe((val) => {
        if (val.errorCode) {
          this.disableSubmit = true;
          this.purchasedItems = [];
          this.grandTotal = 0;
          this.discount = 0;
          this.total = 0;
          this.dangerSwal(val.message);
        } else {
          this.successSwal('Order successfully placed');
          this.purchasedItems = [];
          this.grandTotal = 0;
          this.discount = 0;
          this.total = 0;
          this.disableSubmit = true;
          this.ngOnInit();
        }
      });
    }else{
      const purchase = new Purchase();
      purchase.displayId = parseInt(this.displayId);
      purchase.loginId = parseInt(this.userAuthService.getDisplayId());
      purchase.paymentMode = 'Wallet';
      purchase.enteredBy = this.userAuthService.getDisplayId();
      purchase.totalPrice = this.subTotal;
      // purchase.isFirstPurchase = true;
      purchase.phNo = this.checkoutGroup.controls['phNo'].value?this.checkoutGroup.controls['phNo'].value:'';
      purchase.cityVillage = this.checkoutGroup.controls['cityVillage'].value?this.checkoutGroup.controls['cityVillage'].value:'';
      purchase.stateCode = this.stateCode;
      purchase.shippingAddress =((this.checkoutGroup.controls['address'].value == null) ? ' ' : this.checkoutGroup.controls['address'].value + ',') +
        ((this.checkoutGroup.controls['cityVillage'].value == null) ? ' ' : 'City/Village - ' + this.checkoutGroup.controls['cityVillage'].value + ',') +
        ((this.checkoutGroup.controls['state'].value == null) ? ' ' : 'State - ' + this.checkoutGroup.controls['state'].value + ',') +
        ((this.checkoutGroup.controls['pincode'].value == null) ? ' ' : 'Pincode - ' + this.checkoutGroup.controls['pincode'].value);
      purchase.gstType = "IGST";
      purchase.pinCode = this.checkoutGroup.controls['pincode'].value?this.checkoutGroup.controls['pincode'].value:'';
      purchase.noCartInvolved = true;
      purchase.isShippingSameAsCorrespondense = false;
      purchase.purchasedItems = this.purchasedItems;
      console.log(purchase);
    if(this.checkoutGroup.controls['state'].value){
      this.purchaseService.postPurchaseCartData(purchase).subscribe((val) => {
        if (val.errorCode) {
          this.disableSubmit = true;
          this.dangerSwal(val.message);
        } else {
          this.successSwal('Order successfully placed');
          this.purchasedItems = [];
          this.disableSubmit = true;
          this.walletService.getWalletBalanceData().subscribe((val)=>{
            if(val){
              this.walletBalance = val.data;
            }
          })
          this.itemsTotalPrice = 0;
          this.outstandingBalance = 0;
          this.validMemberSearch = false;
        }
      });
    }else{
      this.disableSubmit = true;
      this.warningSwal("Please select a state from the list");
    }
    }

  }

  show() {
    this.showModal = true;
  }

  hide() {
    this.showModal = false;
  }

  onCheckboxChange(event: any) {
    if (event.target.checked) {
      this.isChecked = true;
    } else {
      this.isChecked = false;
    }
  }
}
