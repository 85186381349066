<!-- This is for pending stock -->
<ng-container *ngIf="!franchiseStockRequestLoading; then thenFranchiseTemplate; else elseTemplate"></ng-container>
<ng-template #thenFranchiseTemplate>
  <ng-container *ngIf="isFranchiseDataAvailable; else elseTemplate">
    <div class="row btn-page">
      <div class="col-sm-12 btn-page">
        <app-card [hidHeader]="true" cardClass="card-datatable">
          <h5>Pending Franchise Stock Requests</h5>
          <div class="col-sm-12 text-right">
            <button type="button" class="btn btn-primary btn-sm" (click)="exportexcel()">Export to Excel</button>
          </div>
          <div class="table-responsive">
            <table datatable id="excel-table" class="table table-striped table-hover mb-0 table-responsive">
              <thead>
                <tr>
                  <th *ngIf="isCnf">Action</th>
                  <th *ngIf="isCnf">Display ID</th>
                  <th>Request ID</th>
                  <th>Name</th>
                  <th>Date</th>
                  <th>Status</th>
                  <th>Total Amount</th>
                  <th>Request Details</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let stock of pendingFranchiseStocks">
                  <th scope="row" *ngIf="isCnf">
                    <div *ngIf="stock.status === 'PENDING'">
                      <button class="btn-sm btn-success" style="color:black;" (click)="approve(stock)">Approve</button>
                      <button class="btn-sm btn-danger" style="color:black;" (click)="reject(stock)">Reject</button>
                    </div>
                  </th>
                  <td *ngIf="isCnf">{{ stock.displayId }}</td>
                  <td>{{ stock.stockRequestId }}</td>
                  <td>{{ stock.userName }}</td>
                  <td>{{ stock.createdOn | date: 'dd/MM/yyyy' }}</td>
                  <td [ngClass]="{
                    'status-pending': stock.status === 'PENDING',
                    'status-approved': stock.status === 'APPROVED',
                    'status-other': stock.status !== 'PENDING' && stock.status !== 'APPROVED'
                  }">
                    {{ stock.status }}
                  </td>
                  <td>{{ stock.totalAmount }}</td>
                  <th scope="row" class="text-center">
                    <div class="d-flex justify-content-center align-items-center">
                      <button class="btn-sm btn-info" style="color:black;" (click)="viewDetailsModal.show()" (click)="viewDetails(stock)">View</button>
                    </div>
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </app-card>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="isAdmin && allFranchiseStockPresent; else allFranchiseElseTemplate">
    <div class="row btn-page">
      <div class="col-sm-12 btn-page">
        <app-card [hidHeader]="true" cardClass="card-datatable">
          <h5>All CNF Stock Requests</h5>
          <div class="col-sm-12 text-right">
            <button type="button" class="btn btn-primary btn-sm" (click)="exportexcel()">Export to Excel</button>
          </div>
          <div class="table-responsive">
            <table datatable id="excel-table" class="table table-striped table-hover mb-0 table-responsive">
              <thead>
                <tr>
                  <th *ngIf="isCnf">Action</th>
                  <th *ngIf="isCnf">Display ID</th>
                  <th>Request ID</th>
                  <th>Name</th>
                  <th>Date</th>
                  <th>Status</th>
                  <th>Total Amount</th>
                  <th>Request Details</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let stock of allFranchiseStockRequests">
                  <th scope="row" *ngIf="isCnf">
                    <div *ngIf="stock.status === 'PENDING'">
                      <button class="btn-sm btn-success" style="color:black;" (click)="approve(stock)">Approve</button>
                      <button class="btn-sm btn-danger" style="color:black;" (click)="reject(stock)">Reject</button>
                    </div>
                  </th>
                  <td *ngIf="isCnf">{{ stock.displayId }}</td>
                  <td>{{ stock.stockRequestId }}</td>
                  <td>{{ stock.userName }}</td>
                  <td>{{ stock.createdOn | date: 'dd/MM/yyyy' }}</td>
                  <td [ngClass]="{
                    'status-pending': stock.status === 'PENDING',
                    'status-approved': stock.status === 'APPROVED',
                    'status-other': stock.status !== 'PENDING' && stock.status !== 'APPROVED'
                  }">
                    {{ stock.status }}
                  </td>
                  <td>{{ stock.totalAmount }}</td>
                  <th scope="row" class="text-center">
                    <div class="d-flex justify-content-center align-items-center">
                      <button class="btn-sm btn-info" style="color:black;" (click)="viewDetailsModal.show()" (click)="viewDetails(stock)">View</button>
                    </div>
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </app-card>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="isAdmin && allCnfStockPresent; else allCnfElseTemplate">
    <div class="row btn-page">
      <div class="col-sm-12 btn-page">
        <app-card [hidHeader]="true" cardClass="card-datatable">
          <h5>All Franchise Stock Requests</h5>
          <div class="col-sm-12 text-right">
            <button type="button" class="btn btn-primary btn-sm" (click)="exportexcel()">Export to Excel</button>
          </div>
          <div class="table-responsive">
            <table datatable id="excel-table" class="table table-striped table-hover mb-0 table-responsive">
              <thead>
                <tr>
                  <th *ngIf="isCnf">Action</th>
                  <th *ngIf="isCnf">Display ID</th>
                  <th>Request ID</th>
                  <th>Name</th>
                  <th>Date</th>
                  <th>Status</th>
                  <th>Total Amount</th>
                  <th>Request Details</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let stock of allCnfStockRequests">
                  <th scope="row" *ngIf="isCnf">
                    <div *ngIf="stock.status === 'PENDING'">
                      <button class="btn-sm btn-success" style="color:black;" (click)="approve(stock)">Approve</button>
                      <button class="btn-sm btn-danger" style="color:black;" (click)="reject(stock)">Reject</button>
                    </div>
                  </th>
                  <td *ngIf="isCnf">{{ stock.displayId }}</td>
                  <td>{{ stock.stockRequestId }}</td>
                  <td>{{ stock.userName }}</td>
                  <td>{{ stock.createdOn | date: 'dd/MM/yyyy' }}</td>
                  <td [ngClass]="{
                    'status-pending': stock.status === 'PENDING',
                    'status-approved': stock.status === 'APPROVED',
                    'status-other': stock.status !== 'PENDING' && stock.status !== 'APPROVED'
                  }">
                    {{ stock.status }}
                  </td>
                  <td>{{ stock.totalAmount }}</td>
                  <th scope="row" class="text-center">
                    <div class="d-flex justify-content-center align-items-center">
                      <button class="btn-sm btn-info" style="color:black;" (click)="viewDetailsModal.show()" (click)="viewDetails(stock)">View</button>
                    </div>
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </app-card>
      </div>
    </div>
  </ng-container>
  <ng-template #elseTemplate>
    <div class="spinner" *ngIf="!noFranchiseData">
      
      <div class="bounce1"></div>
      <div class="bounce2"></div>
      <div class="bounce3"></div>
    </div>
    <div *ngIf="noFranchiseData">
      <h4 style="text-align: center;">No pending franchise stock requests</h4>
    </div>
  </ng-template>
  <app-ui-modal #viewDetailsModal [dialogClass]="'modal-xl'">
    <div class="app-modal-header">
      <h5>Stock Details</h5>
      <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close" (click)="viewDetailsModal.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="app-modal-body">
      <table id="report-table" datatable class="table table-striped table-hover mb-0 table-responsive">
        <thead>
          <tr>
            <th>Product Name</th>
            <th>Quantity</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let detail of selectedStock?.stockDetails; let i = index">
            <th scope="row">{{ detail.itemName }}</th>
            <th scope="row">{{ detail.qty }}</th>
          </tr>
        </tbody>
      </table>
    </div>
  </app-ui-modal>
</ng-template>
<ng-template #elseTemplate>
</ng-template>











<ng-container *ngIf="!stockRequestLoading; then thenTemplate; else elseTemplate"></ng-container>
<ng-template #thenTemplate>
  <ng-container *ngIf="isDataAvailable; else elseTemplate">
    <div class="row btn-page">
      <div class="col-sm-12 btn-page">
        <app-card [hidHeader]="true" cardClass="card-datatable">
          <h5 *ngIf="isAdmin; else allRequests">Pending CNF Stock Requests</h5>
          <ng-template #allRequests>
            <h5>All Stock Requests</h5>
          </ng-template>
          <div class="col-sm-12 text-right">
            <button type="button" class="btn btn-primary btn-sm" (click)="exportexcel()">Export to Excel</button>
          </div>
          <div class="table-responsive">
            <table datatable id="excel-table" class="table table-striped table-hover mb-0 table-responsive">
              <thead>
                <tr>
                  <th *ngIf="isAdmin">Action</th>
                  <th *ngIf="isAdmin || isCnf">Display ID</th>
                  <th *ngIf="isAdmin">Type</th>
                  <th>Request ID</th>
                  <th>Name</th>
                  <th>Date</th>
                  <th>Status</th>
                  <th>Total Amount</th>
                  <th>Request Details</th>
                  <th>Product Delivered</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let stock of pendingStocks">
                  <th scope="row" *ngIf="isAdmin">
                    <div>
                      <button class="btn-sm btn-success" style="color:black;" (click)="approve(stock)">Approve</button>
                      <button class="btn-sm btn-danger" style="color:black;" (click)="reject(stock)">Reject</button>
                    </div>
                  </th>
                  <td *ngIf="isAdmin || isCnf">{{ stock.displayId }}</td>
                  <td *ngIf="isAdmin">{{ stock.userType =='4' ? 'Franchise' : 'CNF' }}</td>
                  <td>{{ stock.stockRequestId }}</td>
                  <td>{{ stock.userName }}</td>
                  <td>{{ stock.createdOn | date: 'dd/MM/yyyy' }}</td>
                  <td [ngClass]="{
                    'status-pending': stock.status === 'PENDING',
                    'status-approved': stock.status === 'APPROVED',
                    'status-other': stock.status !== 'PENDING' && stock.status !== 'APPROVED'
                  }">
                    {{ stock.status }}
                  </td>
                  <td>{{ stock.totalAmount }}</td>
                  <th scope="row" class="text-center">
                    <div class="d-flex justify-content-center align-items-center">
                      <button class="btn-sm btn-info" style="color:black;" (click)="viewDetailsModal.show()" (click)="viewDetails(stock)">View</button>
                    </div>
                  </th>
                  <!-- New checkbox for "Product Delivered" -->
                  <td class="text-center">
                    <input
  type="checkbox"
  [checked]="isChecked(stock)"
  [disabled]="(isCnf && stock.userType === '3') || isFranchise"
  (change)="saveDeliveryStatus($event, stock)"
/>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          
        </app-card>
      </div>
    </div>
  </ng-container>
  <ng-template #elseTemplate>
    <div class="spinner" *ngIf="!noData">
      
      <div class="bounce1"></div>
      <div class="bounce2"></div>
      <div class="bounce3"></div>
    </div>
    <div *ngIf="noData">
      <h4 style="text-align: center;">No pending CNF requests</h4>
    </div>
  </ng-template>
  <app-ui-modal #viewDetailsModal [dialogClass]="'modal-xl'">
    <div class="app-modal-header">
      <h5>Stock Details</h5>
      <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close" (click)="viewDetailsModal.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="app-modal-body">
      <table id="report-table" datatable class="table table-striped table-hover mb-0 table-responsive">
        <thead>
          <tr>
            <th>Product Name</th>
            <th>Quantity</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let detail of selectedStock?.stockDetails; let i = index">
            <th scope="row">{{ detail.itemName }}</th>
            <th scope="row">{{ detail.qty }}</th>
          </tr>
        </tbody>
      </table>
    </div>
  </app-ui-modal>
</ng-template>
<ng-template #elseTemplate>
</ng-template>

