export class PushRequest{
  connectionType: string;
	serviceProvider: string;
	rechargeType: string;
	phNo: number;
	amount: number;
}

export class Type{
  name: string;
}
