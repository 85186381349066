import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserAuthService } from './user-auth.service';
import { MemberRegistration } from '../../member/model/member-registration.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _members_registration_states : string = environment.baseUrl + "ext/api/v1/fetchallstates";
  private _membersregitrationPost : string = environment.baseUrl + "ext/api/v1/member";
  private _verifyMemberInfoDisplayId : string = environment.baseUrl + "ext/api/v1/verifymemberinfo";
  private _verifyMemberInfoParentId : string = environment.baseUrl + "ext/api/v1/verifymemberinfo";
  private _verifyMemberInfoSponsorId : string = environment.baseUrl + "ext/api/v1/verifymemberinfo";
  private _verifyMemberInfoNameDuplication : string = environment.baseUrl + "ext/api/v1/verifyusernameduplication";
  private _registrationEligibleSponsorOrg : string = environment.baseUrl + "ext/api/v1/registrationeligiblesponsororg";
  private _forgotPassword : string = environment.baseUrl + "ext/api/v1/forgotpassword";
  
  constructor(
    private http: HttpClient,
    private userAuthService: UserAuthService
  ) {}

  public login(data: any): Observable<any> {
    return this.http.post(`${environment.baseUrl}auth/v1/authenticate`, data);
  }

  public loginFranchise(data: any): Observable<any> {
    return this.http.post(`${environment.baseUrl}auth/v1/autheticatefranchise`, data);
  }

  public roleMatch(allowedRoles: string): boolean {
    let isMatch = false;
    const userRoles: any = this.userAuthService.getRoles();
    if (userRoles != null && userRoles) {
      if (userRoles.roleName === allowedRoles) {
        isMatch = true;
        return isMatch;
      } else {
        return isMatch;
      }
    }
  }

  getAllStatesData(): Observable<any>{
    return this.http.get<any>(this._members_registration_states);
  }

  postRegistrationData(memberPostForm: MemberRegistration) : Observable<any>{
    return this.http.post<MemberRegistration>(this._membersregitrationPost ,memberPostForm);
  }

  getverifyMemberInfoDisplayId(displayId: number): Observable<any>{
    const url = this._verifyMemberInfoDisplayId + `?displayId=${displayId}`+`&parentId=0`+`&sponsorId=0`;
    return this.http.get<any>(url);
  }

  getverifyMemberInfoParentId(parentId: number): Observable<any>{
    const url = this._verifyMemberInfoParentId + `?displayId=0`+`&parentId=${parentId}`+`&sponsorId=0`;
    return this.http.get<any>(url);
  }

  getverifyMemberInfoSponsorId(sponsorId: number): Observable<any>{
    const url = this._verifyMemberInfoSponsorId + `?displayId=0`+`&parentId=0`+`&sponsorId=${sponsorId}`;
    return this.http.get<any>(url);
  }

  getverifyMemberInfoNameDuplication(userName: string): Observable<any>{
    const url = this._verifyMemberInfoNameDuplication + `?userName=${userName}`;
    return this.http.get<any>(url);
  }

  getRgistrationEligibleSponsorOrg(sponsorId: number): Observable<any>{
    const url = this._registrationEligibleSponsorOrg + `?sponsorId=${sponsorId}`;
    return this.http.get<any>(url);
  }

  getForgotPassword(displayId: number,phNo: string): Observable<any>{
    const url = this._forgotPassword + `?displayId=${displayId}` + `&phNo=${phNo}`;
    return this.http.get<any>(url);
  }
}
