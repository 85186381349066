import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { UserAuthService } from 'src/app/mlm/auth/services/user-auth.service';
import { AllStates } from 'src/app/mlm/member/model/allstates.model';
import { MembersService } from 'src/app/mlm/member/services/members.service';
import { DownlineService } from '../../services/downline.service';
import { ExcelExportService } from '../../services/excel-export.service';
import {
  ItemsPurchaseReports,
  RepurchaseReportFilter,
  RepurchaseReportsTableData,
} from '../../model/repurchase-reports-table.model';
import { CommonComponent } from 'src/app/theme/shared/common/common.component';

@Component({
  selector: 'app-repurchase-report',
  templateUrl: './repurchase-report.component.html',
  styleUrls: ['./repurchase-report.component.scss'],
})
export class RepurchaseReportComponent extends CommonComponent implements OnInit {
  FilterFormGroup: FormGroup;
  gridView: boolean = false;
  isAdmin: boolean = false;
  displayId: string;
  leftBv: number;
  rightBv: number;
  totalBv: number;
  totalAmount: number;
  fileName = 'ExcelSheet.xlsx';
  loading: boolean = true;
  isDataAvailable: boolean;
  toRepurchaseDate: Date;
  fromRepurchaseDate: Date;
  allStatesData: AllStates[] = [];
  repurchaseReportsTableData: RepurchaseReportsTableData[] = [];
  columns = [
    'Display ID',
    'Name',
    'Position',
    'Order Date',
    'Invoice No',
    'Total BV',
    'Total Price',
    'State',
    'Action'
  ];
  columnsDetails = [
    'DisplayId',
    'Item Name',
    'Quantity',
    'Item Price',
    'Total Price',
    'BV',
    'Total BV',
    'Order No',
  ];

  showModal: boolean = false;
  repurchaseReportsDetailsData: ItemsPurchaseReports[] = [];
  repurchaseDetailsGridView: boolean = false;
  noData: boolean;

  constructor(
    private downlineService: DownlineService,
    private userAuthService: UserAuthService,
    public fb: FormBuilder,
    private excelExportService: ExcelExportService,
    private memberService: MembersService
  ) {
    super();
    this.FilterFormGroup = this.fb.group({
      displayId: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      repurchaseFrom: new FormControl('', [Validators.required]),
      repurchaseTo: new FormControl('', [Validators.required]),
      showDownline: new FormControl('', [Validators.required]),
    });
    this.isDataAvailable = this.repurchaseReportsTableData?.length > 0;
  }

  ngOnInit(): void {
    this.isAdmin = this.userAuthService.getRoles() === 'ADMIN' ? true : false;
    this.displayId = this.userAuthService.getDisplayId();
    this.toRepurchaseDate = new Date();
    this.fromRepurchaseDate = new Date(new Date().getTime() - 585000000);
    this.memberService.getAllStatesData().subscribe((value) => {
      this.allStatesData = value.data;
      console.log(this.allStatesData);
    });
    this.firstShow();
  }

  firstShow() {}

  search() {
    console.log(this.FilterFormGroup.value);
    const searchData = new RepurchaseReportFilter();
    searchData.displayId = this.FilterFormGroup.controls['displayId'].value;
    searchData.showDownline = this.FilterFormGroup.controls['showDownline']
      .value
      ? true
      : false;
    searchData.repurchaseFrom =
      this.FilterFormGroup.controls['repurchaseFrom'].value;
    searchData.repurchaseTo =
      this.FilterFormGroup.controls['repurchaseTo'].value;
    this.allStatesData.forEach((element) => {
      if (element.stateName == this.FilterFormGroup.controls['state'].value) {
        searchData.stateCode = element.stateCode;
      }
    });
    if (!searchData.stateCode) {
      searchData.stateCode = '';
    }
    if (
      this.FilterFormGroup.controls['repurchaseFrom'].value <=
      this.FilterFormGroup.controls['repurchaseTo'].value
    ) {
      this.repurchaseReportsTableData = [];
      this.gridView = false;
      this.downlineService
        .getRepurchaseTableData(
          searchData.displayId,
          searchData.showDownline,
          searchData.repurchaseFrom,
          searchData.repurchaseTo,
          searchData.stateCode
        )
        .subscribe((value) => {
          this.repurchaseReportsTableData = value.data.repurchaseReports;
          this.isDataAvailable = this.repurchaseReportsTableData?.length > 0;
          this.noData = this.repurchaseReportsTableData?.length === 0;
          this.leftBv = value.data.leftBV;
          this.rightBv = value.data.rightBV;
          this.totalBv = value.data.totalBV;
          this.totalAmount = value.data.totalAmount;
          this.gridView = true;
        });
      this.loading = false;
    } else {
      this.dangerSwal('Please check Repurchase Date From or Repurchase Date To is wrong');
    }
  }

  reset() {
    this.FilterFormGroup.reset();
    this.FilterFormGroup.controls['displayId'].setValue(this.displayId);
    this.FilterFormGroup.controls['state'].setValue('All');
    this.gridView = false;
  }

  exportexcel(): void {
    this.excelExportService.exportExcel(
      this.repurchaseReportsTableData,
      'repurchase-report'
    );
  }

  repurchaseReportInfo(exp: RepurchaseReportsTableData) {
    console.log(RepurchaseReportsTableData);
    this.repurchaseDetailsGridView = false;
    this.repurchaseReportsDetailsData = [];
    this.downlineService.getRepurchaseTableDataInfo(exp.displayId,exp.orderNo).subscribe((val)=>{
      if(val){
        this.repurchaseDetailsGridView = true;
        this.repurchaseReportsDetailsData = val.data;
      }
    })
  }

  show() {
    this.showModal = true;
  }

  hide() {
    this.showModal = false;
  }
}
