import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbCarouselModule, NgbDatepickerModule, NgbDropdownModule, NgbTabsetModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
import { SharedModule } from '../../theme/shared/shared.module';
import { WalletRoutingModule } from './wallet-routing.module';
import { FundAddComponent } from './components/fund-add/fund-add.component';
import { FundTransferReportComponent } from './components/fund-transfer-report/fund-transfer-report.component';
import { OnlinePaymentReportComponent } from './components/online-payment-report/online-payment-report.component';
import { TransactionReportComponent } from './components/transaction-report/transaction-report.component';
import { FundRequestsComponent } from './components/fund-requests/fund-requests.component';
import { FranchiseBalanceRequestsComponent } from './components/franchise-balance-requests/franchise-balance-requests.component';
import { BalanceRequestStatusComponent } from './components/balance-request-status/balance-request-status.component';
import { CnfBalanceRequestsComponent } from './components/cnf-balance-requests/cnf-balance-requests.component';
import { MemberWalletBalanceComponent } from './components/member-wallet-balance/member-wallet-balance.component';
import { FranchiseCommissionComponent } from './components/franchise-commission/franchise-commission.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    NgbTabsetModule,
    NgbDropdownModule,
    NgbCarouselModule,
    NgbDatepickerModule,
    FormsModule,
    DataTablesModule,
    ReactiveFormsModule,
    WalletRoutingModule,
    NgMultiSelectDropDownModule.forRoot()
  ],
  declarations: [
    FundAddComponent,
    FundTransferReportComponent,
    OnlinePaymentReportComponent,
    TransactionReportComponent,
    FundRequestsComponent,
    FranchiseBalanceRequestsComponent,
    BalanceRequestStatusComponent,
    CnfBalanceRequestsComponent,
    MemberWalletBalanceComponent,
    FranchiseCommissionComponent
  ]
})
export class WalletModule { }
