<div class="row btn-page">
    <div class="col-sm-12">
        <app-card cardTitle="TDS Report" [options]="false" *ngIf="gridView">
            <div class="row">
                <!-- <div class="col-md-2 col-xs-6 border-right">
                    <h3>{{totalAmount}}</h3>
                    <span>Total Amount</span>
                </div>
                <div class="col-md-2 col-xs-6 border-right">
                    <h3>{{adminCharges}}</h3>
                    <span class="text-primary">Admin Charges</span>
                </div> -->
                <div class="col-md-4 col-xs-6 border-right">
                    <h3>{{tdsAmount?.toFixed(2)}}</h3>
                    <span class="text-success">TDS Amount</span>
                </div>
                <div class="col-md-4 col-xs-6 border-right">
                    <h3>{{netAmount?.toFixed(2)}}</h3>
                    <span class="text-danger">Net Amount</span>
                </div>
            </div>
        </app-card>
    </div>
</div>
<div class="row">
    <div class="col-sm-12">
        <app-card cardTitle="Search TDS Report" [options]="false">
            <form [formGroup]="FilterFormGroup" class="form">
                <div class="app-modal-body">
                    <div class="row">
                        <div class="col-sm-8">
                            <div class="form-group">
                                <label class="d-block">Financial Year</label>
                                <select class="form-control" formControlName="financialYear">
                                  <option *ngFor="let year of years">{{year}}</option>
                               </select>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-8">
                            <div class="form-group">
                                <label class="d-block">Display Id</label>
                                <input type="string" class="form-control" formControlName="displayId" placeholder="Display Id" [(ngModel)]="this.displayId" [readonly]="!isAdmin">
                            </div>
                        </div>
                    </div>
                </div>
                <hr>
                <div class="app-modal-footer ">
                    <button class="btn btn-danger" data-dismiss="modal" (click)="clear()">Cancel</button>
                    <button type="submit" class="btn btn-primary" (click)="search()">Search</button>
                </div>
            </form>
        </app-card>
    </div>
</div>

<ng-container *ngIf="!loading; then thenTemplate; else elseTemplate"></ng-container>
<ng-template #thenTemplate>
    <ng-container *ngIf="isDataAvailable; else elseTemplate">
        <div class="row btn-page" *ngIf="gridView">
            <div class="col-sm-12 btn-page">
                <app-card [hidHeader]="true" cardClass="card-datatable" cardTitle="View Commission">
                    <div class="col-sm-12 text-right">
                        <button type="button" class="btn btn-primary btn-sm" (click)="exportexcel()">Export to Excel</button>
                    </div>
                    <div class="table-responsive">
                        <table datatable id="excel-table" class="table table-striped table-hover mb-0 table-responsive">
                            <thead>
                                <tr>
                                    <th *ngFor="let col of columns">
                                        {{col}}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of tdsReportTableData; let i=index; ">
                                    <th scope="row "><button (click)="tdsCertificateGenerate(data)" class="btn btn-outline-primary">TDS Report</button></th>
                                    <th scope="row ">{{ data?.displayId }}</th>
                                    <th scope="row ">{{ data?.deducteeName }}</th>
                                    <th scope="row ">{{ data?.deducteePAN }}</th>
                                    <th scope="row ">{{ data?.financialYear }}</th>
                                    <th scope="row ">{{ data?.stateName }}</th>
                                    <th scope="row ">{{ data?.netAmount }}</th>
                                    <th scope="row ">{{ data?.tdsAmount }}</th>
                            </tbody>
                        </table>
                    </div>
                </app-card>
            </div>
        </div>
    </ng-container>
    <ng-template #elseTemplate>
        <div class="spinner" *ngIf="!noData">
            <h5>Loading...</h5>
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
        </div>
        <div *ngIf="noData">
            <h1 style="text-align: center;">No Data Available</h1>
        </div>
    </ng-template>
</ng-template>
<ng-template #elseTemplate>
</ng-template>

<div class="container mt-5 mb-3" id="pdfTable" #pdfTable hidden>
    <!DOCTYPE html>
    <html lang="en">

    <head>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
        <title>TDS Report</title>
    </head>

    <body>
        <h5 style="text-align: center; font-weight: bold;">INCOME-TAX RULES, 1962</h5>
        <table style="width: 100%;">
            <tbody style="width: 100%;">
                <tr>
                    <td colspan="12" class="no-border text-start heading" style="text-align: center; font-size: 14px;">Form No. 16D</td>
                </tr>
                <tr>
                    <td colspan="12" class="no-border text-start heading" style="text-align: center; font-size: 14px;">[See rule 31(3C)]</td>
                </tr>
                <tr>
                    <td colspan="12" class="no-border text-start heading" style="text-align: center; font-size: 14px;"> Certificate under section 203 of the Income-tax Act, 1961 for tax deducted at source </td>
                </tr>
                <tr>
                    <td colspan="6" class="no-border text-start heading" style="text-align: center; font-size: 14px;">Certificate No.</td>
                    <td colspan="6" class="no-border text-start heading" style="text-align: center; font-size: 14px;">Last updated on</td>
                </tr>
                <tr>
                    <td colspan="6" class="no-border text-start heading" style="text-align: center; font-size: 14px;">Name and address of the Deductor</td>
                    <td colspan="6" class="no-border text-start heading" style="text-align: center; font-size: 14px;">Name and address of the Deductee</td>
                </tr>
                <tr>
                    <td colspan="6" class="no-border text-start heading" style="text-align: center; font-size: 14px;">{{tdsReportShowData?.deductorName + ' and'}} {{tdsReportShowData?.deductorAddress}}</td>
                    <td colspan="6" class="no-border text-start heading" style="text-align: center; font-size: 14px;">{{tdsReportShowData?.deducteeName + ' and'}} {{tdsReportShowData?.deducteeAddress}}</td>
                </tr>
                <tr>
                    <td colspan="4" class="no-border text-start heading" style="text-align: center; font-size: 14px;">PAN/Aadhaar No. of the Deductor</td>
                    <td colspan="4" class="no-border text-start heading" style="text-align: center; font-size: 14px;">PAN/Aadhaar No. of the Deductee</td>
                    <td colspan="4" class="no-border text-start heading" style="text-align: center; font-size: 14px;">Financial Year of Deduction</td>
                </tr>
                <tr>
                    <td colspan="4" class="no-border text-start heading" style="text-align: center; font-size: 14px;">{{tdsReportShowData?.deductorPAN}}</td>
                    <td colspan="4" class="no-border text-start heading" style="text-align: center; font-size: 14px;">{{tdsReportShowData?.deducteePAN}}</td>
                    <td colspan="4" class="no-border text-start heading" style="text-align: center; font-size: 14px;">{{tdsReportShowData?.financialYear}}</td>
                </tr>
                <tr>
                    <td colspan="12" class="no-border text-start heading" style="text-align: center; font-size: 14px;">Summary of the Transaction(s)</td>
                </tr>
                <tr>
                    <td colspan="1" class="no-border text-start heading" style="text-align: center; font-size: 14px;">S.No.</td>
                    <td colspan="2" class="no-border text-start heading" style="text-align: center; font-size: 14px;">Unique Acknowledgement Number</td>
                    <td colspan="2" class="no-border text-start heading" style="text-align: center; font-size: 14px;">Nature of Payment</td>
                    <td colspan="2" class="no-border text-start heading" style="text-align: center; font-size: 14px;">Amount Paid/Credited</td>
                    <td colspan="2" class="no-border text-start heading" style="text-align: center; font-size: 14px;">Date of Payment/Credit(dd/mm/yyyy)</td>
                    <td colspan="3" class="no-border text-start heading" style="text-align: center; font-size: 14px;">Amount of tax deducted and deposited in respect of the deductee</td>
                </tr>
                <tr>
                    <td colspan="1" class="no-border text-start heading" style="text-align: center; font-size: 14px;"></td>
                    <td colspan="2" class="no-border text-start heading" style="text-align: center; font-size: 14px;"></td>
                    <td colspan="2" class="no-border text-start heading" style="text-align: center; font-size: 14px;">{{tdsReportShowData?.natureOfPayment}}</td>
                    <td colspan="2" class="no-border text-start heading" style="text-align: center; font-size: 14px;"></td>
                    <td colspan="2" class="no-border text-start heading" style="text-align: center; font-size: 14px;"></td>
                    <td colspan="3" class="no-border text-start heading" style="text-align: center; font-size: 14px;"></td>
                </tr>
                <tr>
                    <td colspan="12" class="no-border text-start heading" style=" font-size: 14px;">Total (Rs.)</td>
                </tr>
                <tr>
                    <td colspan="12" class="no-border text-start heading" style="text-align: center; font-size: 14px;"> DETAILS OF TAX DEPOSITED TO THE CREDIT OF THE CENTERAL GOVERNMENT FOR WHICH CREDIT IS TO BE GIVEN TO THE DEDUCTEE </td>
                </tr>
                <tr>
                    <td colspan="6" class="no-border text-start heading" style="text-align: center; font-size: 14px;"></td>
                    <td colspan="6" class="no-border text-start heading" style="text-align: center; font-size: 14px;">Challan Identification Number</td>
                </tr>
                <tr>
                    <td colspan="2" class="no-border text-start heading" style="text-align: center; font-size: 14px;">S.No.</td>
                    <td colspan="4" class="no-border text-start heading" style="text-align: center; font-size: 14px;">Amount of tax deposited in respect of deductee (Rs.)</td>
                    <td colspan="1" class="no-border text-start heading" style="text-align: center; font-size: 14px;">BSR Code of the Bank Branch</td>
                    <td colspan="3" class="no-border text-start heading" style="text-align: center; font-size: 14px;">Date on which tax deposited (dd/mm/yyyy)</td>
                    <td colspan="2" class="no-border text-start heading" style="text-align: center; font-size: 14px;">Challan Serial Number</td>
                </tr>
                <tr>
                    <td colspan="2" class="no-border text-start heading" style="text-align: center; font-size: 14px;">1</td>
                    <td colspan="4" class="no-border text-start heading" style="text-align: center; font-size: 14px;">{{tdsReportShowData?.tdsAmount?.toFixed(2)}}</td>
                    <td colspan="1" class="no-border text-start heading" style="text-align: center; font-size: 14px;"></td>
                    <td colspan="3" class="no-border text-start heading" style="text-align: center; font-size: 14px;"></td>
                    <td colspan="2" class="no-border text-start heading" style="text-align: center; font-size: 14px;"></td>
                </tr>
                <tr>
                    <td colspan="2" class="no-border text-start heading" style="text-align: center; font-size: 14px;">Total(Rs.)</td>
                    <td colspan="4" class="no-border text-start heading" style="text-align: center; font-size: 14px;">{{tdsReportShowData?.tdsAmount?.toFixed(2)}}</td>
                    <td colspan="1" class="no-border text-start heading" style="text-align: center; font-size: 14px;"></td>
                    <td colspan="3" class="no-border text-start heading" style="text-align: center; font-size: 14px;"></td>
                    <td colspan="2" class="no-border text-start heading" style="text-align: center; font-size: 14px;"></td>
                </tr>
                <tr>
                    <td colspan="12" class="no-border text-start heading" style="text-align: center; font-size: 14px;">Verification</td>
                </tr>
                <tr>
                    <td colspan="12" class="no-border text-start heading" style="font-size: 12px;">I....................,son/daughter of ......................in the capacity of ..........................(Designation) do hearby ertify that a sum of (Rs.)..........[Rs.................(in words)] has been deducted and deposited to
                        the credit of Central Government, I further certify that the information given above is true, comeplete and correct and is based on the books of account,documents,challan-cum-statement of deduction of tax, TDS deposited and other
                        available records</td>
                </tr>
                <tr>
                    <td colspan="2" class="no-border text-start heading" style="font-size: 14px;">Place</td>
                    <td colspan="4" class="no-border text-start heading" style="font-size: 14px;"></td>
                    <td colspan="6" class="no-border text-start heading" style="font-size: 14px;">(Signature of the person responsible for deduction of tax)</td>
                </tr>
                <tr>
                    <td colspan="2" class="no-border text-start heading" style="font-size: 14px;">Date</td>
                    <td colspan="4" class="no-border text-start heading" style="font-size: 14px;"></td>
                    <td colspan="6" class="no-border text-start heading" style="font-size: 14px;">Full Name:</td>
                </tr>
            </tbody>
        </table>
    </body>

    </html>
</div>
