import { Component, OnInit } from '@angular/core';
import { WalletBalance } from '../../model/wallet.model';
import { WalletService } from '../../services/wallet.service';
import { CommonComponent } from 'src/app/theme/shared/common/common.component';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-member-wallet-balance',
  templateUrl: './member-wallet-balance.component.html',
  styleUrls: ['./member-wallet-balance.component.scss']
})
export class MemberWalletBalanceComponent extends CommonComponent implements OnInit {

  walletBalanceLoading: boolean = true;
  isDataAvailable: boolean = false;
  walletBalanceData: WalletBalance[] = [];

  constructor(private walletService: WalletService) {
      super();
   }

  ngOnInit(): void {
    this.walletService.getUserWalletBalanceData().subscribe((val) => {
      if (val) {
        this.walletBalanceData = val.data;
        this.isDataAvailable = this.walletBalanceData?.length > 0;
        this.walletBalanceLoading = false;
      }
    });
  }

  deleteBalance(data: WalletBalance) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'you want to delete User wallet Balance?',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true
    }).then((willDelete) => {
        if (willDelete.dismiss) {
          Swal.fire('', 'User Wallet balance deletion cancelled', 'error');
        } else {
          const displayId =data.displayId;
          this.walletService
            .deleteUserWalletBalance(displayId)
            .subscribe((val) => {
              if (val.message) {
                this.dangerSwal(val.message);
              } else {
                this.walletBalanceData = val.data;
                this.isDataAvailable = this.walletBalanceData?.length > 0;
                this.successSwal('User wallet balance deleted successfully');
              }
            });
          
        }
      });

  }

}
