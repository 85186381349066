import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ViewCommissionService } from '../../services/view-commission.service';
import { CommissionGenrateDates, UnpaidCommssionGenerateData, PayUnpaidCommission, UnpaidCommissionDetails, UnpaidCommissionUser, PayUnpaidCommissionDataPost } from '../../model/view-commission.model';
import { UserAuthService } from 'src/app/mlm/auth/services/user-auth.service';
import { ExcelExportService } from 'src/app/mlm/report/services/excel-export.service';
import { CommonComponent } from 'src/app/theme/shared/common/common.component';

@Component({
  selector: 'app-unpaid-commission',
  templateUrl: './unpaid-commission.component.html',
  styleUrls: ['./unpaid-commission.component.scss'],
})

export class UnpaidCommissionComponent extends CommonComponent implements OnInit {
  unpaidCommissionGroup: FormGroup;
  paymentMethodGroup: FormGroup;
  allDatesData: CommissionGenrateDates[] = [];
  unpaidCommissionUserData: UnpaidCommissionUser[] = [];
  commssionGeneratedData: UnpaidCommssionGenerateData[] = [];
  gridView: boolean = false;
  loading: boolean = true;
  isDataAvailable: boolean = false;
  showModal: boolean = false;
  noData: boolean;
  columns = [
    'Checked',
    'From Date',
    'To Date',
    'Display ID',
    'Name',
    'Pan No',
    'Total Income',
    'Admin Charge Amount',
    'TDS Amount',
    'Net Amount',
    'Action'
  ];
  columnsAll = [
    'Checked',
    'Display ID',
    'Name',
    'Pan No',
    'Total Income',
    'Admin Charge Amount',
    'TDS Amount',
    'Net Amount',
    'Action'
  ];

  columnsDetailsBiMonthly = [
    'Commission Name',
    'Number of Pair',
    'Amount'
  ];

  columnsDetailsMonthly = [
    'Commission Name',
    'Number of Pair/BV',
    'Unit Income',
    'Total Income'
  ];

  isMonthly: string = '';

  columnsUnpaidCommissionUser = ['Display ID','From Date','To Date','Commission Type','Income Per Pair','Pair Match','Payment Status','Left Member Count','Right Member Count','Total Income'];

  error: boolean = false;
  unpaidCommissionDetailsData: UnpaidCommissionDetails[] = [];
  unpaidDetailsGridView: boolean = false;

  payUnpaidCommissionList: PayUnpaidCommission[] = [];
  isAdmin: boolean = false;

  totalIncome : number = 0;
  totalAdminChargeAmount : number = 0;
  totalTDSAmount : number = 0;
  totalNetAmount : number = 0;
  all: boolean = false;

  constructor(
    private viewCommissionService: ViewCommissionService,
    private fb: FormBuilder,
    private userAuthService: UserAuthService,
    private excelExportService: ExcelExportService
  ) {
    super();
    this.unpaidCommissionGroup = this.fb.group({
      formDateToDate: new FormControl('', [Validators.required]),
      isMonthly: new FormControl('', [Validators.required]),
    });
    this.paymentMethodGroup = this.fb.group({
      paymentMethod: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.unpaidCommissionGroup.controls['isMonthly'].valueChanges.subscribe((val) => {
      if(val){
        this.gridView = false;
        this.allDatesData = [];
        this.all = this.unpaidCommissionGroup.controls['isMonthly'].value == 'All' ? true : false;
        this.isMonthly = this.unpaidCommissionGroup.controls['isMonthly'].value == 'Monthly' || this.unpaidCommissionGroup.controls['isMonthly'].value == 'All' ? 'true' : 'false';
        this.viewCommissionService.getCommissionGenerateDateData(this.isMonthly).subscribe((val)=>{
          if(val.data){
            this.allDatesData = val.data;
          }
        })
      }
    });

    this.isAdmin = this.userAuthService.getRoles()==='ADMIN'?true:false;

    if(!this.isAdmin){
    this.gridView = false;
    this.loading = true;
      this.viewCommissionService.getUnpaidCommissionUser().subscribe((val)=>{
        if(val){
          this.unpaidCommissionUserData=val.data;
          this.isDataAvailable = this.unpaidCommissionUserData?.length > 0;
          this.loading = false;
          this.gridView = true;
        }
      });
    }
  }

  submitCommissionGenerated(){
    if(this.unpaidCommissionGroup.controls['isMonthly'].value){
      if(this.unpaidCommissionGroup.controls['formDateToDate'].value){
        this.totalIncome = 0;
        this.totalAdminChargeAmount = 0;
        this.totalTDSAmount = 0;
        this.totalNetAmount = 0;
        this.gridView = false;
        const formDate = this.unpaidCommissionGroup.controls['formDateToDate'].value.substring(0,10);
        const toDate = this.unpaidCommissionGroup.controls['formDateToDate'].value.substring(13,23);
        if(this.unpaidCommissionGroup.controls['isMonthly'].value == 'Monthly'){
          this.isMonthly = 'true';
        }else if(this.unpaidCommissionGroup.controls['isMonthly'].value == 'Bimonthly'){
          this.isMonthly = 'false';
        }else{
          this.isMonthly = '';
        }
        this.viewCommissionService.getUnpaidCommissionData(formDate,toDate,this.isMonthly).subscribe((val)=>{
          if(val){
            this.commssionGeneratedData = val.data;
            this.isDataAvailable = this.commssionGeneratedData?.length > 0;
            this.noData = this.commssionGeneratedData?.length === 0;
            this.commssionGeneratedData.forEach(element=>{
              element.checked = false;
              this.totalIncome = this.totalIncome + element.totalIncome;
              this.totalAdminChargeAmount = this.totalAdminChargeAmount + element.adminchargeamout;
              this.totalTDSAmount = this.totalTDSAmount + element.tdsamount;
              this.totalNetAmount = this.totalNetAmount + element.netamount;
            })
            this.gridView = true;
            this.loading = false;
          }
        })
      }else{
        this.warningSwal("Please choose a From Date and To Date from the list");
      }
    }else{
      this.warningSwal("Please choose a Frequency from the list");
    }

  }

  cancel(){
    this.unpaidCommissionGroup.reset();
    this.gridView = false;
  }

  checkAllCheckBox(ev: any) {
    this.commssionGeneratedData.forEach(x => x.checked = ev.target.checked)
  }

  isAllCheckBoxChecked() {
    return this.commssionGeneratedData.every(p => p.checked);
  }

  payUnpaid(){
    if(this.paymentMethodGroup.controls['paymentMethod'].value === 'Wallet'){
      this.commssionGeneratedData.forEach(element=>{
        if(element.checked === true){
          const payUnpaidCommssion = new PayUnpaidCommission();
          payUnpaidCommssion.displayId = element.displayId;
          payUnpaidCommssion.paymentType = 'wallet';
          payUnpaidCommssion.fromDate = element.fromDate;
          payUnpaidCommssion.toDate = element.toDate;
          this.payUnpaidCommissionList.push(payUnpaidCommssion);
        }
      });
      let payUnpaidCommissionDataPost = new PayUnpaidCommissionDataPost();
      payUnpaidCommissionDataPost.payUnpaidCommission = this.payUnpaidCommissionList;
      const ismonthly = this.unpaidCommissionGroup.controls['isMonthly'].value == 'Monthly' ? 'true' : 'false';
      this.viewCommissionService.postUnpaidCommissionData(payUnpaidCommissionDataPost,ismonthly).subscribe((val)=>{
        if(val.errorCode){
          this.error  = true;
          this.dangerSwal(val.message);
          this.payUnpaidCommissionList = [];
        }else{
          this.successSwal("Transaction completed successfully through Wallet");
          this.payUnpaidCommissionList = [];
          this.isMonthly = this.unpaidCommissionGroup.controls['isMonthly'].value == 'Monthly' ? 'true' : 'false';
          this.viewCommissionService.getCommissionGenerateDateData(this.isMonthly).subscribe((val)=>{
            if(val.data){
              this.allDatesData = val.data;
            }
          })
          this.gridView = false;
        }
      });
    }else if(this.paymentMethodGroup.controls['paymentMethod'].value === 'Bank'){
      console.log(this.commssionGeneratedData);
      console.log("Bank");
      this.commssionGeneratedData.forEach(element=>{
        if(element.checked === true){
          const payUnpaidCommssion = new PayUnpaidCommission();
          payUnpaidCommssion.displayId = element.displayId;
          payUnpaidCommssion.paymentType = 'bank';
          payUnpaidCommssion.fromDate = element.fromDate;
          payUnpaidCommssion.toDate = element.toDate;
          this.payUnpaidCommissionList.push(payUnpaidCommssion);
        }
      });
      let payUnpaidCommissionDataPost = new PayUnpaidCommissionDataPost();
      payUnpaidCommissionDataPost.payUnpaidCommission = this.payUnpaidCommissionList;
      const ismonthly = this.unpaidCommissionGroup.controls['isMonthly'].value == 'Monthly' ? 'true' : 'false';
      this.viewCommissionService.postUnpaidCommissionData(payUnpaidCommissionDataPost,ismonthly).subscribe((val)=>{
        if(val.errorCode){
          this.error  = true;
          this.dangerSwal(val.message);
          this.payUnpaidCommissionList = [];
        }else{
          this.successSwal("Transaction completed successfully through Bank");
          this.payUnpaidCommissionList = [];
          this.isMonthly = this.unpaidCommissionGroup.controls['isMonthly'].value == 'Monthly' ? 'true' : 'false';
          this.viewCommissionService.getCommissionGenerateDateData(this.isMonthly).subscribe((val)=>{
            if(val.data){
              this.allDatesData = val.data;
            }
          })
          this.gridView = false;
        }
      });
    }
    else{
      this.warningSwal("Please select a payment method");
    }
  }

  cancelPayment(){
    this.paymentMethodGroup.reset();
  }

  show() {
    this.showModal = true;
  }

  unpaidDetailsGenerate(data: UnpaidCommssionGenerateData){
    this.unpaidDetailsGridView = false;
    this.unpaidCommissionDetailsData = [];
    this.isMonthly = this.unpaidCommissionGroup.controls['isMonthly'].value == 'Monthly' ? 'true' : 'false';
    console.log(data);
    this.viewCommissionService.getUnpaidCommissionDetailsData(data.displayId,data.fromDate,data.toDate,this.isMonthly).subscribe((val)=>{
      if(val){
        this.unpaidCommissionDetailsData = val.data;
        this.unpaidDetailsGridView = true;
      }
    })
    this.show();
  }

  hide() {
    this.showModal = false;
  }

  exportexcel(): void {
    this.excelExportService.exportExcel(
      this.commssionGeneratedData,
      'Commssion Generate Data'
    );
  }
}
