import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../auth/guard/auth.guard';
import { UserRoleGuard } from '../auth/guard/user-role.guard';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { KycUpdateComponent } from './components/kyc-update/kyc-update.component';
import { KycVerificationComponent } from './components/kyc-verification/kyc-verification.component';
import { UpdateProfileComponent } from './components/update-profile/update-profile.component';
import { ViewProfileComponent } from './components/view-profile/view-profile.component';
const routes: Routes = [
  {
    path: 'view-profile',
    component: ViewProfileComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'update-profile',
    component: UpdateProfileComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'change-password',
    component: ChangePasswordComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'kyc-update',
    component: KycUpdateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'kyc-verification',
    component: KycVerificationComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProfileRoutingModule {}
