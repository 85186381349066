<app-card [hidHeader]="true">
    <h5>Fund Add</h5>
    <hr>
    <form [formGroup]="fundAddGroup" class="form">
        <div class="app-modal-body">
            <div class="col-sm-6">
                <div class="form-group">
                    <label class="d-block ">Display Id</label>
                    <input type="number" class="form-control" [(ngModel)]="displayId" formControlName="displayId" placeholder="Display Id" [readonly]="!isAdmin">
                </div>
            </div>
            <div class="col-sm-6">
                <div class="form-group">
                    <label class="d-block">Amount</label>
                    <input type="number" class="form-control" formControlName="amount" placeholder="Amount">
                </div>
            </div>
            <div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="d-block">Description</label>
                        <textarea class="form-control" formControlName="description" rows="3" placeholder="Description"></textarea>
                    </div>
                </div>
            </div>
            <!-- <div class="col-sm-6">
                <div class="form-group">
                    <label class="d-block">Instrument</label>
                    <input type="text" class="form-control" formControlName="instrument" placeholder="Instrument">
                </div>
            </div> -->
        </div>
        <hr>
        <div class="app-modal-footer">
            <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="cancel()">Cancel</button>
            <button type="submit" class="btn btn-primary" (click)="submitFundAdd()">Submit</button>
        </div>
    </form>
</app-card>
