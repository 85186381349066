import { Component, OnInit } from '@angular/core';
import { UserAuthService } from 'src/app/mlm/auth/services/user-auth.service';
import { CommonComponent } from 'src/app/theme/shared/common/common.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent extends CommonComponent implements OnInit {
  isAdmin: boolean = false;
  isFranchise: boolean = false;
  isCNF: boolean = false;
  constructor(
    private userAuthService: UserAuthService
    ) {
      super();
  }

  ngOnInit(): void {
    this.isAdmin = (this.userAuthService.getRoles()==="ADMIN")?true:false;
    this.isFranchise = (this.userAuthService.getRoles()==="FRANCHISE")?true:false;
    this.isCNF = (this.userAuthService.getRoles()==="CNF")?true:false;
  }
}
