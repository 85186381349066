import { Component, OnInit } from '@angular/core';
import { WalletService } from '../../services/wallet.service';
import {
  FranchiseBalanceRequests,
  FranchiseRequestApprovalReject,
} from '../../model/wallet.model';
import { CommonComponent } from 'src/app/theme/shared/common/common.component';
import 'sweetalert2/src/sweetalert2.scss';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-franchise-balance-requests',
  templateUrl: './franchise-balance-requests.component.html',
  styleUrls: ['./franchise-balance-requests.component.scss'],
})
export class FranchiseBalanceRequestsComponent
  extends CommonComponent
  implements OnInit
{
  franchiseBalanceRequestsData: FranchiseBalanceRequests[] = [];
  franchiseApprovedBalanceRequestData: FranchiseBalanceRequests[] = [];
  isDataAvailable: boolean;
  isApprovedDataAvailable: boolean;
  pendingGridView: boolean = false;
  approvedGridView: boolean = false;
  fileName = 'ExcelSheet.xlsx';
  pendingBalanceloading: boolean = true;
  approvedBalanceloading: boolean = true;
  columns = [
    'Actions',
    'Created On',
    'Display ID',
    'Name',
    'Phone Number',
    'Description',
    'Request ID',
    'Request Amount',
  ];
  approvedColumns = [
    'Created On',
    'Approved On',
    'Display ID',
    'Name',
    'Request Amount',
    'Phone Number',
    'Description',
    'Request ID',
    
  ];
  noData: boolean;
  noApprovedData: boolean;
  constructor(private walletService: WalletService) {
    super();
  }

  ngOnInit(): void {
    this.walletService.getFranchiseBalanceRequestsData().subscribe((val) => {
      if (val) {
        this.franchiseBalanceRequestsData = val.data;
        this.isDataAvailable = this.franchiseBalanceRequestsData?.length > 0;
        this.noData = this.franchiseBalanceRequestsData?.length === 0;
        this.pendingGridView = true;
        this.pendingBalanceloading = false;
      }
    });

    this.walletService.getFranchiseApprovedBalanceData().subscribe((val) => {
      if (val) {
        this.franchiseApprovedBalanceRequestData = val.data;
        this.isApprovedDataAvailable = this.franchiseApprovedBalanceRequestData?.length > 0;
        this.noApprovedData = this.franchiseApprovedBalanceRequestData?.length === 0;
        this.approvedGridView = true;
        this.approvedBalanceloading = false;
      }
    });
  }

  approve(data: FranchiseBalanceRequests) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'you want to approve the franchise?',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
    }).then((willDelete) => {
      if (willDelete.dismiss) {
        Swal.fire('', 'Franchise balance request approve cancelled', 'error');
      } else {
        const franchiseRequestApprovalReject =
          new FranchiseRequestApprovalReject();
        franchiseRequestApprovalReject.amount = data.requestedAmount;
        franchiseRequestApprovalReject.isApproved = 1;
        franchiseRequestApprovalReject.requestId = data.requestId;
        franchiseRequestApprovalReject.rejectionClause = '';
        this.walletService
          .postFranchiseApprovalBalanceRequest(franchiseRequestApprovalReject)
          .subscribe((val) => {
            if (val.message) {
              this.dangerSwal(val.message);
            } else {
              this.franchiseBalanceRequestsData = [];
              this.walletService
                .getFranchiseBalanceRequestsData()
                .subscribe((val) => {
                  if (val) {
                    this.franchiseBalanceRequestsData = val.data;
                    this.isDataAvailable =
                      this.franchiseBalanceRequestsData?.length > 0;
                    this.noData =
                      this.franchiseBalanceRequestsData?.length === 0;
                    this.pendingGridView = true;
                    this.pendingBalanceloading = false;
                  }
                });
              Swal.fire(
                '',
                'Balance request approved successfully!',
                'success'
              );
            }
          });
      }
    });
  }

  reject(data: FranchiseBalanceRequests) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'you want to reject the franchise?',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true
    }).then((willDelete) => {
        if (willDelete.dismiss) {
          Swal.fire('', 'Franchise balance request rejection cancelled', 'error');
        } else {
          Swal.fire({
            text: 'Write a rejection clause:',
            input: 'text',
          }).then((result) => {
            if (result.value) {
              const franchiseRequestApprovalReject =
                new FranchiseRequestApprovalReject();
              franchiseRequestApprovalReject.amount = data.requestedAmount;
              franchiseRequestApprovalReject.isApproved = 0;
              franchiseRequestApprovalReject.requestId = data.requestId;
              franchiseRequestApprovalReject.rejectionClause = result.value;
              this.walletService
                .postFranchiseApprovalBalanceRequest(franchiseRequestApprovalReject)
                .subscribe((val) => {
                  if (val.message) {
                    this.dangerSwal(val.message);
                  } else {
                    this.franchiseBalanceRequestsData = [];
                    this.walletService
                      .getFranchiseBalanceRequestsData()
                      .subscribe((val) => {
                        if (val) {
                          this.franchiseBalanceRequestsData = val.data;
                          this.isDataAvailable =
                            this.franchiseBalanceRequestsData?.length > 0;
                          this.noData =
                            this.franchiseBalanceRequestsData?.length === 0;
                          this.pendingGridView = true;
                          this.pendingBalanceloading = false;
                        }
                      });
                    this.successSwal('Balance request rejected successfully');
                  }
                });
            } else {
              this.dangerSwal('Please give a Rejection Clause');
            }
          });
        }
      });

  }
}
