<div class="row justify-content-center">
    <div class="col-sm-12">
        <app-card [hidHeader]="true" blockClass="text-center">
            <form [formGroup]="downlineForm" class="form">
                <div class="app-modal-body" class="col-sm-8 text-left">
                    <div class="form-group">
                        <div>
                            <label class="form-check-label">Display ID</label>
                            <input type="text" class="form-control" formControlName="displayId" placeholder="Please enter a display ID">
                        </div>
                        <br>
                        <input type="checkbox" formControlName="viewUpline" style="padding: 15px 15px 15px 15px;">
                        <label>View Upline</label>
                        <br>
                        <input type="checkbox" formControlName="viewDownline" style="padding: 15px 15px 15px 15px;">
                        <label>View Downline</label>
                    </div>
                    <button type="submit" class="btn btn-primary" (click)="submitDownline()">Search</button>
                    <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="resetDownline()">Cancel</button>
                    <button type="button" class="btn btn-warning " (click)="downlineMembers()" style="float: right;">View All Downline</button>
                </div>
            </form>
        </app-card>
    </div>
</div>

<div class="row justify-content-center">
    <div class="col-sm-12" *ngIf="viewFilter">
        <app-card [hidHeader]="true" blockClass="text-center">
            <div class="row align-items-center m-l-0">
                <div class="col-sm-6 text-left">
                    <h5>User Management</h5>
                </div>
                <div class="col-sm-6 text-right">
                    <button type="button" class="btn btn-primary btn-sm" (click)="exampleModalLong.show()"><i class="feather icon-plus "></i>Registration</button>
                </div>
            </div>
            <form [formGroup]="searchForm" class="form">
                <div class="app-modal-body" class="col-sm-8 text-left">
                    <!-- <div class="row"> -->
                    <div>
                        <div class="form-group">
                            <input type="text" class="form-control" formControlName="search" placeholder="Use search to find members. You can search by: Name or Display ID">
                        </div>
                        <button type="submit" class="btn btn-primary" (click)="submitSearch()">Submit</button>
                        <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="reset()">Cancel</button>
                    </div>
                    <!-- </div> -->
                </div>
            </form>
        </app-card>
    </div>

    <ng-container *ngIf="!loading; then thenTemplate; else elseTemplate"></ng-container>
    <ng-template #thenTemplate>
        <ng-container *ngIf="isDataAvailable">
            <div class="col-lg-4 col-md-6 " *ngFor="let item of downlineReportsTableData">
                <app-card [hidHeader]="true " cardClass="user-card user-card-1 mt-4">
                    <div class="user-about-block text-center ">
                        <div class="row align-items-end ">
                            <div class="col text-left ">
                                <span class="badge badge-success" *ngIf="item.isGreen">Active</span>
                                <span class="badge badge-danger" *ngIf="!item.isGreen">Inactive</span>
                            </div>
                            <div class="col "><img class="img-radius img-fluid wid-80 " src="assets/images/user/avatar-sample.jpg " alt="User image "></div>
                            <div class="col text-right pb-3 ">
                                <!-- <div class="dropdown " ngbDropdown placement="auto ">
                                <a class="drp-icon dropdown-toggle " ngbDropdownToggle data-toggle="dropdown " aria-haspopup="true " aria-expanded="false "><i class="feather icon-more-horizontal "></i></a>
                                <div class="dropdown-menu dropdown-menu-right " ngbDropdownMenu>
                                    <a class="dropdown-item " href="javascript: ">Edit</a>
                                    <a class="dropdown-item " href="javascript: ">History</a>
                                    <a class="dropdown-item " href="javascript: ">Trash</a>
                                </div>
                            </div> -->
                            </div>
                        </div>
                    </div>
                    <div class="text-center ">
                        <a href="javascript: " data-toggle="modal " data-target="#modal-report ">
                            <h4 class="mb-1 mt-3 ">{{item.name}}</h4>
                        </a>
                        <p class="mb-2 text-muted "><i class="feather icon-calendar "> </i> {{item.createdOn.substring(0,10)}}</p>

                        <a type="button" class="btn btn-icon btn-info col-sm-3 button-card-right mb-2 " style="color: black" *ngIf="isAdmin" [routerLink]="['update-profile',item.displayId]"><i class="feather icon-info"></i></a>
                        <a type="button" class="btn btn-icon btn-info col-sm-3 button-card-right mb-2 " style="color: black" *ngIf="!isAdmin" [routerLink]="['view-profile',item.displayId]"><i class="feather icon-info"></i></a>
                        <button type="button" class="btn btn-icon btn-warning col-sm-3 button-card-left mb-2 " style="color: black" [routerLink]="['shop',item.displayId]"><i class="feather icon-shopping-cart"></i></button>
                        <br>
                        <p class="mb-0 "><b>Display ID : </b> {{item.displayId}}</p>
                        <p class="mb-0 "><b>Sponsor ID : </b>{{item.sponsorName}} ({{item.sponsorID}})</p>
                        <p class="mb-2 "><b>Parent ID : </b> {{item.parentName}} ({{item.parentID}})</p>
                        <p class="mb-0 col-sm-3 button-card-right badge badge-success" style="color: black" *ngIf="isAdmin">Wallet : 0.00</p>
                        <p class="mb-2 col-sm-4 button-card-left badge badge-success" style="color: black" *ngIf="isAdmin">Commision : 0.00</p>
                        <p class="mb-0 "><b>Position : </b> {{item.parentIdWisePosition}} </p>
                        <p class="mb-0 " *ngIf="isAdmin"><b>Phone Number : </b>{{item.phNo}}</p>
                        <p class="mb-2 " *ngIf="isAdmin"><b>Email :</b> {{item.emailId}}</p>

                        <a class="btn-sm btn-info col-sm-3 button-card-right" style="color: black;" [routerLink]="['add-fund',item.displayId]" *ngIf="isAdmin">Balance</a>
                        <a class="btn-sm btn-warning col-sm-4 button-card-left" style="color: black;" [routerLink]="['transaction-report',item.displayId]" *ngIf="isAdmin">Transaction</a>
                        <br>
                        <button type="button" class="btn btn-sm btn-warning" style="color: black" [routerLink]="['tree',item.displayId]">Tree</button>
                        <div class="form-check" *ngIf="isAdmin">
                            <input class="form-check-input" type="checkbox" [checked]="item.isActive==1" (change)="activeInactive($event,item.displayId)">
                            <label class="form-check-label" for="activeInactive"></label>
                        </div>
                    </div>
                </app-card>
            </div>
        </ng-container>
    </ng-template>
    <ng-template #elseTemplate>
        <div class="spinner">
            <h5>Loading...</h5>
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
        </div>
    </ng-template>

</div>
<app-ui-modal #exampleModalLong [dialogClass]="'modal-xl'">
    <div class="app-modal-header">
        <h5>Add Member</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close" (click)="exampleModalLong.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
        <form [formGroup]="memberGroup" class="form">
            <div class="app-modal-body">
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label class="d-block">Sponsor ID <span style="color: red;">*</span></label>
                            <input type="number" class="form-control" [(ngModel)]="displayId" formControlName="sponsorId" placeholder="Sponsor ID" (keyup)="checkSponsorID($event)">
                            <p style="color: red;">{{showMessageSponsorID}}</p>
                            <div style="color: blue;" *ngIf="sponsorName">Name - <strong>{{ sponsorName }}</strong></div>
                        </div>
                    </div>
                    <!-- <div style="padding-top: 30px; ">
                    <button class="btn btn-warning" style="color:black;" (click)="checkSponsorID($event)">Validate</button>
                </div> -->
                </div>
                <!-- <div class="row" *ngIf="gridView">
                <div class="col-sm-12">
                    <table datatable id="report-table" class="table table-striped table-hover mb-0 table-responsive">
                        <thead>
                            <tr>
                                <th *ngFor="let col of columns">
                                    {{col}}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let reports of allMemberDataBySponsorID">
                                <td *ngFor="let col of index">
                                    <div *ngIf="col != 'positionEligible' && col != 'positionVacant' && col != 'isActive' && col != 'isGreen' && col != 'action'">
                                        {{reports[col]}}
                                    </div>
                                    <div *ngIf="col == 'positionVacant'">
                                        <div *ngIf="reports[col] == 'Left' ">
                                            <span class="badge badge-success inline-block">Left</span>
                                        </div>
                                        <div *ngIf="reports[col] == 'Right' ">
                                            <span class="badge badge-warning inline-block m-r-5">Right</span>
                                        </div>
                                        <div *ngIf="reports[col] == 'Both' ">
                                            <span class="badge badge-primary inline-block m-r-5">Both</span>
                                        </div>
                                    </div>
                                    <div *ngIf="col == 'positionEligible'">
                                        <div *ngIf="reports[col] == 'Left' ">
                                            <span class="badge badge-success inline-block">Left</span>
                                        </div>
                                        <div *ngIf="reports[col] == 'Right' ">
                                            <span class="badge badge-warning inline-block m-r-5">Right</span>
                                        </div>
                                    </div>
                                    <div *ngIf="col == 'isActive'" class="d-inline-block">
                                        <div class="custom-control custom-switch" *ngIf="reports[col] == 1 ">
                                            <input type="checkbox" class="custom-control-input" readonly checked>
                                            <label class="custom-control-label" for="customSwitch1"></label>
                                        </div>

                                        <div class="custom-control custom-switch" *ngIf="reports[col] == 0 ">
                                            <input type="checkbox" class="custom-control-input" readonly>
                                            <label class="custom-control-label" for="customSwitch1"></label>
                                        </div>
                                    </div>
                                    <div *ngIf="col == 'isGreen'">
                                        <div class="custom-control custom-switch" *ngIf="reports[col] == true ">
                                            <input type="checkbox" class="custom-control-input" readonly checked>
                                            <label class="custom-control-label"></label>
                                        </div>

                                        <div class="custom-control custom-switch" *ngIf="reports[col] == false ">
                                            <input type="checkbox" class="custom-control-input" readonly>
                                            <label class="custom-control-label"></label>
                                        </div>
                                    </div>
                                    <div *ngIf="col == 'action'">
                                        <button class="btn-sm btn-info" style="color:black;" (click)="getRowData(reports)">Select</button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div> -->
                <div *ngIf="!gridView">
                    <div class=" row">

                        <!-- <div class="col-sm-4">
                      <label>Position : <span style="color: red;">*</span></label>
                      <div class="col-sm-6">
                          <input id="left" type="radio" value="left" name="position" formControlName="position">
                          <label style="padding-left: 5px; " for="left ">Left</label>
                      </div>
                      <div class="col-sm-6">
                          <input id="right" type="radio" value="right " name="position" formControlName="position" checked>
                          <label style=" padding-left: 5px; " for="right">Right</label>
                      </div>
                  </div> -->
                        <!-- <div class="col-sm-4">
                            <div class="form-group">
                                <label class="d-block">Parent ID <span style="color: red;">*</span></label>
                                <input type="number" class="form-control" formControlName="parentId" placeholder="Parent ID" (keyup)="checkParentID($event)">
                                <p style=" color: red; ">{{showMessageParentID}}</p>
                            </div>
                        </div> -->
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="d-block">Position : <span style="color: red;">*</span></label>
                                <select class="form-control" formControlName="position">
                              <option> </option>
                          <option *ngFor="let pos of position">{{pos}}</option>
                        </select>
                            </div>
                        </div>
                        <!-- <div class="col-sm-4">
                            <div class="form-group">
                                <label class="d-block">User ID <span style="color: red;">*</span></label>
                                <input type="text " class="form-control" formControlName="userId" placeholder="User ID" (keyup)="checkUserID($event)">
                                <p style="color: red; ">{{showMessageUserID}}</p>
                            </div>
                        </div> -->
                    </div>
                    <div class="row ">
                        <div class="col-sm-4 ">
                            <div class="form-group ">
                                <label class="d-block">Name <span style="color: red;">*</span></label>
                                <input type="text" class="form-control" formControlName="name" placeholder="Name">
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="d-block ">Phone Number <span style="color: red;">*</span></label>
                                <input type="number" class="form-control" formControlName="phno" placeholder="Phone Number">
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="d-block ">Email</label>
                                <input type="email" class="form-control" formControlName="email" placeholder="Email">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="d-block">Pincode</label>
                                <input type="number" class="form-control" formControlName="pincode" placeholder="Pincode">
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="d-block">State <span style="color: red;">*</span></label>
                                <select class="form-control" formControlName="state">
                              <option> </option>
                     <option *ngFor="let states of allStatesData " value="{{states.stateCode}}">{{states.stateName}}</option>
                  </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="app-modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal " (click)="exampleModalLong.hide() ">Cancel</button>
                <button type="submit" *ngIf="!gridView " class="btn btn-primary " [disabled]="isSubmitting" (click)="submitMember() ">Submit</button>
            </div>
        </form>

    </div>
</app-ui-modal>
