<ng-container *ngIf="!pendingBalanceloading; then thenTemplate; else elseTemplate"></ng-container>
<ng-template #thenTemplate>
    <ng-container *ngIf="isDataAvailable; else elseTemplate">
        <div class="row btn-page" *ngIf="pendingGridView">
            <div class="col-sm-12 btn-page">
                <app-card [hidHeader]="true" cardClass="card-datatable">
                    <h5>Pending Franchise Balance Requests</h5>
                    <div class="col-sm-12 text-right">
                        <button type="button" class="btn btn-primary btn-sm" (click)="exportexcel()">Export to Excel</button>
                    </div>
                    <div class="table-responsive">
                        <table datatable id="excel-table" class="table table-striped table-hover mb-0 table-responsive">
                            <thead>
                                <tr>
                                    <th *ngFor="let col of columns">
                                        {{col}}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of franchiseBalanceRequestsData; let i=index; ">
                                    <th scope="row">
                                        <div>
                                            <button class="btn-sm btn-success" style="color:black;" (click)="approve(data)">Approve</button>
                                            <button class="btn-sm btn-danger" style="color:black;" (click)="reject(data)">Reject</button>
                                        </div>
                                    </th>
                                    <th scope="row">{{ data?.createdOn }}</th>
                                    <th scope="row">{{ data?.displayId }}</th>
                                    <th scope="row">{{ data?.name }}</th>
                                    <th scope="row">{{ data?.phNo }}</th>
                                    <th scope="row">{{ data?.description }}</th>
                                    <th scope="row">{{ data?.requestId }}</th>
                                    <th scope="row">{{ data?.requestedAmount }}</th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </app-card>
            </div>
        </div>
    </ng-container>
    <ng-template #elseTemplate>
        <div class="spinner " *ngIf="!noData ">
            <h5>Loading...</h5>
            <div class="bounce1 "></div>
            <div class="bounce2 "></div>
            <div class="bounce3 "></div>
        </div>
        <div *ngIf="noData ">
            <h4 style="text-align: center; ">No pending requests</h4>
        </div>
    </ng-template>
</ng-template>
<ng-template #elseTemplate>
</ng-template>




<ng-container *ngIf="!approvedBalanceloading; then thenApprovedTemplate; else elseApprovedTemplate"></ng-container>
<ng-template #thenApprovedTemplate>
    <ng-container *ngIf="isApprovedDataAvailable; else elseTemplate">
        <div class="row btn-page" *ngIf="approvedGridView">
            <div class="col-sm-12 btn-page">
                <app-card [hidHeader]="true" cardClass="card-datatable">
                    <h5>Approved Franchise Balance Requests</h5>
                    <div class="col-sm-12 text-right">
                        <button type="button" class="btn btn-primary btn-sm" (click)="exportexcel()">Export to Excel</button>
                    </div>
                    <div class="table-responsive">
                        <table datatable id="excel-table" class="table table-striped table-hover mb-0 table-responsive">
                            <thead>
                                <tr>
                                    <th data-order="desc">Created On</th>                                  
                                    <th data-order="desc">Approved On</th>
                                    <th>Display ID</th>
                                    <th>Name</th>
                                    <th>Request Amount</th>
                                    <th>Phone Number</th>
                                    <th>Description</th>
                                    <th>Request ID</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of franchiseApprovedBalanceRequestData; let i=index; ">
                                    <th scope="row">{{ data?.createdOn }}</th>
                                    <th scope="row">{{ data?.approvedOn }}</th>
                                    <th scope="row">{{ data?.displayId }}</th>
                                    <th scope="row">{{ data?.name }}</th>
                                    <th scope="row">{{ data?.requestedAmount }}</th>
                                    <th scope="row">{{ data?.phNo }}</th>
                                    <th scope="row">{{ data?.description }}</th>
                                    <th scope="row">{{ data?.requestId }}</th>
                                    
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </app-card>
            </div>
        </div>
    </ng-container>
    <ng-template #elseApprovedTemplate>
        <div class="spinner " *ngIf="!noData ">
            <h5>Loading...</h5>
            <div class="bounce1 "></div>
            <div class="bounce2 "></div>
            <div class="bounce3 "></div>
        </div>
        <div *ngIf="noData ">
            <h1 style="text-align: center; ">No Data Available</h1>
        </div>
    </ng-template>
</ng-template>
<ng-template #elseApprovedTemplate>
</ng-template>