import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { UserAuthService } from 'src/app/mlm/auth/services/user-auth.service';
import { AllStates } from 'src/app/mlm/member/model/allstates.model';
import { MembersService } from 'src/app/mlm/member/services/members.service';
import { CommonComponent } from 'src/app/theme/shared/common/common.component';
import { AddCnf } from '../../model/cnf.model';
import { CnfService } from '../../services/cnf.service';

@Component({
  selector: 'app-add-cnf',
  templateUrl: './add-cnf.component.html',
  styleUrls: ['./add-cnf.component.scss']
})
export class AddCnfComponent extends CommonComponent implements OnInit {
  AddCnfGroup: FormGroup;
  allStatesData: AllStates[] = [];
  isAdmin: boolean = false;

  constructor(
    public fb:FormBuilder,
    private memberService: MembersService,
    private userAuthService: UserAuthService,
    private cnfService: CnfService
  ) {
    super();
    this.AddCnfGroup = this.fb.group({
      name: new FormControl('', [Validators.required]),
      phNo: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      pinCode: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required])
    });
  }

  ngOnInit(): void {
    this.memberService.getAllStatesData().subscribe((value) => {
      this.allStatesData = value.data;
    });
    this.isAdmin = (this.userAuthService.getRoles()==='ADMIN')?true:false;
  }

  addCnf(){
    const addCnf = new AddCnf();
    addCnf.name = this.AddCnfGroup.controls['name'].value;
    addCnf.phNo = this.AddCnfGroup.controls['phNo'].value;
    addCnf.address = this.AddCnfGroup.controls['address'].value;
    addCnf.description = this.AddCnfGroup.controls['description'].value;
    addCnf.pinCode = this.AddCnfGroup.controls['pinCode'].value;
    this.allStatesData.forEach(element => {
      if(element.stateName == this.AddCnfGroup.controls['state'].value){
        addCnf.stateCode = element.stateCode;
      }
    });
    this.cnfService.postAddCnfData(addCnf).subscribe((val)=>{
      if(val.message){
        this.dangerSwal(val.message);
      }
      else{
        this.successSwal('CNF Added Successfully');
        this.AddCnfGroup.reset();
      }
    })
  }

  cancel(){
    this.AddCnfGroup.reset();
  }
}
