import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoggerService } from 'src/app/theme/shared/log/logger.service';
import { environment } from 'src/environments/environment';
import { AbstractHttpService } from '../../../theme/shared/base/abstract-http';

@Injectable({
  providedIn: 'root'
})
export class ProfileService extends AbstractHttpService {
  private _changePassword : string = environment.baseUrl + "api/v1/updatepassword";
  private _profileupdate : string = environment.baseUrl + "api/v1/profileupdate";
  private _kycUpload : string = environment.baseUrl + "api/v1/member/fileupload";

  constructor(
    protected  http: HttpClient,
    protected  logger: LoggerService
    ) {
    super('ProfileService', http, logger);
  };

  postChangePasswordData(changePasswordForm: any) : Observable<any>{
    return this.http.post(this._changePassword ,changePasswordForm);
  }

  postProfileUpdateData(profileUpdateForm: any) : Observable<any>{
    return this.http.post(this._profileupdate ,profileUpdateForm);
  }

  postKycUploadData(formData: any) : Observable<any>{
    return this.http.post(this._kycUpload,formData);
  }
}
