<div class="row btn-page">
    <div class="col-sm-12">
        <app-card cardTitle="Repurchase Report Summary" [options]="false" *ngIf="gridView && !noData">
            <div class="row">
                <div class="col-md-3 col-xs-6 border-right">
                    <h3>{{leftBv}}</h3>
                    <span>Left BV</span>
                </div>
                <div class="col-md-3 col-xs-6 border-right">
                    <h3>{{rightBv}}</h3>
                    <span class="text-primary">Right BV</span>
                </div>
                <div class="col-md-3 col-xs-6 border-right">
                    <h3>{{totalBv}}</h3>
                    <span class="text-success">Total BV</span>
                </div>
                <div class="col-md-3  col-xs-6 border-right">
                    <h3>{{totalAmount}}</h3>
                    <span class="text-info">Total Amount</span>
                </div>
            </div>
        </app-card>
    </div>
</div>
<div class="row">
    <div class="col-sm-12">
        <app-card cardTitle="Filter Repurchase Report" [options]="false">
            <form [formGroup]="FilterFormGroup" class="form">
                <div class="app-modal-body">
                    <div class="row">
                        <div class="col-sm-8">
                            <div class="form-group">
                                <label class="d-block">Display Id</label>
                                <input type="number" class="form-control" [ngModel]="displayId" formControlName="displayId" placeholder="Display Id" [readOnly]="!isAdmin">
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="d-block">State <span style="color: red;">*</span></label>
                                <select class="form-control" formControlName="state">
                                <option>All</option>
                                <option *ngFor="let states of allStatesData ">{{states.stateName}}</option>
                              </select>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="d-block">Repurchase Date From</label>
                                <input type="date" class="form-control" [ngModel]="fromRepurchaseDate | date:'yyyy-MM-dd'" formControlName="repurchaseFrom" placeholder="Repurchase Date From">
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="d-block">Repurchase Date To</label>
                                <input type="date" class="form-control" [ngModel]="toRepurchaseDate | date:'yyyy-MM-dd'" formControlName="repurchaseTo" placeholder="Repurchase Date To">
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="d-block">Show Downline</label>
                                <input type="checkbox" formControlName="showDownline">
                            </div>
                        </div>
                    </div>
                </div>
                <hr>
                <div class="app-modal-footer ">
                    <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="reset()">Cancel</button>
                    <button type="submit" class="btn btn-primary" (click)="search()">Search</button>
                </div>
            </form>
        </app-card>
    </div>
</div>

<ng-container *ngIf="!loading; then thenTemplate; else elseTemplate"></ng-container>
<ng-template #thenTemplate>
    <ng-container *ngIf="isDataAvailable; else elseTemplate">
        <div class="row btn-page" *ngIf="gridView">
            <div class="col-sm-12 btn-page">
                <app-card [hidHeader]="true" cardClass="card-datatable">
                    <div class="col-sm-12 text-right">
                        <button type="button" class="btn btn-primary btn-sm" (click)="exportexcel()">Export to Excel</button>
                    </div>
                    <div class="table-responsive">
                        <table datatable id="excel-table" class="table table-striped table-hover mb-0 table-responsive">
                            <thead>
                                <tr>
                                    <th *ngFor="let col of columns">
                                        {{col}}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of repurchaseReportsTableData; let i=index; ">
                                    <th scope="row ">{{ data.displayId }}</th>
                                    <th scope="row ">{{ data.name }}</th>
                                    <th scope="row ">{{ data.position }}</th>
                                    <th scope="row ">{{ data.orderDate }}</th>
                                    <th scope="row ">{{ data.invoiceNo }}</th>
                                    <th scope="row ">{{ data.totalBV }}</th>
                                    <th scope="row ">{{ data.totalPrice }}</th>
                                    <th scope="row ">{{ data.stateName }}</th>
                                    <th scope="row "> <button class="btn-sm btn-info" style="color:black;" (click)="exampleModalLong.show()" (click)="repurchaseReportInfo(data)">View</button></th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </app-card>
            </div>
        </div>
    </ng-container>
    <ng-template #elseTemplate>
        <div class="spinner" *ngIf="!noData">
            <h5>Loading...</h5>
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
        </div>
        <div *ngIf="noData">
            <h1 style="text-align: center;">No Data Available</h1>
        </div>
    </ng-template>
</ng-template>
<ng-template #elseTemplate>
</ng-template>

<app-ui-modal #exampleModalLong [dialogClass]="'modal-xl'">
    <div class="app-modal-header">
        <h5>Repurchase Reports Details</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close" (click)="exampleModalLong.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body" *ngIf="repurchaseDetailsGridView">
        <table id="report-table" datatable class="table table-striped table-hover mb-0 table-responsive">
            <thead>
                <tr>
                    <th *ngFor="let col of columnsDetails">
                        {{col}}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of repurchaseReportsDetailsData; let i=index; ">
                    <th scope="row ">{{ data.displayId }}</th>
                    <th scope="row ">{{ data.itemName }}</th>
                    <th scope="row ">{{ data.quantity }}</th>
                    <th scope="row ">{{ data.itemPrice }}</th>
                    <th scope="row ">{{ data.totalPrice }}</th>
                    <th scope="row ">{{ data.bv }}</th>
                    <th scope="row ">{{ data.totalBV }}</th>
                    <th scope="row ">{{ data.orderNo }}</th>
                </tr>
            </tbody>
        </table>
    </div>
</app-ui-modal>
