<div class="row btn-page">
    <div class="col-sm-12">
        <app-card cardTitle="Wallet Balance Summary" [options]="false" *ngIf="gridView">
            <div class="row">
                <div class="col-md-12 col-xs-6 border-right">
                    <h3>{{walletBalance?walletBalance.toFixed(2):0}}</h3>
                    <span>Wallet Balance</span>
                </div>
            </div>
        </app-card>
    </div>
</div>
<div class="row mb-n4" *ngIf="gridView">
    <div class="col-sm-12">
        <app-card [customHeader]="true" headerClass="border-0 p-2 pb-0" [options]="false" cardClass="card-datatable">
            <table id="report-table" datatable class="table table-striped table-hover mb-0 table-responsive" *ngIf="isFranchise">
                <thead>
                    <tr>
                        <th *ngFor="let col of columnsFranchise">
                            {{col}}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let transactions of transactionReportData; index as i ">
                        <th scope="row ">{{ transactions?.displayId }}</th>
                        <th scope="row ">{{ transactions?.amount?.toFixed(2) }}</th>
                        <th scope="row ">{{ transactions?.transactiondate?.substring(0,10) }}</th>
                        <th scope="row ">{{ transactions?.transactiontype }}</th>
                        <th scope="row ">{{ transactions?.orderno }}</th>
                        <th scope="row ">{{ transactions?.source }}</th>
                    </tr>
                </tbody>
            </table>
            <table id="report-table" datatable class="table table-striped table-hover mb-0 table-responsive " *ngIf="!isFranchise ">
                <thead>
                    <tr>
                        <th *ngFor="let col of columns">
                            {{col}}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let transactions of transactionReportData; index as i">
                        <th scope="row ">{{ transactions?.displayId }}</th>
                        <th scope="row ">{{ transactions?.transactiondate.substring(0,10) }}</th>
                        <th scope="row ">{{ transactions?.amount }}</th>
                        <th scope="row ">{{ transactions?.transactiontype }}</th>
                    </tr>
                </tbody>
            </table>
        </app-card>
    </div>
</div>