import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../auth/guard/auth.guard';
import { ItemDetailsComponent } from '../franchise-purchase/components/item-details/item-details.component';
import { FranchiseRequestComponent } from './components/franchise-request/franchise-request.component';
import { FranchiseRequestStatusComponent } from './components/franchise-request-status/franchise-request-status.component';
import { FranchiseRequestApprovalComponent } from './components/franchise-request-approval/franchise-request-approval.component';
import { AdminRoleGuard } from '../auth/guard/admin-role.guard';
import { FranchiseMembersComponent } from './components/franchise-members/franchise-members.component';
import { FranchiseMembersDetailsComponent } from './components/franchise-members-details/franchise-members-details.component';

const routes: Routes = [
  {
    path: 'franchise-request',
    component: FranchiseRequestComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'franchise-request-status',
    component: FranchiseRequestStatusComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'franchise-request-approval',
    component: FranchiseRequestApprovalComponent,
    canActivate: [AdminRoleGuard],
  },
  {
    path: 'franchise-members',
    component: FranchiseMembersComponent,
    canActivate: [AdminRoleGuard],
  },
  {
    path: 'franchise-members/view-profile/:displayId',
    component: FranchiseMembersDetailsComponent,
    canActivate: [AdminRoleGuard],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FranchiseRoutingModule {}
