import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { UserAuthService } from '../services/user-auth.service';

@Injectable({
  providedIn: 'root'
})
export class CombinedCnfAdminGuard implements CanActivate {
  constructor(
    private userAuthService: UserAuthService,
    private router: Router,
    private authService: AuthService
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.userAuthService.getToken() !== null) {
      const roles = localStorage.getItem('roles');
      if ( roles === 'ADMIN' || roles === 'CNF') {
        return true;
      } else {
        this.router.navigate(['/auth/signin-v2']);
        return false;
      }
    }
  }
  
}
