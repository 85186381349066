import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminRoleGuard } from '../auth/guard/admin-role.guard';
import { AuthGuard } from '../auth/guard/auth.guard';
import { BiModuleComponent } from './components/bi-module/bi-module.component';
import { DownlineReportComponent } from './components/downline-report/downline-report.component';
import { RepurchaseReportComponent } from './components/repurchase-report/repurchase-report.component';
import { GstMonthwiseComponent } from './components/gst-monthwise/gst-monthwise.component';
import { GstStatewiseComponent } from './components/gst-statewise/gst-statewise.component';
import { ViewCommissionComponent } from './components/view-commission/view-commission.component';
import { PaymentStatusReportComponent } from './components/payment-status-report/payment-status-report.component';
import { TdsReportComponent } from './components/tds-report/tds-report.component';
import { ViewOffersComponent } from './components/view-offers/view-offers.component';
import { FirstpurchaseRankComponent } from './components/firstpurchase-rank/firstpurchase-rank.component';
import { CommissionsTdsComponent } from './components/commissions-tds/commissions-tds.component';



const routes: Routes = [
  {
    path: 'downline-report',
    component: DownlineReportComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'bi-module',
    component: BiModuleComponent,
    canActivate: [AdminRoleGuard],
  },
  {
    path: 'repurchase-report',
    component: RepurchaseReportComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'gst-monthwise',
    component: GstMonthwiseComponent,
    canActivate: [AdminRoleGuard],
  },
  {
    path: 'gst-statewise',
    component: GstStatewiseComponent,
    canActivate: [AdminRoleGuard],
  },
  {
    path: 'firstpurchase-rank',
    component: FirstpurchaseRankComponent,
    canActivate: [AdminRoleGuard],
  },
  {
    path: 'view-commission',
    component: ViewCommissionComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'payment-status-report',
    component: PaymentStatusReportComponent,
    canActivate: [AdminRoleGuard],
  },
  {
    path: 'tds-report',
    component: TdsReportComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'view-offers',
    component: ViewOffersComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'commissions-tds',
    component: CommissionsTdsComponent,
    canActivate: [AdminRoleGuard],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ReportRoutingModule {}
