import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminRoleGuard } from '../auth/guard/admin-role.guard';
import { AuthGuard } from '../auth/guard/auth.guard';
import { StockEntryComponent } from '../purchase/components/stock-entry/stock-entry.component';
import { StockSearchComponent } from '../purchase/components/stock-search/stock-search.component';
import { ItemImageComponent } from './components/item-image/item-image.component';
import { ItemMasterComponent } from './components/item-master/item-master.component';
import { CategoryUpdateComponent } from './components/category-update/category-update.component';
import { SubCategoryUpdateComponent } from './components/sub-category-update/sub-category-update.component';
import { ProductUpdateComponent } from './components/product-update/product-update.component';
import { UpdateItemComponent } from './components/update-item/update-item.component';

const routes: Routes = [
  {
    path: 'item-master',
    component: ItemMasterComponent,
    canActivate: [AdminRoleGuard],
  },
  {
    path: 'item-image',
    component: ItemImageComponent,
    canActivate: [AdminRoleGuard],
  },
  {
    path: 'stock-entry',
    component: StockEntryComponent,
    canActivate: [AdminRoleGuard],
  },
  {
    path: 'stock-search',
    component: StockSearchComponent,
    canActivate: [AdminRoleGuard],
  },
  {
    path: 'category-update',
    component: CategoryUpdateComponent,
    canActivate: [AdminRoleGuard],
  },
  {
    path: 'sub-category-update',
    component: SubCategoryUpdateComponent,
    canActivate: [AdminRoleGuard],
  },
  {
    path: 'update-item',
    component: UpdateItemComponent,
    canActivate: [AdminRoleGuard],
  }
  // {
  //   path: 'product-update',
  //   component: ProductUpdateComponent,
  //   canActivate: [AdminRoleGuard],
  // }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class InventoryRoutingModule {}
