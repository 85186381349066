<app-card [hidHeader]="true">
    <h5>Transaction Enquiry</h5>
    <hr>
    <form [formGroup]="transactionEnquiryGroup" class="form">
        <div class="app-modal-body">
            <div class="row">
                <div class="col-sm-4">
                    <div class="form-group">
                        <label class="d-block ">Request Id</label>
                        <input type="text" class="form-control" formControlName="requestId">
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <div class="app-modal-footer">
            <button type=" button" class="btn btn-danger" data-dismiss="modal" (click)="cancel()">Cancel</button>
            <button type="submit" class="btn btn-primary" (click)="submitTransactionEnquiry()">Search</button>
        </div>
    </form>
</app-card>

<ng-container *ngIf="!loading; then thenTemplate; else elseTemplate"></ng-container>
<ng-template #thenTemplate>
    <ng-container *ngIf="isDataAvailable; else elseTemplate">
        <div class="row btn-page" *ngIf="gridView">
            <div class="col-sm-12 btn-page">
                <app-card [hidHeader]="true" cardClass="card-datatable" cardTitle="View Commission">
                    <!-- <div class="col-sm-12 text-right">
                      <button type="button" class="btn btn-primary btn-sm" (click)="exportexcel()">Export to Excel</button>
                  </div> -->
                    <div class="table-responsive">
                        <table datatable id="excel-table" class="table table-striped table-hover mb-0 table-responsive">
                            <thead>
                                <tr>
                                    <th *ngFor="let col of columns">
                                        {{col}}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of transactionEnquiryTableData; let i=index; ">
                                    <th scope="row ">{{ data?.requestID }}</th>
                                    <th scope="row ">{{ data?.mobileNumber }}</th>
                                    <th scope="row ">{{ data?.description }}</th>
                            </tbody>
                        </table>
                    </div>
                </app-card>
            </div>
        </div>
    </ng-container>
    <ng-template #elseTemplate>
        <div class="spinner" *ngIf="!noData">
            <h5>Loading...</h5>
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
        </div>
        <div *ngIf="noData">
            <h1 style="text-align: center;">No Data Available</h1>
        </div>
    </ng-template>
</ng-template>
<ng-template #elseTemplate>
</ng-template>