<div class="col-sm-12">
    <app-card [hidHeader]="true">
        <div class="row align-items-center m-l-0">
            <div class="col-sm-6 text-left">
                <h5>Member Search</h5>
            </div>
        </div>
        <form [formGroup]="displayIdForm" class="form">
            <div class="app-modal-body" class="col-sm-8 text-left">
                <!-- <div class="row"> -->
                <div>
                    <div class="form-group">
                        <label class="d-block">Display Id</label>
                        <input type="text" class="form-control" formControlName="displayId" placeholder="Display Id">
                        <div class="row col-sm-12">
                            <p style="color: red;">{{showMessageSponsorID}}</p>
                            <p style="color: green;">{{showMessageSponsorName}}</p>
                        </div>
                    </div>
                    
                    <button type="submit" class="btn btn-primary" (click)="submitSearch()">Search</button>
                    <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="reset()">Cancel</button>
                </div>
                <!-- </div> -->
            </div>
        </form>
    </app-card>
    <app-card [hidHeader]="true" *ngIf="validMemberSearch">
        <div class="row align-items-center m-l-0">
            <div class="col-sm-6 text-left">
                <h5>Member Informations</h5>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col-md-4 col-xs-6 border-right">
                <h3>{{searchMemberInformation.name}}</h3>
                <span>Member Name</span>
            </div>
            <div class="col-md-4 col-xs-6 border-right">
                <h3>{{searchMemberInformation.displayId}}</h3>
                <span class="text-primary">Member Display ID</span>
            </div>
            <div class="col-md-4 col-xs-6 border-right">
                <h3>{{searchMemberInformation.phNo}}</h3>
                <span class="text-success">Member Contact Number</span>
            </div>
            <!-- <div class="col-md-2 col-xs-6 border-right">
                <h3>{{redMember}}</h3>
                <span class="text-danger">Red Member</span>
            </div>
            <div class="col-md-2  col-xs-6 border-right">
                <h3>{{totalMember}}</h3>
                <span class="text-info">Total Member</span>
            </div> -->
        </div>
    </app-card>
</div>

<ng-container *ngIf="!loading; then thenTemplate; else elseTemplate">
</ng-container>
<ng-template #thenTemplate>
    <ng-container *ngIf="isDataAvailable; else elseTemplate">
        <div *ngIf="gridView">
            <div class="col-sm-12">
                <app-card [hidHeader]="true" cardClass="card-datatable" *ngIf="validMemberSearch">
                    <!-- <div class="col-sm-12 text-right">
                        <button type="button" class="btn btn-primary btn-sm" (click)="exportexcel()">Export to Excel</button>
                    </div> -->
                    <div class="row align-items-center m-l-0">
                        <div class="col-sm-6 text-left">
                            <h5>Items</h5>
                        </div>
                    </div>
                    <br>
                    <div class="table-responsive">
                        <table datatable id="excel-table" class="table table-striped table-hover mb-0 table-responsive">
                            <thead>
                                <tr>
                                    <th *ngFor="let col of columns">
                                        {{col}}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of allItemsDetailsData; let i=index; ">
                                    <th *ngIf="item?.itemCount > 0">
                                        <img [src]="item?.imagePath" class="me-2 " style="width: 80px; height: auto;">
                                    </th>
                                    <th *ngIf="item?.itemCount > 0" scope="row ">{{ item?.itemName }}</th>
                                    <th *ngIf="item?.itemCount > 0" scope="row ">{{ item?.itemCount }}</th>
                                    <th *ngIf="item?.itemCount > 0" scope="row ">{{ item?.distributorPrice.toFixed(2) }}</th>
                                    <th *ngIf="item?.itemCount > 0" scope="row ">
                                        <input type="number" 
                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57" 
                                        ondragstart="return false;" ondrop="return false;" 
                                        placeholder="qty" class="form-control" 
                                        [value]="item?.qty" 
                                        (keyup)="qtyAdd($event.target.value,item)">
                                    </th>
                                    <th *ngIf="item?.itemCount > 0" scope="row ">{{ item?.subTotal.toFixed(2) }}</th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <hr>
                    <div>
                        <label>
                          <input type="checkbox" [value]="isChecked" (change)="onCheckboxChange($event)" checked="isChecked">
                        Is Shipping Same As Correspondense Address
                      </label>
                    </div>
                    <div class="app-modal-footer" style="text-align:right;">
                        <button *ngIf="!isChecked" class="btn btn-warning" style="color:black;" (click)="checkOutModalLong.show()"><i class="fas fa-cart-plus mr-1"></i>Place Order</button>
                        <button *ngIf="isChecked" class="btn btn-success" style="color:black;" (click)="purchaseConfirmation.show()" (click)="orderSummary()"><i class="fas fa-cart-plus mr-1"></i>Place Order</button>
                    </div>
                </app-card>
            </div>
        </div>
    </ng-container>
    <ng-template #elseTemplate>
        <div class="spinner" *ngIf="!noData">
            <h5>Loading...</h5>
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
        </div>
        <div *ngIf="noData">
            <h1 style="text-align: center;">No Data Available</h1>
        </div>
    </ng-template>
</ng-template>
<ng-template #elseTemplate>
</ng-template>

<app-ui-modal #checkOutModalLong [dialogClass]="'modal-xl'">
    <div class="app-modal-header">
        <h5>Checkout</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close" (click)="checkOutModalLong.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
        <div class="row">
            <div class="col-md-12">
                <app-card [hidHeader]="true" [options]="false">
                    <aw-wizard #wizard class="arc-wizard checkout" navBarLayout="large-filled-symbols">
                        <aw-wizard-step stepTitle="Billing Info" [navigationSymbol]="{ symbol: '&#xe905;', fontFamily: 'feather' }" [style.font-weight]="900">
                            <h5 class="mb-2">Billing information</h5>
                            <p class="text-muted mb-0">Fill the form below in order to send you the order's invoice.</p>
                            <hr/>
                            <form [formGroup]="checkoutGroup" class="form">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label class="d-block">Name</label>
                                                    <input type="text" class="form-control" [(ngModel)]="searchMemberInformation.name" formControlName="name" placeholder="Name">
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="Email"> Email Address</label>
                                                    <input type="email" class="form-control" [(ngModel)]="searchMemberInformation.emailId" formControlName="email" placeholder="Email">
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="Phone">Phone</label>
                                                    <input type="number" class="form-control" [(ngModel)]="searchMemberInformation.phNo" formControlName="phNo" placeholder="Phone No.">
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label class="d-block">Address</label>
                                                    <textarea class="form-control" formControlName="address" [(ngModel)]="searchMemberInformation.address" rows="3" placeholder="Address"></textarea>
                                                </div>
                                            </div>
                                            <div class="col-sm-4">
                                                <div class="form-group">
                                                    <label class="d-block">City/Village</label>
                                                    <input type="text" class="form-control" [(ngModel)]="searchMemberInformation.cityVillage" formControlName="cityVillage" placeholder="City/Village">
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label class="d-block">State</label>
                                                    <select class="form-control" [(ngModel)]="stateName" formControlName="state">
                                   <option *ngFor="let states of allStatesData ">{{states.stateName}}</option>
                                </select>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label class="d-block">Pincode</label>
                                                    <input type="number" class="form-control" [(ngModel)]="searchMemberInformation.pinCode" formControlName="pincode" placeholder="Pincode">
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <button class="btn btn-outline-secondary" (click)="checkOutModalLong.hide()">
                        Back
                      </button>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="text-sm-right">
                                                    <button type="submit" (click)="purchaseConfirmation.show()" (click)="checkOutModalLong.hide()" (click)="orderSummary()" class="btn btn-success text-sm-right mt-md-0 mt-2">
                                                      <i class="fas fa-cart-plus mr-1"></i>Place Order</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </aw-wizard-step>
                    </aw-wizard>
                </app-card>
            </div>
        </div>
    </div>
</app-ui-modal>
<app-ui-modal #purchaseConfirmation [dialogClass]="'modal-lg'">
    <div class="app-modal-header">
        <h5>Order Summary</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close" (click)="purchaseConfirmation.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
        <div class="row">
            <div class="col-md-12">
                <app-card [hidHeader]="true" [options]="false">
                    <div class="table-responsive" *ngIf="confirmationTableShow">
                        <table datatable id="excel-table" class="table table-striped table-hover mb-0 table-responsive">
                            <thead>
                                <tr>
                                    <th *ngFor="let col of columnsConfirmation">
                                        {{col}}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of allItemsDetailsDataConfirmation; let i=index; ">
                                    <th>{{i+1}}</th>
                                    <th scope="row ">{{ item?.itemName }}</th>
                                    <th scope="row ">{{ item?.qty }}</th>
                                    <th scope="row ">{{ item?.totalBV }}</th>
                                    <th scope="row ">{{ item?.subTotal }}</th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <hr>
                    <div class="col-sm-12">
                        <div class="text-sm-left">
                            <h5 class="text-sm-left">Grand Total BV: {{this.bvTotalConfirmation.toFixed(2)}}</h5>
                        </div>
                        <div class="text-sm-left">
                            <h5 class="text-sm-left">Grand Total: {{this.subTotalConfirmation.toFixed(2)}}({{this.subTotalConfirmation.toFixed(2) | amountToWord}})</h5>
                        </div>
                        <div class="text-sm-right">
                            <button type="submit" (click)="placeOrder()" class="btn btn-success text-sm-right mt-md-0 mt-2" [disabled]="activeButton">Submit Order</button>
                            <button class="btn btn-dangertext-sm-right mt-md-0 mt-2" (click)="purchaseConfirmation.hide()">Cancel Order</button>
                        </div>
                    </div>
                </app-card>
            </div>
        </div>
    </div>
</app-ui-modal>
