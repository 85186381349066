import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { error } from 'console';
import { UserAuthService } from 'src/app/mlm/auth/services/user-auth.service';
import { ItemMasterService } from 'src/app/mlm/inventory/services/item-master.service';
import { AllStates } from 'src/app/mlm/member/model/allstates.model';
import { DetailsDisplayId } from 'src/app/mlm/member/model/details-displayid.model';
import { MembersService } from 'src/app/mlm/member/services/members.service';
import { ItemsDetails, ItemwiseStock, Purchase, PurchasedItems, SearchItems } from 'src/app/mlm/purchase/model/purchase.model';
import { PurchaseService } from 'src/app/mlm/purchase/services/purchase.service';
import { StockBalance } from 'src/app/mlm/stock/stock.model';
import { StockService } from 'src/app/mlm/stock/stock.service';
import { WalletService } from 'src/app/mlm/wallet/services/wallet.service';
import { CommonComponent } from 'src/app/theme/shared/common/common.component';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-multi-purchase',
  templateUrl: './multi-purchase.component.html',
  styleUrls: ['./multi-purchase.component.scss']
})
export class MultiPurchaseComponent extends CommonComponent implements OnInit {
  @ViewChild('purchaseConfirmation') purchaseConfirmation: any; // Get reference to the modal
  displayIdForm: FormGroup;
  showMessageSponsorName: string = '';
  showMessageSponsorID: string = '';
  searchMemberInformation: DetailsDisplayId;
  validMemberSearch: boolean = false;
  columns = [
    'Item Image',
    'Item Name',
    'Stock In Hand',
    'MRP',
    'Quantity',
    'Sub Total'
  ];
  columnsConfirmation = [
    'Sl. No.',
    'Item Name',
    'Quantity',
    'Total BV',
    'Sub Total'
  ];
  isAdmin: boolean = false;
  allItemsDetailsData: ItemwiseStock[] = [];
  stockData: StockBalance[] = [];
  isDataAvailable: boolean;
  noData: boolean = false;
  gridView: boolean = false;
  loading: boolean = true;
  itemsTotalPrice: number = 0;
  checkoutGroup: FormGroup;
  showModal: boolean = false;
  allStatesData: AllStates[] = [];
  purchasedItems: PurchasedItems[] = [];
  displayId: string;
  userName: string;
  subTotal: number = 0;
  bvTotal: number = 0;
  stateName : any;
  stateCode: string;
  isChecked: boolean = true;
  activeButton: boolean = false;
  confirmationTableShow = false;
  allItemsDetailsDataConfirmation: ItemwiseStock[] = [];
  subTotalConfirmation: number = 0;
  bvTotalConfirmation: number = 0;
  constructor(
    public fb: FormBuilder,
    public memberService: MembersService,
    private userAuthService: UserAuthService,
    private purchaseService: PurchaseService,
    private itemMasterService: ItemMasterService,
    private walletService: WalletService,
    private stockService: StockService
  ) {
    super();
    this.displayIdForm = this.fb.group({
      displayId: new FormControl('', [Validators.required]),
    });

    this.checkoutGroup = this.fb.group({
      name: new FormControl('', [Validators.required]),
      cityVillage: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      pincode: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required]),
      phNo: new FormControl('', [Validators.required]),
    });
    this.searchMemberInformation = new DetailsDisplayId();
  }

  ngOnInit(): void {
    this.isAdmin = this.userAuthService.getRoles() === 'ADMIN' ? true : false;

    this.memberService.getAllStatesData().subscribe((value) => {
      this.allStatesData = value.data;
    });

    this.purchaseService.getItemwiseStockData().subscribe((val)=>{
      if(val){
        this.allItemsDetailsData = val.data;
        this.allItemsDetailsData.forEach(element => {
          element.qty = 0;
          element.subTotal = 0;
        });
        this.isDataAvailable = this.allItemsDetailsData?.length > 0;
        this.noData = this.allItemsDetailsData?.length === 0;

        this.stockService.getSTockBalance().subscribe((response: any) => {
          if (response.data) {
            this.gridView = true;
            this.loading = false;
            this.stockData = response.data;
    
            this.allItemsDetailsData.forEach(element => {
              const stock = this.stockData.find(stockItem => stockItem.itemId === element.itemId);
              element.qty = 0;
              element.itemCount = stock ? stock.quantity : 0;
              element.subTotal = 0;
            });
          } else {
            Swal.fire('Error', 'Error in fetching stock details', response.message);
          }
        });

      }
    })

    this.userName = this.userAuthService.getUserName();

    this.checkoutGroup.controls['state'].valueChanges.subscribe((val) => {
      if (val) {
        this.allStatesData.forEach(element => {
          if(element.stateName == this.checkoutGroup.controls['state'].value){
            this.stateCode = element.stateCode;
          }
        });
      }
    })
  }

  submitSearch(){
    if (this.displayIdForm.controls['displayId'].value) {
      this.displayId = this.displayIdForm.controls['displayId'].value;
      this.memberService
        .getverifyMemberInfoDisplayId(this.displayIdForm.controls['displayId'].value)
        .subscribe((val) => {
          if (val.data) {
            this.searchMemberInformation = val.data;
            setTimeout(() => {
              this.allStatesData.forEach(element => {
                if(element.stateCode == this.searchMemberInformation.stateCode){
                  this.stateName =  element.stateName;
                }
              });
            }, 2000);
            this.stateCode = val.data.stateCode;
            this.validMemberSearch = true;
          } else {
              this.dangerSwal('Please enter desire Display ID');
              this.validMemberSearch = false;
          }
        });
    }

  }

  reset(){
    this.displayIdForm.reset();
    this.validMemberSearch = false;
  }

  qtyAdd(value:number,item:ItemsDetails){
    if (value > item.itemCount) {
      item.qty = 0;
      Swal.fire('',"You don't have enough stock purchase this item", 'error');
      return;
    }
    this.itemsTotalPrice = 0;
    this.bvTotal = 0;
    this.allItemsDetailsData.forEach(element => {
      if(element.itemId == item.itemId){
        element.qty = value;
        element.subTotal = value * element.distributorPrice;
        element.totalBV = value * element.bv;
      }
    });
    this.allItemsDetailsData.forEach(element => {
      this.itemsTotalPrice += element.subTotal;
    });

  }

  orderSummary(){
    this.allItemsDetailsDataConfirmation = [];
    this.confirmationTableShow = false;
    this.subTotalConfirmation = 0;
    this.bvTotalConfirmation = 0;
      this.allItemsDetailsData.forEach(element => {
      if(element.qty > 0){
        let price = element.qty * element.distributorPrice;
        let bv = element.qty * element.bv;
        this.subTotalConfirmation = this.subTotalConfirmation + price;
        this.bvTotalConfirmation = this.bvTotalConfirmation + bv;
        this.allItemsDetailsDataConfirmation.push(element);
      }
    });
    console.log(this.allItemsDetailsDataConfirmation);
    this.confirmationTableShow = true;
  }

  placeOrder(){
    this.activeButton = true;
    this.subTotal = 0;
    this.allItemsDetailsData.forEach(element => {
      if(element.qty > 0){
        let price = element.qty * element.distributorPrice;
          this.subTotal = this.subTotal + price;
          const temp = new PurchasedItems();
          temp.itemId = element.itemId;
          temp.qty = element.qty;
          this.purchasedItems.push(temp);
      }
    });
    if(this.isChecked){
      const purchase = new Purchase();
      purchase.displayId = parseInt(this.displayId);
      purchase.loginId = parseInt(this.userAuthService.getDisplayId());
      purchase.paymentMode = 'Wallet';
      purchase.enteredBy = this.userAuthService.getDisplayId();
      purchase.gstType = "IGST";
      purchase.noCartInvolved = true;
      purchase.isShippingSameAsCorrespondense = true;
      purchase.purchasedItems = this.purchasedItems;
      console.log(purchase);
      this.purchaseService.postPurchaseCartData(purchase).subscribe((val) => {
        if (val.errorCode) {
          this.activeButton = false;
          this.dangerSwal(val.message);
        } else {
          this.successSwal('Order successfully placed');
          this.purchasedItems = [];
          this.activeButton = false;
          this.itemsTotalPrice = 0;
          this.validMemberSearch = false;
        }
        this.stockService.getSTockBalance().subscribe((response: any) => {
          if (response.data) {
            this.isDataAvailable = this.allItemsDetailsData?.length > 0;
            this.noData = this.allItemsDetailsData?.length === 0;
            this.gridView = true;
            this.stockData = response.data;
    
            this.allItemsDetailsData.forEach(element => {
              const stock = this.stockData.find(stockItem => stockItem.itemId === element.itemId);
              element.qty = 0;
              element.itemCount = stock ? stock.quantity : 0;
              element.subTotal = 0;
            });
          } else {
            Swal.fire('Error', 'Error in fetching stock details', response.message);
          }
        });
        this.purchaseConfirmation.hide();
      });
    }else{
      const purchase = new Purchase();
      purchase.displayId = parseInt(this.displayId);
      purchase.loginId = parseInt(this.userAuthService.getDisplayId());
      purchase.paymentMode = 'Wallet';
      purchase.enteredBy = this.userAuthService.getDisplayId();
      purchase.totalPrice = this.subTotal;
      // purchase.isFirstPurchase = true;
      purchase.phNo = this.checkoutGroup.controls['phNo'].value?this.checkoutGroup.controls['phNo'].value:'';
      purchase.cityVillage = this.checkoutGroup.controls['cityVillage'].value?this.checkoutGroup.controls['cityVillage'].value:'';
      purchase.stateCode = this.stateCode;
      purchase.shippingAddress =((this.checkoutGroup.controls['address'].value == null) ? ' ' : this.checkoutGroup.controls['address'].value + ',') +
        ((this.checkoutGroup.controls['cityVillage'].value == null) ? ' ' : 'City/Village - ' + this.checkoutGroup.controls['cityVillage'].value + ',') +
        ((this.checkoutGroup.controls['state'].value == null) ? ' ' : 'State - ' + this.checkoutGroup.controls['state'].value + ',') +
        ((this.checkoutGroup.controls['pincode'].value == null) ? ' ' : 'Pincode - ' + this.checkoutGroup.controls['pincode'].value);
      purchase.gstType = "IGST";
      purchase.pinCode = this.checkoutGroup.controls['pincode'].value?this.checkoutGroup.controls['pincode'].value:'';
      purchase.noCartInvolved = true;
      purchase.isShippingSameAsCorrespondense = false;
      purchase.purchasedItems = this.purchasedItems;
      console.log(purchase);
    if(this.checkoutGroup.controls['state'].value){
      this.purchaseService.postPurchaseCartData(purchase).subscribe((val) => {
        if (val.errorCode) {
          this.activeButton = false;
          this.dangerSwal(val.message);
        } else {
          this.successSwal('Order successfully placed');
          this.purchasedItems = [];
          this.activeButton = false;
          this.itemsTotalPrice = 0;
          this.validMemberSearch = false;
        }
        this.stockService.getSTockBalance().subscribe((response: any) => {
          if (response.data) {
            this.isDataAvailable = this.allItemsDetailsData?.length > 0;
            this.noData = this.allItemsDetailsData?.length === 0;
            this.gridView = true;
            this.stockData = response.data;
    
            this.allItemsDetailsData.forEach(element => {
              const stock = this.stockData.find(stockItem => stockItem.itemId === element.itemId);
              element.qty = 0;
              element.itemCount = stock ? stock.quantity : 0;
              element.subTotal = 0;
            });
          } else {
            Swal.fire('Error', 'Error in fetching stock details', response.message);
          }
        });
        this.purchaseConfirmation.hide();
      });
    }else{
      this.activeButton = false;
      this.warningSwal("Please select a state from the list");
    }
    }
    this.purchaseConfirmation.hide();

  }

  show() {
    this.showModal = true;
  }

  hide() {
    this.showModal = false;
  }

  onCheckboxChange(event: any) {
    if (event.target.checked) {
      this.isChecked = true;
    } else {
      this.isChecked = false;
    }
  }
}
