export class TdsReportShowData {
  deducteeAadharNo: string;
  deducteeAddress: string;
  deducteeName: string;
  deducteePAN: string;
  deductorAddress: string;
  deductorName: string;
  deductorPAN: string;
  displayId: number;
  financialYear: string;
  natureOfPayment: string;
  netAmount: number;
  stateName: string;
  tdsAmount: number;
}

export class TdsReportTableData{
  displayId: number;
  deducteeName: string;
  deducteePAN: string;
  financialYear: string;
  stateName: string;
  netAmount: number;
  tdsAmount: number;
}
