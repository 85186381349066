import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CombinedCnfFranchiseGuard } from '../auth/guard/combined-cnf-franchise.guard';
import { AddStockComponent } from './add-stock/add-stock.component';
import { StockReportComponent } from './stock-report/stock-report.component';
import { StockRequestsComponent } from './stock-requests/stock-requests.component';
import { CombinedCnfAdminGuard } from '../auth/guard/combined-cnf-admin.guard';

const routes: Routes = [
  {
    path: 'add-stock',
    component: AddStockComponent,
    canActivate: [CombinedCnfFranchiseGuard],
  },
  {
    path: 'stock-report',
    component: StockReportComponent,
  },
  {
    path: 'stock-requests',
    component: StockRequestsComponent,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class StockRoutingModule { }
