<app-card [hidHeader]="true">
    <h5>Stock Entry</h5>
    <hr>
    <form [formGroup]="stockEntryGroup" class="form">
        <div class="app-modal-body">
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="d-block">Item Count</label>
                        <input type="text" class="form-control" formControlName="itemCount" placeholder="Item Count">
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="d-block">Item</label>
                        <select class="form-control" formControlName="itemId">
                        <option> </option>
               <option *ngFor="let items of allItemsData ">{{items.itemName}}</option>
            </select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="d-block">Price</label>
                        <input type="number" class="form-control" formControlName="price" placeholder="Price">
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="d-block">Unit</label>
                        <select class="form-control" formControlName="unitId">
                          <option> </option>
                 <option *ngFor="let unit of unitDetails">{{unit.unitName}}</option>
              </select>
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <div class="app-modal-footer ">
            <button type=" button " class="btn btn-danger " data-dismiss="modal" (click)="cancel()">Cancel</button>
            <button type="submit " class="btn btn-primary " (click)="submitStockEntry()">Submit</button>
        </div>
    </form>
</app-card>