<perfect-scrollbar *ngIf="this.flatConfig['layout'] === 'vertical'" (clickOutside)="navMob()" [excludeBeforeClick]="true" [exclude]="'#mobile-collapse'" id="nav-ps-flat-able" ngClass="flat-able-scroll" [style.max-width]="'300px'" [style.max-height]="'calc(100vh - 56px)'"
    [usePSClass]="'flat-able'" [disabled]="null">
    <div class="navbar-content">
        <div class="">
            <div class="main-menu-header">
                <img class="img-radius" src="{{(profilePicture?.filelocation)?profilePicture.filelocation:'assets/images/user/avatar-sample.jpg'}}">
                <div class="user-details" (click)="isNavProfile = !isNavProfile">
                    <span>{{userName}}</span>
                    <div id="more-details">{{userRole}}
                        <!-- <i class="fa m-l-5" [ngClass]="{'fa-chevron-up': isNavProfile, 'fa-chevron-down': !isNavProfile }"></i> -->
                    </div>
                </div>
            </div>
            <!-- <div class="collapse" id="nav-user-link" *ngIf="isNavProfile" [style.display]="'block'">
                <ul class="list-inline">
                    <li class="list-inline-item">
                        <img style="height: 40px; width: 40px;" src=" assets/images/wallet.png "> 5000
                    </li> -->
            <!-- <li class="list-inline-item "><a href="javascript: "><i class="feather icon-mail m-r-5 "></i><small class="badge badge-pill badge-primary ">5</small></a></li>
                    <li class="list-inline-item "><a href="javascript: " class="text-danger "><i class="feather icon-power m-r-5 "></i></a></li> -->
            <!-- </ul>
        </div> -->
        </div>
        <ul class="nav pcoded-inner-navbar " (clickOutside)="fireOutClick() ">
            <ng-container *ngFor="let item of navigation ">
                <app-nav-group *ngIf="item.type=='group' " [item]="item "></app-nav-group>
                <app-nav-collapse *ngIf="item.type=='collapse' " [item]="item "></app-nav-collapse>
                <app-nav-item *ngIf="item.type=='item' " [item]="item "></app-nav-item>
            </ng-container>
        </ul>
        <!-- <app-card [hidHeader]="true " cardClass="text-center ">
      <i class="feather icon-sunset f-40 "></i>
      <h6 class="mt-3 ">Help?</h6>
      <p>Please contact us on our email for need any support</p>
      <a href="javascript: " target="_blank " class="btn btn-primary btn-sm text-white m-0 ">Support</a>
    </app-card> -->
    </div>
</perfect-scrollbar>

<div *ngIf="this.flatConfig[ 'layout']==='horizontal' " #navbarWrapper class="navbar-content sidenav-horizontal " id="layout-sidenav ">
    <a href="javascript: " class="sidenav-horizontal-prev " [ngClass]="prevDisabled " (click)="scrollMinus() "></a>
    <div class="sidenav-horizontal-wrapper ">
        <!-- add 14-4 viral -->
        <ul #navbarContent id="side-nav-horizontal " class="nav pcoded-inner-navbar sidenav-inner " (clickOutside)="fireLeave() " (mouseleave)="fireLeave() ">
            <ng-container *ngFor="let item of navigation ">
                <app-nav-group *ngIf="item.type=='group' " [item]="item "></app-nav-group>
                <app-nav-collapse *ngIf="item.type=='collapse' " [item]="item "></app-nav-collapse>
                <app-nav-item *ngIf="item.type=='item' " [item]="item "></app-nav-item>
            </ng-container>
        </ul>
    </div>
    <a href="javascript: " class="sidenav-horizontal-next " [ngClass]="nextDisabled " (click)="scrollPlus() "></a>
</div>
