import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoggerService } from '../../../theme/shared/log/logger.service';
import { environment } from '../../../../environments/environment';
import { AbstractHttpService } from '../../../theme/shared/base/abstract-http';
import { AllCommissions, AllCommissionsDetails, CommissionGenrateDates, MakingPayAllCommissionsList, MakingPayUnpaidCommissionList, PaidCommissionTableData, PayAllUnpaidCommissionDataPost, PayUnpaidCommission, PayUnpaidCommissionDataPost, UnpaidCommissionDetails, UnpaidCommssionGenerateData } from '../model/view-commission.model';

@Injectable({
  providedIn: 'root'
})
export class ViewCommissionService extends AbstractHttpService {
  private _commissionpaymentinbank : string = environment.baseUrl + "ext/api/v1/report/commissionpaymentinbank";
  private _commissionpaymentinwallet : string = environment.baseUrl + "ext/api/v1/report/commissionpaymentinwallet";
  private _getcommissiongenerateddates : string = environment.baseUrl + "ext/api/v1/getcommissiongenerateddates";
  private _generatedcommission  : string = environment.baseUrl + "ext/api/v1/generatedcommission/";
  private _getunpaidcommissiondetails : string = environment.baseUrl + "ext/api/v1/getunpaidcommissiondetails";
  private _payunpaidcommission : string = environment.baseUrl + "ext/api/v1/payunpaidcommission";
  private _payallunpaidcommission : string = environment.baseUrl + "ext/api/v1/payallunpaidcommission";
  private _unpaidcommissionuser : string = environment.baseUrl + "ext/api/v1/showcommission?commissionType=BimonthlyCommission";
  private _getallunpaidcommissiondetails : string = environment.baseUrl + "ext/api/v1/getallunpaidcommissiondetails";
  private _viewallunpaidcommissiondetails : string = environment.baseUrl + "ext/api/v1/viewallunpaidcommissiondetails";
  private _paybulkunpaidcommission : string = environment.baseUrl + "ext/api/v1/paybulkunpaidcommission";
  private _getallpaidcommissiondetails : string = environment.baseUrl + "ext/api/v1/getallpaidcommissiondetails";
  private _getAllCommissionByDate : string = environment.baseUrl + "ext/api/v1/allCommissionsForTds";


  constructor(
    protected  http: HttpClient,
    protected  logger: LoggerService
    ) {
    super('ViewCommissionService', http, logger);
  };

  getCommissionPaymentInBankData(displayId: string,orderDateFrom: string,orderDateTo: string): Observable<any>{
    const url = this._commissionpaymentinbank + `?displayid=${displayId}` + `&fromdate=${orderDateFrom}` + `&todate=${orderDateTo}`;
    return this.http.get<any>(url);
  }

  getCommissionPaymentInWalletData(displayId: string,dateFrom: string,dateTo: string): Observable<any>{
    const url = this._commissionpaymentinwallet + `?displayid=${displayId}` + `&fromdate=${dateFrom}` + `&todate=${dateTo}`;
    return this.http.get<any>(url);
  }

  getCommissionGenerateDateData(ismonthly: string): Observable<any>{
    const url = this._getcommissiongenerateddates + `?ismonthly=${ismonthly}`;
    return this.http.get<CommissionGenrateDates>(url);
  }

  getUnpaidCommissionData (dateFrom: string,dateTo: string,ismonthly: string): Observable<any>{
    const url = this._generatedcommission + `${dateFrom}` + '/' + `${dateTo}`+ '/' + `${ismonthly}`;
    return this.http.get<UnpaidCommssionGenerateData[]>(url);
  }

  getUnpaidCommissionDetailsData(displayId: string,dateFrom: string,dateTo: string,ismonthly: string): Observable<any>{
    const url = this._getunpaidcommissiondetails + `?displayid=${displayId}` + `&fromdate=${dateFrom}` + `&todate=${dateTo}` + `&ismonthly=${ismonthly}`;
    return this.http.get<UnpaidCommissionDetails>(url);
  }

  postUnpaidCommissionData(unpaidCommissionPostForm: PayUnpaidCommissionDataPost,ismonthly: string) : Observable<any>{
    const commissiontaymentdtoslist = new MakingPayUnpaidCommissionList();
    commissiontaymentdtoslist.commissiontaymentdtos = unpaidCommissionPostForm.payUnpaidCommission;
    const url = this._payunpaidcommission + `?ismonthly=${ismonthly}`;
    return this.http.post<any>(url ,commissiontaymentdtoslist);
  }

  postAllUnpaidCommissionData(unpaidCommissionPostForm: PayUnpaidCommissionDataPost) : Observable<any>{
    const commissiontaymentdtoslist = new MakingPayUnpaidCommissionList();
    commissiontaymentdtoslist.commissiontaymentdtos = unpaidCommissionPostForm.payUnpaidCommission;
    return this.http.post<any>(this._payallunpaidcommission ,commissiontaymentdtoslist);
  }

  getUnpaidCommissionUser(): Observable<any>{
    return this.http.get<any>(this._unpaidcommissionuser);
  }

  getAllUnpaidCommissionDetailsData(displayId: string,dateFrom: string,dateTo: string): Observable<any>{
    const url = this._getunpaidcommissiondetails + `?displayid=${displayId}` + `&fromdate=${dateFrom}` + `&todate=${dateTo}`;
    return this.http.get<UnpaidCommissionDetails>(url);
  }

  getAllCommissionData(dateFrom: string,dateTo: string,displayIdArray: number[], isChecked:boolean): Observable<any>{
    const url = this._getallunpaidcommissiondetails  + `?fromdate=${dateFrom}` + `&todate=${dateTo}` + `&displayid=${displayIdArray}` + `&isFiltered=${isChecked}`;
    return this.http.get<AllCommissions[]>(url);
  }


  getAllCommissionByDate(month: number,year: number): Observable<any>{
    const url = this._getAllCommissionByDate  + `?month=${month}` + `&year=${year}`;
    return this.http.get<AllCommissions[]>(url);
  }

  getAllCommissionDetailsData(dateFrom: string,dateTo: string,displayId: number): Observable<any>{
    const url = this._viewallunpaidcommissiondetails  + `?fromdate=${dateFrom}` + `&todate=${dateTo}` + `&displayid=${displayId}`;
    return this.http.get<AllCommissionsDetails[]>(url);
  }

  postAllCommissionsData(unpaidCommissionPostForm: PayAllUnpaidCommissionDataPost,iswallet: boolean) : Observable<any>{
    const paymentRequestDetailsList = new MakingPayAllCommissionsList();
    paymentRequestDetailsList.paymentRequestDetails = unpaidCommissionPostForm.paymentRequestDetails;
    const url = this._paybulkunpaidcommission  + `?iswallet=${iswallet}`;
    return this.http.post<any>(url,paymentRequestDetailsList);
  }

  getAllPaidCommissionDetailsData(displayid: string,fromdate: string,todate: string,payfromdate: string,paytodate: string): Observable<any>{
    const url = this._getallpaidcommissiondetails  + `?displayid=${displayid}` + `&fromdate=${fromdate}` + `&todate=${todate}` + `&payfromdate=${payfromdate}` + `&paytodate=${paytodate}`;
    return this.http.get<PaidCommissionTableData[]>(url);
  }

}
