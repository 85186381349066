<ng-container *ngIf="!loading; else elseTemplate">
    <app-card [hidHeader]="true">
        <h5>Recharge</h5>
        <hr>
        <form [formGroup]="rechargeRequest" class="form">
            <div class="app-modal-body">
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label class="d-block">Connection Type <span style="color: red;">*</span></label>
                            <select class="form-control" formControlName="connectionType">
                      <option></option>
                      <option *ngFor="let connectionType of allConnectionTypeData ">{{connectionType.name}}</option>
                  </select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label class="d-block">Service Provider (Choose correct recharge type) <span style="color: red;">*</span></label>
                            <select class="form-control" formControlName="serviceProvider">
                              <option></option>
                              <option *ngFor="let serviceProvider of allServiceProviderData ">{{serviceProvider.name}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <!-- <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label class="d-block">Recharge Type <span style="color: red;">*</span></label>
                            <select class="form-control" formControlName="rechargeType">
                              <option></option>
                              <option *ngFor="let rechargeType of allRechargeTypeData ">{{rechargeType.name}}</option>
                            </select>
                        </div>
                    </div>
                </div> -->
                <div class="row">
                    <div class="col-sm-1" *ngIf="this.rechargeRequest.controls['connectionType'].value == 'DTH'">
                        <label class="d-block" *ngIf="this.rechargeRequest.controls[ 'connectionType'].value=='DTH' ">DTH ID <span style="color: red; ">*</span></label>
                        <div class="form-group ">
                            <input type="string" class="form-control" formControlName="vc" ngModel="VC" readonly>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label *ngIf="this.rechargeRequest.controls[ 'connectionType'].value=='DTH' "><br></label>
                            <label class="d-block" *ngIf="this.rechargeRequest.controls[ 'connectionType'].value !='DTH' ">Phone No. <span style="color: red; ">*</span></label>
                            <input type="number" onkeypress="return event.charCode>= 48 && event.charCode<=57" ondragstart="return false;" ondrop="return false;" class="form-control" formControlName="mobileNo">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label class="d-block">Amount <span style="color: red; ">*</span></label>
                            <input type="number" onkeypress="return event.charCode>= 48 && event.charCode<=57" ondragstart="return false;" ondrop="return false;" class="form-control " formControlName="amount">
                        </div>
                    </div>
                </div>
            </div>
            <hr>
            <div class="app-modal-footer ">
                <button type="button " class="btn btn-danger " data-dismiss="modal " (click)="cancel() ">Cancel</button>
                <button type="submit " class="btn btn-primary " (click)="submitRecharge() ">Submit</button>
            </div>
        </form>
    </app-card>
</ng-container>
<ng-template #elseTemplate>
    <div class="spinner ">
        <h5>Loading...</h5>
        <div class="bounce1 "></div>
        <div class="bounce2 "></div>
        <div class="bounce3 "></div>
    </div>
</ng-template>