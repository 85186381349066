<div class="auth-wrapper img-fluid align-items-stretch aut-bg-img">
    <div class="flex-grow-1">
        <div class="auth-side-form">
            <div class="auth-content">
                <img src="assets/images/logo-dark.png" alt="" class="img-fluid mb-4">
                <form class="login-form" [formGroup]="formGroup" (ngSubmit)="login()">
                    <div class="input-group mb-3">
                        <input type="text" class="form-control" placeholder="Username" formControlName="username">
                    </div>
                    <div class="input-group mb-4">
                        <input [type]="fieldTextType ? 'text' : 'password'" class="form-control" placeholder="Password" formControlName="password">
                        <span class="input-group-text">
                          <i
                            class="fa"
                            [ngClass]="{
                              'fa-eye-slash': !fieldTextType,
                              'fa-eye': fieldTextType
                            }"
                            (click)="toggleFieldTextType()"
                          ></i>
                        </span>
                    </div>
                    <button class=" btn btn-block btn-success mb-0 " type="submit">Sign In</button>
                </form>
                <div class="text-center">
                    <br>
                    <button class="btn btn-block btn-danger mb-0 " (click)="registrationModalLong.show()">Don’t have an account? Sign Up</button>
                    <br>
                    <button class="btn btn-secondary" (click)="forgotPasswordModalLong.show()">Forgot Password</button>
                    <br><br>
                    <a class="btn btn-block btn-warning" style="color: white;" [routerLink]="['/auth/franchise-signin']">Franchise / CNF Log In</a>
                    <br>
                    <a class="btn btn-primary" style="color: white;" href="https://vedikmart.com" target="_blank">Home</a>
                    <div class="exciting-offers-marquee">
                        <span>Exciting Offers</span>
                    </div>
                    <!-- <div class="d-flex justify-content-center">
                        <img src="https://s3.ap-south-1.amazonaws.com/publicimages.vedikmart.com/offer-banner.jpg" 
                            alt="Offer Banner" 
                            class="img-fluid rounded shadow-lg">
                    </div> -->
                    <div class="d-flex justify-content-center">
                        <video id="offerVideo" class="img-fluid rounded shadow-lg" autoplay muted loop playsinline>
                            <source src="assets/videos/offervideo.mp4" type="video/mp4">
                            Your browser does not support the video tag.
                        </video>
                    </div>                                
                </div>
            </div>
        </div>
    </div>
</div>

<app-ui-modal #registrationModalLong [dialogClass]="'modal-xl'">
    <div class="app-modal-header">
        <h5>Add Member</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close" (click)="registrationModalLong.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
        <form [formGroup]="memberGroup" class="form">
            <div class="app-modal-body">
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label class="d-block">Sponsor ID <span style="color: red;">*</span></label>
                            <input type="number" class="form-control" formControlName="sponsorId" placeholder="Sponsor ID" (keyup)="checkSponsorID($event)">
                            <p style="color: red;">{{showMessageSponsorID}}</p>
                            <div style="color: blue;" *ngIf="sponsorName">Name - <strong>{{ sponsorName }}</strong></div>
                        </div>
                    </div>
                    
                    
                </div>
                
                <div *ngIf="!gridView">
                    <div class=" row">
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="d-block">Position : <span style="color: red;">*</span></label>
                                <select class="form-control" formControlName="position">
                              <option> </option>
                          <option *ngFor="let pos of positionReg">{{pos}}</option>
                        </select>
                            </div>
                        </div>
                        
                    </div>
                    <div class="row ">
                        <div class="col-sm-4 ">
                            <div class="form-group ">
                                <label class="d-block">Name <span style="color: red;">*</span></label>
                                <input type="text" class="form-control" formControlName="name" placeholder="Name">
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="d-block ">Phone Number <span style="color: red;">*</span></label>
                                <input type="number" class="form-control" formControlName="phno" placeholder="Phone Number">
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="d-block ">Email</label>
                                <input type="email" class="form-control" formControlName="email" placeholder="Email">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="d-block">Pincode</label>
                                <input type="number" class="form-control" formControlName="pincode" placeholder="Pincode">
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="d-block">State <span style="color: red;">*</span></label>
                                <select class="form-control" formControlName="state">
                              <option> </option>
                     <option *ngFor="let states of allStatesData " value="{{states.stateCode}}">{{states.stateName}}</option>
                  </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="app-modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="registrationModalLong.hide()">Cancel</button>
                <button type="submit" *ngIf="!gridView " class="btn btn-primary" [disabled]="isSubmitting" (click)="submitMember()">Submit</button>
            </div>
        </form>

    </div>
</app-ui-modal>
<app-ui-modal #forgotPasswordModalLong [dialogClass]="'modal-md'">
    <div class="app-modal-header">
        <h5>Forgot Password</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close" (click)="forgotPasswordModalLong.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
        <form [formGroup]="forgotPasswordGroup" class="form">
            <div class="app-modal-body">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label class="d-block">Display ID <span style="color: red;">*</span></label>
                        <input type="number" class="form-control" formControlName="displayId" placeholder="Display ID">
                    </div>
                </div>
                <div class="col-sm-12">
                    <div class="form-group">
                        <label class="d-block">Last 4 Digits of Phone Number <span style="color: red;">*</span></label>
                        <input type="string" class="form-control" formControlName="phno" placeholder="Please enter (Like: XXXXXX1234)">
                    </div>
                </div>
            </div>
            <div class="app-modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal " (click)="forgotPasswordModalLong.hide() ">Cancel</button>
                <button type="submit" class="btn btn-primary " (click)="submitForgotPassword()">Submit</button>
            </div>
        </form>
    </div>
</app-ui-modal>