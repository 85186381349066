<div class="row">
    <div class="col-sm-12">
        <app-card cardTitle="Downline Purchase" [options]="false">
            <form [formGroup]="FilterFormGroup" class="form">
                <div class="app-modal-body">
                    <div class="row">
                        <div class="col-sm-8">
                            <div class="form-group">
                                <label class="d-block">Display Id</label>
                                <input type="number" class="form-control" [ngModel]="displayId" formControlName="displayId" placeholder="Display Id">
                            </div>
                        </div>
                    </div>
                </div>
                <hr>
                <div class="app-modal-footer ">
                    <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="reset()">Cancel</button>
                    <button type="submit" class="btn btn-primary" (click)="search()">Search</button>
                </div>
            </form>
        </app-card>
    </div>
</div>