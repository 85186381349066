import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MembersService } from '../../services/members.service';
import { AllStates } from '../../model/allstates.model';
import {
  MemberRegistration,
  MemberRegistrationDetailsBySponsorID,
} from '../../model/member-registration.model';
import { DownlineService } from 'src/app/mlm/report/services/downline.service';
import {
  DownlineReportFilter,
  DownlineReportsTableData,
} from 'src/app/mlm/report/model/downline-reports-table.model';
import { UserAuthService } from 'src/app/mlm/auth/services/user-auth.service';
import { Router } from '@angular/router';
import { DownlineSubmit } from '../../model/details-displayid.model';
import { CommonComponent } from 'src/app/theme/shared/common/common.component';

@Component({
  selector: 'app-downline-member',
  templateUrl: './downline-member.component.html',
  styleUrls: ['./downline-member.component.scss'],
})
export class DownlineMemberComponent extends CommonComponent implements OnInit {
  showModal: boolean = false;
  sponsorName: string;
  isSubmitting: boolean = false;
  memberGroup: FormGroup;
  downlineForm: FormGroup;
  searchForm: FormGroup;
  activeInactiveForm: FormGroup;
  positionValue: string;
  allStatesData: AllStates[] = [];
  allMemberDataBySponsorID: MemberRegistrationDetailsBySponsorID[] = [];
  showMessageSponsorID: string = '';
  showMessageParentID: string = '';
  showMessageDisplayID: string = '';
  showMessageUserID: string = '';
  showMessagePhoneNumber: string = '';
  gridView: boolean = false;
  columns = [
    'Downline ID',
    'Downline Name',
    'Position Vacant',
    'Position Eligible',
    'Is Active',
    'Is Green',
    'Contact',
    'Action',
  ];
  index = [
    'displayId',
    'name',
    'positionVacant',
    'positionEligible',
    'isActive',
    'isGreen',
    'phNo',
    'action',
  ];
  isCheckedLeft: boolean = false;
  isCheckedRight: boolean = false;
  isCheckedBoth: boolean = false;

  isAdmin: boolean = false;
  downlineReportsTableData: DownlineReportsTableData[] = [];

  displayId: number;
  userName: string = '';

  loading: boolean = false;
  isDataAvailable: boolean = false;
  viewFilter: boolean = false;
  position = ['Left', 'Right'];
  searchList: DownlineReportsTableData[] = [];

  constructor(
    private memberService: MembersService,
    public fb: FormBuilder,
    private downlineService: DownlineService,
    private userAuthService: UserAuthService,
    private router: Router
  ) {
    super();
    this.memberGroup = this.fb.group({
      sponsorId: new FormControl('', [Validators.required]),
      parentId: new FormControl('', [Validators.required]),
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required]),
      phno: new FormControl('', [Validators.required]),
      pincode: new FormControl('', [Validators.required]),
      // userId: new FormControl('', [Validators.required]),
      position: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
    });

    this.searchForm = this.fb.group({
      search: new FormControl('', [Validators.required]),
    });

    this.downlineForm = this.fb.group({
      displayId: new FormControl('', [Validators.required]),
      viewUpline: new FormControl('', [Validators.required]),
      viewDownline: new FormControl('', [Validators.required]),
    });

    this.activeInactiveForm = this.fb.group({
      displayId: new FormControl('', [Validators.required]),
      isActive: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.displayId = parseInt(this.userAuthService.getDisplayId());

    this.memberService.getAllStatesData().subscribe((value) => {
      this.allStatesData = value.data;
    });

    if (this.userAuthService.getRoles() === 'ADMIN') {
      this.isAdmin = true;
    }

    this.userName = this.userAuthService.getUserName().toUpperCase();
  }

  downlineMembers() {
    this.loading = true;
    this.viewFilter = false;
    const downlineReportFilterData = new DownlineReportFilter();
    downlineReportFilterData.displayId = this.displayId.toString();
    this.downlineService
      .getDownlineTableData(downlineReportFilterData)
      .subscribe((value) => {
        this.downlineReportsTableData = value.data.downlineReports;
        this.isDataAvailable = this.downlineReportsTableData?.length > 0;
        this.loading = false;
        this.viewFilter = true;
      });
  }

  submitMember() {
    if (this.isSubmitting) {
      return;
    }
    this.isSubmitting = true;
    console.log(this.memberGroup.value);
    const memberPostForm = new MemberRegistration();
    memberPostForm.sponsorID = this.memberGroup.controls['sponsorId'].value;
    memberPostForm.parentID = this.memberGroup.controls['sponsorId'].value;
    memberPostForm.name = this.memberGroup.controls['name'].value;
    memberPostForm.emailId = this.memberGroup.controls['email'].value;
    memberPostForm.phNo = this.memberGroup.controls['phno'].value;
    memberPostForm.pinCode = this.memberGroup.controls['pincode'].value;
    // memberPostForm.userName = this.memberGroup.controls['userId'].value;
    memberPostForm.isleft =
      this.memberGroup.controls['position'].value == 'Left' ? true : false;
    memberPostForm.stateCode = this.memberGroup.controls['state'].value;

    if (
      this.showMessageSponsorID.length == 0 &&
      this.memberGroup.controls['sponsorId'].value
    ) {
      // if (
      //   this.showMessageParentID.length == 0 &&
      //   this.memberGroup.controls['parentId'].value
      // ) {
        this.memberService
          .postRegistrationData(memberPostForm)
          .subscribe((val) => {
            if (val.data) {
              console.log('Data Updated');
              const name = val.data.name;
              const displayId = val.data.displayId;
              const msg =
                'Hi' +
                name +
                'Welcome to Vedikmart\n' +
                'Your ID is :' +
                displayId;
              this.successSwal(msg);
              // alert('Registration completed successfully!');
              this.memberGroup.reset();
              this.isSubmitting = false;
            } else {
              if (val.message) {
                this.warningSwal(val.message);
              } else {
                this.dangerSwal('Please fill the mandatory fields');
                // alert("Please fill the mandatory fields");
              }
              this.isSubmitting = false;
            }
          });
      // } else {
      //   this.dangerSwal('Please enter desire Parent ID');
      //   // alert("Please enter desire Parent ID");
      // }
    } else {
      this.dangerSwal('Please enter desire Sponsor ID');
      this.isSubmitting = false;
      // alert("Please enter desire Sponsor ID");
    }
  }

  show() {
    this.showModal = true;
  }

  hide() {
    this.showModal = false;
  }

  checkSponsorID(event: any) {
    this.sponsorName = '';
    let sponsorId = this.memberGroup.controls['sponsorId'].value;
    // if(displayId){
    //   this.memberService.getverifyMemberInfoDisplayId(displayId).subscribe((val) => {
    //     if (val.data) {
    //       console.log("matched");
    //       this.showMessageSponsorID ='';
    //       this.memberService.getRgistrationEligibleSponsorOrg(displayId).subscribe((value) => {
    //         this.gridView = true;
    //         this.allMemberDataBySponsorID = value.data;
    //         console.log(this.allMemberDataBySponsorID);
    //       });
    //     }
    //     else{
    //       this.showMessageSponsorID = "Please enter desire Sponsor ID";
    //       this.gridView = false;
    //     }
    //   });
    // }
    if (sponsorId) {
      this.memberService
        .getverifyMemberInfoDisplayId(sponsorId)
        .subscribe((val) => {
          if (val.data) {
            console.log('matched');
            this.showMessageSponsorID = '';
            this.sponsorName = val.data.name;
          } else {
            this.showMessageSponsorID = 'Please enter desire Sponsor ID';
          }
        });
    }
  }

  checkParentID(event: any) {
    let parentId = this.memberGroup.controls['parentId'].value;
    if (parentId) {
      this.memberService
        .getverifyMemberInfoDisplayId(parentId)
        .subscribe((val) => {
          if (val.data) {
            console.log('matched');
            this.showMessageParentID = '';
          } else {
            this.showMessageParentID = 'Please enter desire Parent ID';
          }
        });
    }
  }

  checkUserID(event: any) {
    let userId = this.memberGroup.controls['userId'].value;
    if (userId) {
      this.memberService
        .getverifyMemberInfoNameDuplication(userId)
        .subscribe((val) => {
          if (val.data) {
            console.log('matched');
            this.showMessageUserID = 'User ID already exists';
          } else {
            this.showMessageUserID = '';
          }
        });
    }
  }

  checkPhoneNumber(event: any) {
    let displayId = this.memberGroup.controls['sponsorId'].value;
    let phno = this.memberGroup.controls['phno'].value;
    if (phno) {
      this.memberService
        .getverifyMemberMobileNumber(displayId, phno)
        .subscribe((val) => {
          if (val.message == null) {
            console.log('not matched');
            this.showMessagePhoneNumber = '';
          } else {
            this.showMessagePhoneNumber = 'Please enter other Phone Number';
          }
        });
    }
  }

  getRowData(reports: any) {
    this.gridView = false;
    this.memberGroup.controls['parentId'].setValue(reports.displayId);
    if (reports.positionVacant == 'Left') {
      this.isCheckedLeft = true;
      this.isCheckedRight = false;
      this.isCheckedBoth = false;
    } else if (reports.positionVacant == 'Right') {
      this.isCheckedLeft = false;
      this.isCheckedRight = true;
      this.isCheckedBoth = false;
    } else {
      this.isCheckedLeft = false;
      this.isCheckedRight = false;
      this.isCheckedBoth = true;
    }

    if (reports.positionEligible === 'Left') {
      this.memberGroup.controls['position'].setValue('Left');
    } else if (reports.positionEligible === 'Right') {
      this.memberGroup.controls['position'].setValue('Right');
    }
  }

  submitSearch() {
    const temp = this.searchForm.controls['search'].value.toLowerCase();
    const downlineReportFilterData = new DownlineReportFilter();
    downlineReportFilterData.displayId = this.displayId.toString();
    this.searchList = this.downlineReportsTableData;
    this.downlineReportsTableData = [];
    this.loading = true;
    this.searchList.forEach((element) => {
      if (!element.name.toLowerCase().search(temp)) {
        this.downlineReportsTableData.push(element);
      }
    });
    this.loading = false;
  }

  reset() {
    this.searchForm.reset();
    this.loading = true;
    this.downlineReportsTableData=this.searchList;
    this.loading = false;
  }

  activeInactive(event: any, displayId: any) {
    if (event.target.checked) {
      this.activeInactiveForm.controls['displayId'].setValue(displayId);
      this.activeInactiveForm.controls['isActive'].setValue(1);
      this.memberService
        .postActiveInactive(this.activeInactiveForm.value)
        .subscribe((val) => {
          if (val) {
            console.log('Active');
          }
        });
    } else {
      this.activeInactiveForm.controls['displayId'].setValue(displayId);
      this.activeInactiveForm.controls['isActive'].setValue(0);
      this.memberService
        .postActiveInactive(this.activeInactiveForm.value)
        .subscribe((val) => {
          if (val) {
            console.log('InActive');
          }
        });
    }
    window.location.reload();
  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
  }

  shop() {
    this.router.navigate(['/home/purchase/shop']);
  }

  submitDownline() {
    if(this.downlineForm.controls['displayId'].value){
      this.loading = true;
      this.viewFilter = false;
      const submitDownline = new DownlineSubmit();
      submitDownline.displayId = this.downlineForm.controls['displayId'].value;
      submitDownline.viewUpline = this.downlineForm.controls['viewUpline'].value
        ? true
        : false;
      submitDownline.viewDownline = this.downlineForm.controls['viewDownline']
        .value
        ? true
        : false;
      console.log('Submit Downline', submitDownline);
      this.memberService
        .getDownlineMemberSearch(submitDownline)
        .subscribe((val) => {
          if (val) {
            this.downlineReportsTableData = val.data;
            this.isDataAvailable = true;
            this.loading = false;
            this.viewFilter = true;
          } else {
            this.dangerSwal('Please provide correct Downline ID');
            this.loading = false;
          }
        });
    }else{
      this.warningSwal("Please give a Display ID");
    }
  }

  resetDownline() {
    this.downlineForm.reset();
    this.loading = false;
    this.viewFilter = false;
  }
}
