import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserAuthService } from 'src/app/mlm/auth/services/user-auth.service';
import { ViewCommissionService } from '../../services/view-commission.service';
import { BankCommissionReport } from '../../model/view-commission.model';
import { CommonComponent } from 'src/app/theme/shared/common/common.component';

@Component({
  selector: 'app-bank-list',
  templateUrl: './bank-list.component.html',
  styleUrls: ['./bank-list.component.scss'],
  providers: [DatePipe]
})
export class BankListComponent extends CommonComponent implements OnInit {
  bankListGroup: FormGroup;
  date: string;
  myDate = new Date();
  displayId: string = '';
  gridView: boolean = false;
  bankCommissionReport: BankCommissionReport[] = [];
  columns = [
  'Display ID',
  'Name',
  'Admin Charge Amount',
  'Net Amount',
  'TDS Amount',
  'Total Amount',
  ];
  isAdmin: boolean = false;

  constructor(
    private fb: FormBuilder,
    private userAuthService: UserAuthService,
    private datePipe: DatePipe,
    private viewCommissionService: ViewCommissionService
  ) {
    super();
    this.bankListGroup = this.fb.group({
      displayId: new FormControl('', [Validators.required]),
      dateFrom: new FormControl('', [Validators.required]),
      dateTo: new FormControl('', [Validators.required])
    });
    this.date = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
  }

  ngOnInit(): void {
    this.displayId = this.userAuthService.getDisplayId();
    this.isAdmin = this.userAuthService.getRoles()==='ADMIN'?true:false;
  }

  submitBankList(){
    this.viewCommissionService.getCommissionPaymentInBankData(this.bankListGroup.controls['displayId'].value,this.bankListGroup.controls['dateFrom'].value,this.bankListGroup.controls['dateTo'].value).subscribe((val)=>{
      if(val){
        this.bankCommissionReport = val.data;
        this.gridView = true;
      }
    })
  }

  cancel(){

  }

}
