import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminRoleGuard } from '../auth/guard/admin-role.guard';
import { AuthGuard } from '../auth/guard/auth.guard';
import { FundAddComponent } from './components/fund-add/fund-add.component';
import { FundRequestsComponent } from './components/fund-requests/fund-requests.component';
import { FundTransferReportComponent } from './components/fund-transfer-report/fund-transfer-report.component';
import { OnlinePaymentReportComponent } from './components/online-payment-report/online-payment-report.component';
import { TransactionReportComponent } from './components/transaction-report/transaction-report.component';
import { FranchiseBalanceRequestsComponent } from './components/franchise-balance-requests/franchise-balance-requests.component';
import { BalanceRequestStatusComponent } from './components/balance-request-status/balance-request-status.component';
import { FranchiseRoleGuard } from '../auth/guard/franchise-role.guard';
import { CnfRoleGuard } from '../auth/guard/cnf-role.guard';
import { CnfBalanceRequestsComponent } from './components/cnf-balance-requests/cnf-balance-requests.component';
import { CombinedCnfFranchiseGuard } from '../auth/guard/combined-cnf-franchise.guard';
import { MemberWalletBalanceComponent } from './components/member-wallet-balance/member-wallet-balance.component';


const routes: Routes = [
  {
    path: 'fund-add',
    component: FundAddComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'fund-transfer-report',
    component: FundTransferReportComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'online-payment-report',
    component: OnlinePaymentReportComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'transaction-report',
    component: TransactionReportComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'fund-requests',
    component: FundRequestsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'franchise-balance-requests',
    component: FranchiseBalanceRequestsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'cnf-balance-requests',
    component: CnfBalanceRequestsComponent,
    canActivate: [AdminRoleGuard],
  },
  {
    path: 'balance-request-status',
    component: BalanceRequestStatusComponent,
    canActivate: [CombinedCnfFranchiseGuard],
  },
  {
    path: 'balance-request-status',
    component: BalanceRequestStatusComponent,
    canActivate: [CombinedCnfFranchiseGuard],
  },
  {
    path: 'member-wallet-balance',
    component: MemberWalletBalanceComponent,
    canActivate: [AdminRoleGuard],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class WalletRoutingModule {}
