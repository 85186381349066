import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminRoleGuard } from '../auth/guard/admin-role.guard';
import { AddCnfComponent } from '../cnf/components/add-cnf/add-cnf.component';
import { CnfMembersComponent } from '../cnf/components/cnf-members/cnf-members.component';
import { CnfStockRequestComponent } from './components/cnf-stock-request/cnf-stock-request.component';
import { CombinedCnfAdminGuard } from '../auth/guard/combined-cnf-admin.guard';

const routes: Routes = [
  {
    path: 'add-cnf',
    component: AddCnfComponent,
    canActivate: [AdminRoleGuard],
  },
  {
    path: 'cnf-members',
    component: CnfMembersComponent,
    canActivate: [AdminRoleGuard],
  },
  {
    path: 'cnf-stock-request',
    component: CnfStockRequestComponent,
    canActivate: [CombinedCnfAdminGuard],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CnfRoutingModule {}
