<div class="row btn-page" *ngIf="!noData">
    <div class="col-sm-12">
        <app-card cardTitle="Total Commission Summary for {{displayId}}" [options]="false" *ngIf="gridView">
            <div class="row">
                <div class="col-md-4 col-xs-6 border-right">
                    <h3>{{totalIncome.toFixed(2)}}</h3>
                    <span>Total Income</span>
                </div>
                <div class="col-md-4 col-xs-6 border-right">
                    <h3>{{totalUnpaid.toFixed(2)}}</h3>
                    <span class="text-danger">Total Unpaid</span>
                </div>
                <div class="col-md-4 col-xs-6 border-right">
                    <h3>{{totalPaid.toFixed(2)}}</h3>
                    <span class="text-success">Total Paid</span>
                </div>
            </div>
        </app-card>
    </div>
</div>

<div class="col-sm-12" *ngIf="isAdmin">
    <app-card [hidHeader]="true">
        <div class="row align-items-center m-l-0">
            <div class="col-sm-6 text-left">
                <h5>View Commission Search</h5>
            </div>
        </div>
        <form [formGroup]="displayIdForm" class="form">
            <div class="app-modal-body" class="col-sm-8 text-left">
                <!-- <div class="row"> -->
                <div>
                    <div class="form-group">
                        <label class="d-block">Display Id</label>
                        <input type="text" class="form-control" [(ngModel)]="displayId" formControlName="displayId" placeholder="Display Id">
                    </div>
                    <button type="submit" class="btn btn-primary" (click)="submitSearch()">Search</button>
                    <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="reset()">Cancel</button>
                </div>
                <!-- </div> -->
            </div>
        </form>
    </app-card>
</div>

<ng-container *ngIf="!loading; then thenTemplate; else elseTemplate"></ng-container>
<ng-template #thenTemplate>
    <ng-container *ngIf="isDataAvailable; else elseTemplate">
        <div class="row btn-page" *ngIf="gridView">
            <div class="col-sm-12 btn-page">
                <app-card [hidHeader]="true" cardClass="card-datatable" cardTitle="View Commission">
                    <div class="col-sm-12 text-right">
                        <button type="button" class="btn btn-primary btn-sm" (click)="exportexcel()">Export to Excel</button>
                    </div>
                    <div class="table-responsive">
                        <table datatable id="excel-table" class="table table-striped table-hover mb-0 table-responsive">
                            <thead>
                                <tr>
                                    <th *ngFor="let col of columns">
                                        {{col}}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of viewCommissionTableData; let i=index; ">
                                    <th scope="row ">{{ data?.displayId }}</th>
                                    <th scope="row ">{{ data?.fromDate }}</th>
                                    <th scope="row ">{{ data?.toDate }}</th>
                                    <th scope="row ">{{ data?.commissionType }}</th>
                                    <th scope="row ">{{ data?.purchaseType }}</th>
                                    <th scope="row ">{{ data?.commissionAmount }}</th>
                                    <th scope="row ">{{ data?.paymentStatus }}</th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </app-card>
            </div>
        </div>
    </ng-container>
    <ng-template #elseTemplate>
        <div class="spinner" *ngIf="!noData">
            <h5>Loading...</h5>
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
        </div>
        <div *ngIf="noData">
            <h1 style="text-align: center;">No Data Available</h1>
        </div>
    </ng-template>
</ng-template>
<ng-template #elseTemplate>
</ng-template>