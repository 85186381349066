<div *ngIf="isAdmin">
    <app-card [hidHeader]="true">
        <h5>Bank List Search</h5>
        <hr>
        <form [formGroup]="bankListGroup" class="form">
            <div class="app-modal-body">
                <div class="row">
                    <div class="col-sm-4">
                        <div class="form-group">
                            <label class="d-block ">Display Id</label>
                            <input type="number" [(ngModel)]="displayId" class="form-control" formControlName="displayId">
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group">
                            <label class="d-block ">From Date</label>
                            <input type="date" class="form-control" formControlName="dateFrom">
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group ">
                            <label class="d-block">To Date</label>
                            <input type="date" class="form-control" formControlName="dateTo">
                        </div>
                    </div>
                </div>
            </div>
            <hr>
            <div class="app-modal-footer ">
                <button type=" button " class="btn btn-danger " data-dismiss="modal" (click)="cancel()">Cancel</button>
                <button type="submit " class="btn btn-primary " (click)="submitBankList()">Search</button>
            </div>
        </form>
    </app-card>
    <div class="row mb-n4" *ngIf="gridView">
        <div class="col-sm-12 ">
            <app-card [customHeader]="true " headerClass="border-0 p-2 pb-0 " [options]="false " cardClass="card-datatable">
                <table id="report-table" datatable class="table table-striped table-hover mb-0 table-responsive">
                    <thead>
                        <tr>
                            <th *ngFor="let col of columns">
                                {{col}}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let data of bankCommissionReport; let i=index; ">
                            <th scope="row ">{{ data.displayid }}</th>
                            <th scope="row ">{{ data.name }}</th>
                            <th scope="row ">{{ data.adminchargeamount }}</th>
                            <th scope="row ">{{ data.netamount }}</th>
                            <th scope="row ">{{ data.tdsamount }}</th>
                            <th scope="row ">{{ data.totalamount }}</th>
                        </tr>
                    </tbody>
                </table>
            </app-card>
        </div>
    </div>
</div>
<div *ngIf="!isAdmin">
    <img class="center" src="./../../../../../assets/images/maintenance/maintance.png " alt=" ">
    <h1 style="text-align: center; ">Coming Soon</h1>
</div>
