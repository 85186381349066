<app-card [hidHeader]="true">
    <h5>Update Profile</h5>
    <hr>
    <form [formGroup]="UpdateProfileGroup" class="form">
        <div class="app-modal-body">
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="d-block">Name</label>
                        <input type="text" class="form-control" [(ngModel)]="userInformations.name" formControlName="name" placeholder="Name" [readOnly]="!isAdmin">
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="d-block">Gender</label>
                        <input type="text" class="form-control" [(ngModel)]="userInformations.gender" formControlName="gender" placeholder="Gender" [readOnly]="!isAdmin">
                    </div>
                </div>
            </div>
            <div>

                <div class=" row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label class="d-block">City/Village</label>
                            <input type="text" class="form-control" [(ngModel)]="userInformations.cityVillage" formControlName="cityVillage" placeholder="City/Village" [readOnly]="!isAdmin">
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label class="d-block">Address</label>
                            <textarea class="form-control" [(ngModel)]="userInformations.address" formControlName="address" rows="3" placeholder="Address"></textarea>
                        </div>
                    </div>
                </div>
                <div class=" row">
                    <div class="col-sm-4">
                        <div class="form-group">
                            <label class="d-block">D.O.B</label>
                            <input type="date" class="form-control" [(ngModel)]="userInformations.dob" formControlName="dob" placeholder="Date of Birth" [readOnly]="!isAdmin">
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group">
                            <label class="d-block">Marital Status</label>
                            <input type="text" class="form-control" [(ngModel)]="userInformations.maritalStatus" formControlName="maritalStatus" placeholder="Marital Status" [readOnly]="!isAdmin">
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group">
                            <label class="d-block">Phone Number</label>
                            <input type="number" class="form-control" [(ngModel)]="userInformations.phNo" formControlName="phNo" placeholder="Phone Number" [readOnly]="!isAdmin">
                        </div>
                    </div>
                </div>
                <div class="row ">
                    <div class="col-sm-4">
                        <div class="form-group">
                            <label class="d-block">State <span style="color: red;">*</span></label>
                            <select class="form-control" [(ngModel)]="stateNameHold" formControlName="state">
               <option *ngFor="let states of allStatesData ">{{states.stateName}}</option>
            </select>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group">
                            <label class="d-block">Pincode</label>
                            <input type="number" class="form-control" [(ngModel)]="userInformations.pinCode" formControlName="pincode" placeholder="Pincode">
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group">
                            <label class="d-block">PAN</label>
                            <input type="text" class="form-control" [(ngModel)]="userInformations.panNo" formControlName="pan" placeholder="PAN">
                        </div>
                    </div>
                </div>
                <div class="row ">
                    <div class="col-sm-4">
                        <div class="form-group ">
                            <label class="d-block">Aadhaar</label>
                            <input type="number" class="form-control" [(ngModel)]="userInformations.aadharNo" formControlName="aadhaar" placeholder="Aadhaar" [readOnly]="!isAdmin">
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group">
                            <label class="d-block">Acount Holder Name</label>
                            <input type="text" class="form-control" [(ngModel)]="userInformations.accountHolderName" formControlName="accountHolderName" placeholder="Acount Holder Name" [readOnly]="accountHolderNameExists && !isAdmin">
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <label class="d-block">Account No.</label>
                        <input type="text" class="form-control" [(ngModel)]="userInformations.accountNo" formControlName="accountNo" placeholder="Acount No" [readOnly]="accountNumberExists && !isAdmin">
                    </div>
                </div>
                <div class="row ">
                    <div class="col-sm-4">
                        <div class="form-group ">
                            <label class="d-block">Bank Name</label>
                            <input type="text" class="form-control" [(ngModel)]="userInformations.bankName" formControlName="bankName" placeholder="Bank Name" [readOnly]="bankNameExists && !isAdmin">
                        </div>
                    </div>
                    <div class=" col-sm-4 ">
                        <div class="form-group ">
                            <label class="d-block ">IFSC</label>
                            <input type="text " class="form-control " [(ngModel)]="userInformations.ifsc " formControlName="ifsc" placeholder="IFSC " maxlength="20" [readOnly]="ifscCodeExists && !isAdmin">
                        </div>
                    </div>
                    <div class="col-sm-4 ">
                        <label class="d-block ">Guardian</label>
                        <input type="text " class="form-control " [(ngModel)]="userInformations.gurdian " formControlName="guardian" placeholder="Guardian " [readOnly]="!isAdmin ">
                    </div>
                </div>
                <div class="row ">
                    <div class="col-sm-4 ">
                        <div class="form-group ">
                            <label class="d-block ">Nominee</label>
                            <input type="text " class="form-control " [(ngModel)]="userInformations.nominee " formControlName="nominee" placeholder="Nominee " [readOnly]="!isAdmin ">
                        </div>
                    </div>
                    <div class="col-sm-4 ">
                        <div class="form-group ">
                            <label class="d-block ">Nominee Relation</label>
                            <input type="text " class="form-control " [(ngModel)]="userInformations.relation " formControlName="nomineeRelation" placeholder="Nominee Relation " [readOnly]="!isAdmin ">
                        </div>
                    </div>
                    <div class="col-sm-4 ">
                        <label class="d-block ">Nominee D.O.B</label>
                        <input type="date " class="form-control " [(ngModel)]="userInformations.nomineeDob " formControlName="nomineeDob" [readOnly]="!isAdmin ">
                    </div>
                    <div class="col-sm-4 ">
                        <label class="d-block ">Bank details updated on</label>
                        <input type="date " class="form-control " [(ngModel)]="userInformations.updatedOn " formControlName="updatedOn" [readOnly]=true>
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <div class="app-modal-footer ">
            <button type="button " class="btn btn-danger " data-dismiss="modal " (click)="cancel() ">Cancel</button>
            <button type="submit " class="btn btn-primary " (click)="submitUpdate() ">Submit</button>
        </div>
    </form>
</app-card>
