import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'auth/signin-v2', pathMatch: 'full' },
  {
    path: 'auth',
    loadChildren: () =>
      import('./mlm/auth/authentication.module').then(
        (module) => module.AuthenticationModule
      ),
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./mlm/mlm.module').then((module) => module.MLMModule),
  },
  {
    path: 'notification',
    loadChildren: () =>
      import('./theme/adv-notification/adv-notification.module').then((module) => module.AdvNotificationModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
