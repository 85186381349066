<div class="row btn-page">
    <div class="col-sm-12">
        <app-card [hidHeader]="true">
            <h5>View Recharge Balance</h5>
            <hr>
            <div class="row">
                <div>
                    <h6 class="row" style="padding-left: 30px;">Recharge Balance Amount in RS.
                        <h6 style="font-weight: bold;"> {{currentBalance}} {{currentDatetime}}</h6>
                    </h6>
                </div>
            </div>
        </app-card>
    </div>
</div>