<div class="row">
    <div class="col-sm-12">
        <app-card cardTitle="Search Item" [options]="false">
            <form [formGroup]="searchForm" class="form">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="d-block">Item Name</label>
                        <input type="text" class="form-control" formControlName="item" placeholder="Item Name" (keyup)="search($event)">
                    </div>
                    <hr>
                    <div>
                        <p class="text-success">Note : Please enter atleast 3 letters to search any test</p>
                    </div>
                </div>
            </form>
        </app-card>
    </div>
</div>
<section *ngIf="this.searchForm.controls['item']?.value?.length>2">
    <div class="container px-4 px-lg-5 mt-5">
        <div class="row gx-4 gx-lg-5 row-cols-2 row-cols-md-3 row-cols-xl-4 justify-content-center">
            <div class="col mb-5" *ngFor="let item of allItemsDetailsData">
                <div class="card h-100">
                    <!-- Product image-->
                    <img class="card-img-top" style="height:200px;" src="{{item.image}}" alt="..." />
                    <!-- Product details-->
                    <div class="card-body">
                        <div class="text-center">
                            <!-- Product name-->
                            <h5 class="fw-bolder">{{item.itemName}}</h5>
                            <!-- Product price-->
                            <h6>Rs: {{item.mrp.toFixed(2)}}</h6>
                            <!-- <h6>Stock: {{item.itemCount}}</h6> -->
                        </div>
                    </div>
                    <!-- Product actions-->
                    <div class="card-footer  pt-0 border-top-0 bg-transparent">
                        <div class="text-center">
                            <button class="btn btn-outline-dark mt-auto" (click)="detailsGenerate(item)" (click)="updateTestModalLong.show()">Edit</button></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-ui-modal #updateTestModalLong [dialogClass]="'modal-xl'" id="updateTestModalLong">
    <div class="app-modal-header">
        <h5>Update Item</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close" (click)="updateTestModalLong.hide()"><span aria-hidden="true">&times;</span></button>
    </div>

    <div class="app-modal-body">
        <form [formGroup]="itemGroup" class="form">
            <div class="app-modal-body">
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label class="d-block">Category <span style="color: red;">*</span></label>
                            <input type="text" class="form-control" formControlName="category" placeholder="Category" list="category" readonly [(ngModel)]="testDetails.catName">
                            <datalist id="category">
                          <option *ngFor="let category of allCategoriesData">{{category.catName}}</option>
                       </datalist>
                            <p style="color: red;">{{showMessageCategory}}</p>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label class="d-block">Sub Category <span style="color: red;">*</span></label>
                            <input type="text" class="form-control" formControlName="subCategory" placeholder="Sub Category" list="subCategory" readonly [(ngModel)]="testDetails.subCatName">
                            <datalist id="subCategory">
                          <option *ngFor="let subCategory of allSubCategoriesData">{{subCategory.subCatName}}</option>
                       </datalist>
                            <p style="color: red;">{{showMessageSubCategory}}</p>
                        </div>
                    </div>
                </div>
                <div>
                    <div class=" row">
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="d-block">Product <span style="color: red;">*</span></label>
                                <input type="text" class="form-control" formControlName="product" placeholder="Product" list="product" [(ngModel)]="testDetails.prodName">
                                <datalist id="product">
                              <option *ngFor="let product of allProductsData ">{{product.prodName}}</option>
                           </datalist>
                                <p style=" color: red; ">{{showMessageProduct}}</p>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="d-block">Product Description</label>
                                <textarea class="form-control" formControlName="productDescription" rows="3" placeholder="Product Description" [(ngModel)]="testDetails.prodDesc"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class=" row">
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="d-block">Item <span style="color: red;">*</span></label>
                                <input type="text" class="form-control" formControlName="item" placeholder="Item" list="items" [(ngModel)]="testDetails.itemName">
                                <datalist id="items">
                          <option *ngFor="let items of allItemsData">{{items.itemName}}</option>
                       </datalist>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="d-block">Item Description</label>
                                <textarea class="form-control" formControlName="itemDescription" rows="3" placeholder="Item Description" [(ngModel)]="testDetails.itemDesc"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="row ">
                        <div class="col-sm-4">
                            <div class="form-group ">
                                <label class="d-block">HSN</label>
                                <input type="text" class="form-control" formControlName="hsn" placeholder="HSN" [(ngModel)]="testDetails.hsn">
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="d-block ">MRP/Unit Price <span style="color: red;">*</span></label>
                                <input type="number" class="form-control" formControlName="mrp" placeholder="MRP/Unit Price" [(ngModel)]="testDetails.mrp">
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="d-block">GST Percentage <span style="color: red;">*</span></label>
                                <select class="form-control" formControlName="gstPercentage" [(ngModel)]="testDetails.gstPercentage">
                              <option></option>
                              <option *ngFor="let gst of allGstData ">{{gst}}</option>
                            </select>
                            </div>
                        </div>
                    </div>
                    <div class="row ">
                        <div class="col-sm-6">
                            <div class="form-group ">
                                <label class="d-block">Distributer Price <span style="color: red;">*</span></label>
                                <input type="number" class="form-control" formControlName="distributerPrice" placeholder="Distributer Price" [(ngModel)]="testDetails.distributorPrice">
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="d-block">BV <span style="color: red;">*</span></label>
                                <input type="number" class="form-control" formControlName="bv" placeholder="BV" [(ngModel)]="testDetails.bv">
                            </div>
                        </div>
                        <!-- <div class="col-sm-4">
                        <label class="d-block">Item Image Upload</label>
                        <input type="file" class="form-control" formControlName="itemImageUpload">
                    </div> -->
                    </div>
                </div>
            </div>
            <hr>
            <div class="app-modal-footer ">
                <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="updateTestModalLong.hide()">Cancel</button>
                <button type="submit" class="btn btn-primary" (click)="updateItem()">Submit</button>
            </div>
        </form>
    </div>

</app-ui-modal>