import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ItemMasterService } from '../../services/item-master.service';
import { AllCategories, AllProducts, AllSubCategories, GstData, ItemId, ItemMasterData } from '../../model/item-master.model';
import { CommonComponent } from 'src/app/theme/shared/common/common.component';
import { UserAuthService } from 'src/app/mlm/auth/services/user-auth.service';
import { MembersService } from 'src/app/mlm/member/services/members.service';
import { ItemsDetails, SearchItems } from 'src/app/mlm/purchase/model/purchase.model';
import { PurchaseService } from 'src/app/mlm/purchase/services/purchase.service';

@Component({
  selector: 'app-update-item',
  templateUrl: './update-item.component.html',
  styleUrls: ['./update-item.component.scss']
})
export class UpdateItemComponent extends CommonComponent implements OnInit {
  itemShow: boolean = false;
  searchForm: FormGroup;
  showModal: boolean = false;
  allItemsDetailsData: ItemsDetails[] = [];
  allItemsDetailsDataListSearch: ItemsDetails[] = [];

  itemGroup: FormGroup;
  positionValue: string;
  showMessageCategory: string = '';
  showMessageSubCategory: string = '';
  showMessageProduct: string = '';
  allCategoriesData: AllCategories[] = [];
  allSubCategoriesData: AllSubCategories[] = [];
  allProductsData: AllProducts[] = [];
  categoryId: string = '';
  subCategoryId: string = '';
  productId: string = '';
  allGstData = [];
  allItemsData: string = '';
  discountIsPercentageChecked: boolean = false;
  testDetails: ItemsDetails;
  isAdmin: boolean = false;
  isTangible: boolean = false;
  cashbackIsPercentageChecked: boolean = false;

  constructor(
    public fb: FormBuilder,
    public memberService: MembersService,
    private purchaseService: PurchaseService,
    private itemMasterService: ItemMasterService,
    private userAuthService: UserAuthService
  ) {
    super();

    this.searchForm = this.fb.group({
      item: new FormControl('', [Validators.required]),
    });

    this.itemGroup = this.fb.group({
      category: new FormControl('', [Validators.required]),
      subCategory: new FormControl('', [Validators.required]),
      product: new FormControl('', [Validators.required]),
      productDescription: new FormControl('', [Validators.required]),
      item: new FormControl('', [Validators.required]),
      itemDescription: new FormControl('', [Validators.required]),
      hsn: new FormControl('', [Validators.required]),
      mrp: new FormControl('', [Validators.required]),
      gstPercentage: new FormControl('', [Validators.required]),
      distributerPrice: new FormControl('', [Validators.required]),
      bv: new FormControl('', [Validators.required]),
      doctorCommission: new FormControl('', [Validators.required]),
      franchiseCommission: new FormControl('', [Validators.required]),
      cnfCommission: new FormControl('', [Validators.required]),
      isTangible: new FormControl('', [Validators.required]),
      discount: new FormControl('', [Validators.required]),
      cashback: new FormControl('', [Validators.required]),
    });

    this.testDetails = new ItemsDetails();
  }

  ngOnInit(): void {
    this.isAdmin = this.userAuthService.getRoles() === 'ADMIN' ? true : false;

    this.searchForm.controls['item'].valueChanges.subscribe((val) => {
      if(val){
        if(this.searchForm.controls['item'].value.length>2){
          this.itemShow = true;
        }else{
          this.itemShow = false;
        }
      }
    });

    this.itemMasterService.getAllCategoriesData().subscribe((value) => {
      if(value){
        console.log("Categories:",value.data);
        this.allCategoriesData = value.data;
      }
    });
    this.itemGroup.controls['category'].valueChanges.subscribe((val) => {
      if(val){
        for(let i=0;i<this.allCategoriesData.length;i++){
          if(this.allCategoriesData[i].catName === val){
            this.itemMasterService.getAllSubCategoriesData(this.allCategoriesData[i].catId).subscribe((value) => {
              if(value){
                this.allSubCategoriesData = value.data;
                this.categoryId = this.allCategoriesData[i].catId;
              }
            });
          }
        }
      }
    })

    this.itemGroup.controls['subCategory'].valueChanges.subscribe((val) => {
      if(val){
        for(let i=0;i<this.allSubCategoriesData.length;i++){
          if(this.allSubCategoriesData[i].subCatName === val){
            this.itemMasterService.getAllProductsData(this.categoryId,this.allSubCategoriesData[i].subCatId).subscribe((value) => {
              if(value){
                this.allProductsData = value.data;
                this.subCategoryId = this.allSubCategoriesData[i].subCatId;
              }
            });
          }
        }
      }
    })

    this.itemGroup.controls['product'].valueChanges.subscribe((val) => {
      if(val){
        for(let i=0;i<this.allProductsData.length;i++){
          if(this.allProductsData[i].prodName === val){
            this.itemMasterService.getAllItemsData(this.categoryId,this.subCategoryId,this.allProductsData[i].prodId).subscribe((value) => {
              if(value){
                this.allItemsData = value.data;
                this.productId = this.allProductsData[i].prodId;
              }
           })
          }
        }
      }
    })

    this.itemMasterService.getGSTData().subscribe((val)=>{
      if(val){
        val.data.forEach(element => {
          this.allGstData.push(element.gstPercentage);
        });
      }
    })

    this.itemGroup.controls['discount'].valueChanges.subscribe((val) => {
      if(val){
        const discount = (val * this.itemGroup.controls['mrp'].value)/100;
        this.testDetails.distributorPrice = this.itemGroup.controls['mrp'].value - discount;
      }else{
        this.testDetails.distributorPrice = this.itemGroup.controls['mrp'].value;
      }
    });
  }

  show() {
    this.showModal = true;
  }

  hide() {
    this.showModal = false;
  }

  search(event: any){
    const search = new SearchItems();
    search.catId = '';
    search.subCatId = '';
    search.prodId = '';
    search.itemId = '';
    search.isActive = this.isAdmin ? null : true;
    this.purchaseService.getAllItemsData(search).subscribe((val) => {
      if (val) {
        this.allItemsDetailsData = val.data;
        this.allItemsDetailsData.forEach(element => {
          this.itemMasterService.getItemImageUploadData(element.itemId).subscribe((val)=>{
            if(val){
              element.image = val.data[0].filelocation;
            }
          })
        });
        const temp = this.searchForm.controls['item'].value.toLowerCase();
    const allItemsDetailsDataFilter = new ItemsDetails();
    // allItemsDetailsDataFilter.displayId = this.displayId.toString();
    this.allItemsDetailsDataListSearch = this.allItemsDetailsData;
    this.allItemsDetailsData = [];
    // this.loading = true;
    this.allItemsDetailsDataListSearch.forEach((element) => {
      if (element.itemName.toLowerCase().includes(temp)) {
        this.allItemsDetailsData.push(element);
      }
    });
    // this.loading = false;
      }
    });

  }

  onCheckboxChangeDiscountIsPercentage(event: any) {
    if (event.target.checked) {
      this.discountIsPercentageChecked = true;
    } else {
      this.discountIsPercentageChecked = false;
    }
  }

  onCheckboxChangeisTangible(event: any) {
    if (event.target.checked) {
      this.isTangible = true;
    } else {
      this.isTangible = false;
    }
  }

  onCheckboxChangeCashbackIsPercentage(event: any) {
    if (event.target.checked) {
      this.cashbackIsPercentageChecked = true;
    } else {
      this.cashbackIsPercentageChecked = false;
    }
  }

  updateItem(){
    const itemMasterData = new ItemMasterData();
    itemMasterData.catId = this.categoryId;
    itemMasterData.catName = this.itemGroup.controls['category'].value;
    itemMasterData.subCatId = this.subCategoryId;
    itemMasterData.subCatName = this.itemGroup.controls['subCategory'].value;
    itemMasterData.prodId = this.productId;
    itemMasterData.itemId = this.testDetails.itemId;
    itemMasterData.prodName = this.itemGroup.controls['product'].value;
    itemMasterData.prodDesc = this.itemGroup.controls['productDescription'].value;
    itemMasterData.itemName = this.itemGroup.controls['item'].value;
    itemMasterData.itemDesc = this.itemGroup.controls['itemDescription'].value;
    itemMasterData.hsn = this.itemGroup.controls['hsn'].value;
    itemMasterData.mrp = this.itemGroup.controls['mrp'].value;
    itemMasterData.distributorPrice = this.itemGroup.controls['distributerPrice'].value;
    itemMasterData.bv = 0;
    // itemMasterData.discountIsPercentage = this.discountIsPercentageChecked;
    // itemMasterData.discount = this.itemGroup.controls['discount'].value;
    // itemMasterData.cashbackIsPercentage = this.cashbackIsPercentageChecked;
    // itemMasterData.cashback = this.itemGroup.controls['cashback'].value;
    itemMasterData.gstPercentage = parseInt(this.itemGroup.controls['gstPercentage'].value);
    // itemMasterData.isTangible = this.isTangible;
    // itemMasterData.commissions[0] = new CommissionData();
    // itemMasterData.commissions[0].type = 2;
    // itemMasterData.commissions[0].percentage = this.itemGroup.controls['doctorCommission'].value?this.itemGroup.controls['doctorCommission'].value:0;
    // itemMasterData.commissions[1] = new CommissionData();
    // itemMasterData.commissions[1].type = 3;
    // itemMasterData.commissions[1].percentage = this.itemGroup.controls['franchiseCommission'].value?this.itemGroup.controls['franchiseCommission'].value:0;
    // itemMasterData.commissions[2] = new CommissionData();
    // itemMasterData.commissions[2].type = 4;
    // itemMasterData.commissions[2].percentage = this.itemGroup.controls['cnfCommission'].value?this.itemGroup.controls['cnfCommission'].value:0;
    console.log(itemMasterData);
    this.itemMasterService.postItemMasterData(itemMasterData).subscribe((val)=>{
      if(val.message){
        this.warningSwal(val.message);
      }else{
        this.successSwal("Item details updated successfully");
      }
    })
  }

  cancelUpdate(){
    this.itemGroup.reset();
  }

  detailsGenerate(item: ItemsDetails){
    const itemId = new ItemId();
    itemId.itemId = item.itemId;
    this.testDetails = item;
    this.productId = item.prodId;
    this.subCategoryId = item.subCatId;
    // this.itemMasterService.postGetCommodityByItemData(itemId).subscribe((val)=>{
    //   if(!val.errorCode){
    //     this.testDetails = val.data;
        // this.isTangible = val.data.isTangible;
        // this.discountIsPercentageChecked = val.data.discountIsPercentage;
        // this.cashbackIsPercentageChecked = val.data.cashbackIsPercentage;
    //   }
    // })
    // this.isTangible = this.testDetails.isTangible;
  }

}
