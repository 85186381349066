import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddStockComponent } from './add-stock/add-stock.component';
import { StockReportComponent } from './stock-report/stock-report.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbTabsetModule, NgbDropdownModule, NgbCarouselModule, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { ArchwizardModule } from 'angular-archwizard';
import { DataTablesModule } from 'angular-datatables';
import { SharedModule } from 'src/app/theme/shared/shared.module';
import { StockRoutingModule } from './stock-routing.module';
import { StockRequestsComponent } from './stock-requests/stock-requests.component';

@NgModule({
  declarations: [AddStockComponent, StockReportComponent, StockRequestsComponent],
  imports: [
    CommonModule,
    SharedModule,
    NgbTabsetModule,
    NgbDropdownModule,
    NgbCarouselModule,
    NgbDatepickerModule,
    FormsModule,
    DataTablesModule,
    ReactiveFormsModule,
    StockRoutingModule,
    ArchwizardModule,
    NgxQRCodeModule
  ],
})
export class StockModule { }
