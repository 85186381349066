<app-card [hidHeader]="true" *ngIf="isAdmin">
    <h5>Search Direct Member</h5>
    <hr>
    <form [formGroup]="searchDirectMember" class="form">
        <div class="app-modal-body">
            <div class="col-sm-6">
                <div class="form-group">
                    <label class="d-block ">Display Id</label>
                    <input type="number" class="form-control" formControlName="displayId" placeholder="Display Id">
                </div>
            </div>
        </div>
        <hr>
        <div class="app-modal-footer">
            <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="cancel()">Cancel</button>
            <button type="submit" class="btn btn-primary" (click)="submitDisplayId()">Submit</button>
        </div>
    </form>
</app-card>
<ng-container *ngIf="!loading; then thenTemplate; else elseTemplate">
</ng-container>
<ng-template #thenTemplate>
    <ng-container *ngIf="isDataAvailable; else elseTemplate">
        <div class="row btn-page" *ngIf="gridView && isAdmin">
            <div class="col-sm-12 btn-page">
                <app-card headerClass="border-0 p-2 pb-0 " cardTitle="Direct Member" [options]="false" cardClass="card-datatable">
                    <br>
                    <div class="col-sm-12 text-right">
                    </div>
                    <div class="table-responsive">
                        <table datatable id="excel-table" class="table table-striped table-hover mb-0 table-responsive">
                            <thead>
                                <tr>
                                    <th *ngFor="let col of columnsAdmin">
                                        {{col}}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let reports of downlineReportsTableData">
                                    <td *ngFor="let col of indexAdmin">
                                        <div *ngIf="col != 'isLeft' && col != 'isActive' && col != 'isGreen'">
                                            {{reports[col]}}
                                        </div>
                                        <div *ngIf="col == 'isLeft'">
                                            <div *ngIf="reports[col] == true ">
                                                <span class="badge badge-success inline-block">Left</span>
                                            </div>
                                            <div *ngIf="reports[col] == false ">
                                                <span class="badge badge-warning inline-block m-r-5">Right</span>
                                            </div>
                                        </div>
                                        <div *ngIf="col == 'isActive'" class="d-inline-block">
                                            <div class="custom-control custom-switch" *ngIf="reports[col] == 1 ">
                                                <input type="checkbox" class="custom-control-input" readonly checked>
                                                <label class="custom-control-label" for="customSwitch1"></label>
                                            </div>

                                            <div class="custom-control custom-switch" *ngIf="reports[col] == 0 ">
                                                <input type="checkbox" class="custom-control-input" readonly>
                                                <label class="custom-control-label" for="customSwitch1"></label>
                                            </div>
                                        </div>
                                        <div *ngIf="col == 'isGreen'">
                                            <div class="custom-control custom-switch" *ngIf="reports[col] == true ">
                                                <input type="checkbox" class="custom-control-input" readonly checked>
                                                <label class="custom-control-label"></label>
                                            </div>

                                            <div class="custom-control custom-switch" *ngIf="reports[col] == false ">
                                                <input type="checkbox" class="custom-control-input" readonly>
                                                <label class="custom-control-label"></label>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </app-card>
            </div>
        </div>

        <div class="row btn-page" *ngIf="gridView && !isAdmin">
            <div class="col-sm-12 btn-page">
                <app-card headerClass="border-0 p-2 pb-0 " cardTitle="Direct Member" [options]="false" cardClass="card-datatable">
                    <div class="table-responsive">
                        <table datatable id="excel-table" class="table table-striped table-hover mb-0 table-responsive">
                            <thead>
                                <tr>
                                    <th *ngFor="let col of columnsUser">
                                        {{col}}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let reports of downlineReportsTableData">
                                    <td *ngFor="let col of indexUser">
                                        <div *ngIf="col != 'isLeft' && col != 'isActive' && col != 'isGreen'">
                                            {{reports[col]}}
                                        </div>
                                        <div *ngIf="col == 'isLeft'">
                                            <div *ngIf="reports[col] == true ">
                                                <span class="badge badge-success inline-block">Left</span>
                                            </div>
                                            <div *ngIf="reports[col] == false ">
                                                <span class="badge badge-warning inline-block m-r-5">Right</span>
                                            </div>
                                        </div>
                                        <div *ngIf="col == 'isActive'" class="d-inline-block">
                                            <div class="custom-control custom-switch" *ngIf="reports[col] == 1 ">
                                                <input type="checkbox" class="custom-control-input" readonly checked>
                                                <label class="custom-control-label" for="customSwitch1"></label>
                                            </div>

                                            <div class="custom-control custom-switch" *ngIf="reports[col] == 0 ">
                                                <input type="checkbox" class="custom-control-input" readonly>
                                                <label class="custom-control-label" for="customSwitch1"></label>
                                            </div>
                                        </div>
                                        <div *ngIf="col == 'isGreen'">
                                            <div class="custom-control custom-switch" *ngIf="reports[col] == true ">
                                                <input type="checkbox" class="custom-control-input" readonly checked>
                                                <label class="custom-control-label"></label>
                                            </div>
                                            <div class="custom-control custom-switch" *ngIf="reports[col] == false ">
                                                <input type="checkbox" class="custom-control-input" readonly>
                                                <label class="custom-control-label"></label>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </app-card>
            </div>
        </div>
    </ng-container>
    <ng-template #elseTemplate>
        <div class="spinner">
            <h5>Loading...</h5>
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
        </div>
    </ng-template>
</ng-template>
<ng-template #elseTemplate>

</ng-template>