import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Member } from 'src/app/mlm/dashboard/model/member.model';
import { DashboardService } from 'src/app/mlm/dashboard/services/dashboard.service';
import { DownlineService } from '../../services/downline.service';
import { CommonComponent } from 'src/app/theme/shared/common/common.component';

@Component({
  selector: 'app-bi-module',
  templateUrl: './bi-module.component.html',
  styleUrls: ['./bi-module.component.scss']
})
export class BiModuleComponent extends CommonComponent implements OnInit {
  members: boolean = false;
  membersData: Member[] = [];
  displayIdForm: FormGroup;
  loading: boolean = true;
  isDataAvailable: boolean;
  constructor(
    private downlineService: DownlineService,
    public fb: FormBuilder,
    ) {
    super();
      this.displayIdForm = this.fb.group({
        displayId: new FormControl('', [Validators.required]),
      });
    this.isDataAvailable = this.membersData?.length > 0;
  }

  ngOnInit(): void {
  }

  submitSearch(){
    this.isDataAvailable = false;
    this.downlineService.getBiModule((this.displayIdForm.controls['displayId'].value)?this.displayIdForm.controls['displayId'].value:0).subscribe((value) => {
      if (value) {
        this.membersData = value.data;
        this.isDataAvailable = this.membersData?.length > 0;
        this.members = true;
        console.log(this.membersData);
      }
    });
    this.loading = false;
  }

  reset(){
    this.displayIdForm.reset();
    this.members = false;
    this.membersData = [];
  }
}
