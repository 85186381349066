import { Component, OnInit } from '@angular/core';
import { ItemMasterService } from 'src/app/mlm/inventory/services/item-master.service';
import { CartItems, RemoveItem } from '../../../../../purchase/model/purchase.model';
import { PurchaseService } from '../../../../../purchase/services/purchase.service';
import { Router } from '@angular/router';
import { MembersService } from 'src/app/mlm/member/services/members.service';
import { DetailsDisplayId } from 'src/app/mlm/member/model/details-displayid.model';
import { CommonComponent } from 'src/app/theme/shared/common/common.component';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent extends CommonComponent implements OnInit {
  allCartsData: CartItems[] = [];
  subTotal: number = 0;
  isDataAvailable: boolean = false;
  displayId: string;
  displayIdInformations: DetailsDisplayId;

  constructor(
    private purchaseService: PurchaseService,
    private itemMasterService: ItemMasterService,
    private router: Router,
    private memberService: MembersService
  ) {
    super();
  }

  ngOnInit(): void {
    this.displayId = this.router.url.split('/')?.[5];

    this.memberService.getverifyMemberInfoDisplayId(this.displayId).subscribe((val)=>{
      if(val){
        this.displayIdInformations = val.data;
        console.log(this.displayIdInformations);
      }
    })

    this.purchaseService.getAllCartData(this.displayId).subscribe((val)=>{
      if(val.data.length>0){
        this.isDataAvailable = true;
        this.allCartsData = val.data;
        this.allCartsData.forEach(element => {
          this.itemMasterService.getItemImageUploadData(element.itemId).subscribe((val)=>{
            if(val){
              element.image = val.data[0].filelocation;
            }
          })
        });
        this.subTotal = 0;
        for(let i=0;i<this.allCartsData.length;i++){
          const price = this.allCartsData[i].qty*this.allCartsData[i].distributorPrice;
          this.subTotal = this.subTotal + price;
        }
      }
    })
  }

  removeItem(item: CartItems){
    this.purchaseService.removeCartItemData(item.cartId).subscribe((val)=>{
      if(val){
        console.log(val);
        this.purchaseService.getAllCartData(this.displayId).subscribe((val)=>{
          if(val.data.length>0){
            this.allCartsData = val.data;
            this.allCartsData.forEach(element => {
              this.itemMasterService.getItemImageUploadData(element.itemId).subscribe((val)=>{
                if(val){
                  element.image = val.data[0].filelocation;
                }
              })
            });
            for(let i=0;i<this.allCartsData.length;i++){
              const price = this.allCartsData[i].qty*this.allCartsData[i].distributorPrice;
              this.subTotal = this.subTotal + price;
            }
          }else{
            setTimeout(() => {
              this.router.navigateByUrl("/home/purchase/shop");
            }, 1000);
          }
        })
      }
    })
  }

  checkout(){
    setTimeout(() => {
      this.router.navigateByUrl("/home/member/downline-member/checkout/" + this.displayId);
    }, 1000);
  }

}
