<div>
    <app-card headerClass="border-0 p-2 pb-0 " cardTitle="Payment Status Report" [options]="false">
        <form [formGroup]="paymentStatusReportGroup" class="form">
            <div class="row">
                <div class="col-sm-4">
                    <div class="form-group">
                        <label class="d-block ">Display Id</label>
                        <input type="number" class="form-control" formControlName="displayId">
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group">
                        <label class="d-block">Payment Type <span style="color: red;">*</span></label>
                        <select class="form-control" formControlName="paymentType">
                    <option>Paid</option>
                    <option>Unpaid</option>
                  </select>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group">
                        <label class="d-block">Date Range <span style="color: red;">*</span></label>
                        <select class="form-control" formControlName="fromDateToDate">
                          <option> </option>
                          <option *ngFor="let date of allDatesData ">{{date.fromDate}} - {{date.toDate}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <hr>
            <div class="app-modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="cancel()">Cancel</button>
                <button type="submit" class="btn btn-primary" (click)="submit()">Submit</button>
            </div>
        </form>
    </app-card>
</div>

<ng-container *ngIf="!loading; then thenTemplate; else elseTemplate"></ng-container>
<ng-template #thenTemplate>
    <ng-container *ngIf="isDataAvailable; else elseTemplate">
        <div class="row btn-page" *ngIf="gridView">
            <div class="col-sm-12 btn-page">
                <app-card [hidHeader]="true" cardClass="card-datatable" cardTitle="View Commission">
                    <div class="col-sm-12 text-right">
                        <button type="button" class="btn btn-primary btn-sm" (click)="exportexcel()">Export to Excel</button>
                    </div>
                    <div class="table-responsive">
                        <table datatable id="excel-table" class="table table-striped table-hover mb-0 table-responsive">
                            <thead>
                                <tr>
                                    <th *ngFor="let col of columns">
                                        {{col}}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of paymentStatusReportTableData; let i=index; ">
                                    <th scope="row ">{{ data?.displayId }}</th>
                                    <th scope="row ">{{ data?.name }}</th>
                                    <th scope="row ">{{ data?.totalIncome }}</th>
                                    <th scope="row ">{{ data?.adminCharge }}</th>
                                    <th scope="row ">{{ data?.tdsAmount }}</th>
                                    <th scope="row ">{{ data?.netAmount }}</th>
                                    <th scope="row ">{{ data?.paymentDate }}</th>
                                    <th scope="row ">{{ data?.paymentStatus }}</th>
                                    <th scope="row ">{{ data?.accountNo }}</th>
                                    <th scope="row ">{{ data?.bankName }}</th>
                                    <th scope="row ">{{ data?.ifsc }}</th>
                            </tbody>
                        </table>
                    </div>
                </app-card>
            </div>
        </div>
    </ng-container>
    <ng-template #elseTemplate>
        <div class="spinner" *ngIf="!noData">
            <h5>Loading...</h5>
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
        </div>
        <div *ngIf="noData">
            <h1 style="text-align: center;">No Data Available</h1>
        </div>
    </ng-template>
</ng-template>
<ng-template #elseTemplate>
</ng-template>