import { Component, OnInit } from '@angular/core';
import { CommonComponent } from 'src/app/theme/shared/common/common.component';

@Component({
  selector: 'app-fund-transfer-report',
  templateUrl: './fund-transfer-report.component.html',
  styleUrls: ['./fund-transfer-report.component.scss']
})
export class FundTransferReportComponent extends CommonComponent implements OnInit {

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

}
