<div class="row mb-n4" *ngIf="gridView">
    <div class="col-sm-12 ">
        <app-card [customHeader]="true " headerClass="border-0 p-2 pb-0 " [options]="false " cardClass="card-datatable">
            <div class="col-sm-6 text-left">
                <h5>Approved Requests</h5>
            </div><br>
            <table id="report-table" datatable class="table table-striped table-hover mb-0 table-responsive">
                <thead>
                    <tr>
                        <th *ngFor="let col of columns">
                            {{col}}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let fundRequests of viewTableApproved; index as i ">
                        <th scope="row ">{{ fundRequests.createdOn.substring(0,10) }}</th>
                        <th scope="row ">{{ fundRequests.userId }}</th>
                        <th scope="row ">{{ fundRequests.displayId }}</th>
                        <th scope="row ">{{ fundRequests.requestId }}</th>
                        <td>
                            <div *ngIf="isAdmin && fundRequests.isApproved==0">
                                <input #amount type="number" [(ngModel)]="fundRequests.amount" id="amount">

                            </div>
                            <div *ngIf="!isAdmin || fundRequests.isApproved==1">
                                {{ fundRequests.amount }}
                            </div>
                        </td>
                        <th scope="row ">{{ fundRequests.approvedAmount }}</th>
                        <td>{{ fundRequests.description }}</td>
                        <!-- <td>{{ fundRequests.instrument }}</td> -->
                        <td>
                            <div *ngIf="isAdmin && fundRequests.isApproved==0">
                                <input type="text" #rejectionClause [(ngModel)]="fundRequests.rejectionClause" id="rejectionClause">
                            </div>
                            <div *ngIf="!isAdmin || fundRequests.isApproved==1">
                                {{ fundRequests.rejectionClause }}
                            </div>
                        </td>
                        <td>
                            <div *ngIf="!isAdmin">
                                <div *ngIf="fundRequests.isApproved==1">
                                    <span style="color: green; font-weight: bold;">Approved</span>
                                </div>
                                <div *ngIf="fundRequests.isApproved==0">
                                    <span style="color: rgb(237, 161, 11); font-weight: bold;">Pending</span>
                                </div>
                            </div>
                            <div class="form-check" *ngIf="isAdmin">
                                <span style="color: green; font-weight: bold;" *ngIf="fundRequests.isApproved==1">Approved</span>
                                <button class="btn-sm btn-info" *ngIf="fundRequests.isApproved==0" style="color:black;" (click)="approve(fundRequests,fundRequests.amount,fundRequests.rejectionClause)">Approve</button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </app-card>
    </div>
</div>
<br><br><br>
<div class="row mb-n4" *ngIf="gridView">
    <div class="col-sm-12 ">
        <app-card [customHeader]="true " headerClass="border-0 p-2 pb-0 " [options]="false " cardClass="card-datatable">
            <div class="col-sm-6 text-left">
                <h5>Pending Fund Requests</h5>
            </div><br>
            <table id="report-table" datatable class="table table-striped table-hover mb-0 table-responsive">
                <thead>
                    <tr>
                        <th *ngFor="let col of columns">
                            {{col}}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let fundRequests of viewTableRequests; index as i ">
                        <th scope="row ">{{ fundRequests.createdOn.substring(0,10) }}</th>
                        <th scope="row ">{{ fundRequests.userId }}</th>
                        <th scope="row ">{{ fundRequests.displayId }}</th>
                        <th scope="row ">{{ fundRequests.requestId }}</th>
                        <td>
                            <div *ngIf="isAdmin && fundRequests.isApproved==0">
                                <input #amount type="number" [(ngModel)]="fundRequests.amount" id="amount">

                            </div>
                            <div *ngIf="!isAdmin || fundRequests.isApproved==1">
                                {{ fundRequests.amount }}
                            </div>
                        </td>
                        <th scope="row ">{{ fundRequests.approvedAmount }}</th>
                        <td>{{ fundRequests.description }}</td>
                        <!-- <td>{{ fundRequests.instrument }}</td> -->
                        <td>
                            <div *ngIf="isAdmin && fundRequests.isApproved==0">
                                <input type="text" #rejectionClause [(ngModel)]="fundRequests.rejectionClause" id="rejectionClause">
                            </div>
                            <div *ngIf="!isAdmin || fundRequests.isApproved==1">
                                {{ fundRequests.rejectionClause }}
                            </div>
                        </td>
                        <td>
                            <div *ngIf="!isAdmin">
                                <div *ngIf="fundRequests.isApproved==1">
                                    <span style="color: green; font-weight: bold;">Approved</span>
                                </div>
                                <div *ngIf="fundRequests.isApproved==0">
                                    <span style="color: rgb(237, 161, 11); font-weight: bold;">Pending</span>
                                </div>
                            </div>
                            <div class="form-check" *ngIf="isAdmin">
                                <span style="color: green; font-weight: bold;" *ngIf="fundRequests.isApproved==1">Approved</span>
                                <button class="btn-sm btn-info" *ngIf="fundRequests.isApproved==0" style="color:black;" (click)="approve(fundRequests,fundRequests.amount,fundRequests.rejectionClause)">Approve</button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </app-card>
    </div>
</div>
