<app-card [hidHeader]="true">
    <h5>Search Tree</h5>
    <hr>
    <form [formGroup]="searchTreeGroup" class="form">
        <div class="app-modal-body">
            <div class="col-sm-6">
                <div class="form-group">
                    <label class="d-block">Search Name or Display Id</label>  
                    <input type="text" class="form-control" formControlName="displayId" placeholder="Name or Display Id">
                </div>
            </div>
        </div>
        <hr>
        <div class="app-modal-footer">
            <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="cancel()">Cancel</button>
            <button type="submit" class="btn btn-primary" (click)="submitDisplayId()">Submit</button>
        </div>
    </form>
    <br/>
    <ul *ngIf="searchResults && searchResults.length > 0">
        <li *ngFor="let result of searchResults">
            <button type="submit" class="btn searchbtn" (click)="submitSearchDisplayId(result.displayId)">{{ result.displayId }} {{'-'}} {{ result.name }}</button>
        </li>
    </ul>
</app-card>
<div class="app-modal-footer">
    <button type="submit" class="btn btn-primary" (click)="goBack()">Go Back</button>
</div>
<app-inspector [selectedNode]='selectedNode' [model]='model' (treeData)="newTreeGenerate($event)"></app-inspector>
<app-diagram [model]='model' (nodeClicked)='setSelectedNode($event)'>
</app-diagram>