import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PurchaseDeleteGridData } from '../../model/purchase.model';
import { PurchaseService } from '../../services/purchase.service';
import { CommonComponent } from 'src/app/theme/shared/common/common.component';
import 'sweetalert2/src/sweetalert2.scss';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-purchase-delete',
  templateUrl: './purchase-delete.component.html',
  styleUrls: ['./purchase-delete.component.scss']
})
export class PurchaseDeleteComponent extends CommonComponent implements OnInit {
  searchData: FormGroup;
  purchaseDeleteGridData : PurchaseDeleteGridData[] =[]
  isDataAvailable: boolean;
  gridView: boolean = false;
  fileName = 'ExcelSheet.xlsx';
  loading: boolean = true;
  columns = ['Display ID','Name','Order Date','Order No','Invoice No','Item Name','Quantity','Total Price','Total BV'];
  noData: boolean;
  constructor(
    private fb:FormBuilder,
    private purchaseService: PurchaseService,
  ) {
    super();
    this.searchData = this.fb.group({
      value: new FormControl('', [Validators.required]),
      type: new FormControl('', [Validators.required]),
    });
   }

  ngOnInit(): void {
  }

  submit(){
    this.gridView = false;
    this.purchaseDeleteGridData = [];
    this.purchaseService.getPurchaseDeleteGridData(this.searchData.controls['value'].value).subscribe((val)=>{
      if(val){
        this.purchaseDeleteGridData = val.data;
        this.isDataAvailable = this.purchaseDeleteGridData?.length > 0;
        this.noData = this.purchaseDeleteGridData?.length === 0;
        this.gridView = true;
      }
      this.loading = false;
    })
  }

  cancel(){
    this.searchData.reset();
    this.gridView = false;
  }

  delete(){
    Swal.fire({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover the data!',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true
    }).then((willDelete) => {
        if (willDelete.dismiss) {
          Swal.fire('', 'Data is not deleted!', 'error');
        } else {
          const type = (this.searchData.controls['type'].value == 'Invoice No')?'invoiceno':'orderno';
          this.purchaseService.deletePurchasedData(type,this.searchData.controls['value'].value).subscribe((val)=>{
            if(val.message){
              Swal.fire('', val.message);
            }else{
              this.successSwal('Data deleted successfully');
              this.gridView = false;
            }
          })
        }
      });
  }
}
