import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import * as go from 'gojs';
import {
  DownlineReportFilter,
  DownlineReportsTableData,
} from 'src/app/mlm/report/model/downline-reports-table.model';
import { DownlineService } from 'src/app/mlm/report/services/downline.service';
import { AllStates } from 'src/app/mlm/member/model/allstates.model';
import {
  MemberRegistration,
  MemberRegistrationDetailsBySponsorID,
} from 'src/app/mlm/member/model/member-registration.model';
import { MembersService } from 'src/app/mlm/member/services/members.service';
import { UserAuthService } from 'src/app/mlm/auth/services/user-auth.service';
import { CommonComponent } from 'src/app/theme/shared/common/common.component';

@Component({
  selector: 'app-inspector',
  templateUrl: './inspector.component.html',
  styleUrls: ['./inspector.component.css'],
})
export class InspectorComponent extends CommonComponent implements OnInit {
  showModal: boolean = false;
  sponsorName: string;
  isSubmitting: boolean = false;
  memberGroup: FormGroup;
  positionValue: string;
  allStatesData: AllStates[] = [];
  allMemberDataBySponsorID: MemberRegistrationDetailsBySponsorID[] = [];
  showMessageSponsorID: string = '';
  showMessageParentID: string = '';
  showMessageDisplayID: string = '';
  showMessageUserID: string = '';
  showMessagePhoneNumber: string = '';
  gridView: boolean = false;
  columns = [
    'Downline ID',
    'Downline Name',
    'Position Vacant',
    'Position Eligible',
    'Is Active',
    'Is Green',
    'Contact',
    'Action',
  ];
  index = [
    'displayId',
    'name',
    'positionVacant',
    'positionEligible',
    'isActive',
    'isGreen',
    'phNo',
    'action',
  ];
  isCheckedLeft: boolean = false;
  isCheckedRight: boolean = false;
  isCheckedBoth: boolean = false;
  public _selectedNode: go.Node;
  public data = {
    name: null,
    displayId: null,
    doj: null,
    level: null,
    parentId: null,
    key: null,
    walletBalance: null,
    parent: null,
    address: null,
    validity: null,
    clicked: null,
    isGreen: null,
    position: null
  };
  isAdmin: boolean = false;
  @Input()
  public model: go.Model;
  displayId: string;

  @Input()
  get selectedNode() {
    return this._selectedNode;
  }
  set selectedNode(node: go.Node) {
    if (node && node != null) {
      this._selectedNode = node;
      this.data.name = this._selectedNode.data.name;
      this.data.displayId = this._selectedNode.data.displayId;
      this.data.doj = this._selectedNode.data.doj;
      this.data.address = this._selectedNode.data.address;
      this.data.clicked = this._selectedNode.data.clicked;
      this.data.key = this._selectedNode.data.key;
      this.data.level = this._selectedNode.data.level;
      this.data.parent = this._selectedNode.data.parent;
      this.data.parentId = this._selectedNode.data.parentId;
      this.data.validity = this._selectedNode.data.validity;
      this.data.walletBalance = this._selectedNode.data.walletBalance;
      this.data.isGreen = this._selectedNode.data.isGreen;
      this.data.position = this._selectedNode.data.position;
      this.treeRestructure(this.data);
    } else {
      this._selectedNode = null;
    }
  }
  @Output() treeData = new EventEmitter();
  position = ['Left','Right'];
  // downlineReportsTableData: DownlineReportsTableData[] = [];
  constructor(
    private memberService: MembersService,
    public fb: FormBuilder,
    private downlineService: DownlineService,
    private userAuthService: UserAuthService
  ) {
    super();
    this.memberGroup = this.fb.group({
      sponsorId: new FormControl('', [Validators.required]),
      parentId: new FormControl('', [Validators.required]),
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required]),
      phno: new FormControl('', [Validators.required]),
      pincode: new FormControl('', [Validators.required]),
      // userId: new FormControl('', [Validators.required]),
      position: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
    });
  }
  ngOnInit(): void {
    this.memberService.getAllStatesData().subscribe((value) => {
      this.allStatesData = value.data;
    });

    // const downlineReportFilterData = new DownlineReportFilter();
    // this.downlineService.getDownlineTableData(downlineReportFilterData).subscribe((value) => {
    //   this.downlineReportsTableData = value.data.downlineReports;
    //   this.gridView = true;
    // });

    if (this.userAuthService.getRoles() === 'ADMIN') {
      this.isAdmin = true;
    }

      this.displayId = this.userAuthService.getDisplayId();
  }

  submitMember() {
    if (this.isSubmitting) {
      return;
    }
    this.isSubmitting = true;
    console.log(this.memberGroup.value);
    const memberPostForm = new MemberRegistration();
    memberPostForm.sponsorID = this.memberGroup.controls['sponsorId'].value;
    memberPostForm.parentID = this.memberGroup.controls['sponsorId'].value;
    memberPostForm.name = this.memberGroup.controls['name'].value;
    memberPostForm.emailId = this.memberGroup.controls['email'].value;
    memberPostForm.phNo = this.memberGroup.controls['phno'].value;
    memberPostForm.pinCode = this.memberGroup.controls['pincode'].value;
    // memberPostForm.userName = this.memberGroup.controls['userId'].value;
    memberPostForm.isleft =
      this.memberGroup.controls['position'].value == 'Left' ? true : false;
    memberPostForm.stateCode = this.memberGroup.controls['state'].value;

    if(this.showMessageSponsorID.length == 0 && this.memberGroup.controls['sponsorId'].value){
      //if(this.showMessageParentID.length == 0 && this.memberGroup.controls['parentId'].value){
        this.memberService.postRegistrationData(memberPostForm).subscribe((val) => {
          if (val.data) {
            console.log('Data Updated');
            const name = val.data.name;
            const displayId = val.data.displayId;
            const msg = 'Hi' + name + 'Welcome to Vedikmart\n' + 'Your ID is :' + displayId;
            this.successSwal(msg);
            // alert('Registration completed successfully!');
            this.memberGroup.reset();
            this.isSubmitting = false;
          } else {
            if(val.message){
              this.warningSwal(val.message);
              this.isSubmitting = false;
            }else{
              this.dangerSwal('Please fill the mandatory fields');
              this.isSubmitting = false;
              // alert("Please fill the mandatory fields");
            }
          }
        });
      // }else{
      //   this.dangerSwal('Please enter desire Parent ID');
      //   // alert("Please enter desire Parent ID");
      // }
    }else{
      this.dangerSwal('Please enter desire Sponsor ID');
      this.isSubmitting = false;
      // alert("Please enter desire Sponsor ID");
    }
  }

  show() {
    this.showModal = true;
  }

  hide() {
    this.showModal = false;
  }

  checkSponsorID(event: any) {
    this.sponsorName = '';
    let sponsorId = this.memberGroup.controls['sponsorId'].value;
    // if(displayId){
    //   this.memberService.getverifyMemberInfoDisplayId(displayId).subscribe((val) => {
    //     if (val.data) {
    //       console.log("matched");
    //       this.showMessageSponsorID ='';
    //       this.memberService.getRgistrationEligibleSponsorOrg(displayId).subscribe((value) => {
    //         this.gridView = true;
    //         this.allMemberDataBySponsorID = value.data;
    //         console.log(this.allMemberDataBySponsorID);
    //       });
    //     }
    //     else{
    //       this.showMessageSponsorID = "Please enter desire Sponsor ID";
    //       this.gridView = false;
    //     }
    //   });
    // }
    if (sponsorId) {
      this.memberService
        .getverifyMemberInfoDisplayId(sponsorId)
        .subscribe((val) => {
          if (val.data) {
            console.log('matched');
            this.showMessageSponsorID = '';
            this.sponsorName = val.data.name;
          } else {
              this.showMessageSponsorID = 'Please enter desire Sponsor ID';
          }
        });
    }
  }

  checkParentID(event: any) {
    let parentId = this.memberGroup.controls['parentId'].value;
    if (parentId) {
      this.memberService
        .getverifyMemberInfoDisplayId(parentId)
        .subscribe((val) => {
          if (val.data) {
            console.log('matched');
            this.showMessageParentID = '';
          } else {
            this.showMessageParentID = 'Please enter desire Parent ID';
          }
        });
    }
  }

  checkUserID(event: any) {
    let userId = this.memberGroup.controls['userId'].value;
    if (userId) {
      this.memberService
        .getverifyMemberInfoNameDuplication(userId)
        .subscribe((val) => {
          if (val.data) {
            console.log('matched');
            this.showMessageUserID = "User ID already exists";
          } else {
            this.showMessageUserID = '';
          }
        });
    }
  }

  checkPhoneNumber(event: any) {
    let displayId = this.memberGroup.controls['sponsorId'].value;
    let phno = this.memberGroup.controls['phno'].value;
    if (phno) {
      this.memberService
        .getverifyMemberMobileNumber(displayId, phno)
        .subscribe((val) => {
          if (val.message == null) {
            console.log('not matched');
            this.showMessagePhoneNumber = '';
          } else {
            this.showMessagePhoneNumber = 'Please enter other Phone Number';
          }
        });
    }
  }

  getRowData(reports: any) {
    this.gridView = false;
    this.memberGroup.controls['parentId'].setValue(reports.displayId);
    if (reports.positionVacant == 'Left') {
      this.isCheckedLeft = true;
      this.isCheckedRight = false;
      this.isCheckedBoth = false;
    } else if (reports.positionVacant == 'Right') {
      this.isCheckedLeft = false;
      this.isCheckedRight = true;
      this.isCheckedBoth = false;
    } else {
      this.isCheckedLeft = false;
      this.isCheckedRight = false;
      this.isCheckedBoth = true;
    }

    if (reports.positionEligible === 'Left') {
      this.memberGroup.controls['position'].setValue('Left');
    } else if (reports.positionEligible === 'Right') {
      this.memberGroup.controls['position'].setValue('Right');
    }
  }

  treeRestructure(data: any) {
    this.treeData.emit(data);
  }
}
