import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { StockSearchDetails } from '../../model/purchase.model';
import { PurchaseService } from '../../services/purchase.service';
import { CommonComponent } from 'src/app/theme/shared/common/common.component';

@Component({
  selector: 'app-stock-search',
  templateUrl: './stock-search.component.html',
  styleUrls: ['./stock-search.component.scss'],
  providers: [DatePipe]
})
export class StockSearchComponent extends CommonComponent implements OnInit {
  stockFilterGroup: FormGroup;
  stockSearchData: StockSearchDetails;
  gridView: boolean = false;
  columns = ['Entered By','Item Count','Item Id','Item Name','Price','Stock Date','Stock Id','Unit Id','Action'];
  constructor(
    public fb: FormBuilder,
    private purchaseService: PurchaseService,
  ) {
    super();
    this.stockFilterGroup = this.fb.group({
      from: new FormControl('', [Validators.required]),
      to: new FormControl('', [Validators.required])
    });
   }

  ngOnInit(): void {

  }

  submitStockSearch(){
    this.purchaseService.getStockSearchData(this.stockFilterGroup.controls['from'].value,this.stockFilterGroup.controls['to'].value).subscribe((val)=>{
      if(val){
        this.stockSearchData = val.data;
        this.gridView = true;
      }
    })
  }

  cancel(){

  }

  deleteStockEntry(stockId: any){
    this.purchaseService.deleteStockEntryData(stockId).subscribe((val)=>{
      if(val){
        this.purchaseService.getStockSearchData(this.stockFilterGroup.controls['from'].value,this.stockFilterGroup.controls['to'].value).subscribe((val)=>{
          if(val){
            this.stockSearchData = val.data;
            this.gridView = true;
          }
        })
      }
    })
  }
}
