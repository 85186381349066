import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminRoleGuard } from '../auth/guard/admin-role.guard';
import { AuthGuard } from '../auth/guard/auth.guard';
import { CartComponent } from './components/cart/cart.component';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { ItemDetailsComponent } from './components/item-details/item-details.component';
import { OrdersComponent } from './components/orders/orders.component';
import { PurchaseReportComponent } from './components/purchase-report/purchase-report.component';
import { ShopComponent } from './components/shop/shop.component';
import { StockEntryComponent } from './components/stock-entry/stock-entry.component';
import { StockSearchComponent } from './components/stock-search/stock-search.component';
import { PurchaseDeleteComponent } from './components/purchase-delete/purchase-delete.component';

const routes: Routes = [
  {
    path: 'purchase-report',
    component: PurchaseReportComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'shop',
    component: ShopComponent,
    canActivate: [AdminRoleGuard],
  },
  {
    path: 'shop/item/:itemId',
    component: ItemDetailsComponent,
    canActivate: [AdminRoleGuard],
  },
  {
    path: 'cart',
    component: CartComponent,
    canActivate: [AdminRoleGuard],
  },
  {
    path: 'cart/checkout',
    component: CheckoutComponent,
    canActivate: [AdminRoleGuard],
  },
  {
    path: 'orders',
    component: OrdersComponent,
    canActivate: [AdminRoleGuard],
  },
  {
    path: 'purchase-delete',
    component: PurchaseDeleteComponent,
    canActivate: [AdminRoleGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PurchaseRoutingModule {}
