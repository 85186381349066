import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoggerService } from 'src/app/theme/shared/log/logger.service';
import { environment } from 'src/environments/environment';
import { AbstractHttpService } from '../../../theme/shared/base/abstract-http';
import { AddToCart, ItemsDetails, Orders, Purchase, RemoveItem, SearchItems, StockRequest } from '../model/purchase.model';

@Injectable({
  providedIn: 'root'
})

export class PurchaseService extends AbstractHttpService {
  private _allItemsData : string = environment.baseUrl + "api/v1/searchitems";
  private _allCartData : string = environment.baseUrl + "api/v1/viewitemsfromcart/";
  private _removeItemData : string = environment.baseUrl + "api/v1/removeitemfromcart/";
  private _addToCartData : string = environment.baseUrl + "api/v1/addtocart";
  private _purchase : string = environment.baseUrl + "api/v1/purchase";
  private _myOrders: string = environment.baseUrl + "api/v1/vieworders";
  private _myOrdersAdmin: string = environment.baseUrl + "api/v1/adminvieworders";
  private _invoice: string = environment.baseUrl + "api/v1/invoice";
  private _stockInvoice: string = environment.baseUrl + "api/v1/stockInvoice";
  private _stockentry: string = environment.baseUrl + "api/v1/stockentry";
  private _searchstock: string = environment.baseUrl + "api/v1/searchstock";
  private _units: string = environment.baseUrl + "api/v1/units/";
  private _getpurchasedeletedata: string = environment.baseUrl + "api/v1/getpurchasedata";
  private _deletepurchasedeletedata: string = environment.baseUrl + "api/v1/rollbackpurchase";
  private _getitemwisestock: string = environment.baseUrl + "api/v1/getitemwisestock";
  private _stockPurchase: string = environment.baseUrl + "api/v1/stockpurchase";

  constructor(
    protected  http: HttpClient,
    protected  logger: LoggerService
    ) {
    super('PurchaseService', http, logger);
  };

  getAllItemsData(searchForm: SearchItems): Observable<any>{
    return this.http.post<ItemsDetails>(this._allItemsData, searchForm);
  }

  getAllCartData(displayId: any): Observable<any>{
    const url = this._allCartData + `${displayId}`;
    return this.http.get<any>(url);
  }

  removeCartItemData(cartId: any): Observable<any>{
    const url = this._removeItemData + `${cartId}`;
    return this.http.delete<any>(url);
  }

  postAddToCartData(addToCartData: AddToCart): Observable<any>{
    return this.http.post<any>(this._addToCartData ,[addToCartData]);
  }

  postPurchaseCartData(purchaseCartData: Purchase): Observable<any>{
    return this.http.post<any>(this._purchase, purchaseCartData);
  }

  getAllOrdersData(displayId: string,orderDateFrom: string,orderDateTo: string,stateCode: string): Observable<any>{
    const url = this._myOrders + `?displayId=${displayId}` + `&orderDateFrom=${orderDateFrom}` + `&orderDateTo=${orderDateTo}` + `&stateCode=${stateCode}`;
    return this.http.get<Orders>(url);
  }

  getInvoice(orderNo: string,displayId: string): Observable<any>{
    const url = this._invoice + `?orderNo=${orderNo}` + `&displayId=${displayId}`;
    return this.http.get<any>(url);
  }

  geStocktInvoice(stockRequestId: string,displayId: string): Observable<any>{
    const url = this._stockInvoice + `?stockRequestId=${stockRequestId}` + `&displayId=${displayId}`;
    return this.http.get<any>(url);
  }

  postStockEntryData(stockEntryData: any): Observable<any>{
    return this.http.post<any>(this._stockentry, stockEntryData);
  }

  getStockSearchData(from: string,to: string): Observable<any>{
    const url = this._searchstock + `?from=${from}` + `&to=${to}`;
    return this.http.get<any>(url);
  }

  deleteStockEntryData(stockId: any): Observable<any>{
    const url = this._stockentry + '/' + `${stockId}`;
    return this.http.delete<any>(url);
  }

  getAllUnitsData(itemId: string): Observable<any>{
    const url = this._units + `${itemId}`;
    return this.http.get<any>(url);
  }

  getPurchaseDeleteGridData(itemVal: string): Observable<any>{
    const url = this._getpurchasedeletedata + `?itemVal=${itemVal}`;
    return this.http.get<any>(url);
  }

  deletePurchasedData(type: string,itemVal: string): Observable<any>{
    const url = this._deletepurchasedeletedata + `?type=${type}` + `&itemVal=${itemVal}`;
    return this.http.delete<any>(url);
  }

  getItemwiseStockData(): Observable<any>{
    return this.http.get<any>(this._getitemwisestock);
  }
}
