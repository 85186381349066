<ng-container *ngIf="!loading; then thenTemplate; else elseTemplate"></ng-container>
<ng-template #thenTemplate>
    <ng-container *ngIf="isDataAvailable; else elseTemplate">
        <div class="row btn-page" *ngIf="gridView">
            <div class="col-sm-12 btn-page">
                <app-card [hidHeader]="true" cardClass="card-datatable">
                    <div class="col-sm-12 text-right">
                        <!-- <button type="button" class="btn btn-primary btn-sm" (click)="exportexcel()">Export to Excel</button> -->
                    </div>
                    <div class="table-responsive">
                        <table datatable id="excel-table" class="table table-striped table-hover mb-0 table-responsive">
                            <thead>
                                <tr>
                                    <th *ngFor="let col of columns">
                                        {{col}}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of franchiseApproval; let i=index; ">
                                    <th scope="row ">{{ data?.requestId }}</th>
                                    <th scope="row ">{{ data?.displayId }}</th>
                                    <th scope="row ">{{ data?.name }}</th>
                                    <th scope="row ">{{ data?.phNo }}</th>
                                    <th scope="row ">{{ data?.stateName }}</th>
                                    <th scope="row ">{{ data?.mlmmemberId }}</th>
                                    <th scope="row ">{{ data?.mlmMemberName }}</th>
                                    <th><button class="btn-sm btn-warning" (click)="isApprove(data)">Approve</button><button class="btn-sm btn-danger" (click)="isReject(data)">Reject</button></th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </app-card>
            </div>
        </div>
    </ng-container>
    <ng-template #elseTemplate>
        <div class="spinner" *ngIf="!noData">
            <h5>Loading...</h5>
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
        </div>
        <div *ngIf="noData">
            <h1 style="text-align: center;">No Data Available</h1>
        </div>
    </ng-template>
</ng-template>
<ng-template #elseTemplate>
</ng-template>