import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoggerService } from 'src/app/theme/shared/log/logger.service';
import { environment } from 'src/environments/environment';
import { AbstractHttpService } from '../../../theme/shared/base/abstract-http';

@Injectable({
  providedIn: 'root'
})
export class MemberTreeService extends AbstractHttpService {
  private _memberTree : string = environment.baseUrl +"api/v1/treeinfobasic"
  private _searchQuery : string = environment.baseUrl +"api/v1/searchByNameOrId"
  //api/v1/treeinfobasic?displayId=1000112234&highestKey=15&requestedKey=11
  constructor(
    protected  http: HttpClient,
    protected  logger: LoggerService
    ) {
    super('MemberTreeService', http, logger);
  };

  getMemberTreeData(displayId: number,highestKey: number,requestedKey: number): Observable<any>{
    const url = this._memberTree + `?displayId=${displayId}` + `&highestKey=${highestKey}` + `&requestedKey=${requestedKey}`;
    return this.http.get<any>(url);
  }

  searchMembers(displayId: string): Observable<any>{
    const url = this._searchQuery + `?searchQuery=${displayId}`;
    return this.http.get<any>(url);
  }

}
