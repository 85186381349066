<div class="row justify-content-center">
    <div class="col-sm-12" *ngIf="viewFilter">
        <app-card [hidHeader]="true" blockClass="text-center">
            <div class="row align-items-center m-l-0">
                <div class="col-sm-6 text-left">
                    <h5>User Management</h5>
                </div>
            </div>
            <form [formGroup]="searchForm" class="form">
                <div class="app-modal-body" class="col-sm-8 text-left">
                    <!-- <div class="row"> -->
                    <div>
                        <div class="form-group">
                            <input type="text" class="form-control" formControlName="search" placeholder="Use search to find members. You can search by: Name or Display ID">
                        </div>
                        <button type="submit" class="btn btn-primary" (click)="submitSearch()">Submit</button>
                        <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="reset()">Cancel</button>
                    </div>
                    <!-- </div> -->
                </div>
            </form>
        </app-card>
    </div>

    <ng-container *ngIf="true; then thenTemplate; else elseTemplate"></ng-container>
    <ng-template #thenTemplate>
        <ng-container *ngIf="true">
            <div class="col-lg-4 col-md-6 " *ngFor="let item of franchiseTableData">
                <app-card [hidHeader]="true" cardClass="user-card user-card-1 mt-4">
                    <div class="user-about-block text-center ">
                        <div class="row align-items-end ">
                            <div class="col text-left ">
                                <span class="badge badge-success">Active</span>
                                <!-- <span class="badge badge-danger" *ngIf="!item.isGreen">Inactive</span> -->
                            </div>
                            <div class="col "><img class="img-radius img-fluid wid-80 " src="assets/images/user/avatar-sample.jpg " alt="User image"></div>
                            <div class="col text-right pb-3 ">
                                <!-- <div class="dropdown " ngbDropdown placement="auto ">
                              <a class="drp-icon dropdown-toggle " ngbDropdownToggle data-toggle="dropdown " aria-haspopup="true " aria-expanded="false "><i class="feather icon-more-horizontal "></i></a>
                              <div class="dropdown-menu dropdown-menu-right " ngbDropdownMenu>
                                  <a class="dropdown-item " href="javascript: ">Edit</a>
                                  <a class="dropdown-item " href="javascript: ">History</a>
                                  <a class="dropdown-item " href="javascript: ">Trash</a>
                              </div>
                          </div> -->
                            </div>
                        </div>
                    </div>
                    <div class="text-center ">
                        <a href="javascript: " data-toggle="modal " data-target="#modal-report ">
                            <h4 class="mb-1 mt-3 ">{{item?.name}}</h4>
                        </a>
                        <p class="mb-2 text-muted "><i class="feather icon-calendar "> </i> {{item?.activeOn?.substring(0,10)}}</p>

                        <a type="button" class="btn btn-icon btn-info col-sm-3  mb-2 " style="color: black" [routerLink]="['view-profile',item.displayId]"><i class="feather icon-info"></i></a>
                        <br>
                        <p class="mb-0 "><b>Display ID : </b> {{item?.displayId}}</p>
                        <p class="mb-0 "><b>Phone Number : </b> {{item?.phNo}}</p>
                        <p class="mb-0 "><b>Email :</b> {{item?.emailId}}</p>
                        <!-- <p class="mb-0 "><b>Sponsor ID : </b>{{item.sponsorName}} ({{item.sponsorID}})</p>
                        <p class="mb-2 "><b>Parent ID : </b> {{item.parentName}} ({{item.parentID}})</p> -->
                        <p class="mb-0 col-sm-3 badge badge-success" style="color: black">Wallet : {{item?.walletBalance?.toFixed(2)}}</p>
                        <br>
                    </div>
                </app-card>
            </div>
        </ng-container>
    </ng-template>
    <ng-template #elseTemplate>
        <div class="spinner">
            <h5>Loading...</h5>
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
        </div>
    </ng-template>

</div>