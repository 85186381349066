import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FranchiseRoleGuard } from '../auth/guard/franchise-role.guard';
import { CartComponent } from '../franchise-purchase/components/cart/cart.component';
import { CheckoutComponent } from '../franchise-purchase/components/checkout/checkout.component';
import { ItemDetailsComponent } from '../franchise-purchase/components/item-details/item-details.component';
import { PurchaseComponent } from '../franchise-purchase/components/purchase/purchase.component';
import { ShopComponent } from '../franchise-purchase/components/shop/shop.component';
import { PurchaseReportComponent } from './components/purchase-report/purchase-report.component';
import { MultiPurchaseComponent } from './components/multi-purchase/multi-purchase.component';

const routes: Routes = [
  {
    path: 'purchase',
    component: PurchaseComponent,
    canActivate: [FranchiseRoleGuard],
  },
  {
    path: 'multi-purchase',
    component: MultiPurchaseComponent,
    canActivate: [FranchiseRoleGuard],
  },
  {
    path: 'shop/:displayId',
    component: ShopComponent,
    canActivate: [FranchiseRoleGuard],
  },
  {
    path: 'shop/:displayId/item/:itemId',
    component: ItemDetailsComponent,
    canActivate: [FranchiseRoleGuard],
  },
  {
    path: 'cart/:displayId',
    component: CartComponent,
    canActivate: [FranchiseRoleGuard],
  },
  {
    path: 'checkout/:displayId',
    component: CheckoutComponent,
    canActivate: [FranchiseRoleGuard],
  },
  {
    path: 'purchase-report',
    component: PurchaseReportComponent,
    canActivate: [FranchiseRoleGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FranchisePurchaseRoutingModule {}
