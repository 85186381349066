import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { GstStateMonthwiseReportTableData, GstStatewiseReportTableData } from '../../../report/model/gst-statewise-report.model';
import { UserAuthService } from '../../../auth/services/user-auth.service';
import { ExcelExportService } from '../../../report/services/excel-export.service';
import { CommonComponent } from '../../../../theme/shared/common/common.component';
import { WalletService } from '../../services/wallet.service';
import { FranchiseCommission } from '../../model/wallet.model';

@Component({
  selector: 'app-franchise-commission',
  templateUrl: './franchise-commission.component.html',
  styleUrls: ['./franchise-commission.component.scss'],
})
export class FranchiseCommissionComponent extends CommonComponent implements OnInit {
  years: number[] = [];
    FilterFormGroup: FormGroup;
    dropdownList: { item_id: number; item_text: string }[] = [];
    dropdownSettings: IDropdownSettings = {};
    monthSet: string = '';
    isDataAvailable: boolean;
    isStateDataAvailable: boolean;
    gridView: boolean = false;
    franchiseCommission: FranchiseCommission[] = [];
    gstReportStateMonthwiseTableData: GstStateMonthwiseReportTableData[] = [];
    fileName = 'ExcelSheet.xlsx';
    loading: boolean = true;
    stateDataloading: boolean = true;
    isAdmin: boolean = false;

    columns = [
      'Year',
      'Month',
      'Total Amount',
    ];
    stateAndMonthColumns = [
      'Year',
      'Month',
      'Total Amount',
      'Quantity',
      'Total Price',
      'Total GST',
      'GST Percentage',
      'State Name'
    ];
  
    noData: boolean;
    noStateData: boolean;

  constructor(private walletService: WalletService,
      public fb: FormBuilder,
      private userAuthService: UserAuthService,
      private excelExportService: ExcelExportService) {
        super();
        this.FilterFormGroup = this.fb.group({
          year: new FormControl('', [Validators.required]),
          month: new FormControl('', [Validators.required]),
        });
        this.isDataAvailable = this.franchiseCommission?.length > 0;
        this.isStateDataAvailable = this.gstReportStateMonthwiseTableData?.length > 0;
       }

  ngOnInit(): void {
    if (this.userAuthService.getRoles() === 'ADMIN') {
      this.isAdmin = true;
    }
    for (let i = 2022; i <= 2050; i++) {
      this.years.push(i);
    }

    this.dropdownList = [
      { item_id: 1, item_text: 'January' },
      { item_id: 2, item_text: 'February' },
      { item_id: 3, item_text: 'March' },
      { item_id: 4, item_text: 'April' },
      { item_id: 5, item_text: 'May' },
      { item_id: 6, item_text: 'June' },
      { item_id: 7, item_text: 'July' },
      { item_id: 8, item_text: 'August' },
      { item_id: 9, item_text: 'September' },
      { item_id: 10, item_text: 'October' },
      { item_id: 11, item_text: 'November' },
      { item_id: 12, item_text: 'December' },
    ];
    this.dropdownSettings = {
      idField: 'item_id',
      textField: 'item_text',
    };
  }

  search() {
    this.monthSet = '';
    console.log(this.FilterFormGroup.value);
    let months = this.FilterFormGroup.controls['month'].value;
    months.forEach(element => {
      this.monthSet = this.monthSet + ',' + element.item_id;
    });
    this.monthSet = this.monthSet.slice(1);
    this.franchiseCommission = [];
    this.gstReportStateMonthwiseTableData = [];
    this.gridView = false;
    this.walletService.getFranchiseCommissionByYearMonth(this.FilterFormGroup.controls['year'].value,this.monthSet).subscribe((val)=>{
      if(val){
        this.franchiseCommission = val.data;
        this.isDataAvailable = this.franchiseCommission?.length > 0;
        this.noData = this.franchiseCommission?.length === 0;
        this.gridView = true;
      }
      this.loading = false;
    })
    this.walletService.getFranchiseCommissionByYearMonth(this.FilterFormGroup.controls['year'].value,this.monthSet).subscribe((val)=>{
      console.log(val)
      if(val){
        this.gstReportStateMonthwiseTableData = val.data;
        this.isStateDataAvailable = this.gstReportStateMonthwiseTableData?.length > 0;
        this.noStateData = this.gstReportStateMonthwiseTableData?.length === 0;
        this.gridView = true;
      }
      this.stateDataloading = false;
      console.log('now stateDataloading', this.stateDataloading)
      console.log('now isStateDataAvailable', this.isStateDataAvailable)
      
    })
    
  }


  reset() {
    this.FilterFormGroup.reset();
    this.gridView = false;
  }

  onItemSelect(item: any) {
    console.log('onItemSelect', item);
  }
  onItemDeSelect(item: any) {
    console.log('onItemDeSelect', item);
  }
  onSelectAll(items: any) {
    console.log('onSelectAll', items);
  }
  onUnSelectAll() {
    console.log('onUnSelectAll fires');
  }

  exportexcel(): void {
    this.excelExportService.exportExcel(
      this.franchiseCommission,
      'monthwise-gst-report'
    );
  }

  exportStateAndMonthexcel(): void {
    this.excelExportService.exportExcel(
      this.franchiseCommission,
      'franchise-commission-report'
    );
  }

  getTotalAmount(): number {
    return this.franchiseCommission.reduce((sum, item) => sum + (item.totalamount || 0), 0);
  }

  getMonthName(month: string | number): string {
    const monthMap: { [key: number]: string } = {
      1: 'January', 2: 'February', 3: 'March', 4: 'April',
      5: 'May', 6: 'June', 7: 'July', 8: 'August',
      9: 'September', 10: 'October', 11: 'November', 12: 'December'
    };
  
    const monthNumber = Number(month); // Convert string to number
    return monthMap[monthNumber] || 'Unknown';
  }
  
  

}
