<div class="row mb-n5" style="text-align: center;">
    <div class="col-md-12 col-xl-3">
        <app-card [hidHeader]="true" cardClass="bg-c-green borderless">
            <h6 class="text-c-black" style="font-weight: bold;">Display ID : {{userInformations?.displayId}}</h6>
        </app-card>
    </div>

    <div class="col-md-12 col-xl-3">
        <app-card [hidHeader]="true" cardClass="bg-c-green borderless">
            <h6 class="text-c-black" style="font-weight: bold;">Name : {{userInformations?.name}}</h6>
        </app-card>
    </div>

    <div class="col-md-12 col-xl-3">
        <app-card [hidHeader]="true" cardClass="bg-c-green borderless">
            <h6 class="text-c-black" style="font-weight: bold;">Parent ID : {{userInformations?.parentID}}</h6>
        </app-card>
    </div>

    <div class="col-md-12 col-xl-3">
        <app-card [hidHeader]="true" cardClass="bg-c-green borderless">
            <h6 class="text-c-black" style="font-weight: bold;">Position : {{(userInformations?.isleft === true)?'Left':'Right'}}</h6>
        </app-card>
    </div>
</div>
<br><br>

<div class="row">
    <div class="col-md-8">
        <app-card [hidHeader]="true" [options]="false">
            <aw-wizard #wizard class="arc-wizard checkout" navBarLayout="large-filled-symbols">
                <aw-wizard-step stepTitle="Billing Info" [navigationSymbol]="{ symbol: '&#xe905;', fontFamily: 'feather' }" [style.font-weight]="900">
                    <h5 class="mb-2">Billing information</h5>
                    <p class="text-muted mb-0">Fill the form below in order to send you the order's invoice.</p>
                    <hr/>
                    <form [formGroup]="checkoutGroup" class="form">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label class="d-block">Name</label>
                                            <input type="text" class="form-control" [(ngModel)]="userInformations.name" formControlName="name" placeholder="Name">
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="Email"> Email Address</label>
                                            <input type="email" class="form-control" [(ngModel)]="userInformations.emailId" formControlName="email" placeholder="Email">
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="Phone">Phone</label>
                                            <input type="number" class="form-control" [(ngModel)]="userInformations.phNo" formControlName="phNo" placeholder="Phone No.">
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label class="d-block">Address</label>
                                            <textarea class="form-control" formControlName="address" [(ngModel)]="userInformations.address" rows="3" placeholder="Address"></textarea>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label class="d-block">City/Village</label>
                                            <input type="text" class="form-control" [(ngModel)]="userInformations.cityVillage" formControlName="cityVillage" placeholder="City/Village">
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label class="d-block">State</label>
                                            <select class="form-control" formControlName="state" [(ngModel)]="stateName">
                             <option *ngFor="let states of allStatesData " >{{states.stateName}}</option>
                          </select>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label class="d-block">Pincode</label>
                                            <input type="number" class="form-control" [(ngModel)]="userInformations.pinCode" formControlName="pincode" placeholder="Pincode">
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <a href="home/purchase/shop" class="btn btn-outline-secondary">
                  Continue to Shopping
                </a>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="text-sm-right">
                                            <button type="submit" (click)="checkout()" class="btn btn-danger text-sm-right mt-md-0 mt-2">
                                          <i class="mdi mdi-truck-fast mr-1"></i> Place Order </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </aw-wizard-step>
            </aw-wizard>
        </app-card>
    </div>
    <div class="col-md-4">
        <app-card cardTitle="Order Summary" [options]="false" blockClass="p-0">
            <ul class="list-group list-group-flush">
                <li class="list-group-item py-0" *ngFor="let item of allCartsData">
                    <div class="table-responsive">
                        <table class="table table-borderless mb-0">
                            <tbody>
                                <tr>
                                    <td>
                                        <img src="assets/images/product/prod-1.jpg" alt="contact-img" title="contact-img" class="rounded mr-2" height="48" />
                                        <p class="m-0 d-inline-block align-middle">
                                            <a href="javascript:" class="text-body font-weight-semibold">{{item.itemName}}</a>
                                            <br>
                                            <small>{{item.qty}} x {{item.distributorPrice?.toFixed(2)}}</small>
                                        </p>
                                    </td>
                                    <td class="text-right">
                                        {{(item.qty * item.distributorPrice)?.toFixed(2)}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </li>
            </ul>
            <div class="py-2 p-10">
                <div class="table-responsive">
                    <table class="table table-borderless mb-0 w-auto table-sm float-right text-right">
                        <tbody>
                            <tr>
                                <td>
                                    <h6 class="m-0">Sub Total:</h6>
                                </td>
                                <td>
                                    {{subTotal.toFixed(2)}}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h6 class="m-0">Shipping:</h6>
                                </td>
                                <td>
                                    FREE
                                </td>
                            </tr>
                            <tr class="border-top">
                                <td>
                                    <h5 class="m-0">Total:</h5>
                                </td>
                                <td class="font-weight-semibold">
                                    {{subTotal.toFixed(2)}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </app-card>
    </div>
</div>