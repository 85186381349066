<app-card headerClass="border-0 p-2 pb-0 " cardTitle="Sales Incentives" [options]="false">
    <table class="table table-striped table-responsive">
        <thead>
            <tr>
                <th scope="col" *ngFor="let header of subCategoryHeader">{{header}}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let subCategory of allSubCategoriesData; index as i ">
                <td>{{ subCategory.catId }}</td>
                <td>{{ subCategory.catId }}</td>
                <td>{{ subCategory.catName }}</td>
                <td><button class="btn-sm btn-info" (click)="selectSubCategory(subCategory.catId,subCategory.subCatId,subCategory.subCatName)">Action</button></td>
        </tbody>
    </table>
</app-card>

<app-card [hidHeader]="true">
    <h5>Update Sub Category</h5>
    <hr>
    <form [formGroup]="updateSubCategoryGroup" class="form">
        <div class="app-modal-body">
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="d-block ">Category Id</label>
                        <input type="text" class="form-control" formControlName="catId" placeholder="Category Id">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group ">
                        <label class="d-block">Sub Category ID</label>
                        <input type="text" class="form-control" formControlName="subCatId" placeholder="Sub Category ID">
                    </div>
                </div>
                <div class="col-sm-6 ">
                    <div class="form-group">
                        <label class="d-block">Sub Category Name</label>
                        <input type="text" class="form-control" formControlName="subCatName" placeholder="Sub Category Name">
                    </div>
                </div>
            </div>
            <hr>
            <div class="app-modal-footer ">
                <button type=" button " class="btn btn-danger " data-dismiss="modal" (click)="cancel()">Cancel</button>
                <button type="submit " class="btn btn-primary " (click)="submitUpdateSubCategory() ">Submit</button>
            </div>
        </div>
    </form>
</app-card>
