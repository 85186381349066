import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserAuthService {

  constructor() { }

  public setRoles(roles: string){
    localStorage.setItem("roles",roles);
  }

  public setDisplayId(displayId: string){
    localStorage.setItem("displayId", displayId);
  }

  public setUserName(userName: string){
    localStorage.setItem("userName", userName);
  }

  public getRoles(): string{
    return localStorage.getItem("roles");
  }

  public getUserName(): string{
    return localStorage.getItem("userName");
  }

  public getDisplayId(): string{
    return localStorage.getItem("displayId");
  }

  public setToken(jwtToken: string){
    localStorage.setItem("jwtToken",jwtToken);
  }

  public getToken(): string{
    return localStorage.getItem("jwtToken");
  }

  public clear(){
    localStorage.clear();
  }

  public isLoggedIn(){
    return this.getRoles() && this.getToken();
  }
}
