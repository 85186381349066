<div class="row">
    <div class="col-sm-12">
        <app-card cardTitle="Franchise commission" [options]="false">
            <form [formGroup]="FilterFormGroup" class="form">
                <div class="app-modal-body">
                    <div class="row">
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="d-block">Year <span style="color: red;">*</span></label>
                                <select class="form-control" formControlName="year">
                                  <option> </option>
                                  <option *ngFor="let year of years">{{year}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label class="d-block">Month <span style="color: red;">*</span></label>
                                <div>
                                    <ng-multiselect-dropdown formControlName="month" [settings]="dropdownSettings" [data]="dropdownList" (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)" (onDeSelect)="onItemDeSelect($event)" (onDeSelectAll)="onUnSelectAll()"></ng-multiselect-dropdown>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr>
                <div class="app-modal-footer ">
                    <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="reset()">Cancel</button>
                    <button type="submit" class="btn btn-primary" (click)="search()">Search</button>
                </div>
            </form>
        </app-card>
    </div>
</div>

<ng-container *ngIf="!isAdmin && !loading; then thenTemplate; else elseTemplate"></ng-container>
<ng-template #thenTemplate>
    <ng-container *ngIf="isDataAvailable; else elseTemplate">
        <div class="row btn-page" *ngIf="gridView">
            <div class="col-sm-12 btn-page">
                <app-card [hidHeader]="true" cardClass="card-datatable">
                    <span style="font-weight: bold; color: black;">Total Franchise Commission</span>
                    <div class="col-sm-12 text-right">
                        <button type="button" class="btn btn-primary btn-sm" (click)="exportexcel()">Export to Excel</button>
                    </div>
                    <div class="table-responsive">
                        <table datatable id="excel-table" class="table table-striped table-hover mb-0 table-responsive">
                            <thead>
                                <tr>
                                    <th *ngFor="let col of columns">
                                        {{col}}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of franchiseCommission; let i=index; ">
                                    <th scope="row ">{{ data?.year }}</th>
                                    <th scope="row ">{{ getMonthName(data?.month) }}</th>
                                    <th scope="row ">{{ data?.totalamount | number:'1.2-2' }}</th>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr class="total-row">
                                    <th colspan="2" style="text-align: right;">Total:</th>
                                    <th>{{ getTotalAmount()  | number:'1.2-2' }}</th>
                                </tr>
                            </tfoot>                            
                        </table>
                    </div>
                </app-card>
            </div>
        </div>
    </ng-container>
    <ng-template #elseTemplate>
        <div class="spinner" *ngIf="!noData">
            <h5>Loading...</h5>
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
        </div>
        <div *ngIf="noData">
            <h1 style="text-align: center;">No Data Available</h1>
        </div>
    </ng-template>
</ng-template>
<ng-template #elseTemplate>
</ng-template>

<ng-container *ngIf="isAdmin && !stateDataloading; then stateThenTemplate; else stateElseTemplate"></ng-container>
<ng-template #stateThenTemplate>
    <ng-container *ngIf="isStateDataAvailable; else elseTemplate">
        <div class="row btn-page" *ngIf="gridView">
            <div class="col-sm-12 btn-page">
                <app-card [hidHeader]="true" cardClass="card-datatable">
                    <span style="font-weight: bold; color: black;">Month Wise GST with individual state breakup</span>
                    <div class="col-sm-12 text-right">
                        <button type="button" class="btn btn-primary btn-sm" (click)="exportStateAndMonthexcel()">Export to Excel</button>
                    </div>
                    <div class="table-responsive">
                        <table datatable id="excel-table" class="table table-striped table-hover mb-0 table-responsive">
                            <thead>
                                <tr>
                                    <th *ngFor="let col of stateAndMonthColumns">
                                        {{col}}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of gstReportStateMonthwiseTableData; let i=index; ">
                                    <th scope="row ">{{ data?.year }}</th>
                                    <th scope="row ">{{ data?.month }}</th>
                                    <th scope="row ">{{ data?.productName }}</th>
                                    <th scope="row ">{{ data?.quantity }}</th>
                                    <th scope="row ">{{ data?.totalPrice }}</th>
                                    <th scope="row ">{{ data?.totalGST }}</th>
                                    <th scope="row ">{{ data?.gstPercentage }}</th>
                                    <th scope="row ">{{ data?.stateName }}</th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </app-card>
            </div>
        </div>
    </ng-container>
    <ng-template #stateElseTemplate>
        <div class="spinner" *ngIf="!noStateData">
            <h5>Loading...</h5>
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
        </div>
        <div *ngIf="noStateData">
            <h1 style="text-align: center;">No Data Available</h1>
        </div>
    </ng-template>
</ng-template>
<ng-template #stateElseTemplate>
</ng-template>