import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-franchise-request-status',
  templateUrl: './franchise-request-status.component.html',
  styleUrls: ['./franchise-request-status.component.scss']
})
export class FranchiseRequestStatusComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
