import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { StockService } from '../stock.service';
import { CommonComponent } from 'src/app/theme/shared/common/common.component';
import { ApproveRejectStockRequest, PendingStocksResponse, Stock } from '../stock.model';
import { UserAuthService } from '../../auth/services/user-auth.service';
import { ExcelExportService } from '../../report/services/excel-export.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { StockRequest } from '../../purchase/model/purchase.model';
import { CompanyMasterDetails,Invoice, InvoiceDetails, PurchasedItemsDetails } from '../../purchase/model/purchase.model';
import jsPDF from 'jspdf';
const pdfMake = require('pdfmake/build/pdfmake.js');
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;
// pdfMake.vfs = pdfFonts.pdfMake.vfs; // Removed assignment to read-only property
import { PurchaseService } from '../../purchase/services/purchase.service';
import { MembersService } from 'src/app/mlm/member/services/members.service';
import * as htmlToPdfmake from 'html-to-pdfmake';
import { FranchiseDetails } from '../../franchise/model/franchise.model';
@Component({
  selector: 'app-stock-requests',
  templateUrl: './stock-requests.component.html',
  styleUrls: ['./stock-requests.component.scss']
})
export class StockRequestsComponent extends CommonComponent implements OnInit {
  public pendingStocks: Stock[] = [];
  public pendingFranchiseStocks: Stock[] = [];
  columns = [
    'Display Id',
    'Customer Name',
    'Purchase Invoice No.',
    'Date',
    'Total Purchase Amount',
    'Total BV',
    'State',
    'Invoice Download',
  ];
  columnsNotAdmin = [
    'Customer Name',
    'Purchase Invoice No.',
    'Date',
    'Total Purchase Amount',
    'Total BV',
    'State',
    'Invoice Download',
  ];
  stockRequestLoading: boolean = true;
  franchiseStockRequestLoading: boolean = true;
  isDataAvailable: boolean = false;
  isFranchiseDataAvailable: boolean = false;
  allFranchiseStockPresent: boolean = false;
  allCnfStockPresent: boolean = false;
  noData: boolean = true;
  noFranchiseData: boolean = true;
  selectedStock: Stock = null;
  isAdmin: boolean = false;
  isCnf: boolean = false;
  isFranchise: boolean = false;
  public allFranchiseStockRequests: Stock[] = [];
  public allCnfStockRequests: Stock[] = [];
  companyMasterDetails: CompanyMasterDetails;
  franchisesDetails: FranchiseDetails;
  invoiceDetails: InvoiceDetails;
  stock: Stock;
  purchasedItemsDetails: PurchasedItemsDetails[] = [];
  grandTotal: number = 0;
  allStatesData: any[] = [];
  stateNameHold: string = '';

  constructor(private stockService: StockService, 
    private userAuthService: UserAuthService,
    private purchaseService: PurchaseService,
    private memberService: MembersService, 
    private excelExportService: ExcelExportService) {
    super();
    this.selectedStock = null;
    this.isAdmin = (userAuthService.getRoles()==='ADMIN')?true:false;
    this.isCnf = (userAuthService.getRoles()==='CNF')?true:false;
    this.isFranchise = (userAuthService.getRoles()==='FRANCHISE')?true:false;
    stockService.getPendingStocks().subscribe((response: PendingStocksResponse) => {
      if (response && response.data) {
        this.pendingStocks = response.data.map(stock => ({
          ...stock,
          createdOn: new Date(stock.createdOn) // Ensure createdOn is a Date object
        }));
        this.sortStockRequestsByDate();
      }
      this.stockRequestLoading = false;
      this.isDataAvailable = this.pendingStocks.length > 0;
      this.noData = this.pendingStocks.length == 0;
    });

    if (this.isCnf || this.isAdmin) {
      stockService.getPendingFranchiseStocks().subscribe((response: PendingStocksResponse) => {
        if (response && response.data) {
          this.pendingFranchiseStocks = response.data.map(stock => ({
            ...stock,
            createdOn: new Date(stock.createdOn) // Ensure createdOn is a Date object
          }));
          this.sortStockRequestsByDate();
        }
        this.franchiseStockRequestLoading = false;
        this.isFranchiseDataAvailable = this.pendingFranchiseStocks.length > 0;
        this.noData = this.pendingFranchiseStocks.length == 0;
      })
    }
    if (this.isAdmin) {
      stockService.getAllStockRequests(3).subscribe((response => {
        if (response && response.data) {
          this.allFranchiseStockRequests = response.data.map(stock => ({
            ...stock,
            createdOn: new Date(stock.createdOn) // Ensure createdOn is a Date object
          }));
          this.allFranchiseStockPresent = this.allFranchiseStockRequests && this.allFranchiseStockRequests.length > 0;
        }
      }));
      stockService.getAllStockRequests(4).subscribe((response => {
        if (response && response.data) {
          this.allCnfStockRequests = response.data.map(stock => ({
            ...stock,
            createdOn: new Date(stock.createdOn) // Ensure createdOn is a Date object
          }));
          this.allCnfStockPresent = this.allCnfStockRequests && this.allCnfStockRequests.length > 0;
        }
      }));
    }
    
   }

   sortStockRequestsByDate() {
    console.log('Before sorting:', this.pendingStocks);
    this.pendingStocks.sort((a, b) => b.createdOn.getTime() - a.createdOn.getTime());
    console.log('After sorting:', this.pendingStocks);
  }

  ngOnInit(): void {
    this.memberService.getAllStatesData().subscribe((value) => {
      this.allStatesData = value.data;
      console.log(this.allStatesData);
    });
  }

  viewDetails(stock: Stock) {
    this.selectedStock = stock;
  }

  approve(stock: Stock) {
    var approveReject = new ApproveRejectStockRequest();
    approveReject.stockRequestId = stock.stockRequestId;
    approveReject.isApproved = 1;
    approveReject.adminDisplayId = stock.displayId;
    this.stockService.postApproveReject(approveReject).subscribe((response: any) => {
      if (response && response.data) {
        this.pendingStocks = this.pendingStocks.filter((stock: Stock) => stock.stockRequestId !== approveReject.stockRequestId);
      } else if(response.message){
        Swal.fire('', response.message, 'error');
      }

      this.stockService.getPendingFranchiseStocks().subscribe((response: PendingStocksResponse) => {
        if (response && response.data) {
          this.pendingFranchiseStocks = response.data.map(stock => ({
            ...stock,
            createdOn: new Date(stock.createdOn) // Ensure createdOn is a Date object
          }));
          this.sortStockRequestsByDate();
        }
        this.franchiseStockRequestLoading = false;
        this.isFranchiseDataAvailable = this.pendingFranchiseStocks.length > 0;
        this.noData = this.pendingFranchiseStocks.length == 0;
      })
    });
    
  }

  reject(stock: Stock) {
    var approveReject = new ApproveRejectStockRequest();
    approveReject.stockRequestId = stock.stockRequestId;
    approveReject.isApproved = 2;
    approveReject.adminDisplayId = stock.displayId;
    this.stockService.postApproveReject(approveReject).subscribe((response: any) => {
      if (response && response.message) {
        this.pendingStocks = this.pendingStocks.filter((stock: Stock) => stock.stockRequestId !== approveReject.stockRequestId);
      }
      this.stockService.getPendingFranchiseStocks().subscribe((response: PendingStocksResponse) => {
        if (response && response.data) {
          this.pendingFranchiseStocks = response.data.map(stock => ({
            ...stock,
            createdOn: new Date(stock.createdOn) // Ensure createdOn is a Date object
          }));
          this.sortStockRequestsByDate();
        }
        this.franchiseStockRequestLoading = false;
        this.isFranchiseDataAvailable = this.pendingFranchiseStocks.length > 0;
        this.noData = this.pendingFranchiseStocks.length == 0;
      })
    });
  }

  saveDeliveryStatus(event: Event, stock: Stock) {
    const isChecked = (event.target as HTMLInputElement).checked;
    stock.comments = isChecked ? 'product_delivered' : '';
    this.stockService.updateDeliveryStatus(stock).subscribe((response: any) => {
      if (response && response.data) {
        Swal.fire('', response.data);
      } else if(response.message){
        Swal.fire('', response.message, 'error');
      }

      this.stockService.getPendingFranchiseStocks().subscribe((response: PendingStocksResponse) => {
        if (response && response.data) {
          this.pendingFranchiseStocks = response.data.map(stock => ({
            ...stock,
            createdOn: new Date(stock.createdOn) // Ensure createdOn is a Date object
          }));
          this.sortStockRequestsByDate();
        }
        this.franchiseStockRequestLoading = false;
        this.isFranchiseDataAvailable = this.pendingFranchiseStocks.length > 0;
        this.noData = this.pendingFranchiseStocks.length == 0;
      })
    });
  }

  exportexcel(): void {
    this.excelExportService.exportExcel(
      this.pendingStocks,
      'all stocks'
    );
  }

  invoice = new Invoice();

  invoiceDetailsGenerate(stockRequestId: string,displayId: string) {
    this.companyMasterDetails = new CompanyMasterDetails();
    this.invoiceDetails = new InvoiceDetails();
    this.purchasedItemsDetails = [];
    
    this.purchaseService.geStocktInvoice(stockRequestId,displayId).subscribe((val) => {
      if (val) {
        console.log(val.data.companyMaster);
        this.companyMasterDetails = val.data.companyMaster;
       // this.invoiceDetails = val.data.invoice;
        this.stock = val.data.invoice;
        this.grandTotal = val.data.totalPrice;
        this.franchisesDetails = val.data.showFranchiseDetails;
        if (this.invoiceDetails.stateCode) {
          this.memberService.getAllStatesData().subscribe((value) => {
            this.allStatesData = value.data;
            this.allStatesData.forEach((element) => {
              if (element.stateCode == this.invoiceDetails.stateCode) {
                this.stateNameHold = element.stateName;
              }
            });
          });
        }
        this.purchasedItemsDetails = val.data.purchasedItems;
      }
    });
    setTimeout(() => {
      this.downloadAsPDF();
    }, 2000);
  }

  generatePDF(action = 'open') {
    this.invoice.products = this.purchasedItemsDetails;
    console.log(this.invoice.products);
    let docDefinition = {
      content: [
        {
          text: this.companyMasterDetails.name,
          fontSize: 35,
          alignment: 'center',
          color: '#047886',
          bold: true,
        },
        {
          text: 'INVOICE',
          fontSize: 15,
          bold: true,
          alignment: 'center',
          decoration: 'underline',
          color: 'skyblue',
        },
        {
          text: 'Franchies Details',
          style: 'sectionHeader',
        },
        {
          columns: [
            [
              {
                text: this.franchisesDetails.name,
                bold: true,
              },
              { text: this.franchisesDetails.address },
              { text: this.invoiceDetails.shippingAddress },
            ],
            [
              {
                text:
                  'Date: ' + this.stock.createdOn.toISOString().substring(0, 10),
                alignment: 'right',
              },
              {
                text: 'Stock Request No: ' + this.stock.stockRequestId,
                alignment: 'right',
              },
            ],
          ],
        },
        {
          text: 'Stock Request Details',
          style: 'sectionHeader',
        },
        {
          table: {
            headerRows: 1,
            widths: ['*', 'auto', 'auto', 'auto'],
            body: [
              ['Product', 'Price', 'Quantity', 'Amount'],
              ...this.invoice.products.map((p) => [
                p.prodName,
                p.itemPrice,
                p.qty,
                (p.itemPrice * p.qty).toFixed(2),
              ]),
              [
                { text: 'Total Amount', colSpan: 3 },
                {},
                {},
                this.invoice.products
                  .reduce((sum, p) => sum + p.itemPrice * p.qty, 0)
                  .toFixed(2),
              ],
            ],
          },
        },
        {
          text: 'Additional Details',
          style: 'sectionHeader',
        },
        {
          text: this.invoice.additionalDetails,
          margin: [0, 0, 0, 15],
        },
        {
          columns: [
            [{ qr: `${this.franchisesDetails.name}`, fit: '50' }],
            [{ text: 'Signature', alignment: 'right', italics: true }],
          ],
        },
        {
          text: 'Terms and Conditions',
          style: 'sectionHeader',
        },
        {
          ul: [
            'Order can be return in max 10 days.',
            'Warrenty of the product will be subject to the manufacturer terms and conditions.',
            'This is system generated invoice.',
          ],
        },
      ],
      styles: {
        sectionHeader: {
          bold: true,
          decoration: 'underline',
          fontSize: 14,
          margin: [0, 15, 0, 15],
        },
      },
    };

    if (action === 'download') {
      pdfMake.createPdf(docDefinition).download();
    } else if (action === 'print') {
      pdfMake.createPdf(docDefinition).print();
    } else {
      pdfMake.createPdf(docDefinition).open();
    }
  }

  @ViewChild('pdfTable') pdfTable: ElementRef;
  public downloadAsPDF() {
    const doc = new jsPDF();

    const pdfTable = this.pdfTable.nativeElement;

    var html = htmlToPdfmake(pdfTable.innerHTML);

    const documentDefinition = { content: html };
    pdfMake.createPdf(documentDefinition).open();
  }

  isChecked(stock: Stock): boolean {
    return stock.comments === 'product_delivered';
  }
}
