<app-card [hidHeader]="true">
    <h5>Profile Image Upload</h5>
    <hr>
    <form [formGroup]="ProfileImageGroup" class="form">
        <div class="app-modal-body">
            <div class="col-sm-4">
                <label class="d-block">Profile Image Upload</label>
                <img [src]="urlsProfilePicture" class="rounded mb-3" width="180">
                <input class="form-control" [disabled]="" type="file" (change)="onChangeProfileImage($event)" />
            </div>
        </div>
        <hr>
        <div class="app-modal-footer ">
            <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="cancelProfileImage()">Cancel</button>
            <button type="submit" class="btn btn-primary" (click)="submitProfileImage()">Submit</button>
        </div>
    </form>
    <br><br>
    <div class="col">
        <div class="position-relative d-inline-block">
            <img class="rounded mb-3" width="180" src="{{(profilePicture?.filelocation)?profilePicture.filelocation:'assets/images/user/avatar-sample.jpg'}}">
        </div>
    </div>
</app-card>

<app-card [hidHeader]="true">
    <h5>Cancel Cheque/Bank Passbook Upload</h5>
    <hr>
    <form [formGroup]="AadhaarCardGroup" class="form">
        <div class="app-modal-body">
            <div class="col-sm-4">
                <label class="d-block">Cancel Cheque/Bank Passbook Upload</label>
                <img [src]="urlsAadhaarCard" class="rounded mb-3" width="180">
                <input class="form-control" [disabled]="" type="file" (change)="onChangeAadhaarCard($event)" />
            </div>
        </div>
        <hr>
        <div class="app-modal-footer ">
            <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="cancelAadhaarCard()">Cancel</button>
            <button type="submit" class="btn btn-primary" (click)="submitAadhaarCard()">Submit</button>
        </div>
    </form>
</app-card>

<app-card [hidHeader]="true">
    <h5>Pan Card Upload</h5>
    <hr>
    <form [formGroup]="PanCardGroup" class="form">
        <div class="app-modal-body">
            <div class="col-sm-4">
                <label class="d-block">Pan Card Upload</label>
                <img [src]="urlsPanCard" class="rounded mb-3" width="180">
                <input class="form-control" [disabled]="" type="file" (change)="onChangePanCard($event)" />
            </div>
        </div>
        <hr>
        <div class="app-modal-footer ">
            <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="cancelPanCard()">Cancel</button>
            <button type="submit" class="btn btn-primary" (click)="submitPanCard()">Submit</button>
        </div>
    </form>
</app-card>