import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './theme/shared/shared.module';

import { AppComponent } from './app.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

/* Menu Items */
import { NgbButtonsModule, NgbDropdownModule, NgbTabsetModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { MLMModule } from './mlm/mlm.module';
import { NavigationItem } from './theme/layout/admin/navigation/navigation';

import { AuthenticationRoutingModule } from './mlm/auth/authentication-routing.module';
import { MlmRoutingModule } from './mlm/mlm-routing.module';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { AuthGuard } from '../app/mlm/auth/guard/auth.guard';
import { AuthInterceptor } from './mlm/auth/interceptor/auth.interceptor';
import { AuthService } from './mlm/auth/services/auth.service';
import { UserAuthService } from './mlm/auth/services/user-auth.service';
import { AlertModule } from './theme/shared/components';
import {DataTablesModule} from 'angular-datatables';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgbButtonsModule,
    NgbTabsetModule,
    MLMModule,
    AuthenticationRoutingModule,
    MlmRoutingModule,
    NgbModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    DataTablesModule,
    AlertModule,
  ],
  providers: [
    NavigationItem,
    AuthService,
    UserAuthService,
    AuthGuard,
    {
      provide : HTTP_INTERCEPTORS, useClass : AuthInterceptor, multi : true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
