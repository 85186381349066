import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserAuthService } from 'src/app/mlm/auth/services/user-auth.service';
import { AllStates } from 'src/app/mlm/member/model/allstates.model';
import { MembersService } from 'src/app/mlm/member/services/members.service';
import { CommonComponent } from 'src/app/theme/shared/common/common.component';
import { FranchiseRequest } from '../../model/franchise.model';
import { FranchiseService } from '../../services/franchise.service';
import 'sweetalert2/src/sweetalert2.scss';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-franchise-request',
  templateUrl: './franchise-request.component.html',
  styleUrls: ['./franchise-request.component.scss']
})
export class FranchiseRequestComponent extends CommonComponent implements OnInit {
  FranchiseRequestGroup: FormGroup;
  allStatesData: AllStates[] = [];
  isAdmin: boolean;
  displayId: any;

  constructor(
    public fb:FormBuilder,
    private memberService: MembersService,
    private userAuthService: UserAuthService,
    private franchiseService: FranchiseService
  ) {
    super();
    this.FranchiseRequestGroup = this.fb.group({
      name: new FormControl('', [Validators.required]),
      phNo: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      pinCode: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),
      mlmMemberId: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.memberService.getAllStatesData().subscribe((value) => {
      this.allStatesData = value.data;
    });
    this.isAdmin = (this.userAuthService.getRoles()==='ADMIN')?true:false;
    this.displayId = this.userAuthService.getDisplayId();
  }

  submitFranchise(){
    const franchiseRequest = new FranchiseRequest();
    franchiseRequest.name = this.FranchiseRequestGroup.controls['name'].value;
    franchiseRequest.mlmMemberId = this.FranchiseRequestGroup.controls['mlmMemberId'].value;
    franchiseRequest.phNo = this.FranchiseRequestGroup.controls['phNo'].value;
    franchiseRequest.address = this.FranchiseRequestGroup.controls['address'].value;
    franchiseRequest.description = this.FranchiseRequestGroup.controls['description'].value;
    franchiseRequest.pinCode = this.FranchiseRequestGroup.controls['pinCode'].value;
    this.allStatesData.forEach(element => {
      if(element.stateName == this.FranchiseRequestGroup.controls['state'].value){
        franchiseRequest.stateCode = element.stateCode;
      }
    });
    this.franchiseService.getCheckCnfExist(franchiseRequest.stateCode).subscribe((val)=>{
      if(val.message){
        Swal.fire({
          title: 'Are you sure?',
          text: val.message,
          type: 'warning',
          showCloseButton: true,
          showCancelButton: true
      }).then((willDelete) => {
            if(willDelete.dismiss){
              Swal.fire('', 'Franchise Request Cancelled', 'error');
            }else{
              this.franchiseService.postAddFranchiseData(franchiseRequest).subscribe((val)=>{
                if(val.message){
                  Swal.fire('', val.message, 'error');
                }else{
                  Swal.fire('', 'Franchise Requested Successfully', 'success');
                  this.FranchiseRequestGroup.reset();
                  this.FranchiseRequestGroup.controls['mlmMemberId'].setValue(this.userAuthService.getDisplayId());
                }
              })
            }
          });
      }else{
        Swal.fire({
          title: 'Are you sure?',
          text: '',
          type: 'warning',
          showCloseButton: true,
          showCancelButton: true
      }).then((willDelete) => {
            if(willDelete.dismiss){
              Swal.fire('', 'Franchise Request Cancelled', 'error');
            }else{
              this.franchiseService.postAddFranchiseData(franchiseRequest).subscribe((val)=>{
                if(val.message){
                  Swal.fire('', val.message, 'error');
                }else{
                  Swal.fire('', 'Franchise Requested Successfully', 'success');
                  this.FranchiseRequestGroup.reset();
                  this.FranchiseRequestGroup.controls['mlmMemberId'].setValue(this.userAuthService.getDisplayId());
                }
              })
            }
          });
      }
    })

  }

  cancel(){
    this.FranchiseRequestGroup.reset();
    this.FranchiseRequestGroup.controls['mlmMemberId'].setValue(this.userAuthService.getDisplayId());
  }
}
