import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { AllSubCategories } from '../../model/item-master.model';
import { ItemMasterService } from '../../services/item-master.service';
import { CommonComponent } from 'src/app/theme/shared/common/common.component';

@Component({
  selector: 'app-sub-category-update',
  templateUrl: './sub-category-update.component.html',
  styleUrls: ['./sub-category-update.component.scss']
})
export class SubCategoryUpdateComponent extends CommonComponent implements OnInit {
  updateSubCategoryGroup: FormGroup;
  subCategoryHeader = ['Category ID','Sub Category ID','Sub Category Name','Action'];
  allSubCategoriesData: AllSubCategories[] = [];

  constructor(
    private fb: FormBuilder,
    private itemMasterService: ItemMasterService
  ) {
    super();
    this.updateSubCategoryGroup = this.fb.group({
      catId: new FormControl('', [Validators.required]),
      subCatId: new FormControl('', [Validators.required]),
      subCatName: new FormControl('', [Validators.required])
    });
  }

  ngOnInit(): void {
  }

  submitUpdateSubCategory(){

  }

  cancel(){

  }

  selectSubCategory(catId: string,subCatId: string,subCatName: string){

  }

}
