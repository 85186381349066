<div class="row">
    <div class="col-sm-12">
        <app-card cardTitle="Shop" [options]="false">
            <form [formGroup]="searchForm" class="form">
                <div class="row">
                    <div class="col-sm-2">
                        <div class="form-group">
                            <label class="d-block">Category</label>
                            <select class="form-control" formControlName="category">
                              <option></option>
                                  <option *ngFor="let category of allCategoriesData ">{{category.catName}}</option>
                                 </select>
                        </div>
                    </div>
                    <div class="col-sm-2">
                        <div class="form-group">
                            <label class="d-block">Sub Category</label>
                            <select class="form-control" formControlName="subCategory">
                              <option></option>
                                  <option *ngFor="let subCategory of allSubCategoriesData ">{{subCategory.subCatName}}</option>
                                </select>
                        </div>
                    </div>
                    <div class="col-sm-2">
                        <div class="form-group">
                            <label class="d-block">Product</label>
                            <select class="form-control" formControlName="product">
                              <option></option>
                                  <option *ngFor="let product of allProductsData ">{{product.prodName}}</option>
                                </select>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group">
                            <label class="d-block">Item</label>
                            <select class="form-control" formControlName="item">
                            <option></option>
                                <option *ngFor="let item of allItemsDetailsDataOptions ">{{item.itemName}}</option>
                              </select>
                        </div>
                    </div>
                    <div class="col-sm-2" *ngIf="isAdmin">
                        <div class="form-group">
                            <label class="d-block">Is Active</label>
                            <select class="form-control" formControlName="isActive">
                                <option>Active</option>
                                <option>Inactive</option>
                                <option>Both</option>
                              </select>
                        </div>
                    </div>
                </div>
            </form>
            <button type="submit" class="btn btn-primary" (click)="submitSearch()">Submit</button>
        </app-card>
    </div>
</div>
<section class="py-5">
    <div class="container px-4 px-lg-5 mt-5">
        <div class="row gx-4 gx-lg-5 row-cols-2 row-cols-md-3 row-cols-xl-4 justify-content-center">
            <div class="col mb-5" *ngFor="let item of allItemsDetailsData">
                <div class="card h-100">
                    <!-- Product image-->
                    <img class="card-img-top" src="{{item.image}}" alt="..." />
                    <!-- Product details-->
                    <div class="card-body p-4">
                        <div class="text-center">
                            <!-- Product name-->
                            <h5 class="fw-bolder">{{item.itemName}}</h5>
                            <!-- Product price-->
                            Rs: {{item.distributorPrice.toFixed(2)}}
                        </div>
                    </div>
                    <!-- Product actions-->
                    <div class="card-footer p-4 pt-0 border-top-0 bg-transparent">
                        <div class="text-center">
                            <a class="btn btn-outline-dark mt-auto" [routerLink]="['item', encodeUrlComponent(item.itemId)]">View options</a></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>