import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbCarouselModule, NgbDatepickerModule, NgbDropdownModule, NgbTabsetModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
import { SharedModule } from '../../theme/shared/shared.module';
import { DownlineMemberComponent } from './components/downline-member/downline-member.component';
import { MemberRoutingModule } from './member-routing.module';
import { DirectMemberComponent } from '../member/components/direct-member/direct-member.component';
import { ViewProfileComponent } from './components/downline-member/components/view-profile/view-profile.component';
import { UpdateProfileComponent } from './components/downline-member/components/update-profile/update-profile.component';
import { AddFundComponent } from './components/downline-member/components/add-fund/add-fund.component';
import { TransactionReportComponent } from './components/downline-member/components/transaction-report/transaction-report.component'
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { ArchwizardModule } from 'angular-archwizard';
import { ShopComponent } from './components/downline-member/components/shop/shop.component';
import { CartComponent } from './components/downline-member/components/cart/cart.component';
import { ItemDetailsComponent } from './components/downline-member/components/item-details/item-details.component';
import { CheckoutComponent } from './components/downline-member/components/checkout/checkout.component';
import { DiagramComponent } from './components/downline-member/components/diagram/diagram.component';
import { InspectorComponent } from './components/downline-member/components/inspector/inspector.component';
import { DownlineMemberTreeComponent } from './components/downline-member/components/downline-member-tree/downline-member-tree.component';


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    NgbTabsetModule,
    NgbDropdownModule,
    NgbCarouselModule,
    NgbDatepickerModule,
    FormsModule,
    DataTablesModule,
    ReactiveFormsModule,
    MemberRoutingModule,
    ArchwizardModule,
    NgxQRCodeModule
  ],
  declarations: [
    DownlineMemberComponent,
    DirectMemberComponent,
    ViewProfileComponent,
    UpdateProfileComponent,
    AddFundComponent,
    TransactionReportComponent,
    ShopComponent,
    CartComponent,
    ItemDetailsComponent,
    CheckoutComponent,
    DiagramComponent,
    InspectorComponent,
    DownlineMemberTreeComponent
  ]
})
export class MemberModule { }
