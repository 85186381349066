import { Component, OnInit } from '@angular/core';
import { RechargeService } from '../../services/recharge.service';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { CommonComponent } from 'src/app/theme/shared/common/common.component';
import { TransactionEnquiryTableData } from '../../model/transaction-enquiry.model';

@Component({
  selector: 'app-transaction-enquiry',
  templateUrl: './transaction-enquiry.component.html',
  styleUrls: ['./transaction-enquiry.component.scss']
})
export class TransactionEnquiryComponent extends CommonComponent implements OnInit {
  transactionEnquiryGroup: FormGroup;
  isDataAvailable: boolean;
  gridView: boolean = false;
  fileName = 'ExcelSheet.xlsx';
  loading: boolean = true;
  columns = [
    'Request Id',
    'Mobile Number',
    'Description'
  ];
  noData: boolean;
  transactionEnquiryTableData: TransactionEnquiryTableData[] = [];

  constructor(
    private rechargeService: RechargeService,
    private fb: FormBuilder,
  ) {
    super();
    this.transactionEnquiryGroup = this.fb.group({
      requestId: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit(): void {

  }

  submitTransactionEnquiry(){
    this.gridView = false;
    this.rechargeService.getTransactionenquiryData(this.transactionEnquiryGroup.controls['requestId'].value).subscribe((val)=>{
      if(val){
        this.transactionEnquiryTableData = val.data.soperator;
        this.noData = this.transactionEnquiryTableData?.length === 0;
        this.isDataAvailable = this.transactionEnquiryTableData?.length > 0;
        this.gridView = true;
      }
      this.loading = false;
    },error =>{
      this.dangerSwal("Something went wrong please try again after sometime");
    });
  }

  cancel(){
    this.transactionEnquiryGroup.reset();
    this.gridView = false;
  }
}
