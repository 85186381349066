export class ChangePassword {
  displayId: number;
  oldPassword: string;
  newPassword: string;
  retypeNewPassword: string;
}

export class UpdateProfile {
  name: string;
  address: string;
  pinCode: number;
  panNo: string;
  bankName: string;
  ifsc: string;
  accountHolderName: string;
  accountNo: string;
  maritalStatus: string;
  dob: Date;
  stateCode: string;
  displayId: number;
  gender: string;
  cityVillage: string;
  aadharNo: string;
  gurdian: string;
  nominee: string;
  relation: string;
  nomineeDob: Date;
  phNo: number;
  password: string;
}

export class doctype {
  file: File;
  doctype: string;
}
