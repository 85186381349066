import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminRoleGuard } from '../auth/guard/admin-role.guard';
import { AuthGuard } from '../auth/guard/auth.guard';
import { CommissionGenerateComponent } from '../commission-generate/commission-generate.component';
import { BankListComponent } from './components/bank-list/bank-list.component';
import { PaidCommissionComponent } from './components/paid-commission/paid-commission.component';
import { UnpaidCommissionComponent } from './components/unpaid-commission/unpaid-commission.component';
import { WalletReportComponent } from './components/wallet-report/wallet-report.component';
import { MonthwiseCommissionComponent } from './components/monthwise-commission/monthwise-commission.component';
import { AllCommissionsComponent } from './components/all-commissions/all-commissions.component';

const routes: Routes = [
  {
    path: 'unpaid-commission',
    component: UnpaidCommissionComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'paid-commission',
    component: PaidCommissionComponent,
    canActivate: [AdminRoleGuard],
  },
  {
    path: 'bank-list',
    component: BankListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'wallet-report',
    component: WalletReportComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'monthwise-commission',
    component: MonthwiseCommissionComponent,
    canActivate: [AdminRoleGuard],
  },
  {
    path: 'all-commissions',
    component: AllCommissionsComponent,
    canActivate: [AdminRoleGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PayCommissionRoutingModule {}
