import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserAuthService } from 'src/app/mlm/auth/services/user-auth.service';
import { CommonComponent } from 'src/app/theme/shared/common/common.component';
import { ViewCommissionService } from '../../services/view-commission.service';
import { ExcelExportService } from 'src/app/mlm/report/services/excel-export.service';
import { PaidCommissionTableData } from '../../model/view-commission.model';

@Component({
  selector: 'app-paid-commission',
  templateUrl: './paid-commission.component.html',
  styleUrls: ['./paid-commission.component.scss']
})
export class PaidCommissionComponent extends CommonComponent implements OnInit {
  isAdmin: boolean = false;
  totalAmount: number = 0;
  adminCharges: number = 0;
  tdsCharges: number = 0;
  netAmount: number = 0;
  FilterFormGroup: FormGroup;
  fileName = 'ExcelSheet.xlsx';
  loading: boolean = true;
  isDataAvailable: boolean;
  noData: boolean;
  gridView: boolean = false;
  columns = [
    'From Date',
    'To Date',
    'Display Id',
    'Name',
    'Pan No',
    'Commission Name',
    'Pair BV Match',
    'Total Amount',
    'Admin Charge Amount',
    'TDS Amount',
    'Net Amount',
    'Payment Date',
    'Payment Type',
    'Bank Name',
    'Account No',
    'IFSC'
  ];
  paidCommissionsTableData: PaidCommissionTableData[] = [];

  constructor(
    private userAuthService: UserAuthService,
    public fb:FormBuilder,
    private excelExportService: ExcelExportService,
    private viewCommissionService: ViewCommissionService,
  ) {
    super();
    this.FilterFormGroup = this.fb.group({
      displayId: new FormControl('', [Validators.required]),
      commissionFrom: new FormControl('', [Validators.required]),
      commissionTo: new FormControl('', [Validators.required]),
      paymentFrom: new FormControl('', [Validators.required]),
      paymentTo: new FormControl('', [Validators.required]),
    });
    this.isDataAvailable = this.paidCommissionsTableData?.length > 0;
  }

  ngOnInit(): void {
    this.isAdmin = this.userAuthService.getRoles()==='ADMIN'?true:false;
  }

  search(){
    const displayId = this.FilterFormGroup.controls['displayId'].value?this.FilterFormGroup.controls['displayId'].value:0;
    const commissionFrom = this.FilterFormGroup.controls['commissionFrom'].value;
    const commissionTo = this.FilterFormGroup.controls['commissionTo'].value;
    const PaymentFrom = this.FilterFormGroup.controls['paymentFrom'].value;
    const PaymentTo = this.FilterFormGroup.controls['paymentTo'].value;
    this.viewCommissionService.getAllPaidCommissionDetailsData(displayId,commissionFrom,commissionTo,PaymentFrom,PaymentTo).subscribe((val)=>{
      if(!val.errorcode){
        this.paidCommissionsTableData = val.data.paidCommissions;
        this.totalAmount = val.data.sumTotalAmount;
        this.adminCharges = val.data.sumAdminChargesAmount;
        this.tdsCharges = val.data.sumTdsAmount;
        this.netAmount = val.data.sumNetAmount;
        this.isDataAvailable = this.paidCommissionsTableData?.length > 0;
        this.noData = this.paidCommissionsTableData?.length === 0;
        this.gridView = true;
      }
    });
    this.loading = false;
  }

  reset(){
    this.FilterFormGroup.reset();
    this.gridView = false;
  }

  exportexcel(): void {
    this.excelExportService.exportExcel(
      this.paidCommissionsTableData,
      'paid-commissions'
    );
  }
}
