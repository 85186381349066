import { Component, OnInit } from '@angular/core';
import { DownlineService } from '../../services/downline.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ExcelExportService } from '../../services/excel-export.service';
import { CommonComponent } from 'src/app/theme/shared/common/common.component';
import { FirstPurchaseRank } from '../../model/gst-statewise-report.model';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-firstpurchase-rank',
  templateUrl: './firstpurchase-rank.component.html',
  styleUrls: ['./firstpurchase-rank.component.scss']
})
export class FirstpurchaseRankComponent extends CommonComponent implements OnInit {

  years = [];
  monthSet: string = '';
  FilterFormGroup: FormGroup;
  monthList = [];
  dropdownSettings: IDropdownSettings = {};
  loading: boolean = true;
  noData: boolean = true;
  isDataAvailable: boolean = true;
  firstPurchaseRank: FirstPurchaseRank[] = [];

  constructor(
    private downlineService: DownlineService,
    public fb: FormBuilder,
    private excelExportService: ExcelExportService
  ) {
    super();
    this.FilterFormGroup = this.fb.group({
      year: new FormControl('', [Validators.required]),
      month: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit(): void {
    for (let i = 2023; i <= 2030; i++) {
      this.years.push(i);
    }

    this.monthList = [
      { item_id: 1, item_text: 'January' },
      { item_id: 2, item_text: 'February' },
      { item_id: 3, item_text: 'March' },
      { item_id: 4, item_text: 'April' },
      { item_id: 5, item_text: 'May' },
      { item_id: 6, item_text: 'June' },
      { item_id: 7, item_text: 'July' },
      { item_id: 8, item_text: 'August' },
      { item_id: 9, item_text: 'September' },
      { item_id: 10, item_text: 'October' },
      { item_id: 11, item_text: 'November' },
      { item_id: 12, item_text: 'December' },
    ];
    this.dropdownSettings = {
      idField: 'item_id',
      textField: 'item_text',
    };

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;
    this.downlineService.getFirstPurchaseRankReport(currentYear, currentMonth).subscribe((val)=>{
      if(val && val.data){
        this.firstPurchaseRank = val.data;
        console.log(this.firstPurchaseRank)
        this.isDataAvailable = true;
        this.noData = false;
      }
      this.loading = false;
    })
  }

  search() {
    
    this.monthSet = '';
    this.firstPurchaseRank = null;
    console.log(this.FilterFormGroup.value);
    let year: number = this.FilterFormGroup.controls['year'].value;
    let month: number = this.FilterFormGroup.controls['month'].value;
    if (!year || !month) {
      this.warningSwal("Please select year and month");
    }
    this.loading = true;

    this.downlineService.getFirstPurchaseRankReport(year, month).subscribe((val)=>{
      if(val && val.data){
        this.firstPurchaseRank = val.data;
        console.log(this.firstPurchaseRank)
        this.isDataAvailable = true;
        this.noData = false;
      } else {
        this.dangerSwal(val.message)
      }
      this.loading = false;
    })
    
    this.monthSet = this.monthSet.slice(1);
  }

  reset() {
    this.FilterFormGroup.reset();
  }

  exportexcel(): void {
    this.excelExportService.exportExcel(
      this.firstPurchaseRank,
      'monthwise-gst-report'
    );
  }

}
