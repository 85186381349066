import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoggerService } from 'src/app/theme/shared/log/logger.service';
import { environment } from 'src/environments/environment';
import { AbstractHttpService } from '../../../theme/shared/base/abstract-http';
import { PushRequest } from '../model/recharge.model';

@Injectable({
  providedIn: 'root'
})

export class RechargeService extends AbstractHttpService {
  private _getconnectiontype : string = environment.baseUrl + "api/v1/getconnectiontype";
  private _getserviceprovider : string = environment.baseUrl + "api/v1/getserviceprovider";
  private _getrechargetype : string = environment.baseUrl + "api/v1/getrechargetype";
  private _pushrequest : string = environment.baseUrl + "api/v1/recharge";
  private _getproducts : string = environment.baseUrl + "api/v1/getproducts";
  private _setrechargecommissionandstatus : string = environment.baseUrl + "api/v1/setrechargecommissionandstatus";
  private _getbalance : string = environment.baseUrl + "api/v1/getbalance";
  private _transactionenquiry : string = environment.baseUrl + "api/v1/transactionenquiry";
  private _getrechargereport : string = environment.baseUrl + "api/v1/getrechargereport";



  constructor(
    protected  http: HttpClient,
    protected  logger: LoggerService
    ) {
    super('RechargeService', http, logger);
  };

  getConnectionTypeData(): Observable<any>{
    return this.http.get<any>(this._getconnectiontype);
  }

  getServiceProviderData(connectionType: string): Observable<any>{
    const url = this._getserviceprovider + `?connectionType=${connectionType}`;
    return this.http.get<any>(url);
  }

  getRechargeTypeData(connectionType: string,serviceProvider: string): Observable<any>{
    const url = this._getrechargetype + `?connectionType=${connectionType}` + `&serviceProvider=${serviceProvider}`;
    return this.http.get<any>(url);
  }

  getProductsData(): Observable<any>{
    return this.http.get<any>(this._getproducts);
  }

  getBalanceData(): Observable<any>{
    return this.http.get<any>(this._getbalance);
  }

  getTransactionenquiryData(requestId: string): Observable<any>{
    const url = this._transactionenquiry + `?requestId=${requestId}`;
    return this.http.get<any>(url);
  }

  getRechargeReportData(displayId: string): Observable<any>{
    const url = this._getrechargereport + `?displayId=${displayId}`;
    return this.http.get<any>(url);
  }

  postRechargePushRequest(pushRequestDetails: PushRequest) : Observable<any>{
    return this.http.post<any>(this._pushrequest,pushRequestDetails);
  }

  postSetRechargeCommissionAndStatus(pushProductMasterDetails: any) : Observable<any>{
    return this.http.post<any>(this._setrechargecommissionandstatus,pushProductMasterDetails);
  }
}
