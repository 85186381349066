export class PaymentStatusForm{
  displayId: number;
  fromDate: string;
  toDate: string;
  status: boolean;
}

export class PaymentStatusReportTableData{
  accountNo: string;
  adminCharge: number;
  bankName: string;
  displayId: number;
  ifsc: string;
  name: string;
  netAmount: number;
  paymentDate: string;
  paymentStatus: string;
  tdsAmount: number;
  totalIncome: number;
  checked: boolean;
  trackPayment: string;
}

