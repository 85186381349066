<div class="row btn-page" *ngIf="gridView">
    <div class="col-sm-12">
        <app-card cardTitle="Total Commission Summary" [options]="false">
            <div class="row">
                <div class="col-md-3 col-xs-6 border-right">
                    <h3>{{totalIncome.toFixed(2)}}</h3>
                    <span>Total Income</span>
                </div>
                <div class="col-md-3 col-xs-6 border-right">
                    <h3>{{totalAdminChargeAmount.toFixed(2)}}</h3>
                    <span class="text-primary">Total Admin Charge Amount</span>
                </div>
                <div class="col-md-3 col-xs-6 border-right">
                    <h3>{{totalTDSAmount.toFixed(2)}}</h3>
                    <span class="text-success">Total TDS Amount</span>
                </div>
                <div class="col-md-3 col-xs-6 border-right">
                    <h3>{{totalNetAmount.toFixed(2)}}</h3>
                    <span class="text-info">Total Net Amount</span>
                </div>
            </div>
        </app-card>
    </div>
</div>
<div>
    <app-card headerClass="border-0 p-2 pb-0 " cardTitle="All Commissions Search for TDS " [options]="false">
        <form [formGroup]="allCommissionGroup" class="form">
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="d-block">Choose Month and Year <span style="color: red;">*</span></label>
                        <select class="form-control" formControlName="monthFrom">
                            <option *ngFor="let month of months" [value]="month.value">{{ month.name }}</option>
                        </select>
                        <select class="form-control" formControlName="yearFrom">
                            <option *ngFor="let year of years" [value]="year">{{ year }}</option>
                        </select>
                    </div>
                </div>
                <!-- <div class="col-sm-6">
                    <div class="form-group">
                        <label class="d-block">To Date <span style="color: red;">*</span> (Commission To Date)</label>
                        <select class="form-control" formControlName="monthTo">
                            <option *ngFor="let month of months" [value]="month.value">{{ month.name }}</option>
                        </select>
                        <select class="form-control" formControlName="yearTo">
                            <option *ngFor="let year of years" [value]="year">{{ year }}</option>
                        </select>
                    </div>
                </div> -->
            </div>
            <hr>
            <div class="app-modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="cancel()">Cancel</button>
                <button type="submit" class="btn btn-primary" (click)="submitAllCommission()">Submit</button>
            </div>
        </form>
    </app-card>
    <ng-container *ngIf="!loading; then thenTemplate;"></ng-container>
    <ng-template #thenTemplate>
        <ng-container *ngIf="isDataAvailable; else elseTemplate">
            <div class="row mb-n4" *ngIf="gridView">
                <div class="col-sm-12 ">
                    <app-card [customHeader]="true" headerClass="border-0 p-2 pb-0 " [options]="false " cardClass="card-datatable">
                        <div class="col-sm-12 text-right">
                            <button type="button" class="btn btn-primary btn-sm" (click)="exportexcel()">Export to Excel</button>
                        </div>
                        <div>
                            <input type="checkbox" [checked]="isAllCheckBoxChecked()" (change)="checkAllCheckBox($event)"> All Checked
                        </div>
                        <br>
                        <table id="report-table" datatable class="table table-striped table-hover mb-0 table-responsive">
                            <thead>
                                <tr>
                                    <th *ngFor="let col of columns">
                                        {{col}}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of allCommissionsData; let i=index;">
                                    <td><input type="checkbox" value="{{data.cheked}}" [(ngModel)]="data.checked" (change)="finalListRefresh($event,data)"></td>
                                    <th scope="row ">{{ data.displayId }}</th>
                                    <th scope="row ">{{ data.name }}</th>
                                    <th scope="row ">{{ data.panNo }}</th>
                                    <th scope="row ">{{ data.totalIncome.toFixed(2) }}</th>
                                    <th scope="row ">{{ data.adminCharge.toFixed(2) }}</th>
                                    <th scope="row ">{{ data.tdsCharge.toFixed(2) }}</th>
                                    <th scope="row ">{{ data.netIncome.toFixed(2) }}</th>
                                    <th scope="row ">{{ data.payableAmount.toFixed(2) }}</th>
                                    <th scope="row ">{{ data.paymentDate }}</th>
                                    <th scope="row ">{{ data.paymentStatus }}</th>
                                    <th scope="row ">{{ data.accountNo }}</th>
                                    <th scope="row ">{{ data.bankName }}</th>
                                    <th scope="row ">{{ data.ifsc }}</th>
                                    <th scope="row ">
                                        <button class="btn-sm btn-info" style="color:black;" (click)="exampleModalLong.show()" (click)="unpaidDetailsGenerate(data)" *ngIf="data.checked">View</button>
                                        <button class="btn-sm btn-info" style="color:black;" (click)="uncheckCommissionsModalLong.show()" (click)="unpaidDetailsGenerateUnchecked(data)" *ngIf="!data.checked">View</button>
                                    </th>
                                </tr>
                            </tbody>
                        </table>
                    </app-card>
                    <div *ngIf="finalList && finalAllCommissionsData.length>0">
                        <app-card [customHeader]="true" headerClass="border-0 p-2 pb-0 " [options]="false " cardClass="card-datatable">
                            <table id="report-table" datatable class="table table-striped table-hover mb-0 table-responsive">
                                <thead>
                                    <tr>
                                        <th *ngFor="let col of columnsFinal">
                                            {{col}}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let data of finalAllCommissionsData; let i=index; ">
                                        <th scope="row ">{{ data.displayId }}</th>
                                        <th scope="row ">{{ data.name }}</th>
                                        <th scope="row ">{{ data.totalIncome.toFixed(2) }}</th>
                                        <th scope="row ">{{ data.adminCharge.toFixed(2) }}</th>
                                        <th scope="row ">{{ data.tdsCharge.toFixed(2) }}</th>
                                        <th scope="row ">{{ data.netIncome.toFixed(2) }}</th>
                                        <th scope="row ">{{ data.payableAmount.toFixed(2) }}</th>
                                        <th scope="row ">{{ data.paymentDate }}</th>
                                        <th scope="row ">{{ data.paymentStatus }}</th>
                                        <th scope="row ">{{ data.accountNo }}</th>
                                        <th scope="row ">{{ data.bankName }}</th>
                                        <th scope="row ">{{ data.ifsc }}</th>
                                    </tr>
                                </tbody>
                            </table>
                        </app-card>
                    </div>
                </div>
            </div>
            <br>
            <div class="row mb-n4 " *ngIf="gridView && finalList && finalAllCommissionsData.length>0">
                <div class="col-sm-12 ">
                    <app-card [customHeader]="true " headerClass="border-0 p-2 pb-0 " [options]="false " cardClass="card-datatable ">
                        <form [formGroup]="paymentMethodGroup " class="form ">
                            <div class="row ">
                                <div class="col-sm-6 ">
                                    <div class="form-group ">
                                        <label class="d-block ">Payment Method <span style="color: red; ">*</span></label>
                                        <select class="form-control " formControlName="paymentMethod">
                    <option> </option>
                    <option>Wallet</option>
                    <option>Bank</option>
                  </select>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="app-modal-footer ">
                                <button type="button " class="btn btn-danger " data-dismiss="modal " (click)="cancelPayment() ">Cancel</button>
                                <button class="btn btn-info " style="color:black; " (click)="payUnpaid() ">Payment</button>
                            </div>
                        </form>
                    </app-card>
                </div>
            </div>
            <div class="spinner" *ngIf="!gridView">
                <h5>Loading...</h5>
                <div class="bounce1 "></div>
                <div class="bounce2 "></div>
                <div class="bounce3 "></div>
            </div>
        </ng-container>
        <ng-template #elseTemplate>
            <div class=" spinner " *ngIf="!noData ">
                <h5>Loading...</h5>
                <div class="bounce1 "></div>
                <div class="bounce2 "></div>
                <div class="bounce3 "></div>
            </div>
            <div *ngIf="noData ">
                <h1 style="text-align: center; ">No Data Available</h1>
            </div>
        </ng-template>
    </ng-template>
</div>
<app-ui-modal #exampleModalLong [dialogClass]=" 'modal-xl' ">
    <div class="app-modal-header ">
        <h5>Unpaid Commissions Details</h5>
        <button type="button " class="close basic-close " data-dismiss="modal " aria-label="Close " (click)="exampleModalLong.hide() "><span aria-hidden="true ">&times;</span></button>
    </div>
    <div class="app-modal-body " *ngIf="unpaidDetailsGridView ">
        <div class="col-sm-12 ">
            <app-card cardTitle="Total Commission Summary " [options]="false ">
                <div class="row ">
                    <div class="col-md-3 col-xs-6 border-right ">
                        <h3>{{totalAmountDetails.toFixed(2)}}</h3>
                        <span>Total Amount</span>
                    </div>
                    <div class="col-md-3 col-xs-6 border-right ">
                        <h3>{{payableAmountDetails.toFixed(2)}}</h3>
                        <span>Payable Amount</span>
                    </div>
                </div>
            </app-card>
        </div>
    </div>
    <div class="app-modal-body " *ngIf="unpaidDetailsGridView ">
        <table id="report-table " datatable class="table table-striped table-hover mb-0 table-responsive ">
            <thead>
                <tr>
                    <th *ngFor="let col of columnsDetails ">
                        {{col}}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of allCommissionsDetailsData; let i=index; ">
                    <td><input type="checkbox" value="{{data.isCheked}}" [(ngModel)]="data.isChecked" (change)="detailsCheck($event,data,i)"></td>
                    <th scope="row ">{{ data.fromDate }}</th>
                    <th scope="row ">{{ data.toDate }}</th>
                    <th scope="row ">{{ data.displayId }}</th>
                    <th scope="row ">{{ data.commissionName }}</th>
                    <th scope="row ">{{ data.pairBVMatch }}</th>
                    <th scope="row ">{{ data.unitIncome }}</th>
                    <th scope="row ">{{ data.totalIncome }}</th>
                    <th scope="row ">{{ data.adminCharge }}</th>
                    <th scope="row ">{{ data.tdsCharge }}</th>
                    <th scope="row ">{{ data.netIncome }}</th>
                    <th scope="row ">{{ data.payableAmount }}</th>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="app-modal-body spinner" *ngIf="!unpaidDetailsGridView">
        <h5>Loading...</h5>
        <div class="bounce1 "></div>
        <div class="bounce2 "></div>
        <div class="bounce3 "></div>
    </div>
</app-ui-modal>

<app-ui-modal #uncheckCommissionsModalLong [dialogClass]=" 'modal-xl' ">
    <div class="app-modal-header ">
        <h5>Unpaid Commissions Details</h5>
        <button type="button " class="close basic-close " data-dismiss="modal " aria-label="Close " (click)="uncheckCommissionsModalLong.hide() "><span aria-hidden="true ">&times;</span></button>
    </div>
    <div class="app-modal-body " *ngIf="unpaidDetailsGridViewUnchecked">
        <div class="col-sm-12 ">
            <app-card cardTitle="Total Commission Summary " [options]="false ">
                <div class="row ">
                    <div class="col-md-3 col-xs-6 border-right ">
                        <h3>{{totalAmountDetails.toFixed(2)}}</h3>
                        <span>Total Amount</span>
                    </div>
                    <div class="col-md-3 col-xs-6 border-right ">
                        <h3>{{payableAmountDetails.toFixed(2)}}</h3>
                        <span>Payable Amount</span>
                    </div>
                </div>
            </app-card>
        </div>
    </div>
    <div class="app-modal-body " *ngIf="unpaidDetailsGridViewUnchecked ">
        <table id="report-table " datatable class="table table-striped table-hover mb-0 table-responsive ">
            <thead>
                <tr>
                    <th *ngFor="let col of columnsDetailsUnchecked ">
                        {{col}}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of allCommissionsDetailsDataUnchecked; let i=index; ">
                    <th scope="row ">{{ data.fromDate }}</th>
                    <th scope="row ">{{ data.toDate }}</th>
                    <th scope="row ">{{ data.displayId }}</th>
                    <th scope="row ">{{ data.commissionName }}</th>
                    <th scope="row ">{{ data.pairBVMatch }}</th>
                    <th scope="row ">{{ data.unitIncome }}</th>
                    <th scope="row ">{{ data.totalIncome }}</th>
                    <th scope="row ">{{ data.adminCharge }}</th>
                    <th scope="row ">{{ data.tdsCharge }}</th>
                    <th scope="row ">{{ data.netIncome }}</th>
                    <th scope="row ">{{ data.payableAmount }}</th>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="app-modal-body spinner" *ngIf="!unpaidDetailsGridViewUnchecked">
        <h5>Loading...</h5>
        <div class="bounce1 "></div>
        <div class="bounce2 "></div>
        <div class="bounce3 "></div>
    </div>
</app-ui-modal>