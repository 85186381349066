import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbCarouselModule, NgbDatepickerModule, NgbDropdownModule, NgbTabsetModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
import { SharedModule } from '../../theme/shared/shared.module';
import { DownlineReportComponent } from './components/downline-report/downline-report.component';
import { ReportRoutingModule } from './report-routing.module';
import { BiModuleComponent } from './components/bi-module/bi-module.component';
import { RepurchaseReportComponent } from './components/repurchase-report/repurchase-report.component';
import { GstMonthwiseComponent } from './components/gst-monthwise/gst-monthwise.component';
import { GstStatewiseComponent } from './components/gst-statewise/gst-statewise.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ViewCommissionComponent } from './components/view-commission/view-commission.component';
import { PaymentStatusReportComponent } from './components/payment-status-report/payment-status-report.component';
import { TdsReportComponent } from './components/tds-report/tds-report.component';
import { ViewOffersComponent } from './components/view-offers/view-offers.component';
import { FirstpurchaseRankComponent } from './components/firstpurchase-rank/firstpurchase-rank.component';
import { CommissionsTdsComponent } from './components/commissions-tds/commissions-tds.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    NgbTabsetModule,
    NgbDropdownModule,
    NgbCarouselModule,
    NgbDatepickerModule,
    SharedModule,
    FormsModule,
    DataTablesModule,
    ReactiveFormsModule,
    ReportRoutingModule,
    NgMultiSelectDropDownModule.forRoot()
  ],
  declarations: [
    DownlineReportComponent,
    BiModuleComponent,
    RepurchaseReportComponent,
    GstMonthwiseComponent,
    GstStatewiseComponent,
    ViewCommissionComponent,
    PaymentStatusReportComponent,
    TdsReportComponent,
    ViewOffersComponent,
    FirstpurchaseRankComponent,
    CommissionsTdsComponent
  ]
})
export class ReportModule { }
