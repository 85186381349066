import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { UserAuthService } from 'src/app/mlm/auth/services/user-auth.service';
import { DownlineService } from '../../services/downline.service';
import { ExcelExportService } from '../../services/excel-export.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { GstStateMonthwiseReportTableData, GstStatewiseReportTableData } from '../../model/gst-statewise-report.model';
import { CommonComponent } from 'src/app/theme/shared/common/common.component';

@Component({
  selector: 'app-gst-monthwise',
  templateUrl: './gst-monthwise.component.html',
  styleUrls: ['./gst-monthwise.component.scss'],
})
export class GstMonthwiseComponent extends CommonComponent implements OnInit {
  years = [];
  FilterFormGroup: FormGroup;
  dropdownList = [];
  dropdownSettings: IDropdownSettings = {};
  monthSet: string = '';
  isDataAvailable: boolean;
  isStateDataAvailable: boolean;
  gridView: boolean = false;
  gstReportsMonthwiseTableData: GstStatewiseReportTableData[] = [];
  gstReportStateMonthwiseTableData: GstStateMonthwiseReportTableData[] = [];
  fileName = 'ExcelSheet.xlsx';
  loading: boolean = true;
  stateDataloading: boolean = true;
  columns = [
    'Year',
    'Month',
    'Total Price',
    'Total GST'
  ];
  stateAndMonthColumns = [
    'Year',
    'Month',
    'Product Name',
    'Quantity',
    'Total Price',
    'Total GST',
    'GST Percentage',
    'State Name'
  ];

  noData: boolean;
  noStateData: boolean;
  constructor(
    private downlineService: DownlineService,
    public fb: FormBuilder,
    private excelExportService: ExcelExportService
  ) {
    super();
    this.FilterFormGroup = this.fb.group({
      year: new FormControl('', [Validators.required]),
      month: new FormControl('', [Validators.required]),
    });
    this.isDataAvailable = this.gstReportsMonthwiseTableData?.length > 0;
    this.isStateDataAvailable = this.gstReportStateMonthwiseTableData?.length > 0;
  }

  ngOnInit(): void {
    for (let i = 2022; i <= 2050; i++) {
      this.years.push(i);
    }

    this.dropdownList = [
      { item_id: 1, item_text: 'January' },
      { item_id: 2, item_text: 'February' },
      { item_id: 3, item_text: 'March' },
      { item_id: 4, item_text: 'April' },
      { item_id: 5, item_text: 'May' },
      { item_id: 6, item_text: 'June' },
      { item_id: 7, item_text: 'July' },
      { item_id: 8, item_text: 'August' },
      { item_id: 9, item_text: 'September' },
      { item_id: 10, item_text: 'October' },
      { item_id: 11, item_text: 'November' },
      { item_id: 12, item_text: 'December' },
    ];
    this.dropdownSettings = {
      idField: 'item_id',
      textField: 'item_text',
    };
  }

  search() {
    this.monthSet = '';
    console.log(this.FilterFormGroup.value);
    let months = this.FilterFormGroup.controls['month'].value;
    months.forEach(element => {
      this.monthSet = this.monthSet + ',' + element.item_id;
    });
    this.monthSet = this.monthSet.slice(1);
    this.gstReportsMonthwiseTableData = [];
    this.gstReportStateMonthwiseTableData = [];
    this.gridView = false;
    this.downlineService.getGstMonthWiseDetails(this.FilterFormGroup.controls['year'].value,this.monthSet).subscribe((val)=>{
      if(val){
        this.gstReportsMonthwiseTableData = val.data;
        this.isDataAvailable = this.gstReportsMonthwiseTableData?.length > 0;
        this.noData = this.gstReportsMonthwiseTableData?.length === 0;
        this.gridView = true;
      }
      this.loading = false;
    })
    console.log('calling getGstStateAndMonthWiseDetails-------------')
    this.downlineService.getGstStateAndMonthWiseDetails(this.FilterFormGroup.controls['year'].value,this.monthSet).subscribe((val)=>{
      console.log(val)
      if(val){
        this.gstReportStateMonthwiseTableData = val.data;
        this.isStateDataAvailable = this.gstReportStateMonthwiseTableData?.length > 0;
        this.noStateData = this.gstReportStateMonthwiseTableData?.length === 0;
        this.gridView = true;
      }
      this.stateDataloading = false;
      console.log('now stateDataloading', this.stateDataloading)
      console.log('now isStateDataAvailable', this.isStateDataAvailable)
      
    })
    
  }

  reset() {
    this.FilterFormGroup.reset();
    this.gridView = false;
  }

  onItemSelect(item: any) {
    console.log('onItemSelect', item);
  }
  onItemDeSelect(item: any) {
    console.log('onItemDeSelect', item);
  }
  onSelectAll(items: any) {
    console.log('onSelectAll', items);
  }
  onUnSelectAll() {
    console.log('onUnSelectAll fires');
  }

  exportexcel(): void {
    this.excelExportService.exportExcel(
      this.gstReportsMonthwiseTableData,
      'monthwise-gst-report'
    );
  }

  exportStateAndMonthexcel(): void {
    this.excelExportService.exportExcel(
      this.gstReportStateMonthwiseTableData,
      'state-monthwise-gst-report'
    );
  }
}
