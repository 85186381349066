import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ItemMasterService } from '../../services/item-master.service';
import { AllCategories, AllProducts, AllSubCategories, GstData, ItemMasterData } from '../../model/item-master.model';
import { CommonComponent } from 'src/app/theme/shared/common/common.component';
@Component({
  selector: 'app-item-master',
  templateUrl: './item-master.component.html',
  styleUrls: ['./item-master.component.scss']
})
export class ItemMasterComponent extends CommonComponent implements OnInit {
  itemGroup: FormGroup;
  positionValue: string;
  showMessageCategory: string = '';
  showMessageSubCategory: string = '';
  showMessageProduct: string = '';
  allCategoriesData: AllCategories[] = [];
  allSubCategoriesData: AllSubCategories[] = [];
  allProductsData: AllProducts[] = [];
  categoryId: string = '';
  subCategoryId: string = '';
  productId: string = '';
  allGstData = [];
  allItemsData: string = '';

  constructor(
    public fb: FormBuilder,
    private itemMasterService: ItemMasterService
  ) {
    super();
    this.itemGroup = this.fb.group({
      category: new FormControl('', [Validators.required]),
      subCategory: new FormControl('', [Validators.required]),
      product: new FormControl('', [Validators.required]),
      productDescription: new FormControl('', [Validators.required]),
      item: new FormControl('', [Validators.required]),
      itemDescription: new FormControl('', [Validators.required]),
      hsn: new FormControl('', [Validators.required]),
      mrp: new FormControl('', [Validators.required]),
      gstPercentage: new FormControl('', [Validators.required]),
      distributerPrice: new FormControl('', [Validators.required]),
      bv: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.itemMasterService.getAllCategoriesData().subscribe((value) => {
      if(value){
        console.log("Categories:",value.data);
        this.allCategoriesData = value.data;
      }
    });
    this.itemGroup.controls['category'].valueChanges.subscribe((val) => {
      if(val){
        for(let i=0;i<this.allCategoriesData.length;i++){
          if(this.allCategoriesData[i].catName === val){
            this.itemMasterService.getAllSubCategoriesData(this.allCategoriesData[i].catId).subscribe((value) => {
              if(value){
                this.allSubCategoriesData = value.data;
                this.categoryId = this.allCategoriesData[i].catId;
              }
            });
          }
        }
      }
    })

    this.itemGroup.controls['subCategory'].valueChanges.subscribe((val) => {
      if(val){
        for(let i=0;i<this.allSubCategoriesData.length;i++){
          if(this.allSubCategoriesData[i].subCatName === val){
            this.itemMasterService.getAllProductsData(this.categoryId,this.allSubCategoriesData[i].subCatId).subscribe((value) => {
              if(value){
                this.allProductsData = value.data;
                this.subCategoryId = this.allSubCategoriesData[i].subCatId;
              }
            });
          }
        }
      }
    })

    this.itemGroup.controls['product'].valueChanges.subscribe((val) => {
      if(val){
        for(let i=0;i<this.allProductsData.length;i++){
          if(this.allProductsData[i].prodName === val){
            this.itemMasterService.getAllItemsData(this.categoryId,this.subCategoryId,this.allProductsData[i].prodId).subscribe((value) => {
              if(value){
                this.allItemsData = value.data;
                this.productId = this.allProductsData[i].prodId;
              }
           })
          }
        }
      }
    })

    this.itemMasterService.getGSTData().subscribe((val)=>{
      if(val){
        val.data.forEach(element => {
          this.allGstData.push(element.gstPercentage);
        });
      }
    })
  }



  submitItem(){
    const itemMasterData = new ItemMasterData();
    itemMasterData.catId = this.categoryId;
    itemMasterData.catName = this.itemGroup.controls['category'].value;
    itemMasterData.subCatId = this.subCategoryId;
    itemMasterData.subCatName = this.itemGroup.controls['subCategory'].value;
    itemMasterData.prodId = this.productId;
    itemMasterData.itemId = '';
    itemMasterData.prodName = this.itemGroup.controls['product'].value;
    itemMasterData.prodDesc = this.itemGroup.controls['productDescription'].value;
    itemMasterData.itemName = this.itemGroup.controls['item'].value;
    itemMasterData.itemDesc = this.itemGroup.controls['itemDescription'].value;
    itemMasterData.hsn = this.itemGroup.controls['hsn'].value;
    itemMasterData.mrp = this.itemGroup.controls['mrp'].value;
    itemMasterData.distributorPrice = this.itemGroup.controls['distributerPrice'].value;
    itemMasterData.bv = this.itemGroup.controls['bv'].value;
    itemMasterData.gstPercentage = parseInt(this.itemGroup.controls['gstPercentage'].value);
    console.log(itemMasterData);
    this.itemMasterService.postItemMasterData(itemMasterData).subscribe((val)=>{
      if(val.message){
        this.warningSwal(val.message);
      }else{
        this.successSwal("Item details submitted successfully");
        this.itemGroup.reset();
      }
    })
  }

  cancel(){
    this.itemGroup.reset();
  }
}

