import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserAuthService } from 'src/app/mlm/auth/services/user-auth.service';
import { CommissionGenrateDates } from 'src/app/mlm/pay-commission/model/view-commission.model';
import { ViewCommissionService } from 'src/app/mlm/pay-commission/services/view-commission.service';
import { ExcelExportService } from '../../services/excel-export.service';
import { CommonComponent } from 'src/app/theme/shared/common/common.component';
import { PaymentStatusForm, PaymentStatusReportTableData } from '../../model/payment-status-report.model';
import { DownlineService } from '../../services/downline.service';

@Component({
  selector: 'app-payment-status-report',
  templateUrl: './payment-status-report.component.html',
  styleUrls: ['./payment-status-report.component.scss']
})
export class PaymentStatusReportComponent extends CommonComponent implements OnInit {
  paymentStatusReportGroup: FormGroup;
  allDatesData: CommissionGenrateDates[] = [];

  isDataAvailable: boolean;
  gridView: boolean = false;
  fileName = 'ExcelSheet.xlsx';
  loading: boolean = true;
  columns = [
    'Display Id',
    'Name',
    'Total Amount',
    'Admin Charge',
    'TDS',
    'Net Amount',
    'Payment Date',
    'Payment Status',
    'Account No',
    'Bank Name',
    'IFSC'
  ];

  paymentStatusReportTableData: PaymentStatusReportTableData[] = [];
  isAdmin: boolean = false;
  displayId: string = '';
  displayIdForm: FormGroup;
  noData: boolean;
  constructor(
    private viewCommissionService: ViewCommissionService,
    private fb: FormBuilder,
    private userAuthService: UserAuthService,
    private excelExportService: ExcelExportService,
    private downlineService: DownlineService
  ) {
    super();
    this.paymentStatusReportGroup = this.fb.group({
      displayId: new FormControl('', [Validators.required]),
      paymentType: new FormControl('', [Validators.required]),
      fromDateToDate: new FormControl('', [Validators.required]),
    });
    this.isDataAvailable = this.paymentStatusReportTableData?.length > 0;
  }

  ngOnInit(): void {
    this.viewCommissionService.getCommissionGenerateDateData('true').subscribe((val)=>{
      if(val.data){
        this.allDatesData = val.data;
      }
    })
  }

  submit(){
    if(this.paymentStatusReportGroup.controls['paymentType'].value){
      if(this.paymentStatusReportGroup.controls['fromDateToDate'].value){
        const paymentStatusReportFormData = new PaymentStatusForm();
        paymentStatusReportFormData.displayId = this.paymentStatusReportGroup.controls['displayId'].value?this.paymentStatusReportGroup.controls['displayId'].value:0;
        paymentStatusReportFormData.fromDate = this.paymentStatusReportGroup.controls['fromDateToDate'].value.substring(0,10);
        paymentStatusReportFormData.toDate = this.paymentStatusReportGroup.controls['fromDateToDate'].value.substring(13,23);
        if(this.paymentStatusReportGroup.controls['paymentType'].value == 'Paid'){
          paymentStatusReportFormData.status = true;
        }else{
          paymentStatusReportFormData.status = false;
        }
        this.paymentStatusReportTableData = [];
        this.gridView = false;
        this.downlineService.getPaymentStatusData(paymentStatusReportFormData).subscribe((val)=>{
          if(val){
            this.paymentStatusReportTableData = val.data;
            this.noData = this.paymentStatusReportTableData?.length === 0;
            this.isDataAvailable = this.paymentStatusReportTableData?.length > 0;
            this.gridView = true;
          }
          this.loading = false;
        })
      }else{
        this.warningSwal("Please select a Date Range from the list");
      }
    }else{
      this.warningSwal("Please select a Payment Type from the list");
    }
  }

  cancel(){
    this.paymentStatusReportGroup.reset();
    this.gridView = false;
  }

  exportexcel(): void {
    const paymentReportExcelExport = this.paymentStatusReportTableData;
    paymentReportExcelExport.forEach(element => {
      delete element.trackPayment;
    });
    this.excelExportService.exportExcel(
      paymentReportExcelExport,
      'payment-status-report'
    );
  }

}
