<app-card [hidHeader]="true">
    <h5>Franchise Request</h5>
    <hr>
    <form [formGroup]="FranchiseRequestGroup" class="form">
        <div class="app-modal-body">
            <div class="row">
                <div class="col-sm-4">
                    <div class="form-group">
                        <label class="d-block">Display ID</label>
                        <input type="text" class="form-control" [(ngModel)]="displayId" formControlName="mlmMemberId" placeholder="Display ID" [readOnly]="!isAdmin">
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group">
                        <label class="d-block">Name</label>
                        <input type="text" class="form-control" formControlName="name" placeholder="Name">
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group">
                        <label class="d-block">Phone Number</label>
                        <input type="number" class="form-control" formControlName="phNo" placeholder="Phone Number">
                    </div>
                </div>
            </div>
            <div class="row ">
                <div class="col-sm-4">
                    <div class="form-group">
                        <label class="d-block">State <span style="color: red;">*</span></label>
                        <select class="form-control" formControlName="state">
                          <option></option>
                          <option *ngFor="let states of allStatesData ">{{states.stateName}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group">
                        <label class="d-block">Pincode</label>
                        <input type="number" class="form-control" formControlName="pinCode" placeholder="Pincode">
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group">
                        <label class="d-block">Address</label>
                        <input type="text" class="form-control" formControlName="address" placeholder="Address">
                    </div>
                </div>
            </div>
            <div class="row ">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label class="d-block">Description</label>
                        <textarea class="form-control" formControlName="description" rows="3" placeholder="Description"></textarea>
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <div class="app-modal-footer ">
            <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="cancel()">Cancel</button>
            <button type="submit" class="btn btn-primary" (click)="submitFranchise()">Submit</button>
        </div>
    </form>
</app-card>