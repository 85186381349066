import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { UserAuthService } from 'src/app/mlm/auth/services/user-auth.service';
import { AllStates } from 'src/app/mlm/member/model/allstates.model';
import { MembersService } from 'src/app/mlm/member/services/members.service';
import { DownlineReportsTableData, DownlineReportFilter } from 'src/app/mlm/report/model/downline-reports-table.model';
import { DownlineService } from 'src/app/mlm/report/services/downline.service';
import { ExcelExportService } from 'src/app/mlm/report/services/excel-export.service';
import { CommonComponent } from 'src/app/theme/shared/common/common.component';

@Component({
  selector: 'app-purchase',
  templateUrl: './purchase.component.html',
  styleUrls: ['./purchase.component.scss']
})
export class PurchaseComponent extends CommonComponent implements OnInit {
  FilterFormGroup: FormGroup;
  displayId: string;

  constructor(
    public fb:FormBuilder,
    private memberService: MembersService,
    private router: Router
  ) {
    super();
    this.FilterFormGroup = this.fb.group({
      displayId: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit() {

  }


  search(){
    if(this.FilterFormGroup.controls['displayId'].value){
      this.memberService.getverifyMemberInfoDisplayId(this.FilterFormGroup.controls['displayId'].value).subscribe((val)=>{
        if(val.message){
          this.warningSwal(val.message);
        }else{
          this.router.navigateByUrl("home/franchise-purchase/shop/" + this.FilterFormGroup.controls['displayId'].value);
        }
      })
    }else{
      this.warningSwal("Please enter a Display ID");
    }
  }

  reset(){
    this.FilterFormGroup.reset();
  }

}
