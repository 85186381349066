import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { UserAuthService } from '../services/user-auth.service';
import { CommonComponent } from 'src/app/theme/shared/common/common.component';

@Component({
  selector: 'app-franchise-signin',
  templateUrl: './franchise-signin.component.html',
  styleUrls: ['./franchise-signin.component.scss']
})
export class FranchiseSigninComponent extends CommonComponent implements OnInit {
  franchiseFormGroup: FormGroup;
  fieldTextType: boolean;

  constructor(
    private authService: AuthService,
    private userAuthService: UserAuthService,
    private router: Router,
    private fb: FormBuilder
  ) {
    super();
    this.franchiseFormGroup = new FormGroup({
      username: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit(): void {
  }

  login() {
    if (this.franchiseFormGroup.valid) {
      this.authService.loginFranchise(this.franchiseFormGroup.value).subscribe(
        (result) => {
          if (result) {
            this.userAuthService.setToken(result.data.token);
            this.userAuthService.setRoles(result.data.roleName);
            this.userAuthService.setDisplayId(result.data.displayId);
            this.userAuthService.setUserName(result.data.userName);
            this.userAuthService.setToken(result.data.token);
            this.userAuthService.setRoles(result.data.roleName);
            this.userAuthService.setDisplayId(result.data.displayId);
            this.userAuthService.setUserName(result.data.userName);
            this.successSwal('Welcome to Vedikmart !');
            this.router.navigate(['/home/dashboard']);
          }
        },
        (error) => {
          this.router.navigate(['/auth/franchise-signin']);
          this.dangerSwal('Please enter correct User Id or Password');
        }
      );
    }
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

}
