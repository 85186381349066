import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AllCategories, CategoryUpdate } from '../../model/item-master.model';
import { ItemMasterService } from '../../services/item-master.service';
import { CommonComponent } from 'src/app/theme/shared/common/common.component';

@Component({
  selector: 'app-category-update',
  templateUrl: './category-update.component.html',
  styleUrls: ['./category-update.component.scss']
})
export class CategoryUpdateComponent extends CommonComponent implements OnInit {
  updateCategoryGroup: FormGroup;
  allCategoriesData: AllCategories[] = [];
  categoryHeader = ['Category ID','Category Name','Action'];
  isUpdateCategoryFormShow: boolean = false;
  constructor(
    private fb: FormBuilder,
    private itemMasterService: ItemMasterService
  ) {
    super();
    this.updateCategoryGroup = this.fb.group({
      catId: new FormControl('', [Validators.required]),
      catName: new FormControl('', [Validators.required])
    });
  }

  ngOnInit(): void {
    this.itemMasterService.getAllCategoriesData().subscribe((value) => {
      if(value){
        console.log("Categories:",value.data);
        this.allCategoriesData = value.data;
      }
    });
  }

  submitUpdateCategory(){
    const updateCategory = new CategoryUpdate();
    updateCategory.catId = this.updateCategoryGroup.controls['catId'].value;
    updateCategory.catName = this.updateCategoryGroup.controls['catName'].value;
    this.itemMasterService.postCategoryUpdateData(updateCategory).subscribe((val)=>{
      if(val){
        this.successSwal("Category Updated Successfully");
        this.isUpdateCategoryFormShow = false;
        this.allCategoriesData = [];
        this.itemMasterService.getAllCategoriesData().subscribe((value) => {
          if(value){
            console.log("Categories:",value.data);
            this.allCategoriesData = value.data;
          }
        });
      }
    })
  }

  cancel(){
    this.isUpdateCategoryFormShow = false;
  }

  selectCategory(catId: string,catName: string){
    this.updateCategoryGroup.controls['catId'].setValue(catId);
    this.updateCategoryGroup.controls['catName'].setValue(catName);
    this.isUpdateCategoryFormShow = true;
  }

}
