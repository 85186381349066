import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserAuthService } from '../../../auth/services/user-auth.service';
import { FranchiseDetails } from '../../../franchise/model/franchise.model';
import { FranchiseService } from '../../../franchise/services/franchise.service';
import { PurchaseService } from '../../../purchase/services/purchase.service';
import { FranchiseCommission, StockBalance } from '../../../stock/stock.model';
import { StockService } from '../../../stock/stock.service';
import { WalletService } from '../../../wallet/services/wallet.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-franchise-dashboard',
  templateUrl: './franchise-dashboard.component.html',
  styleUrls: ['./franchise-dashboard.component.scss']
})
export class FranchiseDashboardComponent implements OnInit {

  displayId: string = '';
  walletBalance: number;
  franchiseSales: number;
  gridView: boolean = false;
  isFranchise: boolean = false;
  franchiseName: string;
  loading: boolean = true;
  isCnf: boolean = false;
  isDataAvailable: boolean;
  allItemsDetailsData: StockBalance[] = [];
  allProductDetailsData: StockBalance[] = [];
  userInformations: FranchiseDetails;
  emptyStockDetails: StockBalance[] = [];
  franchiseCommission: FranchiseCommission[] = [];
  currentCommission: number = 0;
  previousCommmission: number = 0;

  constructor(private walletService: WalletService,
    private userAuthService: UserAuthService,
    private router: Router,
    private franchiseService: FranchiseService,
    private purchaseService: PurchaseService,
    private stockService: StockService) { }

  ngOnInit(): void {
    this.displayId = this.userAuthService.getDisplayId();
    this.isCnf = this.userAuthService.getRoles() === 'CNF' ? true : false;
    this.isFranchise = this.userAuthService.getRoles() === 'CNF' ? true : false;
    this.franchiseName = this.userAuthService.getUserName();

    this.franchiseService.getFranchiseDetailsData(this.displayId).subscribe((val)=>{
      if(val){
        this.userInformations = val.data;
      }
    })

    this.franchiseService.getFranchiseCommission().subscribe((val)=>{
      if(val){
        this.franchiseCommission = val.data;
        console.log('===============.',this.franchiseCommission)
        this.franchiseCommission.forEach(item => {
          if (item.monthcategory === 'current') {
            this.currentCommission += item.totalamount;
            console.log(this.currentCommission)
          } else if (item.monthcategory === 'previous') {
            this.previousCommmission += item.totalamount;
            console.log(this.previousCommmission)
          }
        });
      }
    })

    this.franchiseService.getFranchiseSales().subscribe((val)=>{
      if(val){
        this.franchiseSales = val.data;
      }
    })
    
    this.walletService.getWalletBalanceData().subscribe((val)=>{
      if(val){
        this.walletBalance = val.data;
        this.gridView = true;
      }
    })
    this.stockService.getSTockBalance().subscribe((response: any) => {
      if (response.data) {
        this.allItemsDetailsData = response.data;
        this.loading = false;
        this.purchaseService.getItemwiseStockData().subscribe((val)=>{
          if(val){
            this.allProductDetailsData = val.data;
            this.allProductDetailsData.forEach(element => {
              const stock = this.allItemsDetailsData.find(stockItem => stockItem.itemId === element.itemId);
              let qty: number = stock ? stock.quantity : 0;
              if (qty <= 0) {
                this.emptyStockDetails.push(element);
              }
            });
            console.log('============>', this.emptyStockDetails)
          }
        })
      } else {
        Swal.fire('Error', 'Error in fetching stock details', response.message);
      }
      
    });
    this.isDataAvailable = true;
    if (this.isCnf || this.isFranchise) {
      this.loading = false;
    }

  }


  purchase(){
    this.router.navigateByUrl("/home/franchise-purchase/multi-purchase");
  }

  addWallet(){
    this.router.navigateByUrl("/home/wallet/fund-add");
  }

  addStock(){
    this.router.navigateByUrl("/home/stock/add-stock");
  }


  navigateToCommissionPage() {
    this.router.navigate(['home', 'wallet', 'franchise-commission']); 
  }
  

}
