<div class="row btn-page" *ngIf="gridView">
    <div class="col-sm-12 btn-page">
        <app-card [hidHeader]="true" cardClass="card-datatable">
            <div class="col-sm-12 text-right">
                <button type="button" class="btn btn-primary btn-sm" (click)="exportexcel()">Export to Excel</button>
            </div>
            <div class="table-responsive">
                <table datatable id="excel-table" class="table table-striped table-hover mb-0 table-responsive">
                    <thead>
                        <tr>
                            <th *ngFor="let col of columnsAdmin">
                                {{col}}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let reports of downlineReportsTableData">
                            <td *ngFor="let col of indexAdmin">
                                <div *ngIf="col != 'isLeft' && col != 'isActive' && col != 'isGreen'">
                                    {{reports[col]}}
                                </div>
                                <div *ngIf="col == 'isLeft'">
                                    <div *ngIf="reports[col] == true ">
                                        <span class="badge badge-success inline-block">Left</span>
                                    </div>
                                    <div *ngIf="reports[col] == false ">
                                        <span class="badge badge-warning inline-block m-r-5">Right</span>
                                    </div>
                                </div>
                                <!-- <div *ngIf="col == 'isActive'" class="d-inline-block">
                                    <div class="custom-control custom-switch" *ngIf="reports[col] == 1 ">
                                        <input type="checkbox" class="custom-control-input" readonly checked>
                                        <label class="custom-control-label" for="customSwitch1"></label>
                                    </div>

                                    <div class="custom-control custom-switch" *ngIf="reports[col] == 0 ">
                                        <input type="checkbox" class="custom-control-input" readonly>
                                        <label class="custom-control-label" for="customSwitch1"></label>
                                    </div>
                                </div> -->
                                <div *ngIf="col == 'isGreen'">
                                    <div class="custom-control custom-switch" *ngIf="reports[col] == true ">
                                        <input type="checkbox" class="custom-control-input" readonly checked>
                                        <label class="custom-control-label"></label>
                                    </div>

                                    <div class="custom-control custom-switch" *ngIf="reports[col] == false ">
                                        <input type="checkbox" class="custom-control-input" readonly>
                                        <label class="custom-control-label"></label>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </app-card>
    </div>
</div>
