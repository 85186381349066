<app-card [hidHeader]="true">
    <h5>Commission Generated Search</h5>
    <hr>
    <form [formGroup]="commissionGeneratedGroup" class="form">
        <div class="app-modal-body">
            <div class="row">
                <div class="col-sm-4">
                    <div class="form-group">
                        <label class="d-block ">Display Id</label>
                        <input type="number" class="form-control" formControlName="displayId">
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group">
                        <label class="d-block ">From Date</label>
                        <input type="date" class="form-control" formControlName="dateFrom">
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group ">
                        <label class="d-block">To Date</label>
                        <input type="date" class="form-control" formControlName="dateTo">
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <div class="app-modal-footer ">
            <button type=" button " class="btn btn-danger " data-dismiss="modal" (click)="cancel()">Cancel</button>
            <button type="submit " class="btn btn-primary " (click)="submitCommissionList()">Search</button>
        </div>
    </form>
</app-card>