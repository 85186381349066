<app-card [hidHeader]="true">
    <h5>Stock Search</h5>
    <hr>
    <form [formGroup]="stockFilterGroup" class="form">
        <div class="app-modal-body">
            <div class="row ">
                <div class="col-sm-4">
                    <div class="form-group">
                        <label class="d-block ">Form Date</label>
                        <input type="date" class="form-control" formControlName="from">
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group ">
                        <label class="d-block">To Date</label>
                        <input type="date" class="form-control" formControlName="to">
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <div class="app-modal-footer ">
            <button type=" button " class="btn btn-danger " data-dismiss="modal" (click)="cancel()">Cancel</button>
            <button type="submit " class="btn btn-primary " (click)="submitStockSearch()">Search</button>
        </div>
    </form>
</app-card>

<div class="row mb-n4" *ngIf="gridView">
    <div class="col-sm-12 ">
        <app-card [customHeader]="true " headerClass="border-0 p-2 pb-0 " [options]="false " cardClass="card-datatable">
            <table id="report-table" datatable class="table table-striped table-hover mb-0 table-responsive">
                <thead>
                    <tr>
                        <th *ngFor="let col of columns">
                            {{col}}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let stockSearch of stockSearchData; index as i ">
                        <th scope="row ">{{ stockSearch.enteredBy }}</th>
                        <th scope="row ">{{ stockSearch.itemCount }}</th>
                        <th scope="row ">{{ stockSearch.itemId }}</th>
                        <th scope="row ">{{ stockSearch.itemName }}</th>
                        <th scope="row ">{{ stockSearch.price }}</th>
                        <th scope="row ">{{ stockSearch.stockDate.substring(0,10) }}</th>
                        <th scope="row ">{{ stockSearch.stockId }}</th>
                        <th scope="row ">{{ stockSearch.unitId }}</th>
                        <th scope="row "><button (click)="deleteStockEntry(stockSearch.stockId)" class="btn btn-outline-primary">Delete</button></th>
                    </tr>
                </tbody>
            </table>
        </app-card>
    </div>
</div>
