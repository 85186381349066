import { Component, OnInit } from '@angular/core';
import { StockService } from '../stock.service';
import { CommonComponent } from 'src/app/theme/shared/common/common.component';
import { ApproveRejectStockRequest, PendingStocksResponse, Stock } from '../stock.model';
import { UserAuthService } from '../../auth/services/user-auth.service';
import { ExcelExportService } from '../../report/services/excel-export.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-stock-requests',
  templateUrl: './stock-requests.component.html',
  styleUrls: ['./stock-requests.component.scss']
})
export class StockRequestsComponent extends CommonComponent implements OnInit {
  public pendingStocks: Stock[] = [];
  public pendingFranchiseStocks: Stock[] = [];
  stockRequestLoading: boolean = true;
  franchiseStockRequestLoading: boolean = true;
  isDataAvailable: boolean = false;
  isFranchiseDataAvailable: boolean = false;
  allFranchiseStockPresent: boolean = false;
  allCnfStockPresent: boolean = false;
  noData: boolean = true;
  noFranchiseData: boolean = true;
  selectedStock: Stock = null;
  isAdmin: boolean = false;
  isCnf: boolean = false;
  isFranchise: boolean = false;
  public allFranchiseStockRequests: Stock[] = [];
  public allCnfStockRequests: Stock[] = [];

  constructor(private stockService: StockService, 
    private userAuthService: UserAuthService, 
    private excelExportService: ExcelExportService) {
    super();
    this.selectedStock = null;
    this.isAdmin = (userAuthService.getRoles()==='ADMIN')?true:false;
    this.isCnf = (userAuthService.getRoles()==='CNF')?true:false;
    this.isFranchise = (userAuthService.getRoles()==='FRANCHISE')?true:false;
    stockService.getPendingStocks().subscribe((response: PendingStocksResponse) => {
      if (response && response.data) {
        this.pendingStocks = response.data.map(stock => ({
          ...stock,
          createdOn: new Date(stock.createdOn) // Ensure createdOn is a Date object
        }));
        this.sortStockRequestsByDate();
      }
      this.stockRequestLoading = false;
      this.isDataAvailable = this.pendingStocks.length > 0;
      this.noData = this.pendingStocks.length == 0;
    });

    if (this.isCnf || this.isAdmin) {
      stockService.getPendingFranchiseStocks().subscribe((response: PendingStocksResponse) => {
        if (response && response.data) {
          this.pendingFranchiseStocks = response.data.map(stock => ({
            ...stock,
            createdOn: new Date(stock.createdOn) // Ensure createdOn is a Date object
          }));
          this.sortStockRequestsByDate();
        }
        this.franchiseStockRequestLoading = false;
        this.isFranchiseDataAvailable = this.pendingFranchiseStocks.length > 0;
        this.noData = this.pendingFranchiseStocks.length == 0;
      })
    }
    if (this.isAdmin) {
      stockService.getAllStockRequests(3).subscribe((response => {
        if (response && response.data) {
          this.allFranchiseStockRequests = response.data.map(stock => ({
            ...stock,
            createdOn: new Date(stock.createdOn) // Ensure createdOn is a Date object
          }));
          this.allFranchiseStockPresent = this.allFranchiseStockRequests && this.allFranchiseStockRequests.length > 0;
        }
      }));
      stockService.getAllStockRequests(4).subscribe((response => {
        if (response && response.data) {
          this.allCnfStockRequests = response.data.map(stock => ({
            ...stock,
            createdOn: new Date(stock.createdOn) // Ensure createdOn is a Date object
          }));
          this.allCnfStockPresent = this.allCnfStockRequests && this.allCnfStockRequests.length > 0;
        }
      }));
    }
    
   }

   sortStockRequestsByDate() {
    console.log('Before sorting:', this.pendingStocks);
    this.pendingStocks.sort((a, b) => b.createdOn.getTime() - a.createdOn.getTime());
    console.log('After sorting:', this.pendingStocks);
  }

  ngOnInit(): void {
  }

  viewDetails(stock: Stock) {
    this.selectedStock = stock;
  }

  approve(stock: Stock) {
    var approveReject = new ApproveRejectStockRequest();
    approveReject.stockRequestId = stock.stockRequestId;
    approveReject.isApproved = 1;
    approveReject.adminDisplayId = stock.displayId;
    this.stockService.postApproveReject(approveReject).subscribe((response: any) => {
      if (response && response.data) {
        this.pendingStocks = this.pendingStocks.filter((stock: Stock) => stock.stockRequestId !== approveReject.stockRequestId);
      } else if(response.message){
        Swal.fire('', response.message, 'error');
      }

      this.stockService.getPendingFranchiseStocks().subscribe((response: PendingStocksResponse) => {
        if (response && response.data) {
          this.pendingFranchiseStocks = response.data.map(stock => ({
            ...stock,
            createdOn: new Date(stock.createdOn) // Ensure createdOn is a Date object
          }));
          this.sortStockRequestsByDate();
        }
        this.franchiseStockRequestLoading = false;
        this.isFranchiseDataAvailable = this.pendingFranchiseStocks.length > 0;
        this.noData = this.pendingFranchiseStocks.length == 0;
      })
    });
    
  }

  reject(stock: Stock) {
    var approveReject = new ApproveRejectStockRequest();
    approveReject.stockRequestId = stock.stockRequestId;
    approveReject.isApproved = 2;
    approveReject.adminDisplayId = stock.displayId;
    this.stockService.postApproveReject(approveReject).subscribe((response: any) => {
      if (response && response.message) {
        this.pendingStocks = this.pendingStocks.filter((stock: Stock) => stock.stockRequestId !== approveReject.stockRequestId);
      }
      this.stockService.getPendingFranchiseStocks().subscribe((response: PendingStocksResponse) => {
        if (response && response.data) {
          this.pendingFranchiseStocks = response.data.map(stock => ({
            ...stock,
            createdOn: new Date(stock.createdOn) // Ensure createdOn is a Date object
          }));
          this.sortStockRequestsByDate();
        }
        this.franchiseStockRequestLoading = false;
        this.isFranchiseDataAvailable = this.pendingFranchiseStocks.length > 0;
        this.noData = this.pendingFranchiseStocks.length == 0;
      })
    });
  }

  saveDeliveryStatus(event: Event, stock: Stock) {
    const isChecked = (event.target as HTMLInputElement).checked;
    stock.comments = isChecked ? 'product_delivered' : '';
    this.stockService.updateDeliveryStatus(stock).subscribe((response: any) => {
      if (response && response.data) {
        Swal.fire('', response.data);
      } else if(response.message){
        Swal.fire('', response.message, 'error');
      }

      this.stockService.getPendingFranchiseStocks().subscribe((response: PendingStocksResponse) => {
        if (response && response.data) {
          this.pendingFranchiseStocks = response.data.map(stock => ({
            ...stock,
            createdOn: new Date(stock.createdOn) // Ensure createdOn is a Date object
          }));
          this.sortStockRequestsByDate();
        }
        this.franchiseStockRequestLoading = false;
        this.isFranchiseDataAvailable = this.pendingFranchiseStocks.length > 0;
        this.noData = this.pendingFranchiseStocks.length == 0;
      })
    });
  }

  exportexcel(): void {
    this.excelExportService.exportExcel(
      this.pendingStocks,
      'all stocks'
    );
  }

  isChecked(stock: Stock): boolean {
    return stock.comments === 'product_delivered';
  }

}