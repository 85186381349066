<div class="row btn-page">
    <div class="col-md-6 col-sm-12 mb-4">
        <app-card [cardTitle]="'Welcome franchise - ' + franchiseName + '!'" [options]="false" *ngIf="gridView">
            <div class="card-body text-center py-4">
                <!-- Franchise ID Icon -->
                <i class="fa fa-id-card fa-3x text-success mb-3"></i>
                <p class="mb-2 text-muted "><i class="feather icon-calendar "> </i> {{userInformations?.activeOn?.substring(0,10)}}</p>
                <!-- Franchise Info -->
                <p class="mb-2 fs-5 text-dark">
                    <strong>Your Franchise ID is:</strong> <b class="text-primary">{{ displayId }}</b>
                </p>
                <p class="mb-2 fs-5 text-dark">
                    <strong>Address:</strong> <b class="text-primary">{{ userInformations.address }}</b>
                </p>
                <p class="mb-2 fs-5 text-dark">
                    <strong>Pincode:</strong> <b class="text-primary">{{ userInformations.pinCode }}</b>
                </p>
                <p class="mb-2 fs-5 text-dark">
                    <strong>State:</strong> <b class="text-primary">{{ userInformations.stateName }}</b>
                </p>
                <p class="mb-2 fs-5 text-dark">
                    <strong>Mobile Number:</strong> <b class="text-primary">{{ userInformations.phNo }}</b>
                </p>
                <p class="mb-2 fs-5 text-dark">
                    <strong>Email Id:</strong> <b class="text-primary">{{ userInformations.emailId }}</b>
                </p>

                <!-- Action Buttons -->
                <div class="row text-center buttons mt-3">
                    <div class="col">
                        <button (click)="purchase()" class="mb-0 btn-sm btn-success custom-btn">
                            Purchase
                        </button>
                    </div>
                </div>
    
                
            </div>
        </app-card>
    </div>
    
    
    <div class="col-md-6 col-sm-12 mb-4">
        <app-card cardTitle="Your Wallet Balance" [options]="false" *ngIf="gridView">
            <div class="card-body text-center py-4">
                <i class="fa fa-wallet fa-3x text-success mb-3"></i>
                <h3 class="text-success fw-bold mb-2">
                    ₹{{ walletBalance ? walletBalance.toFixed(2) : '0.00' }}
                </h3>    
                <!-- Conditional Warning Message -->
                <div *ngIf="walletBalance < 100" class="text-danger fw-bold mt-2">
                    Warning: Your wallet balance is low.
                </div>
                <div class="row text-center buttons mt-3">
                    <div class="col">
                        <button (click)="addWallet()" class="mb-0 btn-sm btn-success custom-btn">Add Wallet Balance</button>
                    </div>
                </div>
                <br/>
                <div *ngIf="emptyStockDetails?.length > 0" class="alert alert-warning text-left" role="alert">
                    <h5 class="text-danger fw-bold">
                        <i class="fa fa-exclamation-triangle me-2"></i> The following products are not available in stock:
                    </h5>
                    <p class="mb-2 text-dark">
                        <span *ngFor="let stock of emptyStockDetails; let last = last">
                            <span class="text-primary">{{ stock.itemName }}</span><span *ngIf="!last">, </span>
                        </span>
                    </p>
                    <div class="text-center mt-3">
                        <button (click)="addStock()" class="btn btn-sm btn-success custom-btn">Order Now</button>
                    </div>
                </div>
                
            </div>
        </app-card>
    </div>
</div>


<div class="row">
    <div class="col-sm-4">
        <app-card 
            [hidHeader]="true" 
            cardClass="bg-c-blue text-white widget-visitor-card borderless" 
            blockClass="text-center clickable-card" 
            (click)="navigateToCommissionPage()">
            
            <h6 class="text-white">Current Month Commission</h6>
            <h3>
                ₹{{ currentCommission.toFixed(2) }}
            </h3>
            <i class="feather icon-user-plus"></i>
        </app-card>
    </div>    
    <div class="col-sm-4">
        <app-card [hidHeader]="true" 
        cardClass="bg-c-green text-white widget-visitor-card borderless" 
        blockClass="text-center"
        (click)="navigateToCommissionPage()">
            <h6 class="text-white">Previous Month Commission</h6>
            <h3>
                ₹{{ previousCommmission.toFixed(2) }}
            </h3>
            <i class="feather icon-user-check"></i>
        </app-card>
    </div>
    <div class="col-sm-4">
        <app-card [hidHeader]="true" cardClass="bg-c-yellow text-white widget-visitor-card borderless" blockClass="text-center">
            <h6 class="text-white">Current Month Total sale</h6>
            <h3>
                ₹{{ franchiseSales }}
            </h3>
            <h3>
                
            </h3>
            <i class="feather icon-users"></i>
        </app-card>
    </div>
</div>

<!-- Data Table Section -->
<ng-container *ngIf="!loading; then thenTemplate; else elseTemplate"></ng-container>
<ng-template #thenTemplate>
    <ng-container *ngIf="isDataAvailable; else noDataTemplate">
        <div class="mb-4">
            <div class="col-sm-12">
                <app-card [hidHeader]="true" cardClass="card-datatable shadow-lg rounded">
                    <div class="row align-items-center mb-3 mx-0">
                        <div class="col-sm-6">
                            <h5 class="text-primary fw-bold">Your Available Stock</h5>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table id="excel-table" class="table table-striped table-hover mb-0 table-bordered">
                            <thead class="table-light">
                                <tr>
                                    <th>Item Image</th>
                                    <th>Item Name</th>
                                    <th>Total Stock Quantity</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of allItemsDetailsData; let i=index;">
                                    <td>
                                        <img [src]="item?.imageUrl" class="img-fluid rounded shadow-sm" style="width: 80px; height: auto;">
                                    </td>
                                    <td>{{ item?.name }}</td>
                                    <td>{{ item?.quantity }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </app-card>
            </div>
        </div>
    </ng-container>
</ng-template>

<!-- Loading and No Data Templates -->
<ng-template #elseTemplate>
    <div class="d-flex justify-content-center align-items-center" style="height: 150px;">
        <div class="spinner">
            <h5 class="text-primary mb-3">Loading...</h5>
            <div class="bounce1 bg-primary"></div>
            <div class="bounce2 bg-primary"></div>
            <div class="bounce3 bg-primary"></div>
        </div>
    </div>
</ng-template>

<ng-template #noDataTemplate>
    <div class="text-center py-5">
        <h1 class="text-muted">No Data Available</h1>
    </div>
</ng-template>
